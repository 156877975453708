import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { useAuthService } from 'services/authorization';
import { ToastGlobalStyles, Toasts } from 'services/toast';
import { ThemeProvider } from 'styled-components';
import { History } from './Navigation';
import { Routing } from './Routing';
import { store } from './state/store';
import { GlobalStyle } from './styles/GlobalStyle';
import { Theme, ThemeInterface } from './styles/Theme';
import './translations/configureTranslations';
import './validation/configureValidator';
import 'leaflet/dist/leaflet.css';

interface Props {}

export default function App(props: Props) {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={Theme}>
          <ThemeProvider theme={Theme}>
            <Router history={History}>
              <ToastGlobalStyles />
              <Toasts />
              <GlobalStyle />
              <ConfiguredApp />
            </Router>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}

function ConfiguredApp() {
  const { isInitializationInProgress } = useAuthService();

  if (isInitializationInProgress === true) {
    return <LoadingScreen />;
  }

  return <Routing />;
}
