import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import useOrderCompany from '../../hooks/useOrderCompany';
import { Input } from 'components/ReactHookForm/Input/Input';

// Props type
type Props = {};

// Component
const CompanyFields = (props: Props) => {
  const { t } = useTranslation(['order', 'validationErrors']);
  const company = useOrderCompany();

  const fields: { [fieldName: string]: boolean } = useMemo(
    () => ({
      externalId:
        !!company?.orderSettings?.orderFormFields &&
        company.orderSettings.orderFormFields.indexOf('externalId') !== -1,
    }),
    [company],
  );
  const externalIdRules = useMemo(
    () => ({
      maxLength: { value: 32, message: t('validationErrors:maxSize', { count: 32 }) },
    }),
    [t],
  );

  const foundAny = useMemo(() => Object.keys(fields).some((key) => fields[key]), [fields]);

  return company && foundAny ? (
    <>
      <StyledDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          {fields.externalId && (
            <Input name="externalId" label={t('order:labels.externalId')} rules={externalIdRules} />
          )}
        </Grid>
      </Grid>
    </>
  ) : null;
};

const StyledDivider = styled(Divider)(
  ({ theme }) => `
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
`,
);

export default CompanyFields;
