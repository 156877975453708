import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PA } from 'utlis/State';
import { StockMovementType } from '../components/ReportGenerator/components/Filters/components/AuditStockMovementTypeSelect';

export interface State {
  isGeneratingReport: boolean;
}

const initialState: State = {
  isGeneratingReport: false,
};

const slice = createSlice({
  name: 'reportPage',
  initialState,
  reducers: {
    generateXDelivererActivityReport(
      state,
      _action: PA<ActionTypes.GenerateXDelivererActivityReport>,
    ) {
      state.isGeneratingReport = true;
    },
    generateXDelivererActivityFinished(state) {
      state.isGeneratingReport = false;
    },
    generateXDelivererAvailabilityReport(
      state,
      _action: PA<ActionTypes.GenerateXDelivererAvailabilityReport>,
    ) {
      state.isGeneratingReport = true;
    },
    generateXDelivererAvailabilityReportFinished(state) {
      state.isGeneratingReport = false;
    },
    generateOrderOffersReport(state, _action: PA<ActionTypes.GenerateOrderOffersReport>) {
      state.isGeneratingReport = true;
    },
    generateOrderOffersFinished(state) {
      state.isGeneratingReport = false;
    },
    generateOrdersFullReport(state, _action: PA<ActionTypes.GenerateOrdersFullReport>) {
      state.isGeneratingReport = true;
    },
    generateOrdersFullFinished(state) {
      state.isGeneratingReport = false;
    },
    generateDriversStockReport(state, _action: PA<ActionTypes.GenerateDriversStockReport>) {
      state.isGeneratingReport = true;
    },
    generateDriversStockFinished(state) {
      state.isGeneratingReport = false;
    },
    generateCorrectionsReport(state, _action: PA<ActionTypes.GenerateCorrectionsReport>) {
      state.isGeneratingReport = true;
    },
    generateCorrectionsFinished(state) {
      state.isGeneratingReport = false;
    },
    generateDriversFeedbackReport(state, _action: PA<ActionTypes.GenerateDriversFeedbackReport>) {
      state.isGeneratingReport = true;
    },
    generateDriversFeedbackFinished(state) {
      state.isGeneratingReport = false;
    },
    generateAuditStockMovementReport(
      state,
      _action: PA<ActionTypes.GenerateAuditStockMovementReport>,
    ) {
      state.isGeneratingReport = true;
    },
    generateAuditStockMovementFinished(state) {
      state.isGeneratingReport = false;
    },
    generateStockExchangeReport(state, _action: PA<ActionTypes.GenerateStockExchangeReport>) {
      state.isGeneratingReport = true;
    },
    generateStockExchangeReportFinished(state) {
      state.isGeneratingReport = false;
    },
  },
});

export declare namespace ActionTypes {
  export interface GenerateXDelivererActivityReport {
    dateSince: Date;
    dateTo: Date;
  }
  export interface GenerateXDelivererAvailabilityReport {
    dateSince: Date;
    dateTo: Date;
  }
  export interface GenerateOrderOffersReport {
    dateFrom: Date;
    dateTo: Date;
    region: string | null;
    companyId: string | null;
  }
  export interface GenerateOrdersFullReport {
    dateFrom: Date;
    dateTo: Date;
    region: string | null;
    companyId: string | null;
  }
  export interface GenerateDriversStockReport {
    region: string | null;
    companyId: string | null;
  }
  export interface GenerateCorrectionsReport {
    dateFrom: Date;
    dateTo: Date;
  }
  export interface GenerateDriversFeedbackReport {
    dateFrom: Date;
    dateTo: Date;
  }
  export interface GenerateAuditStockMovementReport {
    dateFrom: Date;
    dateTo: Date;
    reportType: string | null;
    region: string | null;
    companyIds: string[] | null;
  }

  export interface GenerateStockExchangeReport {
    dateFrom: Date;
    dateTo: Date;
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectIsGeneratingReport: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.isGeneratingReport;
    }),
};
