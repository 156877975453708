export interface DayNumbers {
  year: number;
  month: number;
  day: number;
}

export interface WorkingHours {
  from: { hour: number; minute: number };
  to: { hour: number; minute: number };
  zone?: string;
}

export interface DayWorkingHours extends WorkingHours {
  day: DayNumbers;
}

export interface SlotRange {
  from: Date;
  to: Date;
}

export enum WeekDays {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
}
export type WeekDayKey = keyof typeof WeekDays;

export type WeekType<T> = {
  [key in WeekDayKey]: T;
};

export type WeekTypeOptional<T> = {
  [key in WeekDayKey]?: T;
};

export interface WorkingSettings {
  zone: string;
  workingHours?: WorkingHours;
  defaultWorkingHours?: WorkingHours;
  nonStandardWorkingHours?: WeekTypeOptional<WorkingHours>;
  workingDays?: {
    weekDays?: WeekType<boolean>;
    customDates?: DayNumbers[];
  };
}
