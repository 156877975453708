import React from 'react';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import { SecondaryButton } from 'components/Button/Buttons';
import { useState } from 'react';
import { OrderCommentItem } from './components/OrderCommentItem';
import { Comments } from 'pages/Orders/model/Order';
import { AddCommentForm } from './AddCommentForm';
import { Divider } from '@mui/material';

interface Props {
  orderId: string;
  comments: Comments;
}

export function ShowAddCommentAction(props: Props) {
  const { t } = useTranslation('order');
  const [isModalOpen, setModalIsOpen] = useState(false);
  const { list } = props.comments;
  return (
    <>
      <SecondaryButton onClick={() => setModalIsOpen(true)}>
        {t('actions.showComments')}
      </SecondaryButton>
      <Dialog open={isModalOpen} onClose={() => setModalIsOpen(false)} scroll="paper" maxWidth="lg">
        <DialogContent>
          <Box>
            <AddCommentForm orderId={props.orderId} />
            <Divider />
            {(list ?? []).length === 0 && (
              <Typography variant={'h3'}>{t('addComment.commentsEmpty')}</Typography>
            )}
            {list &&
              list.map((comment, index) => (
                <OrderCommentItem commentItem={comment} key={`commentItem-${index}`} />
              ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
  background: ${theme.colors.primary.background};
`,
);
