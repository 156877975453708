import React, { useMemo } from 'react';

import { Input as FormInput } from 'components/ReactHookForm/Input/Input';
import { useTranslation } from 'react-i18next';

// Props type
type Props = {
  fieldGroupName: string;
};

// Component
const ReceiptNoField = ({ fieldGroupName }: Props) => {
  const { t } = useTranslation(['order', 'validationErrors']);

  const rules = useMemo(
    () => ({
      required: t('validationErrors:required'),
      pattern: {
        value: /^\d{4}$/,
        message: t('validationErrors:receiptNoPattern'),
      },
    }),
    [t],
  );

  return (
    <FormInput
      name={`${fieldGroupName}.receiptNo`}
      label={t('labels.productFields.receiptNo')}
      rules={rules}
      size="small"
    />
  );
};

export default ReceiptNoField;
