import { createGlobalStyle } from 'styled-components';
import { ThemeInterface } from './Theme';

export const GlobalStyle = createGlobalStyle<{ theme: ThemeInterface }>`
  *:not(svg):not(svg > *) {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", "Arial", sans-serif;
  }
  
  html, body, #root {
    height: 100%;
    width: 100%;
    color: ${(props) => props.theme.palette.text.primary};
  }
`;
