import OutlinedInput from '@mui/material/OutlinedInput';
import { default as SearchIcon } from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { SelectFilter as Styled } from './styled';

interface Props {
  searchedValue: string;
  onValueChange: (newValue: string) => void;
}

export function SearchInput(props: Props) {
  return (
    <Styled.Box>
      <Input
        value={props.searchedValue}
        onChange={(e) => {
          props.onValueChange(e.target.value);
        }}
        endAdornment={<SearchIcon />}
        data-cy={'input-search'}
        size="small"
      />
    </Styled.Box>
  );
}

const Input = styled(OutlinedInput)(
  ({ theme }) => `
  width: 300px;
  path {
    color: ${theme.colors.primary.icons};
  };
`,
);
