import React from 'react';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import Header from './Header';

// Props type
type Props = {
  children: React.ReactNode;
};

// Component
const Page = ({ children }: Props) => {
  return <Box>{children}</Box>;
};

const Box = styled(MUIBox)(
  ({ theme }) => `
  margin: ${theme.spacing(2)} ${theme.spacing(3)};
`,
);

Page.Header = Header;

export default Page;
