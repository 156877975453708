import { useCallback, useMemo } from 'react';
import { FieldValues, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';
import { useTranslation } from 'app/translations/useTranslation';

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object().shape({
      defaultPackageSize: yup.string().oneOf(['S', 'M', 'L']),
      // selectedProduct: yup.string().required(),
    });
  }, [t]);
}

export const useProductFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    form.reset();
  }, [form]);
};

export function useProductForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<FieldValues>([yupResolver(validationSchema), simpleResolver()]),
    [validationSchema],
  );

  const form = useForm({
    resolver,
    mode: 'onSubmit',
  });

  return form;
}
