import { useCallback, useMemo } from 'react';
import { FieldValues, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';
import { useTranslation } from 'app/translations/useTranslation';

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object().shape({
      phone: yup
        .string()
        .optional()
        .matches(/^(((((\([+]?\d{1,3}\))|[+]\d{1,3})[ ]?)?\d([ ]?\d){8})|-)$/, t('pattern')),
      email: yup.string().required().min(3),
      name: yup.string().required().min(3),
      password: yup
        .string()
        .required()
        .min(7)
        .matches(/^(?=.*[a-zA-Z])(?=.*[0-9]).{7,}$/, t('passwordPattern')),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], t('passwordConfirmation'))
        .required(),
      deliveryProductType: yup.string().optional(),
      city: yup.string().optional(),
      confirmPrivacyPolicy: yup
        .bool()
        .oneOf([true], t('privacyPolicyConfirmed'))
        .required(t('privacyPolicyConfirmed')),
    });
  }, [t]);
}

export const useRegisterFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    form.reset();
  }, [form]);
};

export function useRegisterForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<FieldValues>([yupResolver(validationSchema), simpleResolver()]),
    [validationSchema],
  );

  const form = useForm({
    resolver,
    mode: 'onSubmit',
  });

  return form;
}
