import React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import CartItems from './components/CartItems';
import ProductsList from './components/ProductsList';

// Props type
type Props = {};

// Component
const Cart = (props: Props) => {
  const form = useFormContext();

  const cart = useFieldArray({ control: form.control, name: 'products', shouldUnregister: true });

  const addToCart = (productId: string, payload?: { helperProduct?: boolean }) => {
    cart.append({ productId, helperProduct: payload?.helperProduct ?? false });
  };

  return (
    <Box>
      <ProductsList onAddToCart={addToCart} cartItems={cart.fields} />
      <StyledDivider orientation="vertical" flexItem />
      <CartItems formFieldArray={cart} />
    </Box>
  );
};

const Box = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: ${theme.spacing(1)} 0;
  align-items: flex-start;
`,
);

const StyledDivider = styled(Divider)(
  ({ theme }) => `
  margin-left: ${theme.spacing(2)};
  margin-right: ${theme.spacing(2)};
`,
);

export default Cart;
