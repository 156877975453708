import React from 'react';

import { styled } from '@mui/material/styles';
import { default as MuiModal } from '@mui/material/Modal';
import { useTranslation } from 'app/translations/useTranslation';
import { useWallet } from '../../../../../../state/useWallet';
import { DeleteWalletEntryForm } from './DeleteWalletEntryForm';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  walletEntryId: string;
}

export function DeleteWalletEntryModal(props: Props) {
  const { t } = useTranslation('order');

  return (
    <>
      <Modal open={props.isOpen} onClose={props.onClose}>
        <ModalContentBox>
          <Box>
            <DeleteWalletEntryForm walletEntryId={props.walletEntryId} />
          </Box>
        </ModalContentBox>
      </Modal>
    </>
  );
}

const ModalContentBox = styled('div')`
  height: 80%;
`;

const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled('div')(
  ({ theme }) => `
  width: 800px;
  height: 100%;
  padding: ${theme.spacing(6)};
  background: ${theme.colors.primary.background};
  border-radius: 5px;
`,
);
