import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { AppConfig } from 'app/Config';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { useCompanies } from 'services/companies/state/useCompanies';
import { Controller, useFormContext } from 'react-hook-form';
import useOrderCompany from '../hooks/useOrderCompany';
import { useOrderCreateFormReset } from '../../../hooks/useOrderCreateForm';
import { useAuth } from '../../../../../../services/authorization';

// Props type
type Props = {};

// Component
const CompanySelect = (props: Props) => {
  const { isActivated } = useAuth();
  const { companies } = useCompanies();

  const company = useOrderCompany();

  const { control } = useFormContext();

  const reset = useOrderCreateFormReset();

  useEffect(() => {
    reset();
  }, [company]);

  return (
    <Box style={!isActivated ? { overflow: 'hidden', height: '0px' } : {}}>
      {company?.imageFile && (
        <Image src={`${AppConfig.backendUrl}/static/images/${company.imageFile}`} />
      )}
      <Controller
        name="companyId"
        control={control}
        render={({ field }) => {
          return (
            <LabeledSelect
              id={'select-company'}
              onChange={field.onChange}
              value={field.value}
              key={field.value}
            >
              {companies.map((company) => (
                <MenuItem value={company.id} key={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </LabeledSelect>
          );
        }}
      />
    </Box>
  );
};

const Box = styled(MUIBox)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled('img')(
  ({ theme }) => `
  height: 3rem;
  margin-left: ${theme.spacing(2)};
  margin-right: ${theme.spacing(2)};
`,
);

export default CompanySelect;
