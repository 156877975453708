import { useDispatch, useSelector } from 'react-redux';
import * as Slice from './slice';

export function useAddOrderComment() {
  const dispatch = useDispatch();
  const addOrderCommentStatus = useSelector(Slice.selectors.makeSelectAddOrderCommentStatus());
  return {
    addOrderCommentStatus,
    addOrderComment(params: Slice.ActionTypes.AddOrderComment) {
      dispatch(Slice.actions.addOrderComment(params));
    },
  };
}
