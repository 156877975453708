import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { FieldValues, useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';

export type AddCommentFormValues = {
  comment: string;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(
    () =>
      yup.object().shape({
        comment: yup.string().required(),
      }),
    [t],
  );
}

export const useAddCommentFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    form.setValue('comment', '');
  }, [form]);
};

export function useAddCommentForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<AddCommentFormValues>([
      simpleResolver<AddCommentFormValues>(),
      yupResolver(validationSchema),
    ]),
    [validationSchema],
  );
  const form = useForm<AddCommentFormValues>({
    resolver,
    mode: 'onSubmit',
  });

  return form;
}
