import { useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import { useProducts } from 'services/products/state/useProducts';

export const useOrderType = () => {
  const mainProductId = useWatch({ name: 'products.0.productId' });

  const companyId = useWatch({ name: 'companyId' });
  const { products } = useProducts({ companyId: companyId || undefined });

  return useMemo(
    () => mainProductId && products.find((p) => p.id === mainProductId)?.type,
    [mainProductId, products],
  );
};
