import { default as MuiModal } from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import { SecondaryButton } from 'components/Button/Buttons';
import { useState } from 'react';
import { RouteMap } from './components/RouteMap';

interface Props {
  orderId: string;
}

export function ShowRouteAction(props: Props) {
  const { t } = useTranslation('order');
  const [isModalOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <SecondaryButton onClick={() => setModalIsOpen(true)}>
        {t('actions.showRoute')}
      </SecondaryButton>
      <Modal open={isModalOpen} onClose={() => setModalIsOpen(false)}>
        {/* The div wrapper is required as MuiModal complains on missing allowed props in the Location component */}
        <ModalContentBox>
          <RouteMap orderId={props.orderId} />
        </ModalContentBox>
      </Modal>
    </>
  );
}

const ModalContentBox = styled('div')`
  height: 80%;
`;

const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
