import { eventChannel } from 'redux-saga';
import { call, take, takeLatest, all, put, race } from 'redux-saga/effects';
import { AuthorizedInitSteps, LogoutSteps } from 'services/authorization';
import { HandlerRegister } from './HandlerRegister';
import * as Slice from './slice';
import { SocketManager } from './SocketManager';

type SocketEvent = {
  name: string,
  payload: unknown
}

function createChannel() {
  return eventChannel<SocketEvent>(emitAction => {
    SocketManager.connect();

    SocketManager.onAny(event => {
      emitAction(event);
    });

    return () => {
      SocketManager.disconnect();
    };
  });
}

function* handleSocketConnect() {
  try {
    const sagaChannel = createChannel();

    while (true) {
      const { unsubscribe, event } = yield race({
        unsubscribe: take(Slice.actions.disconnect),
        event: take(sagaChannel),
      });

      if (unsubscribe) {
        yield call(sagaChannel.close);
        break;
      }

      const handlers = HandlerRegister.getForEvent(event.name);
      yield all(
        handlers.map(handler => call(handler, event.payload)),
      );
    }
  } catch (err) {
    console.error(err);
  }
}

function* triggerSocketConnection() {
  yield put(Slice.actions.connect());
}

function* triggerSocketDisconnection() {
  yield put(Slice.actions.disconnect());
}

export function* saga() {
  yield takeLatest(Slice.actions.connect, handleSocketConnect);
  AuthorizedInitSteps.addStep(triggerSocketConnection);
  LogoutSteps.addStep(triggerSocketDisconnection);
}
