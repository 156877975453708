import React from 'react';

import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

import { useTranslation } from 'app/translations/useTranslation';

interface Props {}

export function ListHeaders(props: Props) {
  const { t } = useTranslation('stock');
  return (
    <TableHead>
      <TableRow>
        <TableHeaderCell>{t('list.headers.pendingStock')}</TableHeaderCell>
      </TableRow>
    </TableHead>
  );
}

const TableHeaderCell = styled(TableCell)`
  font-weight: 600;
`;
