import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const ExtendableTableCell = styled(TableCell)`
  padding: 0;
`;

export const TableRowHead = styled(TableRow)``;

export const TableBox = styled('div')(
  ({ theme }) => `
  margin: ${theme.spacing(2.5)};
`,
);
