import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useWarehouseAddStock() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectWarehouseAddStockStatus());

  return {
    warehouseAddStock: (params: Slice.ActionTypes.WarehouseAddStock['data']) => {
      dispatch(Slice.actions.warehouseAddStock({ data: params }));
    },
    isInProgress: status === AsyncStatus.Pending,
  };
}

