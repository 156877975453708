import { put, takeEvery, takeLatest } from 'typed-redux-saga';

import { PA } from 'utlis/State';
import { CompanyApiSaga } from 'api/CompanyApi';
import { AuthorizedInitSteps } from 'services/authorization';

import { actions } from '../slice';
import { CompanyActionTypes } from '../model/CompanyActionTypes';

function* fetchCompanyList() {
  const response = yield* CompanyApiSaga.listCompanies();
  yield put(actions.fetchCompanyListSuccess({ companies: response.companies }));
  for (let company of response.companies) {
    yield put(actions.fetchCompanyDetails({ companyId: company.id }));
  }
}

function* fetchCompanyDetails({ payload }: PA<CompanyActionTypes.fetchCompanyDetails>) {
  const response = yield* CompanyApiSaga.getCompanyDetails(payload.companyId);
  yield put(actions.fetchCompanyDetailsSuccess(response.company));
}

export function* saga() {
  yield* takeLatest(actions.fetchCompanyList, fetchCompanyList);
  yield* takeEvery(actions.fetchCompanyDetails, fetchCompanyDetails);

  yield AuthorizedInitSteps.addStep(fetchCompanyList);
}
