import { DeliveryAvailability as ApiDeliveryAvailability } from 'api/OrderApi';
import { DeliveryAvailability } from '../model/Order';

class Service {
  public mapToDeliveryAvailability(
    deliveryAvailability: ApiDeliveryAvailability,
  ): DeliveryAvailability {
    return {
      available: deliveryAvailability.available,
      price: deliveryAvailability.price,
      estimatedDistance: deliveryAvailability.estimatedDistance,
    };
  }
}

export const ApiDeliveryAvailabilityMapper = new Service();
