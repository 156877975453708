import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'app/translations/useTranslation';
import { XDLogo, LogoVersion } from 'assets/Logo';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import * as Styled from './styled';
import { Divider } from '@mui/material';
import { PrimaryButton } from '../../../../components/Button/Buttons';
import { navigate, AppRoutes } from 'app/Navigation';

interface FormValues {
  username: string;
  password: string;
}

interface Props {
  onSubmit: (data: FormValues) => void;
}

function useValidationSchema() {
  return useMemo(
    () =>
      yup.object().shape({
        username: yup.string().required().min(3),
        password: yup.string().required().min(3),
      }),
    [],
  );
}

export function LoginForm(props: Props) {
  const { t } = useTranslation('login');
  const validationSchema = useValidationSchema();

  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.LogoBox>
          <XDLogo version={LogoVersion.TwoLine} />
        </Styled.LogoBox>
        <Styled.Form onSubmit={form.handleSubmit(props.onSubmit)}>
          <Styled.Input name={'username'} label={t('fields.username')} dataCy={'input-username'} />
          <Styled.Input
            name={'password'}
            label={t('fields.password')}
            type={'password'}
            dataCy={'input-password'}
          />
          <Styled.LoginButton type={'submit'} dataCy={'button-log_in'}>
            {t('buttons.logIn')}
          </Styled.LoginButton>
        </Styled.Form>
      </FormProvider>
      {/*<Divider style={{ margin: '24px 0' }} />
      <PrimaryButton onClick={() => navigate(AppRoutes.register.root())}>
        {t('buttons.register')}
      </PrimaryButton>*/}
    </Styled.Box>
  );
}
