import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { Company } from 'model/Company';

export interface CompanySelectProps {
  selectedCompanyId: string | null;
  onCompanyIdChange: (companyId: string | null) => void;
  availableCompanies: Company[];
}

const notSelectedValue = 'notSelectedValue';

export function CompanySelect(props: CompanySelectProps) {
  const { t } = useTranslation('user');

  return (
    <LabeledSelect
      id={'select-company'}
      onChange={MuiHandlers.Select.onChange((selectedValue) => {
        props.onCompanyIdChange(selectedValue === notSelectedValue ? null : selectedValue);
      })}
      label={t('labels.company')}
      value={props.selectedCompanyId ?? notSelectedValue}
    >
      <MenuItem value={notSelectedValue} key={notSelectedValue}>
        {'---'}
      </MenuItem>
      {props.availableCompanies.map((company) => (
        <MenuItem value={company.id} key={company.id} data-label={company.name}>
          {company.name}
        </MenuItem>
      ))}
    </LabeledSelect>
  );
}
