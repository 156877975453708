import { useTranslation } from 'react-i18next';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useMemo } from 'react';

const useMUIDataTableDefaultOptions = (): // withMultipartSearch: boolean = false,
MUIDataTableOptions => {
  const { t } = useTranslation('common');

  return useMemo(
    () => ({
      print: false,
      download: false,
      filterType: 'multiselect',
      responsive: 'vertical',
      elevation: 0,
      selectableRows: 'none',
      searchAlwaysOpen: true,
      enableNestedDataAccess: '.',
      textLabels: {
        body: {
          noMatch: t('MUIDataTable.body.noMatch'),
          toolTip: t('MUIDataTable.body.toolTip'),
          columnHeaderTooltip: (column: MUIDataTableColumn) =>
            t('MUIDataTable.body.columnHeaderTooltip', { columnLabel: column.label }),
        },
        pagination: {
          next: t('MUIDataTable.pagination.next'),
          previous: t('MUIDataTable.pagination.previous'),
          rowsPerPage: t('MUIDataTable.pagination.rowsPerPage'),
          displayRows: t('MUIDataTable.pagination.displayRows'),
        },
        toolbar: {
          search: t('MUIDataTable.toolbar.search'),
          downloadCsv: t('MUIDataTable.toolbar.downloadCsv'),
          print: t('MUIDataTable.toolbar.print'),
          viewColumns: t('MUIDataTable.toolbar.viewColumns'),
          filterTable: t('MUIDataTable.toolbar.filterTable'),
        },
        filter: {
          all: t('MUIDataTable.filter.all'),
          title: t('MUIDataTable.filter.title'),
          reset: t('MUIDataTable.filter.reset'),
        },
        viewColumns: {
          title: t('MUIDataTable.viewColumns.title'),
          titleAria: t('MUIDataTable.viewColumns.titleAria'),
        },
        selectedRows: {
          text: t('MUIDataTable.selectedRows.text'),
          delete: t('MUIDataTable.selectedRows.delete'),
          deleteAria: t('MUIDataTable.selectedRows.deleteAria'),
        },
      },
    }),
    [t],
  );
};

export default useMUIDataTableDefaultOptions;
