import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';

export type ActivateCompanyFormValues = {
  name: string;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object().shape({
      name: yup.string().required(),
    });
  }, [t]);
}

export const useActivateCompanyFormReset = (formContext?: any) => {
  const context = useFormContext();
  const form = formContext ?? context;

  return useCallback(() => {
    form.setValue('name', undefined);
  }, [form]);
};

export function useActivateCompanyForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<ActivateCompanyFormValues>([
      simpleResolver<ActivateCompanyFormValues>(),
      yupResolver(validationSchema),
    ]),
    [validationSchema],
  );
  const form = useForm<ActivateCompanyFormValues>({
    resolver,
    mode: 'onSubmit',
  });

  return form;
}
