import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import type { Warehouse } from '../Warehouse';
import * as Slice from './slice';

export interface WarehouseListing {
  list: Warehouse[],
  hasError: boolean,
  isLoading: boolean,
}

export function useWarehouses() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Slice.actions.fetchWarehouseList());
  }, [dispatch]);

  const warehouses = useSelector(Slice.selectors.makeSelectWarehouseList());
  const loadingStatus = useSelector(Slice.selectors.makeSelectWarehouseListLoadingStatus());
  const warehouseListing: WarehouseListing = {
    list: warehouses,
    hasError: loadingStatus === AsyncStatus.Error,
    isLoading: [AsyncStatus.Pending, AsyncStatus.NotStarted].includes(loadingStatus),
  };

  return {
    warehouseListing,
    reloadWarehouseListing: () => {
      dispatch(Slice.actions.fetchWarehouseList());
    },
  };
}
