import { styled } from '@mui/material/styles';

export const Box = styled('div')(
  ({ theme }) => `
   display: flex;
   flex-direction: column;
   padding: ${theme.spacing(0.5)};
`,
);

export const ErrorBox = styled('span')(
  ({ theme }) => `
  color: ${theme.palette.error.main};
`,
);
