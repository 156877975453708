import React, { useState } from 'react';
import { useTranslation } from 'app/translations/useTranslation';
import { Input } from 'components/ReactHookForm/Input/Input';
import { FormProvider } from 'react-hook-form';
import * as Styled from './styled';
import { AsyncStatus } from 'utlis/State';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAddOrderComment } from 'pages/Orders/OrderPage/state/useAddOrderComment';
import {
  useAddCommentFormReset,
  useAddCommentForm,
  AddCommentFormValues,
} from './hooks/useAddCommentForm';

interface Props {
  orderId: string;
}

export function AddCommentForm(props: Props) {
  const { t } = useTranslation('order');
  const { addOrderComment, addOrderCommentStatus } = useAddOrderComment();

  const form = useAddCommentForm();
  const reset = useAddCommentFormReset();

  const onSubmit = (formValues: AddCommentFormValues) => {
    addOrderComment({
      comment: formValues.comment,
      orderId: props.orderId,
    });
  };

  const onReset = () => {
    reset();
  };

  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.Form onSubmit={form.handleSubmit(onSubmit)} onReset={onReset}>
          <Styled.DetailsBox>
            <Input
              name={'comment'}
              label={t('addComment.form.fields.comment')}
              dataCy={'input-comment'}
            />
          </Styled.DetailsBox>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={addOrderCommentStatus === AsyncStatus.Pending}
            loading={addOrderCommentStatus === AsyncStatus.Pending}
          >
            {t('addComment.form.submitButton')}
          </LoadingButton>
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
