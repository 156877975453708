import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AsyncStatus, PA } from 'utlis/State';

export interface State {
  changePasswordStatus: AsyncStatus;
  updateCompanyDataStatus: AsyncStatus;
  isDownloadingContract: boolean;
}

const initialState: State = {
  changePasswordStatus: AsyncStatus.NotStarted,
  updateCompanyDataStatus: AsyncStatus.NotStarted,
  isDownloadingContract: false,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changePassword(state, _action: PA<ActionTypes.ChangePassword>) {
      state.changePasswordStatus = AsyncStatus.Pending;
    },
    changePasswordFinish(state) {
      state.changePasswordStatus = AsyncStatus.Success;
    },
    updateCompanyData(state, _action: PA<ActionTypes.UpdateCompanyData>) {
      state.updateCompanyDataStatus = AsyncStatus.Pending;
    },
    updateCompanyDataFinish(state) {
      state.updateCompanyDataStatus = AsyncStatus.Success;
    },
    downloadContract(state) {
      state.isDownloadingContract = true;
    },
    downloadContractFinished(state) {
      state.isDownloadingContract = false;
    },
  },
});

export declare namespace ActionTypes {
  export interface ChangePassword {
    oldPassword: string;
    newPassword: string;
  }

  export interface UpdateCompanyData {
    name: string;
    street: string;
    headquartersIn: string;
    zipCode: string;
    city: string;
    nip: string;
    regon: string;
    firstName: string;
    lastName: string;
  }
  export interface DownloadContract {}
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectChangePasswordStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.changePasswordStatus;
    }),
  makeSelectUpdateCompanyDataStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.updateCompanyDataStatus;
    }),
  makeSelectIsDownloadingContract: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.isDownloadingContract;
    }),
};
