import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Product } from 'model/Product';

const useDimensions = (product: Product, fieldGroupName: string) => {
  const { t } = useTranslation('validationErrors');

  const { getValues } = useFormContext();

  const isAvailable = useMemo(
    () => ({
      width: product.productSettings?.productFormFields?.includes('dimensions.width'),
      height: product.productSettings?.productFormFields?.includes('dimensions.height'),
      length: product.productSettings?.productFormFields?.includes('dimensions.length'),
    }),
    [product],
  );

  const sideRules = useMemo(
    () => ({
      required:
        (!!product.maximumDimensions?.largestSide || !!product.maximumDimensions?.sideSum) &&
        t('validationErrors:required'),
      validate: {
        largestSide: (v: number | '') =>
          v === '' ||
          !product.maximumDimensions?.largestSide ||
          v <= product.maximumDimensions.largestSide ||
          (t('validationErrors:order.largestSideTooLongCm', {
            max: product.maximumDimensions.largestSide,
          }) as string),
        sideSum: (v: number | '') =>
          v === '' ||
          !product.maximumDimensions?.sideSum ||
          (getValues(`${fieldGroupName}.dimensions.width`) || 0) +
            (getValues(`${fieldGroupName}.dimensions.height`) || 0) +
            (getValues(`${fieldGroupName}.dimensions.length`) || 0) <=
            product.maximumDimensions.sideSum ||
          (t('order.sideSumTooLong', {
            max: product.maximumDimensions.sideSum,
          }) as string),
      },
    }),
    [product, getValues, fieldGroupName, t],
  );

  const widthRules = useMemo(
    () => ({
      ...sideRules,
      ...(product.maximumDimensions?.width
        ? {
            max: {
              value: product.maximumDimensions?.width,
              message: t('order.sideSumTooLong', {
                max: product.maximumDimensions.width,
              }) as string,
            },
          }
        : {}),
    }),
    [sideRules, product, t],
  );

  const heightRules = useMemo(
    () => ({
      ...sideRules,
      ...(product.maximumDimensions?.height
        ? {
            max: {
              value: product.maximumDimensions?.height,
              message: t('order.sideSumTooLong', {
                max: product.maximumDimensions.height,
              }) as string,
            },
          }
        : {}),
    }),
    [sideRules, product, t],
  );

  const lengthRules = useMemo(
    () => ({
      ...sideRules,
      ...(product.maximumDimensions?.length
        ? {
            max: {
              value: product.maximumDimensions?.length,
              message: t('validationErrors:order.sideSumTooLong', {
                max: product.maximumDimensions.length,
              }) as string,
            },
          }
        : {}),
    }),
    [sideRules, product, t],
  );

  return {
    sideRules,
    rules: { width: widthRules, height: heightRules, length: lengthRules },
    isAvailable,
  };
};

export default useDimensions;
