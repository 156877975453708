import { AppConfig } from 'app/Config';
import { ResponseType } from 'axios';
import * as Axios from 'axios';
import { omitBy, isUndefined } from 'lodash';
import * as Auth from 'services/authorization';
import { HttpError, isBadRequestResponse, BadRequestError } from './RequestErrors';
import { QueryObject, QueryParser } from './QueryParser';

const company = Axios.default.create({
  baseURL: `${AppConfig.backendUrl}/api`,
  timeout: 20000,
  validateStatus() {
    return true;
  },
});

interface RequestParams {
  endpoint: string;
  query?: QueryObject;
  method: Axios.Method;
  data?: unknown;
  timeout?: number;
  responseType: ResponseType;
}

async function apiRequest<ResponseBody>(params: RequestParams): Promise<ResponseBody> {
  const token = Auth.getToken();
  const headers = omitBy(
    {
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    isUndefined,
  );

  const queryParams = params.query ? QueryParser.stringify(params.query) : null;

  const response = await company.request<ResponseBody>({
    url: `${params.endpoint}${queryParams !== null ? `?${queryParams}` : ''}`,
    method: params.method,
    headers,
    data: params.data,
    responseType: params.responseType,
    timeout: params.timeout,
  });

  throwErrorIfFailure(response);

  return response.data;
}

function throwErrorIfFailure(response: Axios.AxiosResponse) {
  if (response.status >= 400) {
    const data = response.data;
    if (isBadRequestResponse(data)) {
      throw new BadRequestError({
        status: response.status,
        message: response.statusText,
        response: data.error,
      });
    } else {
      throw new HttpError({
        status: response.status,
        message: response.statusText,
        response: data,
      });
    }
  }
}

export type GetFileRequestParams = Pick<RequestParams, 'endpoint' | 'query'>;

export async function getFileRequest(params: GetFileRequestParams) {
  return await apiRequest<Blob>({
    ...params,
    method: 'GET',
    responseType: 'blob',
    timeout: 50000,
  });
}

export type PostRequestParams = Pick<RequestParams, 'endpoint' | 'data'>;

export async function postRequest<ResponseBody = any>(
  params: PostRequestParams,
): Promise<ResponseBody> {
  return await apiRequest({
    ...params,
    method: 'POST',
    responseType: 'json',
  });
}

export type GetRequestParams = Pick<RequestParams, 'endpoint' | 'query'>;

export async function getRequest<ResponseBody = any>(
  params: GetRequestParams,
): Promise<ResponseBody> {
  return await apiRequest({
    ...params,
    method: 'GET',
    responseType: 'json',
  });
}

export type PatchRequestParams = Pick<RequestParams, 'endpoint' | 'data'>;

export async function patchRequest<ResponseBody = any>(
  params: PatchRequestParams,
): Promise<ResponseBody> {
  return await apiRequest({
    ...params,
    method: 'PATCH',
    responseType: 'json',
  });
}

export type DeleteRequestParams = Pick<RequestParams, 'endpoint'>;

export async function deleteRequest<ResponseBody = any>(
  params: DeleteRequestParams,
): Promise<ResponseBody> {
  return await apiRequest({
    ...params,
    method: 'DELETE',
    responseType: 'json',
  });
}

export type PutRequestParams = Pick<RequestParams, 'endpoint' | 'data'>;

export async function putRequest<ResponseBody = any>(
  params: PutRequestParams,
): Promise<ResponseBody> {
  return await apiRequest({
    ...params,
    method: 'PUT',
    responseType: 'json',
  });
}
