import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useAcceptCorrection() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectAcceptCorrectionStatus());

  return {
    acceptCorrection: (params: Slice.ActionTypes.AcceptCorrection) => {
      dispatch(Slice.actions.acceptCorrection(params));
    },
    isInProgress: status === AsyncStatus.Pending,
    status,
  };
}
