import { flow, omitBy } from 'lodash';
import { stringify } from 'query-string';

export type QueryObject = Record<string, unknown>;

class Service {
  public stringify(obj: QueryObject): string {
    return flow(
      obj => omitBy(obj, value => value === undefined || value === null || value === ''),
      obj => stringify(obj, { arrayFormat: 'bracket' }),
    )(obj);
  }
}

export const QueryParser = new Service();
