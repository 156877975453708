import { filter } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { SelectFilter as Styled } from './styled';
import { useDrivers } from 'pages/DriverPage/state/useDrivers';
import FilterableLabeledSelect from '../../../../../components/Form/FilterableLabeledSelect';

interface Props {
  selectedDriverId: string | null;
  omitDriverId: string | null;
  onDriverChange: (clientId: string | null) => void;
  target: 'receivingDriver' | 'givingDriver';
}

const notSelectedValue = 'notSelectedValue';

export function DriverSelect(props: Props) {
  const { driverListing } = useDrivers();
  const { t } = useTranslation('stock');
  return (
    <Styled.Box>
      <FilterableLabeledSelect
        label={t(`labels.${props.target}`)}
        onChange={(selectedValue) =>
          props.onDriverChange(
            selectedValue?.value === notSelectedValue || !selectedValue?.value
              ? null
              : selectedValue.value,
          )
        }
        // @ts-ignore
        listItems={(driverListing.list || [])
          .map(
            (driver) =>
              driver.id !== props.omitDriverId && {
                value: driver.id,
                display: `${driver.firstName} ${driver.lastName} (${driver.email})`,
              },
          )
          .filter((driver) => !!driver)}
      />
    </Styled.Box>
  );
}
