import { useTranslation } from 'app/translations/useTranslation';
import { DateRangeSelect, DateRangePresets } from 'components/DateRangeSelect';
import { Section } from 'components/Section';
import { useState } from 'react';
import { DateRange } from 'utlis/Date';
import { useReports } from 'pages/ReportPage/state/useReports';
import { ActionBox } from '../ActionBox';
import { SettingsBox } from '../styled';
import { RegionSelect } from '../Filters/components/RegionSelect';
import { CompanySelect } from '../Filters/components/CompanySelect';

interface Props {}

export function DriversStockReport(props: Props) {
  const [dateRange, setDateRange] = useState<DateRange>(DateRange.CreateLastMonthRange());
  const [region, setRegion] = useState<string | null>(null);
  const [company, setCompany] = useState<string | null>(null);
  const { t } = useTranslation('report');
  const { isGeneratingReport, generateDriversStockReport } = useReports();

  return (
    <>
      <RegionSelect selectedRegion={region} onRegionChange={setRegion} />
      <CompanySelect selectedCompanyId={company} onCompanyChange={setCompany} />
      <Section.Divider />
      <ActionBox
        onGenerate={() => {
          generateDriversStockReport({
            region,
            companyId: company,
          });
        }}
        isLoading={isGeneratingReport}
      />
    </>
  );
}
