import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import * as Slice from '../../state/slice';
import { ActiveSelect } from './components/ActiveSelect';
import { RegionSelect } from './components/RegionSelect';
import { StatusSelect } from './components/StatusSelect';

interface Props {}

function useFilters() {
  const dispatch = useDispatch();
  const filters = useSelector(Slice.selectors.makeSelectDriverListFilters());

  return {
    filters,
    changeFilters(filters: Slice.ActionTypes.SetFilters) {
      dispatch(Slice.actions.setFilters(filters));
    },
  };
}

export function Filters(props: Props) {
  const { filters, changeFilters } = useFilters();
  return (
    <Box>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <StatusSelect
            selectedStatuses={filters.statuses}
            onStatusSelect={(statuses) => changeFilters({ statuses })}
          />
        </Grid>
        <Grid item>
          <RegionSelect
            onRegionChange={(region) => {
              changeFilters({ region });
            }}
            selectedRegion={filters.region}
          />
        </Grid>
        <Grid item>
          <ActiveSelect
            onStatusSelect={(active) => changeFilters({ active })}
            selectedStatus={filters.active}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
`,
);
