export enum WalletEntryType {
  RideAdded = 'ride_added',
  Correction = 'correction',
  Bonus = 'bonus',
  Ambassador = 'ambassador',
  IqosWeeExchange = 'iqos_wee_exchange',
}

export interface WalletEntry {
  id: string;
  externalId?: string;
  no: string;
  driverId: string;
  type: WalletEntryType;
  payoutDriver: number;
  payoutClient: number;

  currency: string;

  description?: string;

  createdAt: number;
  createdBy: string;
  createdByEmail: string;
  creationComment?: string;

  deletedAt?: number;
  deletedBy?: string;
  deletedByEmail?: string;
  deletionReason?: string;
  deletionComment?: string;
}
