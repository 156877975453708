// import { useTranslation } from 'app/translations/useTranslation';
import { Section } from 'components/Section';
import { ActionBox } from '../ActionBox';

interface Props {}

export function NotSelectedReport(props: Props) {
  // const { t } = useTranslation('report');

  return (
    <>
      <Section.Divider />
      <ActionBox isDisabled={true} />
    </>
  );
}
