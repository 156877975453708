import React, { useEffect, useMemo } from 'react';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';

import { useTranslation } from 'app/translations/useTranslation';
import useMUIDataTableDefaultOptions from 'utlis/MUIDataTables/useMUIDataTableDefaultOptions';

import TableFiltersProvider from 'components/dataTables/filters/TableFiltersProvider';
import TableFiltersToolbar from 'components/dataTables/filters/TableFiltersToolbar';
import { Filters } from '../Filters/Filters';

import { useWallet } from '../../state/useWallet';
import { WalletEntry, WalletEntryType } from '../../model/Wallet';
import useMUIDataTablePersistentExpandedRows from '../../../../utlis/MUIDataTables/useMUIDataTablePersistentExpandedRows';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { WalletEntryDetails } from './components/WalletEntryDetails';

interface Props {}

export function Wallet(props: Props) {
  const { t } = useTranslation('wallet');

  const { walletListing } = useWallet();

  const { onExpand, rowsExpanded } = useMUIDataTablePersistentExpandedRows<WalletEntry>(
    walletListing.list,
    (row: WalletEntry) => row.id,
  );

  const wallet = useMemo(() => {
    return walletListing.list;
  }, [walletListing]);

  const defaultOptions = useMUIDataTableDefaultOptions();
  const options = useMemo<MUIDataTableOptions>(
    () => ({
      ...defaultOptions,
      onRowExpansionChange: onExpand,
      expandableRows: true,
      expandableRowsOnClick: true,
      expandableRowsHeader: false,
      rowsExpanded,
      renderExpandableRow: (rowData, rowMeta) => {
        return (
          <TableRow>
            <TableCell colSpan={rowData.length + 1}>
              <WalletEntryDetails walletEntry={wallet[rowMeta.dataIndex]} />
            </TableCell>
          </TableRow>
        );
      },
      search: false,
      searchOpen: false,
      searchAlwaysOpen: false,
      filter: false,
      viewColumns: false,
      download: true,
      customSearchRender: () => <></>,
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: false,
          useDisplayedRowsOnly: true,
        },
      },
    }),
    [defaultOptions, wallet],
  );

  const columns = useMemo<MUIDataTableColumn[]>(
    () => [
      {
        name: 'no',
        label: t('wallet.labels.no'),
        options: {
          filter: false,
        },
      },
      {
        name: 'type',
        label: t('wallet.labels.type'),
        options: {
          customBodyRender: (type: WalletEntryType) => t(`walletEntry.types.${type}`),
        },
      },
      {
        name: 'payoutDriver',
        label: t('wallet.labels.payoutDriver'),
      },
      {
        name: 'payoutClient',
        label: t('wallet.labels.payoutClient'),
      },
      {
        name: 'createdAt',
        label: t('wallet.labels.createdAt'),
        options: {
          filter: false,
          customBodyRender: (createdAt: number) => {
            const date = new Date(createdAt);
            return (
              <>
                {t('common:datetime.datetime', {
                  date,
                })}
              </>
            );
          },
        },
      },
      {
        name: 'deletedAt',
        label: t('wallet.labels.deletedAt'),
        options: {
          filter: false,
          customBodyRender: (deletedAt: number) => {
            const date = new Date(deletedAt);
            return (
              <>
                {deletedAt
                  ? t('common:datetime.datetime', {
                      date,
                    })
                  : ''}
              </>
            );
          },
        },
      },
    ],
    [t],
  );

  return (
    <TableFiltersProvider filters={<Filters />}>
      <MUIDataTable
        title=""
        data={wallet}
        columns={columns}
        options={options}
        components={{ TableToolbar: TableFiltersToolbar }}
      />
    </TableFiltersProvider>
  );
}
