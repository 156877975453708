import { format } from 'date-fns';
import { useState } from 'react';
import { DateRange } from 'utlis/Date';
import { Calendar } from './components/Calendar';
import { DateRangePreset } from './DateRangePresets';
import * as Styled from './styled';

interface Props {
  label: string,
  selectedDateRange: DateRange,
  onDateRangeSelect: (range: DateRange) => void,
  className?: string,
  dateRangePresets: DateRangePreset[],
  maxDateSpreadInDays: number,
  alignPickerTo: Styled.AlignTo,
}

function formatDateRange(range: DateRange): string {
  const [startDate, endDate] = [range.getStartDate(), range.getEndDate()];
  const formatPattern = 'dd-MM-yyyy';

  return `${format(startDate, formatPattern)} - ${format(endDate, formatPattern)}`;
}

export function DateRangeSelect(props: Props) {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  return (
    <Styled.Box className={props.className}>
      <Styled.Label>
        {props.label}:
      </Styled.Label>
      <Styled.DateRange onClick={() => setIsCalendarOpen(prev => !prev)}>
        {formatDateRange(props.selectedDateRange)}
      </Styled.DateRange>
      {isCalendarOpen && (
        <Styled.CalendarBox alignTo={props.alignPickerTo}>
          <Calendar
            selectedDateRange={props.selectedDateRange}
            onClose={() => setIsCalendarOpen(false)}
            onSelectDateRange={range => {
              props.onDateRangeSelect(range);
              setIsCalendarOpen(false);
            }}
            presets={props.dateRangePresets}
            maxDateSpreadInDays={props.maxDateSpreadInDays}
          />
        </Styled.CalendarBox>
      )}
    </Styled.Box>
  );
}
