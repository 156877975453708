import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { usePermissions } from 'services/authorization/hooks/usePermissions';
import { CreateWalletEntryModal } from '../CreateWalletEntryModal/CreateWalletEntryModal';
import { PrimaryButton } from '../../../../components/Button/Buttons';
import { useTranslation } from '../../../../app/translations/useTranslation';
import { navigate, AppRoutes } from 'app/Navigation';
interface Props {}

export function Actions(props: Props) {
  const { t } = useTranslation('wallet');
  const walletPermissions = usePermissions('wallet');
  const location = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const driverId = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('driverId');
  }, [location]);
  return (
    <>
      {walletPermissions.canAccessPage && driverId && (
        <>
          <PrimaryButton onClick={() => setIsOpen(true)}>
            {t('actions.createWalletEntry')}
          </PrimaryButton>
          <CreateWalletEntryModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            driverId={driverId}
          />
        </>
      )}
      {!driverId && (
        <PrimaryButton onClick={() => navigate(AppRoutes.driver.root())}>
          {t('actions.selectDriver')}
        </PrimaryButton>
      )}
    </>
  );
}
