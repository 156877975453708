import React from 'react';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import MUIDivider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// Props type
type Props = {
  children: React.ReactNode;
  actions?: React.ReactNode;
  divider?: boolean;
};

// Component
const Header = ({ children, actions, divider = true }: Props) => {
  return (
    <Box>
      <HeaderWrapper>
        <Typography variant="h6" component="h2">
          {children}
        </Typography>
        {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
      </HeaderWrapper>
      {divider && <Divider />}
    </Box>
  );
};

const Box = styled(MUIBox)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(2)};
  `,
);

const HeaderWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

const ActionsWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
});

const Divider = styled(MUIDivider)(
  ({ theme }) => `
    margin-top: ${theme.spacing(1)};
  `,
);

export default Header;
