import React from 'react';

import { useTranslation } from 'react-i18next';
import FormSection from 'components/Form/FormSection';
import { AllowedPayment } from 'model/Company';
import EprufCode from './methods/EprufCode';

// Props type
type Props = {
  fieldGroupName: string;
  allowedPayments: AllowedPayment[];
};

// Component
const Payment: React.FC<Props> & {
  useWillRender: (allowedPayments?: AllowedPayment[]) => boolean;
} = ({ fieldGroupName, allowedPayments }) => {
  const { t } = useTranslation(['payment']);

  return Payment.useWillRender(allowedPayments) ? (
    <FormSection title={t('label')} collapsible>
      <EprufCode fieldGroupName={`${fieldGroupName}.paymentDetails`} />
    </FormSection>
  ) : null;
};
Payment.useWillRender = (allowedPayments) => !!allowedPayments?.length;

export default Payment;
