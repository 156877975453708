import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import { SecondaryButton } from 'components/Button/Buttons';
import { useState } from 'react';
import { RenewalItem } from './components/RenewalItem';
import { Renewal } from 'pages/Orders/model/Order';

interface Props {
  renewals: Renewal[];
}

export function ShowRenewalsAction(props: Props) {
  const { t } = useTranslation('order');
  const [isModalOpen, setModalIsOpen] = useState(false);
  const renewals = props.renewals ?? [];
  return (
    <>
      <SecondaryButton onClick={() => setModalIsOpen(true)}>
        {t('actions.showRenewals')}
      </SecondaryButton>
      <Dialog open={isModalOpen} onClose={() => setModalIsOpen(false)} scroll="paper" maxWidth="lg">
        <DialogContent>
          <Box>
            {renewals.map((renewal, index) => (
              <RenewalItem renewal={renewal} key={`renewalItem-${index}`} />
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
  background: ${theme.colors.primary.background};
`,
);
