import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'app/translations/useTranslation';
import * as Slice from '../state/slice';

export function useAuth() {
  const isAuthorized = useSelector(Slice.selectors.makeSelectIsAuthorized());
  const registerStatus = useSelector(Slice.selectors.makeSelectRegisterStatus());
  const registerError = useSelector(Slice.selectors.makeSelectRegisterClientError());
  const auth = useSelector(Slice.selectors.makeSelectAuthorization());
  const registrationSteps = useSelector(Slice.selectors.makeSelectRegistrationSteps());

  const selectProductStatus = useSelector(Slice.selectors.makeSelectSelectProductStatus());

  const addLocationsStatus = useSelector(Slice.selectors.makeSelectAddLocationsStatus());

  const { t } = useTranslation('auth');
  const dispatch = useDispatch();

  return {
    isAuthorized,
    isActivated: !!registrationSteps?.activated || !registrationSteps,
    redirectFromRestrictedZone: () => dispatch(Slice.actions.redirectFromRestrictedZone()),
    redirectToProductSelectPage: () => dispatch(Slice.actions.redirectToProductSelectPage()),
    redirectToLocationsPage: () => dispatch(Slice.actions.redirectToLocationsPage()),
    redirectToMainPage: () => dispatch(Slice.actions.redirectToMainPage()),
    logOut: () =>
      dispatch(
        Slice.actions.logOut({
          message: { id: 'logged_out', content: t('notifications.loggedOut') },
        }),
      ),
    logIn: (params: Slice.ActionTypes.LogIn) => {
      return dispatch(Slice.actions.logIn(params));
    },
    register: (params: Slice.ActionTypes.RegisterClient) => {
      return dispatch(Slice.actions.registerClient(params));
    },
    registrationSteps: auth.registrationSteps,
    registerError,
    registerStatus,
    selectProduct: (params: Slice.ActionTypes.SelectProduct) => {
      return dispatch(Slice.actions.selectProduct(params));
    },
    selectProductStatus,
    displayAccountCreationSuccessMessage:
      !!registrationSteps?.productSelectionCompleted && !registrationSteps?.activated,
    addLocations: (params: Slice.ActionTypes.AddLocations) => {
      return dispatch(Slice.actions.addLocations(params));
    },
    addLocationsStatus,
  };
}
