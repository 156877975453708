import { useTranslation } from 'app/translations/useTranslation';
import { useState } from 'react';
import { DateRange } from 'utlis/Date';
import { useOnKeyUp, KeyboardKeys } from 'utlis/useOnKeyUp';
import { DateRangePreset } from '../../DateRangePresets';
import { CalendarDates } from './CalendarDates';
import { ApplyAction } from './components/ApplyAction';
import { CalendarDateSelector } from './components/CalendarDateSelector';
import * as Styled from './styled';

interface Props {
  selectedDateRange: DateRange,
  onClose: VoidFunction,
  onSelectDateRange: (dateRange: DateRange) => void,
  presets: DateRangePreset[],
  maxDateSpreadInDays: number,
}

export function Calendar(props: Props) {
  const { t } = useTranslation('common');
  useOnKeyUp({ keyboardKey: KeyboardKeys.Esc, callback: props.onClose });

  const [calendarDates, setCalendarDates] = useState<CalendarDates>(new CalendarDates(props.selectedDateRange.toArray()));

  return (
    <Styled.Box>
      <Styled.ContentBox>
        <CalendarDateSelector
          calendarDates={calendarDates}
          onDateSelect={selectedDate => {
            setCalendarDates(prevCalendarDates => {
              return prevCalendarDates.pickNewDate(selectedDate);
            });
          }}
        />
        <Styled.PresetsBox>
          <Styled.PresetHeader>
            {t('dateRange.ranges')}
          </Styled.PresetHeader>
          {props.presets.map(preset => (
            <Styled.Preset
              onClick={() => {
                const dateRange = preset.getDateRange();
                setCalendarDates(new CalendarDates([dateRange.getStartDate(), dateRange.getEndDate()]));
              }}
              key={preset.key}
            >
              {t(`dateRange.preset.${preset.key}`)}
            </Styled.Preset>
          ))}
        </Styled.PresetsBox>
      </Styled.ContentBox>
      <Styled.ActionsBox>
        <Styled.ActionButton onClick={props.onClose}>
          {t('dateRange.cancel')}
        </Styled.ActionButton>
        <ApplyAction
          maxDateSpreadInDays={props.maxDateSpreadInDays}
          calendarDates={calendarDates}
          onApply={props.onSelectDateRange}
        />
      </Styled.ActionsBox>
    </Styled.Box>
  );
}
