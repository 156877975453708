import { DecodedToken } from 'api/AuthApi';
import jwt_decode from 'jwt-decode';
import { Settings } from '../Settings';
import { RegistrationSteps } from '../RegistrationSteps';

class Service {
  public map(token: string): RegistrationSteps {
    const decodedToken = jwt_decode<DecodedToken>(token);
    return {
      productSelectionCompleted: decodedToken.registrationSteps?.productSelectionCompleted
        ? new Date(decodedToken.registrationSteps?.productSelectionCompleted).getTime()
        : undefined,
      locationsCompleted: decodedToken.registrationSteps?.locationsCompleted
        ? new Date(decodedToken.registrationSteps?.locationsCompleted).getTime()
        : undefined,
      companyDataCompleted: decodedToken.registrationSteps?.companyDataCompleted
        ? new Date(decodedToken.registrationSteps?.companyDataCompleted).getTime()
        : undefined,
      contractGenerated: decodedToken.registrationSteps?.contractGenerated
        ? new Date(decodedToken.registrationSteps?.contractGenerated).getTime()
        : undefined,
      activated: !decodedToken.registrationSteps,
    };
  }
}

export const ApiTokenToRegistrationSteps = new Service();
