import { translate } from 'app/translations/useTranslation';
import { takeLeading } from 'redux-saga/effects';
import { addSuccessToast, addErrorToast } from 'services/toast';
import { call, put, select } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { WalletApiSaga } from 'api/WalletApi';
import * as Slice from './slice';
import { ApiWalletMapper } from './ApiWalletMapper';

function* createWalletEntry({ payload }: PA<Slice.ActionTypes.CreateWalletEntry>) {
  try {
    yield* call(WalletApiSaga.createWalletEntry, {
      payoutDriver: payload.payoutDriver,
      payoutClient: payload.payoutClient,
      type: payload.type,
      currency: payload.currency,
      comment: payload.comment,
      driverId: payload.driverId,
    });

    yield call(addSuccessToast, {
      message: translate('wallet', 'notifications.walletEntryCreated'),
    });
    yield getWallet();
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.createWalletEntryFinish());
}

function* getWallet() {
  try {
    const filters = yield* select(Slice.selectors.makeSelectWalletFilters());
    const response = yield* WalletApiSaga.getWallet(filters);
    yield put(
      Slice.actions.getWalletSuccess({
        wallet: response.wallet?.map((walletEntry) =>
          ApiWalletMapper.mapToWalletEntry(walletEntry),
        ),
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.getWalletError());
  }
}

function* deleteWalletEntry({ payload }: PA<Slice.ActionTypes.DeleteWalletEntry>) {
  try {
    yield* call(WalletApiSaga.deleteWalletEntry, {
      walletEntryId: payload.walletEntryId,
      reason: payload.reason,
      comment: payload.comment,
    });

    yield call(addSuccessToast, {
      message: translate('correction', 'notifications.walletEntryDeleted'),
    });
    yield getWallet();
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.deleteWalletEntryFinish());
}

export function* saga() {
  yield takeLeading(Slice.actions.createWalletEntry, createWalletEntry);
  yield takeLeading(Slice.actions.getWallet, getWallet);
  yield takeLeading(Slice.actions.deleteWalletEntry, deleteWalletEntry);
}
