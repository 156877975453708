import React, { useMemo } from 'react';

import { Input as FormInput } from 'components/ReactHookForm/Input/Input';
import { useTranslation } from 'react-i18next';

// Props type
type Props = {
  fieldGroupName: string;
};

// Component
const ExternalNoField = ({ fieldGroupName }: Props) => {
  const { t } = useTranslation(['order', 'validationErrors']);

  const rules = useMemo(
    () => ({
      required: t('validationErrors:required'),
      maxLength: { value: 32, message: t('validationErrors:maxSize', { count: 32 }) },
    }),
    [t],
  );

  return (
    <FormInput
      name={`${fieldGroupName}.externalNo`}
      label={t('labels.productFields.externalNo')}
      rules={rules}
      size="small"
    />
  );
};

export default ExternalNoField;
