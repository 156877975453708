import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PA } from 'utlis/State';
import { Product } from 'model/Product';

export interface State {
  products: Product[];
}

const initialState: State = {
  products: [],
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    fetchProductList(state) {},
    fetchProductListSuccess(state, { payload }: PA<ActionTypes.FetchProductListSuccess>) {
      state.products = payload.products;
    },
  },
});

export declare namespace ActionTypes {
  export interface FetchProductListSuccess {
    products: Product[];
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export interface SelectProductListFilters {
  companyId?: string;
  hideOnOrderForm?: boolean;
}
export const selectors = {
  makeSelectProductList: (filters: SelectProductListFilters) =>
    createSelector(makeSelectDomain(), (state) => {
      return state.products.filter((product) => {
        if (filters.companyId !== undefined && filters.companyId !== product.companyId) {
          return false;
        }

        if (
          filters.hideOnOrderForm !== undefined &&
          !!product.productSettings?.hideOnOrderForm !== filters.hideOnOrderForm
        ) {
          return false;
        }

        return true;
      });
    }),
  makeSelectProduct: (productId: string) =>
    createSelector(makeSelectDomain(), (state) => {
      return state.products.find((product) => product.id === productId);
    }),
};
