import { useTranslation } from 'app/translations/useTranslation';
import { Section } from 'components/Section';
import { ReportGenerator } from './components/ReportGenerator/ReportGenerator';
import { useTypeSelect } from './components/TypeSelect';

interface Props {}

export function ReportPage(props: Props) {
  const { t } = useTranslation('report');
  const { selectedType, TypeSelect, typeSelectProps } = useTypeSelect();

  return (
    <Section.Box>
      <Section.Header>{t('headers.generateReport')}</Section.Header>
      <Section.Divider />
      <TypeSelect {...typeSelectProps} />
      <Section.Divider />
      <ReportGenerator type={selectedType} />
    </Section.Box>
  );
}
