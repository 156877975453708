import { default as MuiTextField } from '@mui/material/TextField';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as Styled from './styled';

type Props = {
  name: string;
  className?: string;
  dataCy?: string;
  defaultValue?: string;
  step?: string;
  transform?: {
    text?: (value: string) => string | false;
    value?: (value: string) => any;
  };
  readOnly?: boolean;
} & React.ComponentProps<typeof MuiTextField> &
  Pick<React.ComponentProps<typeof Controller>, 'rules' | 'shouldUnregister'>;

function ParsedErrorMessage(props: { inputName: string; message: string }) {
  return (
    <Styled.ErrorBox data-cy={`input_error-${props.inputName}`}>
      {props.message.replace(`"${props.inputName}"`, 'Value')}
    </Styled.ErrorBox>
  );
}

export const Input = React.forwardRef<any, Props>(
  (
    {
      name,
      className,
      dataCy,
      defaultValue,
      rules,
      shouldUnregister,
      transform,
      step,
      readOnly,
      ...inputProps
    },
    ref,
  ) => {
    const { control } = useFormContext();

    const [v, setV] = useState<string>(defaultValue || '');

    return (
      <Styled.Box className={className}>
        <Controller
          name={name}
          control={control}
          shouldUnregister={shouldUnregister}
          render={(fieldProps) => {
            const error = fieldProps.fieldState.error;
            const errorMessage = error?.message;
            return (
              <MuiTextField
                {...fieldProps.field}
                onChange={(e) => {
                  const text = transform?.text ? transform.text(e.target.value) : e.target.value;
                  if (text !== false) {
                    setV(text);
                    const value = transform?.value ? transform.value(text) : text;
                    fieldProps.field.onChange(value ?? '');
                  }
                }}
                value={(transform ? v : fieldProps.field.value) || ''}
                error={error !== undefined}
                helperText={
                  errorMessage ? (
                    <ParsedErrorMessage inputName={name} message={errorMessage} />
                  ) : undefined
                }
                data-cy={dataCy}
                InputLabelProps={{
                  shrink: !!fieldProps.field.value || undefined,
                }}
                {...inputProps}
                inputProps={step ? { step, readOnly } : { readOnly }}
              />
            );
          }}
          rules={rules}
          {...(defaultValue ? { defaultValue } : {})}
        />
      </Styled.Box>
    );
  },
);
