import React, { useMemo } from 'react';

import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Styled from './styled';
import LoadingButton from '@mui/lab/LoadingButton';

import { AsyncStatus } from 'utlis/State';
import { useTranslation } from 'app/translations/useTranslation';

import { Input } from 'components/ReactHookForm/Input/Input';

import {
  ChangeOrderDriverFormValues,
  useChangeOrderDriverForm,
  useChangeOrderDriverFormReset,
} from './hooks/useChangeOrderDriverForm';

import { useChangeOrderDriver } from '../../../../../../../../../../state/useChangeOrderDriver';
import DriverSelector from './components/DriverSelector';

interface Props {
  orderId: string;
}

export function ChangeOrderDriverForm(props: Props) {
  const { t } = useTranslation('order');
  const { changeOrderDriver, changeOrderDriverStatus } = useChangeOrderDriver();

  const form = useChangeOrderDriverForm();

  const onSubmit: SubmitHandler<ChangeOrderDriverFormValues> = (formValues) => {
    changeOrderDriver({
      comment: formValues.comment,
      orderId: props.orderId,
      driverId: formValues.driverId,
    });
  };

  const onReset = () => {
    form.reset();
  };

  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.Form
          onSubmit={form.handleSubmit<ChangeOrderDriverFormValues>(onSubmit)}
          onReset={onReset}
        >
          <DriverSelector />
          <Styled.DetailsBox>
            <Input
              name={'comment'}
              label={t('changeOrderDriver.form.fields.comment')}
              dataCy={'input-comment'}
            />
          </Styled.DetailsBox>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={changeOrderDriverStatus === AsyncStatus.Pending}
            loading={changeOrderDriverStatus === AsyncStatus.Pending}
          >
            {t('changeOrderDriver.form.submitButton')}
          </LoadingButton>
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
