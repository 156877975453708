import { selectState } from '../slice';
import { createSelector } from '@reduxjs/toolkit';
import { InProgressState } from 'utlis/State';

export const selectCheckDeliveryAvailabilityState = createSelector(
  selectState,
  (state) => state.checkDeliveryAvailability,
);

export const selectCheckDeliveryAvailabilityResponse = createSelector(
  selectCheckDeliveryAvailabilityState,
  (state) => state.deliveryAvailability,
);

export const selectCheckDeliveryAvailabilityLastQueryParams = createSelector(
  selectCheckDeliveryAvailabilityState,
  (state) => state.lastQueryParams,
);

export const selectCheckDeliveryAvailabilityStatus = createSelector(
  selectCheckDeliveryAvailabilityState,
  (state): InProgressState => state.status,
);
