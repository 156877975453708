import { useDispatch, useSelector } from 'react-redux';
import * as Slice from './slice';

export function useReports() {
  const dispatch = useDispatch();
  const isGeneratingReport = useSelector(Slice.selectors.makeSelectIsGeneratingReport());

  return {
    isGeneratingReport,
    generateXDelivererActivityReport(params: Slice.ActionTypes.GenerateXDelivererActivityReport) {
      dispatch(Slice.actions.generateXDelivererActivityReport(params));
    },
    generateXDelivererAvailabilityReport(
      params: Slice.ActionTypes.GenerateXDelivererAvailabilityReport,
    ) {
      dispatch(Slice.actions.generateXDelivererAvailabilityReport(params));
    },
    generateOrderOffersReport(params: Slice.ActionTypes.GenerateOrderOffersReport) {
      dispatch(Slice.actions.generateOrderOffersReport(params));
    },
    generateOrdersFullReport(params: Slice.ActionTypes.GenerateOrdersFullReport) {
      dispatch(Slice.actions.generateOrdersFullReport(params));
    },
    generateDriversStockReport(params: Slice.ActionTypes.GenerateDriversStockReport) {
      dispatch(Slice.actions.generateDriversStockReport(params));
    },
    generateCorrectionsReport(params: Slice.ActionTypes.GenerateCorrectionsReport) {
      dispatch(Slice.actions.generateCorrectionsReport(params));
    },
    generateDriversFeedbackReport(params: Slice.ActionTypes.GenerateDriversFeedbackReport) {
      dispatch(Slice.actions.generateDriversFeedbackReport(params));
    },
    generateAuditStockMovementReport(params: Slice.ActionTypes.GenerateAuditStockMovementReport) {
      dispatch(Slice.actions.generateAuditStockMovementReport(params));
    },
    generateStockExchangeReport(params: Slice.ActionTypes.GenerateStockExchangeReport) {
      dispatch(Slice.actions.generateStockExchangeReport(params));
    },
  };
}
