import { SimplifiedStockApi, StockExchangeApi } from 'api/StockApi';
import { SimplifiedStock } from '../Stock';
import { StockExchange } from '../StockExchange';

class Service {
  public mapDriverStockApiToDriverStock(stock: SimplifiedStockApi): SimplifiedStock[] {
    const arr = [];
    for (const productId of Object.keys(stock)) {
      if (stock[productId]) {
        arr.push({
          amount: stock[productId].amount,
          name: stock[productId].name,
          productId: stock[productId].productId,
          productCode: stock[productId].productCode,
          companyId: stock[productId].companyId,
          companyImageUrl: stock[productId]?.companyImageUrl,
          companyName: stock[productId]?.companyName,
          imageUrl: stock[productId]?.imageUrl,
          canTransferStock: stock[productId].canTransferStock,
        });
      }
    }
    return arr;
  }

  public mapStockExchangeApiToStockExchange(stock: StockExchangeApi[]): StockExchange[] {
    return stock.map((stockExchange) => {
      return {
        id: stockExchange.id,
        driverId: stockExchange.driverId,
        driverName: stockExchange.driverName,
        givingDriverId: stockExchange.givingDriverId,
        givingDriverName: stockExchange.givingDriverName,
        productId: stockExchange.productId,
        productName: stockExchange.productName,
        amount: stockExchange.amount,
        status: stockExchange.status,
        createdAt: new Date(stockExchange.createdAt).getTime(),
        rejectedAt: stockExchange.rejectedAt
          ? new Date(stockExchange.rejectedAt).getTime()
          : undefined,
        confirmedAt: stockExchange.confirmedAt
          ? new Date(stockExchange.confirmedAt).getTime()
          : undefined,
      };
    });
  }
}

export const ApiStockMapper = new Service();
