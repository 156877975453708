import { put } from 'typed-redux-saga';
import { takeLatest, takeEvery } from 'redux-saga/effects';

import { RegionApiSaga } from 'api/RegionApi';
import { AuthorizedInitSteps } from 'services/authorization';
import * as Slice from './slice';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchRegionList() {
  const response = yield* RegionApiSaga.listRegions({ isActive: true });
  yield put(Slice.actions.fetchRegionListSuccess({ regions: response.list }));
}

function* fetchLocationsList({ payload }: PayloadAction<{ companyId: string }>) {
  const response = yield* RegionApiSaga.listActiveLocationsByCompanyId(payload.companyId);
  yield put(
    Slice.actions.fetchLocationsSuccess({
      companyId: payload.companyId,
      locations: response.list && response.list[0],
    }),
  );
}

export function* saga() {
  yield takeLatest(Slice.actions.fetchRegionList, fetchRegionList);
  yield takeEvery(Slice.actions.fetchLocations, fetchLocationsList);
  AuthorizedInitSteps.addStep(fetchRegionList);
}
