import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import type { Order } from '../../model/Order';
import * as Slice from './slice';

export interface OrderListing {
  list: Order[];
  hasError: boolean;
  isLoading: boolean;
}

export function useOrders() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Slice.actions.fetchOrderList());
  }, [dispatch]);

  const orders = useSelector(Slice.selectors.makeSelectOrderList());
  const loadingStatus = useSelector(Slice.selectors.makeSelectOrderListLoadingStatus());
  const orderListing: OrderListing = {
    list: orders,
    hasError: loadingStatus === AsyncStatus.Error,
    isLoading: [AsyncStatus.Pending, AsyncStatus.NotStarted].includes(loadingStatus),
  };

  return {
    orderListing,
    reloadOrderListing: () => {
      dispatch(Slice.actions.fetchOrderList());
    },
  };
}
