import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { navigate, AppRoutes } from 'app/Navigation';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import Grid from '@mui/material/Grid';

import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { LoadingScreen } from '../../../../components/LoadingScreen/LoadingScreen';
import { AsyncStatus } from '../../../../utlis/State';
import { Select } from 'components/ReactHookForm/Select/Select';
import {
  CreateWalletEntryFormValues,
  useCreateWalletEntryForm,
  useCreateWalletEntryFormReset,
} from './hooks/useCreateWalletEntryForm';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Styled from './styled';
import { Input } from '../../../../components/ReactHookForm/Input/Input';
import { Divider as MuiDivider } from '@mui/material';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import { useCreateWalletEntry } from '../../state/useCreateWalletEntry';
import ImageViewer from 'react-simple-image-viewer';
import { WalletEntryType } from '../../model/Wallet';
import { useDrivers } from '../../../DriverPage/state/useDrivers';
import { Driver } from '../../../DriverPage/Driver';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  driverId: string | null;
}

const getName = (driver?: Driver): string => {
  return (
    (driver?.firstName ?? '') + ' ' + (driver?.lastName ?? '') + ' (' + (driver?.email ?? '-') + ')'
  );
};

export function CreateWalletEntryModal({ isOpen, onClose, driverId }: Props) {
  const { t } = useTranslation('wallet');
  const { create, status } = useCreateWalletEntry();
  const { driverListing } = useDrivers();
  const driver = useMemo(() => {
    return driverListing.list.find((driver) => driver.id == driverId);
  }, [driverListing]);
  const form = useCreateWalletEntryForm();
  const reset = useCreateWalletEntryFormReset(form);
  const onSubmit: SubmitHandler<CreateWalletEntryFormValues> = (formValues) => {
    try {
      if (driverId) {
        create({
          driverId,
          payoutDriver: formValues.payoutDriver,
          ...(formValues.payoutClient && { payoutClient: formValues.payoutClient }),
          currency: formValues.currency,
          comment: formValues.comment,
          type: formValues.type,
        });
        resetAndClose();
      }
    } catch (e) {}
  };

  const resetAndClose = () => {
    reset();
    onClose();
  };

  const payoutDriverValue = form.watch('payoutDriver');

  const isPositive = useMemo(() => {
    return payoutDriverValue > 0 || !payoutDriverValue;
  }, [payoutDriverValue]);
  useEffect(() => {
    if (!driver) {
      navigate(AppRoutes.driver.root());
    }
  }, [driver]);
  return (
    <>
      <Dialog open={isOpen} onClose={resetAndClose} fullWidth maxWidth={'lg'}>
        <DialogTitle>{t('walletEntry.createModal.title', { driver: getName(driver) })}</DialogTitle>
        <FormProvider {...form}>
          <Styled.Form
            onSubmit={form.handleSubmit<CreateWalletEntryFormValues>(onSubmit)}
            onReset={resetAndClose}
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item lg={3}>
                  <RecordLabel>{t('walletEntry.createModal.infoSection.driver')}</RecordLabel>
                  <RecordValue></RecordValue>
                </Grid>
              </Grid>
              <Divider />
              <Grid container item lg={12}>
                <Styled.DetailsBox>
                  <Select
                    id={'type'}
                    name={'type'}
                    label={t('walletEntry.createModal.form.fields.type')}
                    fullWidth
                    values={[
                      {
                        value: WalletEntryType.Bonus,
                        display: t(`walletEntry.types.${WalletEntryType.Bonus}`),
                      },
                      {
                        value: WalletEntryType.Correction,
                        display: t(`walletEntry.types.${WalletEntryType.Correction}`),
                      },
                      {
                        value: WalletEntryType.Ambassador,
                        display: t(`walletEntry.types.${WalletEntryType.Ambassador}`),
                      },
                    ]}
                  />
                </Styled.DetailsBox>
                <Select
                  id={'currency'}
                  name={'currency'}
                  label={t('walletEntry.createModal.form.fields.currency')}
                  fullWidth
                  values={[
                    {
                      value: 'pln',
                      display: 'pln',
                    },
                  ]}
                />
                <Styled.DetailsBox>
                  <Input
                    name={'payoutDriver'}
                    label={t('walletEntry.createModal.form.fields.payoutDriver')}
                    dataCy={'input-payoutDriver'}
                  />
                </Styled.DetailsBox>
                <Styled.DetailsBox>
                  <Input
                    name={'payoutClient'}
                    label={t('walletEntry.createModal.form.fields.payoutClient')}
                    dataCy={'input-payoutClient'}
                  />
                </Styled.DetailsBox>
                <Styled.DetailsBox>
                  <Input
                    name={'comment'}
                    label={t('walletEntry.createModal.form.fields.comment')}
                    dataCy={'input-comment'}
                  />
                </Styled.DetailsBox>
              </Grid>
            </DialogContent>
            <DialogActions>
              {isPositive ? (
                <CreatePositiveLoadingButton
                  type="submit"
                  variant="contained"
                  disabled={status === AsyncStatus.Pending}
                  loading={status === AsyncStatus.Pending}
                >
                  {t('walletEntry.createModal.form.actions.addSubmit')}
                </CreatePositiveLoadingButton>
              ) : (
                <CreateNegativeLoadingButton
                  type="submit"
                  variant="contained"
                  disabled={status === AsyncStatus.Pending}
                  loading={status === AsyncStatus.Pending}
                >
                  {t('walletEntry.createModal.form.actions.subtractSubmit')}
                </CreateNegativeLoadingButton>
              )}
            </DialogActions>
          </Styled.Form>
        </FormProvider>
      </Dialog>
    </>
  );
}

const ModalContentBox = styled('div')`
  height: 80%;
`;

const DialogActions = styled(MuiDialogActions)`
  padding: 20px;
`;

const Dialog = styled(MuiDialog)`
  padding: 20px;
`;

const Divider = styled(MuiDivider)`
  padding-top: 20px;
  margin-bottom: 20px;
`;

const RecordLabel = styled('p')(
  ({ theme }) => `
  font-weight: 'bold';
  `,
);
const RecordValue = styled('p')(
  ({ theme }) => `
  
  `,
);

const CreatePositiveLoadingButton = styled(MuiLoadingButton)(
  ({ theme }) => `
    background: ${theme.colors.experimental.success};
    color: ${theme.colors.experimental.buttonText.success};
    &:hover {
      background: ${theme.colors.experimental.hover.success};
    }
  `,
);
const CreateNegativeLoadingButton = styled(MuiLoadingButton)(
  ({ theme }) => `
    background: ${theme.colors.experimental.error};
    color: ${theme.colors.experimental.buttonText.error};
    &:hover {
      background: ${theme.colors.experimental.hover.error};
    }
  `,
);

const GridItem = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(6)};
  background: ${theme.colors.primary.background};
  border-radius: 5px;
`,
);
