import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useCreateMultiplier() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectCreateMultiplierStatus());

  return {
    createMultiplier: (params: Slice.ActionTypes.CreateMultiplier) => {
      dispatch(Slice.actions.createMultiplier(params));
    },
    isInProgress: status === AsyncStatus.Pending,
    status,
  };
}
