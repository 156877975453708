import React from 'react';

import { styled } from '@mui/material/styles';
import { default as MuiModal } from '@mui/material/Modal';
import { useTranslation } from 'app/translations/useTranslation';
import { ChangeOperatorPasswordForm } from './ChangeOperatorPasswordForm';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  operatorId: string;
}

export function ChangeOperatorPasswordModal(props: Props) {
  const { t } = useTranslation('user');
  return (
    <>
      <Modal open={props.isOpen} onClose={props.onClose}>
        <ModalContentBox>
          <Box>
            <ChangeOperatorPasswordForm operatorId={props.operatorId} onSuccess={props.onClose} />
          </Box>
        </ModalContentBox>
      </Modal>
    </>
  );
}

const ModalContentBox = styled('div')`
  height: auto;
`;

const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled('div')(
  ({ theme }) => `
  width: 800px;
  height: 100%;
  padding: ${theme.spacing(6)};
  background: ${theme.colors.primary.background};
  border-radius: 5px;
`,
);
