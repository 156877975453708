// Core libraries
import React from 'react';

import { Switch } from 'react-router-dom';
import RoleRoute from 'app/components/RoleRoute';
import LinkFactory from './LinkFactory';
import { Redirect } from 'react-router';
import CompaniesListPage from './CompaniesListPage/CompaniesListPage';
import CompanyPage from './CompanyPage/CompanyPage';

// type RoutingType = React.ComponentProps<typeof RoleRoute>;

// Component
const Routing = () => {
  return (
    <Switch>
      <RoleRoute
        roles="company.canAccessPage"
        path={LinkFactory.list()}
        exact
        component={CompaniesListPage}
      />

      <RoleRoute
        roles="company.canAccessPage"
        path={LinkFactory.company()}
        component={CompanyPage}
      />
      {/*<RoleRoute*/}
      {/*  roles="order.canWrite"*/}
      {/*  exact*/}
      {/*  path={LinkFactory.orderCreate()}*/}
      {/*  component={}*/}
      {/*  redirect={LinkFactory.root()}*/}
      {/*/>*/}
      <Redirect to={LinkFactory.list()} />
    </Switch>
  );
};

export default Routing;
