import { useSelector } from 'react-redux';
import * as Slice from './slice';

export function useRegions() {
  const regions = useSelector(Slice.selectors.makeSelectRegionList());

  return {
    regions,
  };
}

