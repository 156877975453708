import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { useRegions } from 'services/regions/state/useRegions';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { SelectFilter as Styled } from './styled';

interface Props {
  selectedRegion: string | null,
  onRegionChange: (region: string | null) => void
}

const notSelectedValue = 'notSelectedValue';

export function RegionSelect(props: Props) {
  const { regions } = useRegions();
  const { t } = useTranslation('order');

  return (
    <Styled.Box>
      <LabeledSelect
        id={'select-region'}
        onChange={MuiHandlers.Select.onChange((selectedValue) => {
          props.onRegionChange(selectedValue === notSelectedValue ? null : selectedValue);
        })}
        label={t('labels.region')}
        value={props.selectedRegion ?? notSelectedValue}
      >
        <MenuItem value={notSelectedValue} key={notSelectedValue}>{'---'}</MenuItem>
        {regions.map(region => <MenuItem value={region.name} key={region.id}>{region.name}</MenuItem>)}
      </LabeledSelect>
    </Styled.Box>
  );
}
