import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MUIBox from '@mui/material/Box';

import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { Location } from 'model/Location';
import { Controller, useFormContext } from 'react-hook-form';
import { useRegions } from '../../../../../../../../../../../../../../services/regions/state/useRegions';
import { useDrivers } from '../../../../../../../../../../../../../DriverPage/state/useDrivers';

// Props type
type Props = {};

// Component
const DriverSelector = (props: Props) => {
  const { t } = useTranslation('order');

  const { regions } = useRegions();
  const {
    driverListing: { list: drivers },
  } = useDrivers();

  const { control, watch, setValue } = useFormContext();

  const selectedDriverId = watch('driverId');

  const [selected, setSelected] = useState<string>('empty');

  const filteredDrivers = useMemo(() => {
    return drivers?.filter((driver) => {
      return selected === 'empty' || driver.region === selected;
    });
  }, [regions, selected]);

  useEffect(() => {
    if (selected && !filteredDrivers?.find((driver) => driver.id === selectedDriverId)) {
      setValue('driverId', undefined);
    }
  }, [filteredDrivers, selected, setSelected]);

  const handleChange = (region: string) => {
    setSelected(region || 'empty');
  };

  return regions && regions.length ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>
          <LabeledSelect
            onChange={(e) => handleChange(e.target.value as string)}
            id="region-selector"
            value={selected || 'empty'}
            size="small"
            fullWidth
            label={t('changeOrderDriver.form.selectRegion')}
          >
            <MenuItem value={'empty'}>-</MenuItem>
            {regions?.map((region) => (
              <MenuItem value={region.name} key={region.id}>
                <Typography variant="inherit" noWrap>
                  {region.name}
                </Typography>
              </MenuItem>
            ))}
          </LabeledSelect>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Controller
            name="driverId"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <LabeledSelect
                  id={'select-driver'}
                  onChange={field.onChange}
                  value={field.value}
                  key={field.value}
                  fullWidth
                  error={fieldState.error}
                  label={t('changeOrderDriver.form.selectDriver')}
                >
                  {filteredDrivers.map((driver) => (
                    <MenuItem value={driver.id} key={driver.id}>
                      {driver.firstName} {driver.lastName} ({driver.email})
                    </MenuItem>
                  ))}
                </LabeledSelect>
              );
            }}
          />
        </Box>
      </Grid>
    </Grid>
  ) : null;
};

const Box = styled(MUIBox)({
  display: 'flex',
});

export default DriverSelector;
