import { createTheme } from '@mui/material/styles';

type Colors = {
  primary: {
    main: string;
    mainDisabled: string;
    mainLight: string;
    border: string;
    borderLight: string;
    icons: string;
    background: string;
    backgroundAccented: string;
    error: string;
    hover: {
      [key: string]: string;
    };
  };
  secondary: {
    main: string;
  };
  experimental: {
    main: string;
    secondary: string;
    error: string;
    light: string;
    success: string;
    buttonText: {
      main: string;
      secondary: string;
      error: string;
      light: string;
      success: string;
    };
    hover: {
      main: string;
      secondary: string;
      error: string;
      light: string;
      success: string;
    };
  };
};

declare module '@mui/material/styles' {
  interface Theme {
    colors: Colors;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: Colors;
  }
}

const mainColor = '#666';
const secondaryColor = '#FF4100';
export const Theme = createTheme({
  palette: {
    primary: {
      main: mainColor,
    },
    text: {
      primary: '#242A3F',
    },
    background: { default: '#eee' },
  },
  colors: {
    primary: {
      main: mainColor,
      mainDisabled: '#959595',
      mainLight: '#bbe4ff',
      border: '#959595',
      borderLight: '#e0e0e0',
      icons: '#959595',
      background: '#ffffff',
      backgroundAccented: '#F6F6F6',
      hover: {
        background: 'rgba(0, 0, 0, 0.12)',
      },
      error: '#de0000',
    },
    secondary: {
      main: secondaryColor,
    },
    experimental: {
      main: '#2B3467',
      secondary: '#BAD7E9',
      error: '#EB455F',
      light: '#FCFFE7',
      success: '#10A19D',
      buttonText: {
        main: '#ffffff',
        secondary: '#000000',
        error: '#ffffff',
        light: '#000000',
        success: '#ffffff',
      },
      hover: {
        main: '#1a224c',
        secondary: '#8fb5cc',
        error: '#d13049',
        light: '#dadfb1',
        success: '#087976',
      },
    },
  },
});

export type ThemeInterface = typeof Theme;
