import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';

import { Button } from 'components/Button/Buttons';
import Medicine from './Medicine';

// Props type
type Props = {
  fieldGroupName: string;
};

// Component
const Medicines: React.FC<Props> = ({ fieldGroupName }) => {
  const { t } = useTranslation('order');
  const form = useFormContext();
  const medicines = useFieldArray({
    control: form.control,
    name: `${fieldGroupName}.medicines`,
    shouldUnregister: true,
  });

  const addMedicine = () => {
    medicines.append({});
  };

  const removeMedicine = (index: number) => {
    medicines.remove(index);
  };

  useEffect(() => {
    if (medicines.fields.length === 0) {
      addMedicine();
    }
  }, [medicines]);

  return (
    <Root>
      {medicines.fields.map((item, index) => (
        <Medicine
          fieldGroupName={`${fieldGroupName}.medicines.${index}`}
          key={item.id}
          index={index}
          onDelete={() => removeMedicine(index)}
        />
      ))}
      <AddButton onClick={addMedicine} size="small">
        {t('labels.medicines.add')}
      </AddButton>
    </Root>
  );
};

const Root = styled(MUIBox)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));

export default Medicines;
