import { createSelector, createSlice } from '@reduxjs/toolkit';
import { PA } from 'utlis/State';
import { Region } from 'model/Region';
import { State } from './model/State';
import * as Reducers from './reducers';

const initialState: State = {
  regions: [],
  locations: {},
};

const slice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    ...Reducers,
    fetchRegionList(_state) {},
    fetchRegionListSuccess(state, { payload }: PA<ActionTypes.FetchRegionListSuccess>) {
      state.regions = payload.regions.sort((regionA, regionB) => {
        return new Intl.Collator().compare(regionA.name, regionB.name);
      });
    },
  },
});

export declare namespace ActionTypes {
  export interface FetchRegionListSuccess {
    regions: Region[];
  }
}

export const { name, actions, reducer } = slice;

export const selectState = (state: any) => state[name] as State;
export const selectors = {
  makeSelectRegionList: () =>
    createSelector(selectState, (state) => {
      return state.regions;
    }),
};
