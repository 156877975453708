import React from 'react';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Props type
type Props = {
  value: string;
  label: string;
  unit?: string;
  tooltip?: string;
};

const Base: React.FC<Omit<Props, 'tooltip'>> = ({ value, label, unit }) => (
  <Box>
    <Label variant="body1">{label}</Label>
    <Value variant="body1">
      {value}
      {unit && <Unit as="span">{unit}</Unit>}
    </Value>
  </Box>
);

// Component
const String: React.FC<Props> = ({ tooltip, ...rest }) => {
  return tooltip ? (
    <Tooltip title={tooltip}>
      <MUIBox>
        <Base {...rest} />
      </MUIBox>
    </Tooltip>
  ) : (
    <Base {...rest} />
  );
};

const Box = styled(MUIBox)(
  ({ theme }) => `
  display: flex;
`,
);

const Label = styled(Typography)(
  ({ theme }) => `
  flex-grow: 1;
  margin-right: ${theme.spacing(2)}
`,
);
const Value = styled(Typography)`
  flex-grow: 1;
  text-align: right;
  font-weight: 500;
`;
const Unit = styled(Typography)(
  ({ theme }) => `
  margin-left: ${theme.spacing(0.5)};
  font-weight: 400;
`,
);

export default String;
