import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AsyncStatus, PA } from 'utlis/State';
import { Multiplier } from '../model/Multiplier';

export interface State {
  createMultiplierStatus: AsyncStatus;
  deactivateMultiplierStatus: AsyncStatus;
  multiplierList: {
    data: Multiplier[];
    status: AsyncStatus;
  };
}

const initialState: State = {
  createMultiplierStatus: AsyncStatus.NotStarted,
  deactivateMultiplierStatus: AsyncStatus.NotStarted,
  multiplierList: {
    data: [],
    status: AsyncStatus.NotStarted,
  },
};

const slice = createSlice({
  name: 'multiplier',
  initialState,
  reducers: {
    createMultiplier(state, _action: PA<ActionTypes.CreateMultiplier>) {
      state.createMultiplierStatus = AsyncStatus.Pending;
    },
    createMultiplierFinish(state) {
      state.createMultiplierStatus = AsyncStatus.Success;
    },
    deactivateMultiplier(state, _action: PA<ActionTypes.DeactivateMultiplier>) {
      state.deactivateMultiplierStatus = AsyncStatus.Pending;
    },
    deactivateMultiplierFinish(state) {
      state.deactivateMultiplierStatus = AsyncStatus.Success;
    },
    fetchMultiplierList(state, { payload }: PA<ActionTypes.FetchMultiplierList>) {
      state.multiplierList.status = AsyncStatus.Pending;
    },
    fetchMultiplierListInBackground(_state) {},
    fetchMultiplierListSuccess(state, { payload }: PA<ActionTypes.FetchMultiplierListSuccess>) {
      state.multiplierList.status = AsyncStatus.Success;
      state.multiplierList.data = payload.multipliers;
    },
    fetchMultiplierListError(state) {
      state.multiplierList.status = AsyncStatus.Error;
    },
  },
});

export declare namespace ActionTypes {
  export interface CreateMultiplier {
    dateFrom: Date;
    dateTo: Date;
    multiplier: number;
    description?: string;
    regions: string[];
    companyIds: string[];
  }
  export interface DeactivateMultiplier {
    multiplierId: string;
    deactivationReason?: string;
  }
  export interface FetchMultiplierList {
    date: Date;
  }
  export interface FetchMultiplierListSuccess {
    multipliers: Multiplier[];
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectCreateMultiplierStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.createMultiplierStatus;
    }),
  makeSelectDeactivateMultiplierStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.createMultiplierStatus;
    }),
  makeSelectMultiplierList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.multiplierList.data;
    }),
  makeSelectMultiplierListStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.multiplierList.status;
    }),
};
