import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import useMUIDataTableDefaultOptions from 'utlis/MUIDataTables/useMUIDataTableDefaultOptions';
import TableFiltersProvider from 'components/dataTables/filters/TableFiltersProvider';
import TableFiltersToolbar from 'components/dataTables/filters/TableFiltersToolbar';

import { useWarehouses } from '../../state/useWarehouses';
import { Filters } from '../Filters/Filters';
import { WarehouseRowDetails } from './components/Row/components/WarehouseRowDetails/WarehouseRowDetails';

interface Props {}

export function WarehouseList(props: Props) {
  const { t } = useTranslation('warehouse');

  const { warehouseListing, reloadWarehouseListing } = useWarehouses();
  const warehouses = useMemo(
    () => warehouseListing?.list.map((warehouse) => warehouse),
    [warehouseListing],
  );

  const defaultOptions = useMUIDataTableDefaultOptions();
  const options = useMemo<MUIDataTableOptions>(
    () => ({
      ...defaultOptions,
      expandableRows: true,
      expandableRowsOnClick: true,
      expandableRowsHeader: false,
      renderExpandableRow: (rowData, rowMeta) => (
        <TableRow>
          <TableCell colSpan={rowData.length + 1}>
            <WarehouseRowDetails warehouse={warehouses[rowMeta.dataIndex]} />
          </TableCell>
        </TableRow>
      ),
      search: false,
      searchOpen: false,
      searchAlwaysOpen: false,
      filter: false,
      viewColumns: false,
      download: true,
      customSearchRender: () => <></>,
    }),
    [defaultOptions, warehouses],
  );

  const columns = useMemo<MUIDataTableColumn[]>(
    () => [
      {
        name: 'owner',
        label: t('list.headers.name'),
      },
      {
        name: 'region',
        label: t('list.headers.region'),
      },
      {
        name: 'totalStockCount',
        label: t('list.headers.totalStockCount'),
      },
    ],
    [t],
  );

  return (
    <TableFiltersProvider filters={<Filters />}>
      <MUIDataTable
        title=""
        data={warehouses}
        columns={columns}
        options={options}
        components={{ TableToolbar: TableFiltersToolbar }}
      />
    </TableFiltersProvider>
  );
}
