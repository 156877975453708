import { useState } from 'react';
import { useCompanies } from 'services/companies/state/useCompanies';
import { CompanySelectProps, CompanySelect } from './CompanySelect';

export function useCompanySelect() {
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
  const { companies } = useCompanies();

  const companySelectProps: CompanySelectProps = {
    selectedCompanyId,
    onCompanyIdChange: setSelectedCompanyId,
    availableCompanies: companies,
  };

  return {
    selectedCompanyId,
    companySelectProps,
    CompanySelect: CompanySelect,
  };
}
