import React from 'react';

import { UseFieldArrayReturn, useFormState } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import RemoveShoppingCart from '@mui/icons-material/RemoveShoppingCart';

import ProductForm from './ProductForm/ProductForm';
import NotificationCard from 'components/Cards/NotificationCard';
import { useTranslation } from 'app/translations/useTranslation';
import { Divider } from '@mui/material';

// Props type
type Props = {
  index: number;
  productItem: {
    id?: string;
    productId?: string;
  };
  formFieldArray: UseFieldArrayReturn;
};

// Component
const CartItem = ({ productItem, index, formFieldArray }: Props) => {
  return (
    <Grid item xs={12} md={6} lg={4} xl={3} key={productItem.id}>
      <ProductForm
        fieldName={`products.${index}`}
        // @ts-ignore
        productId={productItem.productId}
        onDelete={() => formFieldArray.remove(index)}
      />
    </Grid>
  );
};

export default CartItem;
