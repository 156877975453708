import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'app/translations/useTranslation';
import { MiscData } from 'pages/Orders/model/Order';
import * as Styled from '../styled';

interface Props {
  deliveredProducts: MiscData['deliveredProducts'];
}

export function DeliveredProductList(props: Props) {
  const { t } = useTranslation('order');
  return (
    <Styled.Table>
      <TableHead>
        <TableRow>
          <Styled.NameHead>{t('labels.miscData.name')}</Styled.NameHead>
          <Styled.HeadCell>{t('labels.miscData.productCode')}</Styled.HeadCell>
          <Styled.HeadCell>{t('labels.miscData.serialNumber')}</Styled.HeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.deliveredProducts.map((product, index) => (
          <TableRow key={index}>
            <Styled.ContentCell>{product.name}</Styled.ContentCell>
            <Styled.ContentCell>{product.productCode}</Styled.ContentCell>
            <Styled.ContentCell>{product.serialNumber}</Styled.ContentCell>
          </TableRow>
        ))}
      </TableBody>
    </Styled.Table>
  );
}
