import { useMemo } from 'react';
import { resources } from './resources';
import { StoredLanguage } from './StoredLanguage';
import { useTranslation } from './useTranslation';
import { pl, enGB, cs } from 'date-fns/locale';

function getDateLocale(locale: string): Locale {
  switch (locale) {
    case 'pl':
      return pl;
    case 'cz':
      return cs;
    case 'en':
    default:
      return enGB;
  }
}

export function useLanguageManager() {
  const { i18n } = useTranslation();
  const supportedLangs = useMemo(() => Object.keys(resources), []);
  const selectedLang = i18n.languages[0] ?? '';

  const dateLocale = getDateLocale(selectedLang);

  return {
    changeLang(newLang: string) {
      if (supportedLangs.includes(newLang) === false) {
        return;
      }
      i18n.changeLanguage(newLang);
      StoredLanguage.setValue(newLang);
    },
    supportedLangs,
    selectedLang,
    dateLocale,
  };
}
