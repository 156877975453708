import { ApiRequestSaga } from './Request/ApiRequestSaga';
import { ActionTypes } from '../pages/CorrectionPage';
import { PackageSize } from '../pages/Orders/model/Order';

interface ListPendingCorrectionsResponse {
  corrections: PendingCorrection[];
}
type GetCorrectionInfoResponse = Correction;

interface ListPendingCorrectionsParams {
  /*dateFrom: Date;
  dateTo: Date;*/
}

export interface CreateMultiplierParams {
  dateFrom: Date;
  dateTo: Date;
  multiplier: number;
  description?: string;
  regions: string[];
  companyIds: string[];
}

export interface AcceptCorrectionParams {
  correctionId: string;
  amount: number;
  comment?: string;
}
export interface RejectCorrectionParams {
  correctionId: string;
  rejectionReason: string;
  comment?: string;
}

export interface Correction {
  id: string;
  driverId: string;
  driverEmail: string;
  orderId: string;
  orderNo: string;
  createdAt: string;
  status: CorrectionStatus;
  category: CorrectionCategory;
  data: CorrectionData;
  note?: string;
  driversComment?: string;
  // attachments?: CorrectionAttachment[];
  rejectedAt?: string;
  acceptedAt?: string;
  rejectionComment?: string;
  payoutDriver: number;
  estimatedPayoutDriver: number;
  currency: string;
  estimatedDistance: number;
  recalculatedDistance?: number;
  suggestedCorrectionPay?: number;
  packageSize: PackageSize;
  clientsAddress: string;
  attachmentUrl?: string;
}

export interface PendingCorrection {
  companyImageUrl: string;
  id: string;
  orderNo: string;
  createdAt: string;
  driverEmail: string;
  companyName: string;
}

export type CorrectionData = IncorrectDistanceData &
  IncorrectSizeData &
  DifferentDeliveryAddressData &
  ReattemptedDeliveryData;

export enum CorrectionCategory {
  IncorrectDistance = 'incorrect_distance',
  IncorrectSize = 'incorrect_size',
  DifferentDeliveryAddress = 'different_delivery_address',
  ReattemptedDelivery = 'reattempted_delivery',
  Other = 'other',
  IqosWeeExchange = 'iqos_wee_exchange',
}

export enum CorrectionStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Accepted = 'accepted',
}

export interface IncorrectDistanceData {
  extraDistance?: number; // in km
}

export interface IncorrectSizeData {
  actualPackageSize?: PackageSize;
}

export interface DifferentDeliveryAddressData {
  clientAddress?: {
    street: string;
    city: string;
    zipCode: string;
  };
}
export interface ReattemptedDeliveryData {}

export const CorrectionApiSaga = {
  listPendingCorrections: (params: ListPendingCorrectionsParams) => {
    return ApiRequestSaga.Get<ListPendingCorrectionsResponse>({
      endpoint: '/correction/pending-list',
      query: {},
    });
  },
  acceptCorrection(params: AcceptCorrectionParams) {
    return ApiRequestSaga.Post({
      endpoint: '/correction/accept',
      data: {
        correctionId: params.correctionId,
        amount: params.amount,
        comment: params.comment,
      },
    });
  },
  rejectCorrection(params: RejectCorrectionParams) {
    return ApiRequestSaga.Post({
      endpoint: '/correction/reject',
      data: {
        correctionId: params.correctionId,
        rejectionReason: params.rejectionReason,
        comment: params.comment,
      },
    });
  },
  getCorrectionInfo(correctionId: string) {
    return ApiRequestSaga.Get<GetCorrectionInfoResponse>({
      endpoint: '/correction/info',
      query: {
        correctionId,
      },
    });
  },
};
