import { styled } from '@mui/material/styles';
import MUIAppBar from '@mui/material/AppBar';
import MUIIconButton from '@mui/material/IconButton';

import { Menu as MenuComponent } from './components/Menu/Menu';

export const topBarHeight = 48;
export const shrunkMenuWidth = 56;
export const extendedMenuWidth = 240;
export const menuWidthTransition = 'ease 200ms';

export const Box = styled('div')(({ theme }) => ({
  minHeight: '100%',
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'row',
}));

export const LogoBox = styled('div')({
  height: '100%',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: 'auto',
  flexShrink: 1,
});

export const AppBarContainer = styled('div')({
  height: '100%',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
});

export const AccountStatusBox = styled('div')({
  marginRight: 22,
});

export const AccountStatusActive = styled('div')({
  color: '#15ce3e',
});

export const AccountStatusInactive = styled('div')({
  color: '#df615a',
});
export const ContentBox = styled('div')({
  minHeight: '100%',
  paddingTop: topBarHeight,
  paddingBottom: 35,
  flexGrow: 1,
  maxWidth: '100%',
});

export const AppBar = styled(MUIAppBar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  zIndex: theme.zIndex.drawer + 1,
  alignItems: 'center',
  height: theme.spacing(6),
}));

export const IconButton = styled(MUIIconButton)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
}));

export const Menu = styled(MenuComponent)({
  paddingTop: topBarHeight,
});
