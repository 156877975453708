import { useTranslation } from 'app/translations/useTranslation';
import { OrderPickupPoint } from 'pages/Orders/model/Order';
import * as Styled from '../styled';
import { PaymentType } from '../../../../../../../../../../api/OrderApi';

interface Props {
  pickupPoint: OrderPickupPoint;
}

export function PickupPointSubsection(props: Props) {
  const { t } = useTranslation('order');
  return (
    <Styled.Subsection>
      <Styled.Header>{t('labels.pickupPoint')}</Styled.Header>
      {props.pickupPoint.hasHashedSensitiveData === true && (
        <Styled.RecordBox>
          <Styled.RecordValue>{t('list.values.customer')}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.pickupPoint.hasHashedSensitiveData === false && (
        <Styled.RecordBox>
          <Styled.RecordValue>{props.pickupPoint.name}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      <Styled.RecordBox>
        <Styled.RecordValue>{props.pickupPoint.street}</Styled.RecordValue>
      </Styled.RecordBox>
      <Styled.RecordBox>
        <Styled.RecordValue>{props.pickupPoint.city}</Styled.RecordValue>
      </Styled.RecordBox>
      <Styled.RecordBox>
        <Styled.RecordValue>{props.pickupPoint.zipCode}</Styled.RecordValue>
      </Styled.RecordBox>
      {props.pickupPoint.hasHashedSensitiveData === false && (
        <Styled.RecordBox>
          <Styled.RecordValue>{props.pickupPoint.phone}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.pickupPoint.notes && (
        <Styled.RecordBox>
          <Styled.RecordValue>"{props.pickupPoint.notes}"</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.pickupPoint.paymentDetails &&
        !props.pickupPoint?.paymentDetails?.hasHashedSensitiveData && (
          <Styled.RecordBox>
            <Styled.RecordLabel>{t('labels.paymentCode')}:</Styled.RecordLabel>
            <Styled.RecordValue>
              {props.pickupPoint.paymentDetails.paymentType !== PaymentType.BlikManual
                ? props.pickupPoint.paymentDetails.paymentCode ??
                  t('labels.paymentCodeAvailableForDriver')
                : t('labels.paymentCodeNotAvailable')}
            </Styled.RecordValue>
          </Styled.RecordBox>
        )}
      {props.pickupPoint.orderDetails?.discounts && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.discounts')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.pickupPoint.orderDetails.discounts}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
    </Styled.Subsection>
  );
}
