import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';

import { Location } from 'model/Location';
import CardTitled from 'components/Cards/CardTitled';
import { Input } from 'components/ReactHookForm/Input/Input';
import { actions } from '../../../../../../../../state';
import { selectOrderCreateSelectedPickupPoint } from '../../../../../../../../state/selectors';
import useOrderCompany from '../../../../../hooks/useOrderCompany';
import Payment from '../../../../Payment';
import LocationSelector from './LocationSelector';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { LabeledSelect } from '../../../../../../../../../../externals/MaterialUI/LabeledSelect';
import { PackageSize } from '../../../../../../../../model/Order';
import { useSettings } from '../../../../../../../../../../services/authorization/hooks/useSettings';

// Props type
type Props = {
  showLocationsSelect?: boolean;
};

// Component
const PickupPointForm = (props: Props) => {
  const { t } = useTranslation(['order', 'common', 'validationErrors']);
  const dispatch = useDispatch();
  const settings = useSettings();

  const form = useFormContext();

  useEffect(() => {
    return () => form && form.unregister('pickupPoint');
  }, []);

  const company = useOrderCompany();

  const applyLocation = (location?: Location) => {
    dispatch(actions.setSelectedPickupPoint(location));
  };

  const location = useSelector(selectOrderCreateSelectedPickupPoint);
  const lockEdit = useMemo(() => !!location, [location]);

  useEffect(() => {
    return () => {
      dispatch(actions.setSelectedPickupPoint(undefined));
    };
  }, [dispatch]);

  return (
    <CardTitled title={t('labels.pickupPoint')}>
      <Grid container spacing={1}>
        {props.showLocationsSelect && (
          <Grid item xs={12}>
            <LocationSelector
              companyId={company.id}
              onChange={applyLocation}
              defaultSelectedLocationId={settings.defaultLocationId}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Input label={t('common:address.name')} name="pickupPoint.name" disabled={lockEdit} />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t('common:address.streetFull')}
            name="pickupPoint.street"
            disabled={lockEdit}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            label={t('common:address.zipCode')}
            name="pickupPoint.zipCode"
            disabled={lockEdit}
          />
        </Grid>
        <Grid item xs={8}>
          <Input label={t('common:address.city')} name="pickupPoint.city" disabled={lockEdit} />
        </Grid>
        <Grid item xs={12}>
          <Input label={t('common:address.phone')} name="pickupPoint.phone" disabled={lockEdit} />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t('common:address.notes')}
            name="pickupPoint.notes"
            multiline
            minRows="3"
            maxRows="5"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="packageSize"
            control={form.control}
            render={({ field }) => {
              return (
                <LabeledSelect
                  id={'packageSize'}
                  label={t('labels.packageSize')}
                  onChange={field.onChange}
                  value={field.value}
                  defaultValue={company?.orderSettings?.defaultPackageSize ?? PackageSize.Medium}
                  key={field.value}
                >
                  {Object.keys(PackageSize)
                    // @ts-ignore
                    .map((k: string) => PackageSize[k])
                    .map((packageSize) => (
                      <MenuItem value={packageSize} key={packageSize}>
                        <Typography variant="inherit" noWrap>
                          {t(`labels.packageSizes.${packageSize}`)}
                        </Typography>
                      </MenuItem>
                    ))}
                </LabeledSelect>
              );
            }}
          />
        </Grid>
        {Payment.useWillRender(company?.orderSettings.allowedPayments) && (
          <Grid item xs={12}>
            <Payment
              allowedPayments={company?.orderSettings.allowedPayments}
              fieldGroupName="pickupPoint"
            />
          </Grid>
        )}
        {(company?.orderSettings?.orderFormFields ?? []).indexOf(
          'pickupPoint.orderDetails.discounts',
        ) !== -1 && (
          <Grid item xs={12}>
            <Input
              label={t('common:orderDetails.discounts')}
              name="pickupPoint.orderDetails.discounts"
            />
          </Grid>
        )}
      </Grid>
    </CardTitled>
  );
};

export default PickupPointForm;
