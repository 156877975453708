import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useDeleteWalletEntry() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectDeleteWalletEntryStatus());

  return {
    deleteWalletEntry: (params: Slice.ActionTypes.DeleteWalletEntry) => {
      dispatch(Slice.actions.deleteWalletEntry(params));
    },
    isInProgress: status === AsyncStatus.Pending,
    status,
  };
}
