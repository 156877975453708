import { useTranslation } from 'app/translations/useTranslation';
import { Input } from 'components/ReactHookForm/Input/Input';
import React, { useEffect, useState } from 'react';
import * as Styled from './styled';
import { AsyncStatus } from 'utlis/State';
import LoadingButton from '@mui/lab/LoadingButton';
import { useHoldOrder } from '../../../../../../../../../../state/useHoldOrder';

interface Props {
  orderId: string;
}

export function ReleaseOrderAction(props: Props) {
  const { t } = useTranslation('order');
  const { releaseHeldOrder, releaseHeldOrderStatus } = useHoldOrder();

  const onSubmit = () => {
    releaseHeldOrder({
      orderId: props.orderId,
    });
  };
  return (
    <Styled.Box>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        disabled={releaseHeldOrderStatus === AsyncStatus.Pending}
        loading={releaseHeldOrderStatus === AsyncStatus.Pending}
        onClick={onSubmit}
      >
        {t('releaseHeldOrder.form.submitButton')}
      </LoadingButton>
    </Styled.Box>
  );
}
