import { useMemo } from 'react';

export const peselValidator = (message?: string) => (value: string) =>
  (checkPattern(value) && checkChecksum(value) && checkDateOfBirth(value)) || message || false;

const checkPattern = (pesel: string): boolean => /^\d{4}[0-3]\d{6}$/.test(pesel);

const checkChecksum = (pesel: string): boolean => {
  // Classic validation using Luhn algorithm with wages used by MSWiA
  // Based on: http://phpedia.pl/wiki/Walidacja_numeru_PESEL

  const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];

  // Multiply each digit by corresponding weight and add
  const sum = weights.reduce((sum, weight, idx) => sum + weight * Number(pesel[idx]), 0);

  // Calc checksum...
  const checksum = (10 - (sum % 10)) % 10;

  // ...and compare it with the last digit.
  return checksum === Number(pesel[10]);
};

const checkDateOfBirth = (pesel: string): boolean => {
  // Check month
  const monthDigits = Number(pesel.substr(2, 2));
  const month = monthDigits % 20;
  if (!month || month > 12) {
    return false;
  }

  // Calc year flag:
  //   flag 0 - year 1900 - 1999;
  //   flag 1 - added 20, year 2000 - 2099;
  //   flag 2 - added 40, year 2100 - 2199;
  //   flag 3 - added 60, year 2200 = 2299;
  //   flag 4 - added 80, year 1800 - 1899;
  const yearFlag = Math.floor(monthDigits / 20);
  const year = [1900, 2000, 2100, 2200, 1800][yearFlag] + Number(pesel.substr(0, 2));

  //Check day
  const daysInMonth = new Date(year, month, 0).getDate();
  const day = Number(pesel.substr(4, 2));
  return day > 0 && day <= daysInMonth;
};

const usePeselValidator = (message?: string) => {
  return useMemo(() => peselValidator(message), [message]);
};
export default usePeselValidator;
