import { useTranslation } from 'app/translations/useTranslation';
import { Section } from 'components/Section';
import Grid from '@mui/material/Grid';
import { Wallet } from './components/Wallet/Wallet';

import Page from 'components/Page/Page';
import { Card } from '@mui/material';
import { Actions } from './components/ActionRow/Actions';
interface Props {}

export function WalletPage(props: Props) {
  const { t } = useTranslation('wallet');
  return (
    <Page>
      <Page.Header actions={<Actions />}>{t('mainHeader')}</Page.Header>
      <Card style={{ minHeight: 500 }}>
        <Wallet />
      </Card>
    </Page>
  );
}
