import React from 'react';
import { styled } from '@mui/material/styles';

export function Logo(props: { src: string }) {
  return <Image src={props.src} alt={'Logo'} />;
}

const Image = styled('img')`
  max-height: 100%;
  max-width: 100%;
`;
