const pattern = /^[0-9]*([.,][0-9]*)?$/;

const toNumber = {
  text: (value: string) => (pattern.test(value) ? value : false),
  value: (value: string) => {
    const ret = parseFloat(value.replace(/[,]/, '.'));
    return isNaN(ret) ? undefined : ret;
  },
};

export default toNumber;
