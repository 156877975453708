import { styled } from '@mui/material/styles';
import { PrimaryButton } from 'components/Button/Buttons';
import { Input as FormInput } from 'components/ReactHookForm/Input/Input';
import { Checkbox as FormCheckbox } from 'components/ReactHookForm/Checkbox/Checkbox';
import { Divider, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export const Box = styled('div')(
  ({ theme }) => `
  margin-top: ${theme.spacing(5)};
  width: 100%;
  padding: ${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(5)};
 
  border-radius: 3px;
  display: flex;
  flex-direction: column;
`,
);

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
`;

export const FormDivider = styled(Divider)(
  ({ theme }) => `
  margin: ${theme.spacing(2)};
`,
);

export const RegisterButton = styled(LoadingButton)(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)};
`,
);

export const Input = styled(FormInput)(
  ({ theme }) => `
  margin: 0;
  width: 100%;
`,
);

export const Checkbox = styled(FormCheckbox)(
  ({ theme }) => `
  margin: 0;
  width: 100%;
`,
);
export const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;
export const FieldContainer = styled(Grid)`
  width: 100%;
  /*padding: 20px;*/
  display: flex;
  align-items: center;
`;

export const FieldsContainer = styled(Grid)`
  padding: 20px;
  align-items: flex-start;
  /*margin-left: 0;
  margin-top: 0;
  width: 100%;*/
`;
