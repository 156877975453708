import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import useOrderCreate from '../../../../state/hooks/useOrderCreate';
import { useAuth } from '../../../../../../services/authorization';

// Props type
type Props = {};

// Component
const FormActions = (props: Props) => {
  const { t } = useTranslation('order');

  const { inProgressState } = useOrderCreate();

  const { isActivated } = useAuth();

  return (
    <Box>
      <ResetButton type="reset">{t('orderCreate.reset')}</ResetButton>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        disabled={inProgressState.inProgress || !isActivated}
        loading={inProgressState.inProgress}
      >
        {t('orderCreate.submit')}
      </LoadingButton>
    </Box>
  );
};

const Box = styled(MUIBox)(`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`);

const ResetButton = styled(Button)(
  ({ theme }) => `
  margin-right: ${theme.spacing(1)};
`,
);

export default FormActions;
