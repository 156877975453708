import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { FieldValues, useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';

export type OnHoldOrderFormValues = {
  notes?: string;
  holdOrderTill?: Date;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(
    () =>
      yup.object().shape({
        notes: yup.string().optional(),
        holdOrderTill: yup.date().required(t('required')),
      }),
    [t],
  );
}

export const useOnHoldOrderFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    form.setValue('notes', '');
    form.setValue('holdOrderTill', undefined);
  }, [form]);
};

export function useOnHoldOrderForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<FieldValues>([simpleResolver(), yupResolver(validationSchema)]),
    [validationSchema],
  );
  const form = useForm({
    resolver,
    mode: 'onSubmit',
  });

  return form;
}
