import * as EN from './en';
import * as PL from './pl';
import * as CZ from './cz';
/**
 * ADL
 * Right now we won't benefit from separating translation files and downloading them as needed - quite the opposite, it prolongs page initialization.
 * The following solution simplifies the process as the application don't have to display translation loading indicators and we gain type safety.
 */
export const resources = {
  pl: PL,
  en: EN,
  cz: CZ,
};

export type Namespace = keyof typeof resources['en'];
