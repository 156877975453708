import { createSelector } from '@reduxjs/toolkit';
import { selectState } from '../slice';

export const selectCompaniesList = createSelector(selectState, (state) => state.companies);

export const selectFetchCompaniesState = createSelector(
  selectState,
  (state) => state.fetchCompaniesState,
);

export const makeSelectCompany = (companyId: string) =>
  createSelector(selectState, (state) => state.companies.find((c) => c.id === companyId));
