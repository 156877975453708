import { DriverApiSaga } from 'api/DriverApi';
import { call, takeLatest, takeLeading } from 'redux-saga/effects';
import { put, select } from 'typed-redux-saga';
import { ApiDriverMapper } from './ApiDriverMapper';
import * as Slice from './slice';
import { PA } from '../../../utlis/State';
import { addErrorToast, addSuccessToast } from '../../../services/toast';
import { translate } from '../../../app/translations/useTranslation';

function* fetchDriverList() {
  try {
    const filters = yield* select(Slice.selectors.makeSelectDriverListFilters());
    const response = yield* DriverApiSaga.listDrivers({
      status: filters.statuses.length !== 0 ? filters.statuses.join(',') : undefined,
      region: filters.region ?? undefined,
      active: !!filters.active ? JSON.parse(filters.active) : undefined,
    });
    yield put(
      Slice.actions.fetchDriverListSuccess({
        drivers: response.drivers.map((driver) => ApiDriverMapper.mapToDriver(driver)),
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.fetchDriverListError());
  }
}

function* sendNotification({ payload }: PA<Slice.ActionTypes.SendNotification>) {
  try {
    yield* DriverApiSaga.sendNotification(payload.data);
    yield call(addSuccessToast, {
      message: translate('driver', 'notifications.notificationSent'),
    });
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.sendNotificationFinish());
}

function* triggerFetchDriverList() {
  yield put(Slice.actions.fetchDriverList());
}

export function* saga() {
  yield takeLatest(Slice.actions.fetchDriverList, fetchDriverList);
  yield takeLeading(Slice.actions.fetchDriverListInBackground, fetchDriverList);
  yield takeLatest(Slice.actions.setFilters, triggerFetchDriverList);
  yield takeLeading(Slice.actions.sendNotification, sendNotification);
}
