import { useForm } from 'react-hook-form';

export type CreateFormValues = {
  [productId: string]: string;
}

export function useCreateForm() {
  const form = useForm<CreateFormValues>({
    mode: 'onSubmit',
  });

  return form;
}
