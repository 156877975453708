import {
  Operator,
  OmniValue,
  OperatorScope,
  CreateOperatorParams,
  UpdateOperatorParams,
  OperatorCanView,
} from 'api/OperatorApi';
import { uniq } from 'lodash';
import { User, UserPermission, CreateUserParams, UpdateUserParams, UserCanView } from '../User';

class Service {
  public mapOperatorToUser(operator: Operator): User {
    return {
      id: operator.id,
      email: operator.email,
      permissions: this.mapScopesToPermissions(operator.scope),
      assignedCompanies: {
        toAll: operator.companyIds.includes(OmniValue),
        ids: operator.companyIds.filter((companyId) => companyId !== OmniValue),
      },
      assignedRegions: {
        toAll: operator.regions.includes(OmniValue),
        names: operator.regions,
      },
      createdAt: new Date(operator.createdAt),
      accountType: operator.accountType,
      canView: this.mapOperatorCanViewToUserCanView(operator.canView),
    };
  }

  public mapOperatorCanViewToUserCanView(operatorCanView?: OperatorCanView): UserCanView {
    return {
      sensitiveData: !!operatorCanView?.sensitiveData,
      pinCode: !!operatorCanView?.pinCode,
      cancellation: !!operatorCanView?.cancellation,
      holdOrder: !!operatorCanView?.holdOrder,
      orderEstimations: !!operatorCanView?.orderEstimations,
      orderRoute: !!operatorCanView?.orderRoute,
      comments: !!operatorCanView?.comments,
      changeDriver: !!operatorCanView?.changeDriver,
      driverInfo: !!operatorCanView?.driverInfo,
      driverPayout: !!operatorCanView?.driverPayout,
    };
  }

  public mapCreateUserToCreateOperator(params: CreateUserParams): CreateOperatorParams {
    return {
      email: params.email,
      password: params.password,
      companyIds: params.assignedCompanyIds,
      regions: params.assignedRegionNames,
      scope: this.mapPermissionsToScopes(params.permissions),
      accountType: params.accountType,
      canView: params.canView,
    };
  }

  public mapUpdateUserToUpdateOperator(params: UpdateUserParams): UpdateOperatorParams {
    return {
      operatorId: params.id,
      companyIds: params.assignedCompanyIds,
      regions: params.assignedRegionNames,
      scope: this.mapPermissionsToScopes(params.permissions),
      accountType: params.accountType,
      canView: params.canView,
    };
  }

  private mapScopesToPermissions(scopes: OperatorScope[]): UserPermission[] {
    if (scopes.includes(OperatorScope.OmniValue)) {
      return Object.values(UserPermission);
    }

    // FIXME: is this really necessary to map permissions back and forth?
    const permissions: UserPermission[] = [];
    for (const scope of scopes) {
      switch (scope) {
        case OperatorScope.OrdersRead:
          permissions.push(UserPermission.OrdersRead);
          break;
        case OperatorScope.Orders:
          permissions.push(UserPermission.Orders);
          break;
        case OperatorScope.Operators:
          permissions.push(UserPermission.Operators);
          break;
        case OperatorScope.SuperAdmin:
          permissions.push(UserPermission.SuperAdmin);
          break;
        case OperatorScope.Drivers:
          permissions.push(UserPermission.Drivers);
          break;
        case OperatorScope.ReportOffers:
          permissions.push(UserPermission.ReportOffers);
          break;
        case OperatorScope.ReportFull:
          permissions.push(UserPermission.ReportFull);
          break;
        case OperatorScope.ReportDriverActivity:
          permissions.push(UserPermission.ReportDriverActivity);
          break;
        case OperatorScope.ReportDriversStock:
          permissions.push(UserPermission.ReportDriversStock);
          break;
        case OperatorScope.Reports:
          permissions.push(UserPermission.Reports);
          break;
        case OperatorScope.Notifications:
          permissions.push(UserPermission.Notifications);
          break;
        case OperatorScope.Stock:
          permissions.push(UserPermission.Stock);
          break;
        case OperatorScope.Warehouse:
          permissions.push(UserPermission.Warehouse);
          break;
        case OperatorScope.ReportCorrections:
          permissions.push(UserPermission.ReportCorrections);
          break;
        case OperatorScope.ReportDriversFeedback:
          permissions.push(UserPermission.ReportDriversFeedback);
          break;
        case OperatorScope.ReportAuditStockMovement:
          permissions.push(UserPermission.ReportAuditStockMovement);
          break;
        case OperatorScope.Corrections:
          permissions.push(UserPermission.Corrections);
          break;
        case OperatorScope.Wallet:
          permissions.push(UserPermission.Wallet);
          break;
      }
    }

    return uniq(permissions);
  }

  private mapPermissionsToScopes(permissions: UserPermission[]): OperatorScope[] {
    const scopes: OperatorScope[] = [];
    for (const permission of permissions) {
      switch (permission) {
        case UserPermission.OrdersRead:
          scopes.push(OperatorScope.OrdersRead);
          break;
        case UserPermission.Orders:
          scopes.push(OperatorScope.Orders);
          break;
        case UserPermission.Operators:
          scopes.push(OperatorScope.Operators);
          break;
        case UserPermission.SuperAdmin:
          scopes.push(OperatorScope.SuperAdmin);
          break;
        case UserPermission.Drivers:
          scopes.push(OperatorScope.Drivers);
          break;
        case UserPermission.ReportOffers:
          scopes.push(OperatorScope.ReportOffers);
          break;
        case UserPermission.ReportFull:
          scopes.push(OperatorScope.ReportFull);
          break;
        case UserPermission.ReportDriverActivity:
          scopes.push(OperatorScope.ReportDriverActivity);
          break;
        case UserPermission.ReportDriversStock:
          scopes.push(OperatorScope.ReportDriversStock);
          break;
        case UserPermission.Reports:
          scopes.push(OperatorScope.Reports);
          break;
        case UserPermission.Notifications:
          scopes.push(OperatorScope.Notifications);
          break;
        case UserPermission.Stock:
          scopes.push(OperatorScope.Stock);
          break;
        case UserPermission.Warehouse:
          scopes.push(OperatorScope.Warehouse);
          break;
        case UserPermission.ReportCorrections:
          scopes.push(OperatorScope.ReportCorrections);
          break;
        case UserPermission.ReportDriversFeedback:
          scopes.push(OperatorScope.ReportDriversFeedback);
          break;
        case UserPermission.ReportAuditStockMovement:
          scopes.push(OperatorScope.ReportAuditStockMovement);
          break;
        case UserPermission.Corrections:
          scopes.push(OperatorScope.Corrections);
          break;
        case UserPermission.Wallet:
          scopes.push(OperatorScope.Wallet);
          break;
      }
    }

    return uniq(scopes);
  }
}

export const ApiOperatorACL = new Service();
