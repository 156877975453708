import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';

// Props type
type Props = {
  title?: React.ReactNode;
  collapsible?: boolean;
  collapsed?: boolean;
  onCollapseChange?: (collapsed: boolean) => void;
};

// Component
const FormSection: React.FC<Props> = ({
  title,
  children,
  collapsible,
  collapsed,
  onCollapseChange,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(!!collapsed);

  useEffect(() => {
    setIsCollapsed(!!collapsed);
  }, [collapsed]);

  return (
    <Root>
      {!!title && (
        <Header>
          <Title>{title}</Title>
          {collapsible && (
            <Switch
              checked={!isCollapsed}
              onChange={(event) =>
                onCollapseChange
                  ? onCollapseChange(!event.target.checked)
                  : setIsCollapsed(!event.target.checked)
              }
            />
          )}
        </Header>
      )}
      {!!title && (!collapsible || !isCollapsed) && <Divider />}
      {(!collapsible || !isCollapsed) && <Content>{children}</Content>}
    </Root>
  );
};

const Root = styled(MUIBox)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${theme.palette.divider}`,
}));

const Header = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Title = styled(MUIBox)(({ theme }) => ({
  margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

const Content = styled(MUIBox)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export default FormSection;
