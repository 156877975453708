import { SimpleGenerator } from 'api/Request/ApiRequestSaga';

type Step = () => SimpleGenerator<any>;
class StepRegister {
  private steps: Step[] = [];

  public addStep(step: Step) {
    this.steps.push(step);
  }

  public* execute(): SimpleGenerator<void> {
    for (const step of this.steps) {
      yield step();
    }
  }
}

export const LogoutSteps = new StepRegister();
export const AuthorizedInitSteps = new StepRegister();
