import React, { ReactElement, useMemo, useState } from 'react';

import { Path } from 'history';
import { useLocation } from 'react-router';

import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Badge from '@mui/material/Badge';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { navigate } from 'app/Navigation';

interface Props {
  content: ReactElement | string;
  icon: ReactElement;
  onSelect?: VoidFunction;
  dataCy?: string;
  children?: React.ReactNode;
  redirectTo?: Path;
  disabled?: boolean;
  badge?: number;
}

export function MenuItem(props: Props) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { pathname } = useLocation();

  const onClick = () => {
    if (props.redirectTo) {
      navigate(props.redirectTo);
    } else if (props.children) {
      setExpanded(!expanded);
    }

    props.onSelect && props.onSelect();
  };

  const selected = useMemo(() => {
    if (!props.redirectTo) {
      return false;
    }
    const currentPath = pathname.split('/');
    const expectedPath = props.redirectTo.split('/');

    if (expectedPath.length > currentPath.length) {
      return false;
    }

    for (let i = 0; i < expectedPath.length; i++) {
      if (expectedPath[i] !== currentPath[i]) {
        return false;
      }
    }
    return true;
  }, [pathname, props.redirectTo]);

  return (
    <div>
      <Tooltip arrow title={props.content} placement="right">
        <ListItem disablePadding>
          <ListItemButton
            component="span"
            onClick={onClick}
            data-cy={props.dataCy}
            selected={selected}
            disabled={props.disabled}
          >
            {!!props.icon && (
              <ListItemIcon>
                <Badge badgeContent={props.badge} color={'warning'}>
                  {props.icon}
                </Badge>
              </ListItemIcon>
            )}
            <ListItemText>{props.content}</ListItemText>
            {/*{!!props.badge && (
              <ListItemSecondaryAction>
                <Badge color={'error'}>{props.badge}</Badge>
              </ListItemSecondaryAction>
            )}*/}
          </ListItemButton>
        </ListItem>
      </Tooltip>
      {(selected || expanded) && !!props.children && (
        <ChildrenWrapper>
          {props.children}
          <Divider />
        </ChildrenWrapper>
      )}
    </div>
  );
}

const ChildrenWrapper = styled(Box)(({ theme }) => ({
  boxShadow:
    'rgba(0, 0, 0, 0.5) 0px 5px 3px -5px inset, rgba(0, 0, 0, 0.3) 0px -6px 3px -5px inset',
}));
