import React, { useEffect, useMemo, useCallback, useState } from 'react';

import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useTranslation } from 'app/translations/useTranslation';
import { Calendar as ReactCalendar, momentLocalizer } from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useMultipliers } from '../../state/useMultipliers';
import { Multiplier } from '../../model/Multiplier';
import { useCreateMultiplier } from '../../state/useCreateMultiplier';
import { useCreateMultiplierForm } from '../CreateMultiplier/hooks/useCreateMultiplierForm';
import { ShowMultiplierDetailsModal } from './components/ShowMultiplierDetailsModal';

const localizer = momentLocalizer(moment);

interface Props {}

export function MultiplierCalendar(props: Props) {
  const { t } = useTranslation('multiplierCalendar');
  const [calendarDate, setCalendarDate] = useState<Date>(new Date());
  const [selectedMultiplier, setSelectedMultiplier] = useState<Multiplier | null>(null);
  const multiplier = useMultipliers();

  const multipliers = useMemo(
    () =>
      multiplier.multiplierListing.list?.map((multiplier: Multiplier) => ({
        id: multiplier.id,
        title: `${multiplier.multiplier}x`,
        start: new Date(multiplier.dateFrom),
        end: new Date(multiplier.dateTo),
      })),
    [multiplier.multiplierListing],
  );

  const { isInProgress } = useCreateMultiplier();

  useEffect(() => {
    if (!isInProgress) {
      multiplier.reloadMultiplierList(calendarDate);
    }
  }, [isInProgress, calendarDate]);

  const { defaultDate } = useMemo(
    () => ({
      defaultDate: new Date(),
    }),
    [],
  );

  const handleSelectEvent = useCallback(
    (event) => {
      if (event) {
        const multiplierDetails = multiplier.multiplierListing.list?.find(
          (multiplier) => multiplier.id === event.id,
        );
        if (multiplierDetails) {
          setSelectedMultiplier(multiplierDetails);
        }
      }
    },
    [multiplier.multiplierListing],
  );

  const onNavigate = useCallback((newDate) => setCalendarDate(newDate), []);

  return (
    <div>
      <ReactCalendar
        localizer={localizer}
        events={multipliers}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        defaultDate={defaultDate}
        views={['day', 'month', 'week']}
        onNavigate={onNavigate}
        onSelectEvent={handleSelectEvent}
      />
      <ShowMultiplierDetailsModal
        multiplier={selectedMultiplier}
        onClose={() => setSelectedMultiplier(null)}
      />
    </div>
  );
}
