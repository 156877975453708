import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';
import { WalletEntry } from '../model/Wallet';
import { useLocation } from 'react-router-dom';

export interface WalletListing {
  list: WalletEntry[];
  hasError: boolean;
  isLoading: boolean;
}

export function useWallet() {
  const dispatch = useDispatch();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const filters = useSelector(Slice.selectors.makeSelectWalletFilters());
  const query = useQuery();
  const driverId = useMemo(() => {
    return query.get('driverId') ?? undefined;
  }, []);

  useEffect(() => {
    dispatch(Slice.actions.setWalletDriverId({ driverId }));
  }, [dispatch, driverId]);

  useEffect(() => {
    dispatch(Slice.actions.getWallet());
  }, [dispatch, filters]);

  const wallet = useSelector(Slice.selectors.makeSelectWallet());
  const loadingStatus = useSelector(Slice.selectors.makeSelectWalletStatus());

  const walletListing: WalletListing = {
    list: wallet,
    hasError: loadingStatus === AsyncStatus.Error,
    isLoading: [AsyncStatus.Pending, AsyncStatus.NotStarted].includes(loadingStatus),
  };

  return {
    walletListing,
    reloadWallet: () => {
      dispatch(Slice.actions.getWallet());
    },
    filters: filters,
    setFilters: (filters: { dateFrom: Date; dateTo: Date; driverId?: string }) =>
      dispatch(Slice.actions.setWalletFilters(filters)),
    getWalletEntry: (walletEntryId: string) =>
      wallet?.find((walletEntry) => walletEntry.id === walletEntryId),
  };
}
