import { styled } from '@mui/material/styles';
import { Button } from 'components/Button/Buttons';

export const Box = styled('div')(
  ({ theme }) => `
  display: grid;
  margin-bottom: ${theme.spacing(2.5)};
  font-size: 14px;
  padding: ${theme.spacing(3)};
  border-left: solid 2px ${theme.colors.primary.main};
`,
);

export const Header = styled('p')(
  ({ theme }) => `
  font-size: 14px;
  padding-bottom: ${theme.spacing(2)};
`,
);

export const HeaderOnHold = styled('p')(
  ({ theme }) => `
  font-size: 15px;
  padding-bottom: ${theme.spacing(2)};
  color: ${theme.colors.secondary.main};
`,
);

export const RecordBox = styled('div')(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(1)};
`,
);

export const RecordLabel = styled('span')(
  ({ theme }) => `
  font-weight: normal;
  padding-right: ${theme.spacing(0.5)};
`,
);

export const RecordValue = styled('span')`
  font-weight: lighter;
`;

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
`;

export const DetailsBox = styled('div')(
  ({ theme }) => `
  max-width: 700px;
  margin-bottom: ${theme.spacing(3)};
`,
);

export const SubmitButton = styled(Button)`
  width: 200px;
  align-self: end;
`;
