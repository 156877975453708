import React from 'react';

import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { useTranslation } from 'app/translations/useTranslation';
import { Loader } from 'assets/Loader/Loader';
import { SthWentWrong } from 'components/Statements/SthWentWrong';
import { ListRow } from './ListRow';
import { StockExchange } from 'pages/StockPage/StockExchange';

interface Props {
  list: StockExchange[];
  hasError: boolean;
  isLoading: boolean;
  onReload: VoidFunction;
}

export function ListBody(props: Props) {
  const { t } = useTranslation('stock');
  if (props.hasError === true) {
    return (
      <TableBody>
        <TableRow key={'error'}>
          <TableCell colSpan={100}>
            <SthWentWrong onRefresh={props.onReload} />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (props.isLoading === true) {
    return (
      <TableBody>
        <TableRow key={'loader'}>
          <TableCell colSpan={100}>
            <LoaderBox>
              <Loader />
            </LoaderBox>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (props.list.length === 0) {
    return (
      <TableBody>
        <TableRow key={'noPendingStock'}>
          <TableCell colSpan={100} data-cy={'component-warehouse_list_no_pending_stock'}>
            {t('list.noPendingStock')}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {props.list.map((stockExchange) => (
        <ListRow stockExchange={stockExchange} key={stockExchange.productId} />
      ))}
    </TableBody>
  );
}

const LoaderBox = styled('div')`
  height: 50px;
  width: 50px;
  margin: 0 auto;
`;
