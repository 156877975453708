import { filter } from 'lodash';
// import { useTranslation } from 'app/translations/useTranslation';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { ListBody } from './components/ListBody';
import { ListHeaders } from './components/ListHeaders';
import { useStockPendingList } from 'pages/StockPage/state/useStockPendingList';

interface Props {
  productId: string;
}

export function StockExchangeRowDetails(props: Props) {
  // const { t } = useTranslation('stock');
  const { pendingStockListing, reloadPendingStockListing } = useStockPendingList();
  return (
    <TableContainer>
      <Table>
        <ListHeaders />
        <ListBody
          list={filter(
            pendingStockListing.list,
            (stockExchange) => stockExchange.productId === props.productId,
          )}
          hasError={pendingStockListing.hasError}
          isLoading={pendingStockListing.isLoading}
          onReload={reloadPendingStockListing}
        />
      </Table>
    </TableContainer>
  );
}
