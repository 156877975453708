import React from 'react';

import Grid from '@mui/material/Grid';
import DestinationForm from './components/DestinationForm';

// Props type
type Props = {};

// Component
const SimpleDelivery = (props: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <DestinationForm />
      </Grid>
    </Grid>
  );
};

export default SimpleDelivery;
