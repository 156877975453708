import { createSelector, createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { AsyncStatus, PA } from 'utlis/State';
import type { Warehouse, WarehouseAddStockParams } from '../Warehouse';

interface Filters {
  companyId: string | null;
}

export interface State {
  warehouseList: {
    filters: Filters;
    data: Warehouse[];
    status: AsyncStatus;
  };
  warehouseAddStockStatus: AsyncStatus;
}

const initialState: State = {
  warehouseList: {
    filters: {
      companyId: null,
    },
    data: [],
    status: AsyncStatus.NotStarted,
  },
  warehouseAddStockStatus: AsyncStatus.NotStarted,
};

const slice = createSlice({
  name: 'warehouseList',
  initialState,
  reducers: {
    fetchWarehouseList(state) {
      state.warehouseList.status = AsyncStatus.Pending;
    },
    fetchWarehouseListInBackground(_state) {},
    fetchWarehouseListSuccess(state, { payload }: PA<ActionTypes.FetchWarehouseListSuccess>) {
      state.warehouseList.status = AsyncStatus.Success;
      state.warehouseList.data = orderBy(payload.warehouses, ['region'], ['asc']);
    },
    fetchWarehouseListError(state) {
      state.warehouseList.status = AsyncStatus.Error;
    },
    setFilters(state, { payload }: PA<ActionTypes.SetFilters>) {
      if (payload.companyId !== undefined) {
        state.warehouseList.filters.companyId = payload.companyId;
      }
    },
    warehouseAddStock(state, _action: PA<ActionTypes.WarehouseAddStock>) {
      state.warehouseAddStockStatus = AsyncStatus.Pending;
    },
    warehouseAddStockFinish(state) {
      state.warehouseAddStockStatus = AsyncStatus.Success;
    },
  },
});

export declare namespace ActionTypes {
  export interface FetchWarehouseListSuccess {
    warehouses: Warehouse[];
  }

  export interface SetFilters extends Partial<Filters> {}

  export interface WarehouseAddStock {
    data: WarehouseAddStockParams;
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectWarehouseList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.warehouseList.data;
    }),
  makeSelectWarehouseListLoadingStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.warehouseList.status;
    }),
  makeSelectWarehouseListFilters: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.warehouseList.filters;
    }),
  makeSelectWarehouseAddStockStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.warehouseAddStockStatus;
    }),
};
