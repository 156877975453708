import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';

import useMUIDataTableDefaultOptions from 'utlis/MUIDataTables/useMUIDataTableDefaultOptions';

import TableFiltersProvider from '../../../../components/dataTables/filters/TableFiltersProvider';
import TableFiltersToolbar from '../../../../components/dataTables/filters/TableFiltersToolbar';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { usePendingCorrections } from '../../state/usePendingCorrections';

import { styled } from '@mui/material/styles';
import { navigate } from '../../../../app/Navigation';
import { LinkFactory } from '../../../Companies';
import { CorrectionModal } from './CorrectionModal/CorrectionModal';

// Props type
type CompanyImageProps = {
  companyImageUrl: string;
};

// Component
const CompanyImage: React.FC<CompanyImageProps> = ({ companyImageUrl }) => {
  return companyImageUrl ? <Image src={companyImageUrl} /> : null;
};

export const Image = styled('img')({
  height: '1.25em',
  verticalAlign: 'center',
});
interface Props {}

const PendingList = (props: Props) => {
  const { t } = useTranslation('correction');

  const { pendingCorrectionsListing, reloadPendingCorrectionsList } = usePendingCorrections();
  const corrections = useMemo(() => pendingCorrectionsListing?.list, [pendingCorrectionsListing]);

  const [clickedCorrectionId, setClickedCorrectionId] = useState<string | null>(null);

  const defaultOptions = useMUIDataTableDefaultOptions();
  const options = useMemo<MUIDataTableOptions>(
    () => ({
      ...defaultOptions,
      searchOpen: false,
      searchAlwaysOpen: false,
      search: false,
      download: true,
      viewColumns: false,
      filter: false,
      expandableRowsHeader: false,
      onRowClick: (rowData, rowMeta) => {
        setClickedCorrectionId(corrections[rowMeta.dataIndex].id);
      },
      customSearchRender: () => <></>,
    }),
    [defaultOptions, corrections],
  );

  const columns = useMemo<MUIDataTableColumn[]>(
    () => [
      {
        name: 'companyImageUrl',
        label: t('list.headers.company'),
        options: {
          customBodyRender: (companyImageUrl) =>
            !!companyImageUrl && <CompanyImage companyImageUrl={companyImageUrl} />,
        },
      },
      {
        name: 'orderNo',
        label: t('list.headers.orderNo'),
      },
      {
        name: 'createdAt',
        label: t('list.headers.createdAt'),
        options: {
          customBodyRender: (timestamp: number) =>
            timestamp ? t('common:datetime.datetime', { date: new Date(timestamp) }) : '-',
        },
      },
      {
        name: 'driverEmail',
        label: t('list.headers.email'),
      },
    ],
    [t],
  );

  return (
    <>
      <TableFiltersProvider filters={<></>}>
        <MUIDataTable
          title=""
          data={corrections}
          columns={columns}
          options={options}
          components={{ TableToolbar: TableFiltersToolbar }}
        />
      </TableFiltersProvider>
      <CorrectionModal
        isOpen={!!clickedCorrectionId}
        onClose={() => setClickedCorrectionId(null)}
        correctionId={clickedCorrectionId}
      />
    </>
  );
};

export default PendingList;
