import { AuthorizedRoute } from 'app/components/AuthorizedRoute';
import { Switch, Redirect } from 'react-router-dom';
import { LinkFactory } from './LinkFactory';
import { UserManagerPage } from './pages/UserManagerPage/UserManagerPage';

interface Props {

}

export function Routing(props: Props) {
  return (
    <Switch>
      <AuthorizedRoute
        exact={true}
        path={LinkFactory.userManager()}
        component={UserManagerPage}
      />
      <Redirect
        exact={true}
        path={LinkFactory.root()}
        to={LinkFactory.userManager()}
      />
    </Switch>
  );
}
