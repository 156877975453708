import React from 'react';

import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';

import Page from 'components/Page/Page';

import DriverList from './components/DriverList/DriverList';
import { Actions } from './components/DriverList/components/ActionRow/Actions';

interface Props {}

export function DriverPage(props: Props) {
  const { t } = useTranslation('driver');

  return (
    <Page>
      <Page.Header actions={<Actions />}>{t('list.title')}</Page.Header>
      <Card>
        <DriverList />
      </Card>
    </Page>
  );
}
