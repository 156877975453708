import React from 'react';

// import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Company } from 'model/Company';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Data from 'components/Data/Data';

// Props type
type Props = { company: Company };

// Component
const DeliverySettings = ({ company }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Card>
      <CardHeader title={t('config.deliverySettings.label')} />
      <CardContent>
        <Data
          label={t('config.deliverySettings.cancelOrderAfter.label')}
          tooltip={t('config.deliverySettings.cancelOrderAfter.description')}
          value={company.deliverySettings?.cancelOrderAfter}
          unit={t('unit.seconds.short')}
        />
        <Data
          label={t('config.deliverySettings.maxDeliveryDistanceRadius.label')}
          tooltip={t('config.deliverySettings.maxDeliveryDistanceRadius.description')}
          value={company.deliverySettings?.maxDeliveryDistanceRadius}
          unit={t('unit.seconds.short')}
        />
        <Data
          label={t('config.deliverySettings.groupPickupPointOrders.label')}
          tooltip={t('config.deliverySettings.groupPickupPointOrders.description')}
          value={company.deliverySettings?.groupPickupPointOrders}
        />
        <Data
          label={t('config.deliverySettings.groupedOrdersMaxDistance.label')}
          tooltip={t('config.deliverySettings.groupedOrdersMaxDistance.description')}
          value={company.deliverySettings?.groupedOrdersMaxDistance}
        />
        <Data
          label={t('config.deliverySettings.maxDestinationDistanceRadius.label')}
          tooltip={t('config.deliverySettings.maxDestinationDistanceRadius.description')}
          value={company.deliverySettings?.maxDestinationDistanceRadius}
        />
        <Data
          label={t('config.deliverySettings.showOnOrderMarketAfter.label')}
          tooltip={t('config.deliverySettings.showOnOrderMarketAfter.description')}
          value={company.deliverySettings?.showOnOrderMarketAfter}
        />
        {/*<pre>{JSON.stringify(company.deliverySettings?.offersLimit, undefined, 2)}</pre>*/}
      </CardContent>
    </Card>
  );
};

export default DeliverySettings;
