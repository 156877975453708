import { ApiRequestSaga } from './Request/ApiRequestSaga';
import { StockMovementType } from '../pages/ReportPage/components/ReportGenerator/components/Filters/components/AuditStockMovementTypeSelect';

export const ReportApiSaga = {
  downloadDriverActivityReport: (params: { dateSince: Date; dateTo: Date }) => {
    const sinceTime = params.dateSince;
    const toTime = params.dateTo;

    /**
     * This mapping is required as if you pass 31.04.2021 22:00 the report will cover 31.05.2021 too.
     * The issue is because the timezone of the user. If you are in Europe/Warsaw zone date 01.05.2021 00:00 is equivalent of 31.04.2021 22:00.
     */
    const sinceDateTimestamp = Date.UTC(
      sinceTime.getFullYear(),
      sinceTime.getMonth(),
      sinceTime.getDate(),
    );
    const toDateTimestamp = Date.UTC(toTime.getFullYear(), toTime.getMonth(), toTime.getDate());

    return ApiRequestSaga.GetFile({
      endpoint: '/report/driver-activity',
      query: {
        dateFrom: new Date(sinceDateTimestamp).toISOString(),
        dateTo: new Date(toDateTimestamp).toISOString(),
      },
    });
  },
  downloadDriverAvailabilityReport: (params: { dateSince: Date; dateTo: Date }) => {
    const sinceTime = params.dateSince;
    const toTime = params.dateTo;

    /**
     * This mapping is required as if you pass 31.04.2021 22:00 the report will cover 31.05.2021 too.
     * The issue is because the timezone of the user. If you are in Europe/Warsaw zone date 01.05.2021 00:00 is equivalent of 31.04.2021 22:00.
     */
    const sinceDateTimestamp = Date.UTC(
      sinceTime.getFullYear(),
      sinceTime.getMonth(),
      sinceTime.getDate(),
    );
    const toDateTimestamp = Date.UTC(toTime.getFullYear(), toTime.getMonth(), toTime.getDate());

    return ApiRequestSaga.GetFile({
      endpoint: '/report/driver-availability',
      query: {
        dateFrom: new Date(sinceDateTimestamp).toISOString(),
        dateTo: new Date(toDateTimestamp).toISOString(),
      },
    });
  },
  downloadOrderOffersReport: (params: {
    dateFrom: Date;
    dateTo: Date;
    region: string | null;
    companyId: string | null;
  }) => {
    const fromTime = params.dateFrom;
    const toTime = params.dateTo;

    /**
     * This mapping is required as if you pass 31.04.2021 22:00 the report will cover 31.05.2021 too.
     * The issue is because the timezone of the user. If you are in Europe/Warsaw zone date 01.05.2021 00:00 is equivalent of 31.04.2021 22:00.
     */
    const fromDateTimestamp = Date.UTC(
      fromTime.getFullYear(),
      fromTime.getMonth(),
      fromTime.getDate(),
    );
    const toDateTimestamp = Date.UTC(toTime.getFullYear(), toTime.getMonth(), toTime.getDate());
    return ApiRequestSaga.GetFile({
      endpoint: '/report/order-offers',
      query: {
        createdAtFrom: new Date(fromDateTimestamp).toISOString(),
        createdAtTo: new Date(toDateTimestamp).toISOString(),
        ...(params.region && { region: params.region }),
        ...(params.companyId && { companyIds: [params.companyId] }),
      },
    });
  },
  downloadAuditStockMovementReport: (params: {
    dateFrom: Date;
    dateTo: Date;
    reportType: string | null;
    region: string | null;
    companyIds: string[] | null;
  }) => {
    const fromTime = params.dateFrom;
    const toTime = new Date(params.dateTo.getTime());
    toTime.setUTCHours(23, 59, 59, 999);
    const fromDateTimestamp = Date.UTC(
      fromTime.getFullYear(),
      fromTime.getMonth(),
      fromTime.getDate(),
    );
    return ApiRequestSaga.GetFile({
      endpoint: '/report/audit-stock-movement',
      query: {
        reportType: params.reportType,
        dateFrom: new Date(fromDateTimestamp).toISOString(),
        dateTo: toTime.toISOString(),
        ...(params.region && { region: params.region }),
        ...(params.companyIds && { companyIds: params.companyIds }),
      },
    });
  },
  downloadStockExchangeReport: (params: { dateFrom: Date; dateTo: Date }) => {
    const fromTime = params.dateFrom;
    const toTime = new Date(params.dateTo.getTime());
    toTime.setUTCHours(23, 59, 59, 999);
    const fromDateTimestamp = Date.UTC(
      fromTime.getFullYear(),
      fromTime.getMonth(),
      fromTime.getDate(),
    );
    return ApiRequestSaga.GetFile({
      endpoint: '/report/stock-exchange',
      query: {
        dateFrom: new Date(fromDateTimestamp).toISOString(),
        dateTo: toTime.toISOString(),
      },
    });
  },
  downloadOrdersFullReport: (params: {
    dateFrom: Date;
    dateTo: Date;
    region: string | null;
    companyId: string | null;
  }) => {
    const fromTime = params.dateFrom;
    const toTime = new Date(params.dateTo.getTime());
    toTime.setUTCHours(23, 59, 59, 999);
    const fromDateTimestamp = Date.UTC(
      fromTime.getFullYear(),
      fromTime.getMonth(),
      fromTime.getDate(),
    );
    return ApiRequestSaga.GetFile({
      endpoint: '/order/generate-report',
      query: {
        reportType: 'full',
        dateFrom: new Date(fromDateTimestamp).toISOString(),
        dateTo: toTime.toISOString(),
        ...(params.region && { region: params.region }),
        ...(params.companyId && { companyIds: [params.companyId] }),
      },
    });
  },
  downloadDriversStockReport: (params: { region: string | null; companyId: string | null }) => {
    return ApiRequestSaga.GetFile({
      endpoint: '/report/driver',
      query: {
        ...(params.region && { region: params.region }),
        ...(params.companyId && { companyIds: [params.companyId] }),
      },
    });
  },
  downloadCorrectionsReport: (params: { dateFrom: Date; dateTo: Date }) => {
    const sinceTime = params.dateFrom;
    const toTime = params.dateTo;

    /**
     * This mapping is required as if you pass 31.04.2021 22:00 the report will cover 31.05.2021 too.
     * The issue is because the timezone of the user. If you are in Europe/Warsaw zone date 01.05.2021 00:00 is equivalent of 31.04.2021 22:00.
     */
    const sinceDateTimestamp = Date.UTC(
      sinceTime.getFullYear(),
      sinceTime.getMonth(),
      sinceTime.getDate(),
    );
    const toDateTimestamp = Date.UTC(toTime.getFullYear(), toTime.getMonth(), toTime.getDate());

    return ApiRequestSaga.GetFile({
      endpoint: '/report/corrections',
      query: {
        dateFrom: new Date(sinceDateTimestamp).toISOString(),
        dateTo: new Date(toDateTimestamp).toISOString(),
      },
    });
  },
  downloadDriversFeedbackReport: (params: { dateFrom: Date; dateTo: Date }) => {
    const sinceTime = params.dateFrom;
    const toTime = params.dateTo;

    /**
     * This mapping is required as if you pass 31.04.2021 22:00 the report will cover 31.05.2021 too.
     * The issue is because the timezone of the user. If you are in Europe/Warsaw zone date 01.05.2021 00:00 is equivalent of 31.04.2021 22:00.
     */
    const sinceDateTimestamp = Date.UTC(
      sinceTime.getFullYear(),
      sinceTime.getMonth(),
      sinceTime.getDate(),
    );
    const toDateTimestamp = Date.UTC(toTime.getFullYear(), toTime.getMonth(), toTime.getDate());

    return ApiRequestSaga.GetFile({
      endpoint: '/report/drivers-feedback',
      query: {
        dateFrom: new Date(sinceDateTimestamp).toISOString(),
        dateTo: new Date(toDateTimestamp).toISOString(),
      },
    });
  },
};
