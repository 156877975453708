import React from 'react';

import { styled } from '@mui/material/styles';
import { AppConfig } from '../../../app/Config';
import { Company } from 'model/Company';

// Props type
type Props = {
  company: Company;
};

// Component
const CompanyImage: React.FC<Props> = ({ company }) => {
  return company?.imageFile ? (
    <Image src={`${AppConfig.backendUrl}/static/images/${company.imageFile}`} />
  ) : null;
};

export const Image = styled('img')({
  height: '1.25em',
  verticalAlign: 'center',
});

export default CompanyImage;
