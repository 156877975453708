import { StockItem } from '../WarehousePage/Warehouse';

export interface DriverLocation {
  lat: number;
  lng: number;
  loggedAt: number;
}

export enum DriverStatus {
  Online = 'online',
  Offline = 'offline',
}
export enum OsType {
  Android = 'android',
  Ios = 'ios',
}

export interface Driver {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  status: DriverStatus;
  isAmbassador?: boolean;
  region: string;
  priorityDriver?: boolean;
  lastKnownLocation?: DriverLocation;
  car: DriverCar;
  mobile: DriverMobileApp;
  allowedCompanies: string[]; // company id string, all delivery products from company will be available

  driverType?: any;
  lastLogin?: number;

  blocked: boolean;
  active: boolean;

  stock: DriverStock[];
}

export interface DriverCar {
  registrationPlate?: string;
  model?: string;
  color?: string;
}

export interface DriverMobileApp {
  version?: string;
  osType?: OsType;
}

export interface DriverStock {
  productId: string;
  name?: string;
  amount: number;
  items?: StockItem[];
}