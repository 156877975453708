import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { Input } from 'components/ReactHookForm/Input/Input';
import CardTitled from 'components/Cards/CardTitled';
import { useFormContext } from 'react-hook-form';
import LocationSelector from './PickupPointForm/LocationSelector';
import { useDispatch, useSelector } from 'react-redux';
import { useSettings } from '../../../../../../../../../services/authorization/hooks/useSettings';
import useOrderCompany from '../../../../hooks/useOrderCompany';
import { Location } from '../../../../../../../../../model/Location';
import { actions } from '../../../../../../../state';
import {
  selectOrderCreateSelectedClient,
  selectOrderCreateSelectedPickupPoint,
} from '../../../../../../../state/selectors';

// Props type
type Props = {
  showLocationsSelect?: boolean;
};

// Component
const DestinationForm = (props: Props) => {
  const { t } = useTranslation(['order', 'common', 'validationErrors']);
  const dispatch = useDispatch();
  const settings = useSettings();

  const form = useFormContext();

  useEffect(() => {
    return () => form && form.unregister('client');
  }, []);

  const company = useOrderCompany();

  const applyLocation = (location?: Location) => {
    dispatch(actions.setSelectedClient(location));
  };

  const location = useSelector(selectOrderCreateSelectedClient);
  const lockEdit = useMemo(() => !!location, [location]);

  useEffect(() => {
    return () => {
      dispatch(actions.setSelectedClient(undefined));
    };
  }, [dispatch]);

  return (
    <CardTitled title={t('labels.receiver')}>
      <Grid container spacing={1}>
        {props.showLocationsSelect && (
          <Grid item xs={12}>
            <LocationSelector
              companyId={company.id}
              onChange={applyLocation}
              defaultSelectedLocationId={settings.defaultLocationId}
              setLocationFor={'client'}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Input label={t('common:address.name')} name="client.name" />
        </Grid>
        <Grid item xs={12}>
          <Input label={t('common:address.streetFull')} name="client.street" />
        </Grid>
        <Grid item xs={4}>
          <Input label={t('common:address.zipCode')} name="client.zipCode" />
        </Grid>
        <Grid item xs={8}>
          <Input label={t('common:address.city')} name="client.city" />
        </Grid>
        <Grid item xs={12}>
          <Input label={t('common:address.phone')} name="client.phone" />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t('common:address.notes')}
            name="client.notes"
            multiline
            minRows="3"
            maxRows="5"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </CardTitled>
  );
};

export default DestinationForm;
