import { some } from 'lodash';
import { translate } from 'app/translations/useTranslation';

export const CheckboxAtLeastOneSelectedTest: [
  string,
  () => string,
  (values: any[] | undefined) => boolean,
] = [
  'at_least_one_value',
  () => translate('validationErrors', 'atLeastOneValue'),
  (values) => {
    return some(values);
  },
];

export const CheckboxesObjectAtLeastOneSelectedTest: [
  string,
  () => string,
  (values: { [key: string]: string } | undefined) => boolean,
] = [
  'at_least_one_value',
  () => translate('validationErrors', 'atLeastOneValue'),
  (values) => !!values && Object.keys(values).some((key) => !!values[key]),
];
