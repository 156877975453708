import { useDispatch, useSelector } from 'react-redux';
import * as Slice from './slice';

export function useChangeOrderDriver() {
  const dispatch = useDispatch();
  const changeOrderDriverStatus = useSelector(Slice.selectors.makeSelectChangeOrderDriverStatus());

  return {
    changeOrderDriverStatus,
    changeOrderDriver(params: Slice.ActionTypes.ChangeOrderDriver) {
      dispatch(Slice.actions.changeOrderDriver(params));
    },
  };
}
