import styled, { createGlobalStyle, keyframes } from 'styled-components/macro';
import { ThemeInterface } from 'app/styles/Theme';
import { ToastType } from './ToastType';

const getTypeColor = (theme: ThemeInterface, type: ToastType) => {
  switch (type) {
    case ToastType.Error:
      return theme.palette.error.dark;
    case ToastType.Success:
      return theme.palette.success.dark;
    case ToastType.Warning:
      return theme.palette.warning.dark;
    case ToastType.Info:
    default:
      return theme.palette.info.dark;
  }
};

const animateOut = keyframes`
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
`;

const animateIn = keyframes`
  0% {
    clip-path: circle(13px at calc(100% - 26px) calc(100% - 26px));
    transform: scale(0.3);
    opacity: 0;
  }

  30% {
    opacity: 1;
    transform: scale(1.5);
  }

  60% {
    clip-path: circle(13px at calc(100% - 26px) calc(100% - 26px));
    transform: scale(1);
  }

  100% {
    clip-path: circle(400px at calc(100% - 26px) calc(100% - 26px));
  }
`;

export const ToastGlobalStyles = createGlobalStyle`
  [class^="notification-container"] {
    width: 400px; /** Keep in sync with service.ts width prop */
  }

  .react-notification-root {
    width: 100vw;
    height: 100vh;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  .notification-item.animation-out {
    animation: ${animateOut} 250ms ease-in forwards;
  }

  .notification-item.animation-in {
    animation: ${animateIn} 500ms ease-in-out forwards;
    transform-origin: calc(100% - 26px) calc(100% - 26px);
  }

  .notification-item {
    box-shadow: none !important;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 1em;
`;

export const Message = styled.div`
  color: ${({ theme }) => theme.mainDimmed};
`;

export const Wrapper = styled.div`
  width: 100%;
  cursor: default;
  background: ${({ theme }) => theme.mainBackground};
  color: ${({ theme }) => theme.main};
  font-size: 13px;
  border-radius: 4px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
`;

export const StyledWrapper = styled(Wrapper)<{ type: ToastType }>`
  border-right: 10px solid ${props => getTypeColor(props.theme, props.type)};
  padding: 1em 48px 1em 1.5em;
  background: ${props => props.theme.palette.background.paper};
  ${Title} {
    color: ${props => getTypeColor(props.theme, props.type)};
  }
`;
