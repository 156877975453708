import { styled } from '@mui/material/styles';
import { Button } from 'components/Button/Buttons';
import { Grid } from '@mui/material';
import { Input as FormInput } from '../../../../../components/ReactHookForm/Input/Input';

export const Box = styled('div')(
  ({ theme }) => `
  margin: ${theme.spacing(1)} ${theme.spacing(5)};
`,
);

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
`;
export const FieldContainer = styled(Grid)`
  width: 100%;
  /*padding: 20px;*/
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const FieldsContainer = styled(Grid)`
  padding: 20px;
  align-items: flex-start;
  /*margin-left: 0;
  margin-top: 0;
  width: 100%;*/
`;

export const Input = styled(FormInput)(
  ({ theme }) => `
  margin: 0;
  width: 100%;
`,
);

export const DetailsBox = styled('div')(
  ({ theme }) => `
  max-width: 700px;
  margin-bottom: ${theme.spacing(3)};
`,
);

export const SubmitButton = styled(Button)`
  width: 200px;
  align-self: end;
`;
