import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Product } from 'model/Product';

const useWeight = (product: Product) => {
  const { t } = useTranslation('validationErrors');

  const rules = useMemo(
    () => ({
      ...(!!product.maximumDimensions?.weight
        ? {
            required: t('required'),
            max: {
              value: product.maximumDimensions.weight,
              message: t('order.maxWeightKg', {
                max: product.maximumDimensions.weight,
              }),
            },
          }
        : {}),
    }),
    [product, t],
  );

  const isAvailable = useMemo<boolean>(
    () => !!product.productSettings?.productFormFields?.includes('dimensions.weight'),
    [product],
  );

  return { rules, isAvailable };
};

export default useWeight;
