// import { useTranslation } from 'app/translations/useTranslation';
import { Routing } from './Routing';

interface Props {}

export function UserPage(props: Props) {
  // const { t } = useTranslation('user');

  return <Routing />;
}
