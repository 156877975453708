import { useTranslation } from 'app/translations/useTranslation';
import { differenceInCalendarDays } from 'date-fns';
import { DateRange } from 'utlis/Date';
import { CalendarDates } from '../CalendarDates';
import * as Styled from '../styled';

function areDatesExceedMaxSpread(calendarDates: CalendarDates, maxDateSpreadInDays: number): boolean {
  const [startDate, endDate] = calendarDates.toArray();
  if (endDate === undefined) {
    return false;
  }

  const datesSpread = differenceInCalendarDays(endDate, startDate);
  if (datesSpread >= maxDateSpreadInDays) {
    return true;
  }

  return false;
}

interface Props {
  maxDateSpreadInDays: number,
  calendarDates: CalendarDates,
  onApply: (dateRange: DateRange) => void
}

export function ApplyAction(props: Props) {
  const { t } = useTranslation('common');
  const cannotUpdate = areDatesExceedMaxSpread(props.calendarDates, props.maxDateSpreadInDays);

  return (
    <div>
      {cannotUpdate && (
        <Styled.ApplyWarning>
          {t('dateRange.exceededDateRange', { maxDateSpreadInDays: props.maxDateSpreadInDays })}
        </Styled.ApplyWarning>
      )}
      <Styled.ActionButton
        onClick={() => {
          if (cannotUpdate) {
            return;
          }
          const [startDate, endDate] = props.calendarDates.getDates();
          if (endDate !== null) {
            props.onApply(new DateRange(startDate, endDate));
          }
        }}
        isDisabled={cannotUpdate}
      >
        {t('dateRange.apply')}
      </Styled.ActionButton>
    </div>
  );
}
