import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import useOrderCompany from '../../hooks/useOrderCompany';
import {
  DayNumbers,
  DayWorkingHours,
  SlotRange,
  WorkingSettings,
} from 'utlis/workingSettings/model/WorkingSettings';
import {
  checkDay,
  dayToDate,
  findNextWorkingDay,
  splitDayHoursIntoSlotRanges,
} from 'utlis/workingSettings';
import { useSelector } from 'react-redux';
import { selectOrderCreateSelectedPickupPoint } from '../../../../../state/selectors';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

// Props type
type Props = {};

// Component
const TimeSlotSelector = (props: Props) => {
  const { t } = useTranslation(['order', 'common']);

  const company = useOrderCompany();

  const selectedPickupPoint = useSelector(selectOrderCreateSelectedPickupPoint);

  const workingSettings = useMemo<WorkingSettings[]>(
    () => [company.workingSettings, selectedPickupPoint?.workingSettings].filter((v) => !!v),
    [company.workingSettings, selectedPickupPoint],
  );

  const workingHours = useMemo<DayWorkingHours[]>(
    () =>
      [checkDay(workingSettings, true), findNextWorkingDay(workingSettings)].filter(
        (v) => !!v,
      ) as DayWorkingHours[],
    [workingSettings],
  );

  const slotDays = useMemo<{ day: DayNumbers; slots: SlotRange[] }[]>(
    () => splitDayHoursIntoSlotRanges(workingHours).filter((wh) => !!wh.slots.length),
    [workingHours],
  );

  const { unregister, setValue } = useFormContext();

  const setSlotRange = useCallback(
    (date) => {
      setValue('scheduledAt', date);
    },
    [setValue],
  );

  const scheduledAt = useWatch({ name: 'scheduledAt' });

  const slotDaysWithSelection = useMemo(() => {
    const scheduledAtTime = new Date(scheduledAt).getTime();
    return slotDays.map((sd) => ({
      ...sd,
      slots: sd.slots.map((slot) => ({
        ...slot,
        selected: slot.from.getTime() === scheduledAtTime,
      })),
    }));
  }, [slotDays, scheduledAt]);

  useEffect(() => () => unregister('scheduledAt'), []);
  useEffect(() => {
    const scheduledAtTime = new Date(scheduledAt).getTime();
    if (
      scheduledAt &&
      !slotDays.some((sd) => sd.slots.some((slot) => slot.from.getTime() === scheduledAtTime))
    ) {
      setSlotRange(undefined);
    }
  }, [slotDays, setSlotRange]);

  return workingHours.length && company.orderSettings?.scheduledAtToScheduledRange ? (
    <>
      <StyledDivider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{t('orderCreate.slotRange.choose')}</Typography>
          <Typography variant={'caption'}>{t('orderCreate.slotRange.description')}</Typography>
          {slotDaysWithSelection.map((slotDay) => (
            <DayBox key={`${slotDay.day.year}-${slotDay.day.month}-${slotDay.day.day}`}>
              <DayTitle>
                {t('common:datetime.date', { date: dayToDate(slotDay.day) })}{' '}
                {t('common:datetime.weekDay', { date: dayToDate(slotDay.day) })}
              </DayTitle>
              <DaySlots>
                {slotDay.slots.map((slot) => (
                  <SlotButton
                    variant={slot.selected ? 'contained' : 'outlined'}
                    onClick={() => setSlotRange(!slot.selected ? slot.from : undefined)}
                    key={slot.from.getTime()}
                  >
                    {t('common:datetime.time', { date: slot.from })} -{' '}
                    {t('common:datetime.time', { date: slot.to })}
                  </SlotButton>
                ))}
              </DaySlots>
            </DayBox>
          ))}
        </Grid>
      </Grid>
    </>
  ) : null;
};

const StyledDivider = styled(Divider)(
  ({ theme }) => `
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
`,
);

const DayBox = styled(Box)({
  display: 'flex',
  marginTop: 8,
});
const DayTitle = styled(Box)({
  margin: 8,
});
const DaySlots = styled(Box)({});
const SlotButton = styled(Button)({
  margin: 4,
});

export default TimeSlotSelector;
