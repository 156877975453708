import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { DateRange } from 'utlis/Date';
import { DateSelect } from './components/DateSelect';
import { Grid } from '@mui/material';
import { useWallet } from '../../state/useWallet';

interface Props {}

function useCreatedAtDateRange(filters: ReturnType<typeof useWallet>['filters']) {
  const { dateFrom, dateTo } = filters;
  return useMemo(() => {
    return new DateRange(dateFrom, dateTo);
  }, [dateFrom, dateTo]);
}

export function Filters(props: Props) {
  const { setFilters, filters } = useWallet();
  const selectedDateRange = useCreatedAtDateRange(filters);

  return (
    <Box>
      <Grid container spacing={1} direction="row-reverse" alignItems="center">
        <Grid item>
          <DateSelect
            selectedDateRange={selectedDateRange}
            onDateRangeSelect={(range) => {
              setFilters({ dateFrom: range.getStartDate(), dateTo: range.getEndDate() });
            }}
          />
        </Grid>
        <Grid item xs>
          <Grid container spacing={1} alignItems="stretch"></Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
`,
);
