import { pick } from 'lodash';
import { postRequest } from './Request/ApiRequest';
import { PackageSize } from '../pages/Orders/model/Order';
import { Location } from '../model/Location';

export enum UserType {
  Operator = 'operator',
}

export interface DecodedToken {
  scope: string[];
  type: UserType;
  dashboardImage?: string;
  defaultLocationId?: string;
  canView: {
    cancellation?: boolean;
    sensitiveData?: boolean;
    pinCode?: boolean;
    holdOrder?: boolean;
    orderEstimations?: boolean;
    orderRoute?: boolean;
    comments?: boolean;
    changeDriver?: boolean;
    driverInfo?: boolean;
    driverPayout?: boolean;
  };
  // timestamps, absence means registration is completed
  registrationSteps?: {
    productSelectionCompleted?: string;
    locationsCompleted?: string;
    companyDataCompleted?: string;
    contractGenerated?: string;
  };
}

interface LogInParams {
  username: string;
  password: string;
}

export interface RegisterLeadParams {
  email: string;
  name: string;
  phone?: string;
  city?: string;
  deliveryProductType?: string;
  source?: string;
}

export interface RegisterClientParams {
  email: string;
  name: string;
  phone?: string;
  city?: string;
  deliveryProductType?: string;
  password: string;
}

export interface SelectProductParams {
  defaultPackageSize: PackageSize;
}

export interface AddLocationsParams {
  locations: Partial<Location>[];
}

export interface RegisterResponse {
  token: string;
}

export const AuthApi = {
  refreshToken: () =>
    postRequest<{ token: string }>({
      endpoint: '/auth/refresh-token',
    }),
  logOut: () =>
    postRequest({
      endpoint: '/logout',
    }),
  logIn: (params: LogInParams) =>
    postRequest<{ token: string }>({
      endpoint: '/call-center-user/login',
      data: pick(params, 'username', 'password'),
    }),
  registerLead: (params: RegisterLeadParams) =>
    postRequest<void>({
      endpoint: '/lead/register',
      data: params,
    }),
  registerClient: (params: RegisterClientParams) =>
    postRequest<RegisterResponse>({
      endpoint: '/auth/register',
      data: params,
    }),
  selectProduct: (params: SelectProductParams) =>
    postRequest<void>({
      endpoint: '/auth/select-product',
      data: params,
    }),
  addLocations: (params: AddLocationsParams) =>
    postRequest<void>({
      endpoint: '/auth/add-locations',
      data: params,
    }),
};
