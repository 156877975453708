import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import type { Driver } from '../Driver';
import * as Slice from './slice';

export interface DriverListing {
  list: Driver[],
  hasError: boolean,
  isLoading: boolean,
}

export function useDrivers() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Slice.actions.fetchDriverList());
  }, [dispatch]);

  const drivers = useSelector(Slice.selectors.makeSelectDriverList());
  const loadingStatus = useSelector(Slice.selectors.makeSelectDriverListLoadingStatus());
  const driverListing: DriverListing = {
    list: drivers,
    hasError: loadingStatus === AsyncStatus.Error,
    isLoading: [AsyncStatus.Pending, AsyncStatus.NotStarted].includes(loadingStatus),
  };

  return {
    driverListing,
    reloadDriverListing: () => {
      dispatch(Slice.actions.fetchDriverList());
    },
  };
}
