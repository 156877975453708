import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../slice';
import * as selectors from '../selectors';

export const useLocations = (companyId: string) => {
  const dispatch = useDispatch();

  const fetchLocations = useCallback(() => {
    dispatch(actions.fetchLocations({ companyId }));
  }, [dispatch, companyId]);

  const locations = useSelector(selectors.makeSelectCompanyLocations(companyId));

  useEffect(() => {
    fetchLocations();
  }, [companyId, fetchLocations]);

  return { locations, fetchLocations };
};
