import React, { useEffect } from 'react';

import { FormProvider } from 'react-hook-form';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'app/translations/useTranslation';
import { PrimaryButton } from 'components/Button/Buttons';
import { Input } from 'components/ReactHookForm/Input/Input';
import { useChangePassword } from 'pages/SettingsPage/state/useChangePassword';
import { useAddLocationForm, AddLocationFormValues } from './hooks/useAddLocationForm';
import { LocationList } from './LocationList';
import { Location } from '../../../../model/Location';
import { AddLocationListItem } from './AddLocationListItem';
interface Props {
  locations?: Partial<Location>[];
  onAddLocation: (location: Partial<Location>) => void;
  onDeleteLocation: (locationIndex: number) => void;
  max?: number;
}

export function LocationsManager({ locations, onAddLocation, onDeleteLocation, max }: Props) {
  const { t } = useTranslation('settings');

  const form = useAddLocationForm();

  return (
    <Container>
      <LocationList locations={locations} onDelete={onDeleteLocation} />
      {!max || ((locations?.length ?? 0) < max && <AddLocationListItem onSubmit={onAddLocation} />)}
    </Container>
  );
}

const Container = styled('div')``;
