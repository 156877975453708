import React from 'react';
import { Order, OrderStatus } from 'pages/Orders/model/Order';
import * as Styled from '../../styled';
import { ShowRouteAction } from './components/ShowRouteAction/ShowRouteAction';
import { ShowOnHoldHistoryAction } from './components/ShowOnHoldHistoryAction/ShowOnHoldHistoryAction';
import { DownloadLabelAction } from './components/DownloadLabelAction/DownloadLabelAction';
import { ShowCancelOrderModalAction } from './components/CancelOrderModal/ShowCancelOrderModalAction';
import { ShowAddCommentAction } from './components/ShowAddCommentAction/ShowAddCommentAction';
import { usePermissions } from '../../../../../../../../../../../services/authorization/hooks/usePermissions';
import { ShowRenewalsAction } from './components/ShowRenewalsAction/ShowRenewalsAction';
import { ShowChangeOrderDriverModalAction } from './components/ChangeOrderDriverAction/ShowChangeOrderDriverModalAction';

interface Props {
  order: Order;
}

function getAvailabilityMatrix(
  order: Order,
  orderPermissions: {
    canViewCancellation: boolean;
    canViewComments: boolean;
    canViewRoute: boolean;
    canViewHoldOrder: boolean;
    canViewChangeDriver: boolean;
  },
) {
  return {
    downloadLabel: !!order.pickupPoint,
    showRouteAction: orderPermissions.canViewRoute,
    onHoldHistory: !!order.onHold.history,
    cancelOrder:
      (order.stage.isOngoing || order.stage.status === OrderStatus.Scheduled) &&
      orderPermissions.canViewCancellation,
    renewals: !!order.renewals.length,
    holdOrderAction: orderPermissions.canViewHoldOrder,
    comments: orderPermissions.canViewComments,
    changeDriver:
      orderPermissions.canViewChangeDriver &&
      !!order.pickupPoint &&
      order.stage.status === OrderStatus.Accepted,
  };
}

export function ActionSection(props: Props) {
  const orderPermissions = usePermissions('order');
  const isAvailable = getAvailabilityMatrix(props.order, {
    canViewCancellation: orderPermissions.canView.cancellation,
    canViewComments: orderPermissions.canView.comments,
    canViewRoute: orderPermissions.canView.orderRoute,
    canViewHoldOrder: orderPermissions.canView.holdOrder,
    canViewChangeDriver: orderPermissions.canView.changeDriver,
  });

  return (
    <Styled.ActionSection>
      {isAvailable.downloadLabel && <DownloadLabelAction orderId={props.order.id} />}
      {isAvailable.showRouteAction && <ShowRouteAction orderId={props.order.id} />}
      {isAvailable.onHoldHistory && isAvailable.holdOrderAction && (
        <ShowOnHoldHistoryAction
          onHoldHistory={props.order.onHold.history}
          orderId={props.order.id}
          onHold={props.order.onHold.currentlyOnHold ?? false}
          canHoldOrder={isAvailable.holdOrderAction}
        />
      )}
      {isAvailable.cancelOrder && <ShowCancelOrderModalAction orderId={props.order.id} />}
      {isAvailable.comments && (
        <ShowAddCommentAction orderId={props.order.id} comments={props.order.comments} />
      )}
      {isAvailable.renewals && <ShowRenewalsAction renewals={props.order.renewals} />}
      {isAvailable.changeDriver && <ShowChangeOrderDriverModalAction orderId={props.order.id} />}
    </Styled.ActionSection>
  );
}
