// Core libraries
import React from 'react';

import { Switch } from 'react-router-dom';
import RoleRoute from 'app/components/RoleRoute';
import LinkFactory from './LinkFactory';
import { OrderPage } from './OrderPage';
import { OrderCreatePage } from './OrderCreatePage';
import { Redirect } from 'react-router';

// type RoutingType = React.ComponentProps<typeof RoleRoute>;

// Component
const Routing = () => {
  return (
    <Switch>
      <RoleRoute
        roles="order.canAccessPage"
        exact
        path={LinkFactory.orderList()}
        component={OrderPage}
      />
      <RoleRoute
        roles="order.canWrite"
        exact
        path={LinkFactory.orderCreate()}
        component={OrderCreatePage}
        redirect={LinkFactory.root()}
      />
      <Redirect to={LinkFactory.orderList()} />
    </Switch>
  );
};

export default Routing;
