import { styled } from '@mui/material/styles';
import { useAuth } from 'services/authorization';
import { LoginForm } from './components/LoginForm/LoginForm';
interface Props {}

export function LoginPage(props: Props) {
  const { logIn } = useAuth();

  return (
    <Box>
      <LoginForm onSubmit={logIn} />
    </Box>
  );
}

const Box = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
