import React from 'react';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import Card from '@mui/material/Card';

// Props type
type Props = {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
};

// Component
const CardTitled = ({ title, actions, children }: Props) => {
  return (
    <StyledPaper>
      {(!!title || !!actions) && (
        <Header>
          <Title>{title}</Title>
          <Actions>{actions}</Actions>
        </Header>
      )}
      {children && <Content>{children}</Content>}
    </StyledPaper>
  );
};

const Box = styled(MUIBox)({});

const StyledPaper = styled(Card)(({ theme }) => ({}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme.palette.primary.dark,
  color: theme.palette.primary.contrastText,
}));

const Title = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
  color: theme.palette.primary.contrastText,
  width: '100%',
  flexGrow: 1,
}));

const Actions = styled(Box)(({ theme }) => ({}));

const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.background.paper,
}));

export default CardTitled;
