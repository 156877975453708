import { useTranslation } from 'app/translations/useTranslation';
import React, { useContext } from 'react';
import { Title, Message, StyledWrapper } from './styled';
import { ToastType } from './ToastType';

export interface ToastProps {
  id?: string;
  title?: string;
  message?: string;
  type: ToastType;
}

function useDefaultTitle(type: ToastType) {
  const { t } = useTranslation('common');

  return t(`notifications.titles.${type}`);
}

const ToastContext = React.createContext<ToastProps>({ type: ToastType.Info });

export function createToast(props: ToastProps) {
  return (
    <ToastContext.Provider value={props}>
      <Toast />
    </ToastContext.Provider>
  );
}

function Toast() {
  const props = useContext(ToastContext);

  const defaultTitle = useDefaultTitle(props.type);

  return (
    <StyledWrapper type={props.type} data-cy={`${props.type}_toast-${props.id}`}>
      <Title>{props.title ?? defaultTitle}</Title>
      <Message>{props.message}</Message>
    </StyledWrapper>
  );
}
