import { useTranslation } from 'app/translations/useTranslation';
import { SecondaryButton } from 'components/Button/Buttons';
import { navigate, AppRoutes } from 'app/Navigation';

interface Props {
  // FIXME: driverId is never used
  driverId: string;
}

export function ShowWalletAction(props: Props) {
  const { t } = useTranslation('wallet');
  return (
    <SecondaryButton onClick={() => navigate(AppRoutes.wallet.root(props.driverId))}>
      {t('actions.showWallet')}
    </SecondaryButton>
  );
}
