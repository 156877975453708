import { StockApiSaga } from 'api/StockApi';
import { takeLatest, takeLeading, call } from 'redux-saga/effects';
import { put, select } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { ApiStockMapper } from './ApiStockMapper';
import * as Slice from './slice';
import { addErrorToast, addSuccessToast } from '../../../services/toast';
import { translate } from '../../../app/translations/useTranslation';

function* fetchReceivingDriverStockList() {
  try {
    const filters = yield* select(Slice.selectors.makeSelectStockTransferFilters());
    const currentDriverIds = yield* select(Slice.selectors.makeSelectStockTransferDriverIds());
    const forceRefresh = yield* select(Slice.selectors.makeSelectDriverStockListForceRefresh());
    if (filters.receivingDriverId) {
      if (forceRefresh || filters.receivingDriverId !== currentDriverIds.receivingDriverId) {
        const response = yield* StockApiSaga.getStock({
          driverId: filters.receivingDriverId,
        });
        yield put(
          Slice.actions.fetchReceivingDriverStockListSuccess({
            stock: ApiStockMapper.mapDriverStockApiToDriverStock(response?.stock),
            driverId: filters.receivingDriverId,
          }),
        );
      }
    } else {
      yield put(Slice.actions.fetchReceivingDriverStockListNoFilter());
    }
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.fetchReceivingDriverStockListError());
  }
}

function* fetchPendingStockList() {
  try {
    const filters = yield* select(Slice.selectors.makeSelectStockTransferFilters());
    if (filters.receivingDriverId) {
      const response = yield* StockApiSaga.stockPendingList({
        driverId: filters.receivingDriverId,
      });
      yield put(
        Slice.actions.fetchPendingStockListSuccess({
          stock: ApiStockMapper.mapStockExchangeApiToStockExchange(response?.stock),
        }),
      );
    }
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.fetchPendingStockListError());
  }
}

function* fetchGivingDriverStockList() {
  try {
    const filters = yield* select(Slice.selectors.makeSelectStockTransferFilters());
    const currentDriverIds = yield* select(Slice.selectors.makeSelectStockTransferDriverIds());
    const forceRefresh = yield* select(Slice.selectors.makeSelectDriverStockListForceRefresh());

    if (filters.givingDriverId) {
      if (forceRefresh || filters.givingDriverId !== currentDriverIds.givingDriverId) {
        const response = yield* StockApiSaga.getStock({
          driverId: filters.givingDriverId,
        });
        yield put(
          Slice.actions.fetchGivingDriverStockListSuccess({
            stock: ApiStockMapper.mapDriverStockApiToDriverStock(response?.stock),
            driverId: filters.givingDriverId,
          }),
        );
      }
    } else {
      yield put(Slice.actions.fetchGivingDriverStockListNoFilter());
    }
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.fetchGivingDriverStockListError());
  }
}

function* triggerFetchStockList() {
  yield put(Slice.actions.fetchGivingDriverStockList());
  yield put(Slice.actions.fetchReceivingDriverStockList());
}

function* transferStock({ payload }: PA<Slice.ActionTypes.TransferStock>) {
  try {
    const data = {
      ...payload.data,
    };
    if (!data.givingDriverId) {
      yield call(addErrorToast, {
        message: translate('stock', 'notifications.noGivingDriverId'),
      });
    } else if (!data.receivingDriverId) {
      yield call(addErrorToast, {
        message: translate('stock', 'notifications.noReceivingDriverId'),
      });
    } else {
      yield* StockApiSaga.stockTransferBatch({
        ...data,
        givingDriverId: data.givingDriverId,
        receivingDriverId: data.receivingDriverId,
      });
      yield call(addSuccessToast, {
        message: translate('stock', 'notifications.stockTransferred'),
      });
    }
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.transferStockFinish());
  yield put(Slice.actions.enableForceRefresh());
  yield put(Slice.actions.fetchReceivingDriverStockList());
  yield put(Slice.actions.fetchGivingDriverStockList());
  yield put(Slice.actions.disableForceRefresh());
}

export function* saga() {
  yield takeLatest(Slice.actions.fetchGivingDriverStockList, fetchGivingDriverStockList);
  yield takeLatest(Slice.actions.fetchReceivingDriverStockList, fetchReceivingDriverStockList);
  yield takeLatest(Slice.actions.fetchPendingStockList, fetchPendingStockList);
  /* yield takeLeading(Slice.actions.fetchWarehouseListInBackground, fetchGivingDriverStockList);*/
  yield takeLatest(Slice.actions.setFilters, triggerFetchStockList);
  yield takeLeading(Slice.actions.transferStock, transferStock);
}
