import App from 'app/App';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
ReactGA.initialize('G-H33M0Z6XF5');

const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);

const render = (Component: any) => {
  return ReactDOM.render(
    <React.StrictMode>
      <Component />
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

render(App);

if ((module as any).hot) {
  (module as any).hot.accept('app/App', () => {
    const NextApp = require('app/App').default;
    render(NextApp);
  });
}
