import { ApiRequestSaga } from './Request/ApiRequestSaga';
import { Company } from 'model/Company';
import { postRequest } from './Request/ApiRequest';
import { ActionTypes } from '../pages/SettingsPage';
import { ActionTypes as CompanyActionTypes } from '../pages/Companies';

interface ListCompaniesResponse {
  companies: Company[];
}

interface GetCompanyDetails {
  company: Company;
}

const listCompanies = () => {
  return ApiRequestSaga.Get<ListCompaniesResponse>({
    endpoint: '/company/list',
  });
};

const getCompanyDetails = (companyId: string) => {
  return ApiRequestSaga.Get<GetCompanyDetails>({
    endpoint: `/company/info`,
    query: { companyId },
  });
};

export const CompanyApiSaga = {
  listCompanies,
  getCompanyDetails,
  updateCompanyData: (params: ActionTypes.UpdateCompanyData) => {
    return postRequest<{ token: string }>({
      endpoint: '/company/set-company-data',
      data: params,
    });
  },
  getContract() {
    return ApiRequestSaga.GetFile({
      endpoint: `/company/generate-contract`,
    });
  },
  activateCompany(params: CompanyActionTypes.ActivateCompany) {
    return postRequest<{ name: string; companyId: string }>({
      endpoint: '/company/accept',
      data: params,
    });
  },
};
