import { Order } from 'pages/Orders/model/Order';
import { useTranslation } from 'app/translations/useTranslation';
import * as Styled from '../styled';

interface Props {
  order: Order;
}

export function ReceiverSubsection(props: Props) {
  const { t } = useTranslation('order');

  if (props.order.receiver === undefined) {
    return (
      <Styled.Subsection>
        <Styled.Header>{t('labels.receiver')}: </Styled.Header>
        <Styled.RecordValue>{t('list.values.none')}</Styled.RecordValue>
      </Styled.Subsection>
    );
  }

  return (
    <Styled.Subsection>
      <Styled.Header>{t('labels.receiver')}: </Styled.Header>
      {props.order.receiver.hasHashedSensitiveData === false ? (
        <>
          <Styled.RecordBox>
            <Styled.RecordValue>{props.order.receiver.name}</Styled.RecordValue>
          </Styled.RecordBox>
          <Styled.RecordBox>
            <Styled.RecordValue>{props.order.receiver.phone}</Styled.RecordValue>
          </Styled.RecordBox>
        </>
      ) : (
        <Styled.RecordBox>
          <Styled.RecordValue>{t('list.values.customer')}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      <Styled.RecordBox>
        <Styled.RecordValue>{props.order.receiver.address.street}</Styled.RecordValue>
      </Styled.RecordBox>
      <Styled.RecordBox>
        <Styled.RecordValue>{props.order.receiver.address.city}</Styled.RecordValue>
      </Styled.RecordBox>
      <Styled.RecordBox>
        <Styled.RecordValue>{props.order.receiver.address.zipCode}</Styled.RecordValue>
      </Styled.RecordBox>
      {props.order.receiver.address.notes && (
        <Styled.RecordBox>
          <Styled.RecordValue>"{props.order.receiver.address.notes}"</Styled.RecordValue>
        </Styled.RecordBox>
      )}
    </Styled.Subsection>
  );
}
