import React from 'react';
import { useTranslation } from 'app/translations/useTranslation';
import { SecondaryButton } from 'components/Button/Buttons';
import { useState } from 'react';
import { CancelOrderModal } from './CancelOrderModal';

interface Props {
  orderId: string;
}

export function ShowCancelOrderModalAction(props: Props) {
  const { t } = useTranslation('order');
  const [isModalOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <SecondaryButton onClick={() => setModalIsOpen(true)}>
        {t('actions.showCancelOrderModal')}
      </SecondaryButton>
      <CancelOrderModal
        isOpen={isModalOpen}
        onClose={() => setModalIsOpen(false)}
        orderId={props.orderId}
      />
    </>
  );
}
