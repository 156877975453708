import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { AccountType } from 'api/OperatorApi';

export interface AccountTypeSelectProps {
  selectedAccountType: string | null;
  onAccountTypeChange: (accountType: string | null) => void;
  availableAccountTypes: AccountType[];
}

const notSelectedValue = 'notSelectedValue';

export function AccountTypeSelect(props: AccountTypeSelectProps) {
  const { t } = useTranslation('user');

  return (
    <LabeledSelect
      id={'select-account-type'}
      onChange={MuiHandlers.Select.onChange((selectedValue) => {
        props.onAccountTypeChange(selectedValue === notSelectedValue ? null : selectedValue);
      })}
      label={t('labels.accountType')}
      value={props.selectedAccountType ?? notSelectedValue}
    >
      <MenuItem value={notSelectedValue} key={notSelectedValue}>
        {'---'}
      </MenuItem>
      {props.availableAccountTypes.map((accountType) => (
        <MenuItem
          value={accountType}
          key={accountType}
          data-label={t(`labels.accountTypeValues.${accountType}`)}
        >
          {t(`labels.accountTypeValues.${accountType}`)}
        </MenuItem>
      ))}
    </LabeledSelect>
  );
}
