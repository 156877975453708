import { state as ordersState } from 'pages/Orders';
import * as Order from 'pages/Orders/OrderPage';
import * as Report from 'pages/ReportPage';
import * as User from 'pages/UserPage';
import * as Settings from 'pages/SettingsPage';
import * as Driver from 'pages/DriverPage';
import * as Warehouse from 'pages/WarehousePage';
import * as Stock from 'pages/StockPage';
import * as Multiplier from 'pages/MultiplierPage';
import * as Correction from 'pages/CorrectionPage';
import * as Wallet from 'pages/WalletPage';
import * as Companies from 'pages/Companies';
import * as Authorization from 'services/authorization';
import { state as companiesState } from 'services/companies';
import * as Products from 'services/products';
import * as Regions from 'services/regions';
import * as Sockets from 'services/sockets';

type State = {
  name: string;
  saga?: any;
  reducer: any;
  middleware?: any;
};

export const States: State[] = [
  { name: Sockets.name, reducer: Sockets.reducer, saga: Sockets.saga() },
  { name: Authorization.name, reducer: Authorization.reducer, saga: Authorization.saga() },
  { name: Regions.name, reducer: Regions.reducer, saga: Regions.saga() },
  { name: Products.name, reducer: Products.reducer, saga: Products.saga() },
  { name: companiesState.name, reducer: companiesState.reducer, saga: companiesState.saga() },
  { name: ordersState.name, reducer: ordersState.reducer, saga: ordersState.saga() },
  { name: Order.name, reducer: Order.reducer, saga: Order.saga() },
  { name: Report.name, reducer: Report.reducer, saga: Report.saga() },
  { name: User.name, reducer: User.reducer, saga: User.saga() },
  { name: Settings.name, reducer: Settings.reducer, saga: Settings.saga() },
  { name: Driver.name, reducer: Driver.reducer, saga: Driver.saga() },
  { name: Warehouse.name, reducer: Warehouse.reducer, saga: Warehouse.saga() },
  { name: Stock.name, reducer: Stock.reducer, saga: Stock.saga() },
  { name: Multiplier.name, reducer: Multiplier.reducer, saga: Multiplier.saga() },
  { name: Correction.name, reducer: Correction.reducer, saga: Correction.saga() },
  { name: Wallet.name, reducer: Wallet.reducer, saga: Wallet.saga() },
  { name: Companies.name, reducer: Companies.reducer, saga: Companies.saga() },
];
