import { useTranslation } from 'app/translations/useTranslation';
import { DateRangeSelect, DateRangePresets } from 'components/DateRangeSelect';
import { Section } from 'components/Section';
import { useState } from 'react';
import { DateRange } from 'utlis/Date';
import { useReports } from 'pages/ReportPage/state/useReports';
import { ActionBox } from '../ActionBox';
import { SettingsBox } from '../styled';

interface Props {}

export function CorrectionsReport(props: Props) {
  const [dateRange, setDateRange] = useState<DateRange>(DateRange.CreateTodayRange());
  const { t } = useTranslation('report');
  const { isGeneratingReport, generateCorrectionsReport } = useReports();

  return (
    <>
      <SettingsBox>
        <DateRangeSelect
          label={t('corrections.date')}
          selectedDateRange={dateRange}
          onDateRangeSelect={setDateRange}
          dateRangePresets={[DateRangePresets.Today, DateRangePresets.Yesterday]}
          maxDateSpreadInDays={60}
          alignPickerTo={'left'}
        />
      </SettingsBox>
      <Section.Divider />
      <ActionBox
        onGenerate={() => {
          generateCorrectionsReport({
            dateFrom: dateRange.getStartDate(),
            dateTo: dateRange.getEndDate(),
          });
        }}
        isLoading={isGeneratingReport}
      />
    </>
  );
}
