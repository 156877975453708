import { useCallback, useMemo, useState } from 'react';

const useMUIDataTablePersistentExpandedRows = <T>(
  data: T[],
  getId: (row: T) => string | number,
) => {
  const [rowsExpandedIds, setRowsExpandedIds] = useState<(string | number)[]>([]);

  const rowsExpanded = useMemo(() => {
    return rowsExpandedIds.map((id) => data.findIndex((row) => getId(row) === id));
  }, [rowsExpandedIds, data, getId]);

  const onExpand = useCallback(
    (currentRowsExpanded: any, allRowsExpanded: { index: number; dataIndex: number }[]) => {
      setRowsExpandedIds(
        data
          .map((row) => getId(row))
          .filter((id, idx) => allRowsExpanded.some(({ dataIndex }) => dataIndex === idx)),
      );
    },
    [data, getId],
  );

  return { rowsExpanded, rowsExpandedIds, onExpand };
};

export default useMUIDataTablePersistentExpandedRows;
