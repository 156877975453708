import { useDispatch, useSelector } from 'react-redux';
import * as Slice from './../../../state/slice';

export function useDownloadContract() {
  const dispatch = useDispatch();
  const isDownloadingContract = useSelector(Slice.selectors.makeSelectIsDownloadingContract());

  return {
    isDownloadingContract,
    downloadContract() {
      dispatch(Slice.actions.downloadContract());
    },
  };
}
