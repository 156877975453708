import { DateRangePresets } from 'components/DateRangeSelect/DateRangePresets';
import { useTranslation } from 'app/translations/useTranslation';
import { DateRangeSelect } from 'components/DateRangeSelect';
import { DateRange } from 'utlis/Date';

interface Props {
  selectedDateRange: DateRange;
  onDateRangeSelect: (range: DateRange) => void;
  className?: string;
}

export function DateSelect(props: Props) {
  const { t } = useTranslation('order');

  return (
    <DateRangeSelect
      label={t('date')}
      selectedDateRange={props.selectedDateRange}
      onDateRangeSelect={props.onDateRangeSelect}
      dateRangePresets={[DateRangePresets.Today, DateRangePresets.Yesterday]}
      maxDateSpreadInDays={7}
      alignPickerTo={'right'}
    />
  );
}
