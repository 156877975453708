import { postRequest } from './Request/ApiRequest';

export namespace MeApi {
  export interface ChangePasswordParams {
    newPassword: string,
    oldPassword: string,
  }

  export enum ErrorCodes {
    PasswordNotMatch = 'password_does_not_match',
  }

  export const Requests = {
    changePassword: (params: ChangePasswordParams) => {
      return postRequest<{ token: string }>({
        endpoint: '/@me/change-password',
        data: {
          newPassword: params.newPassword,
          oldPassword: params.oldPassword,
        },
      });
    },
  };
}
