import React, { useMemo } from 'react';

import { UseFieldArrayReturn, useFormState } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import RemoveShoppingCart from '@mui/icons-material/RemoveShoppingCart';

import ProductForm from './ProductForm/ProductForm';
import NotificationCard from 'components/Cards/NotificationCard';
import { useTranslation } from 'app/translations/useTranslation';
import { Divider } from '@mui/material';
import CartItem from './CardItem';

// Props type
type Props = {
  formFieldArray: UseFieldArrayReturn;
};

// Component
const CartItems = ({ formFieldArray }: Props) => {
  const { t } = useTranslation('order');
  const { errors } = useFormState();
  const cartItemsWithIndexes: {
    id?: string;
    productId?: string;
    fixedIndex: number;
    helperProduct?: boolean;
  }[] = useMemo(
    () => (formFieldArray.fields ?? []).map((field, index) => ({ ...field, fixedIndex: index })),
    [formFieldArray],
  );
  const regularProductsGrid = cartItemsWithIndexes.filter((item) => !item.helperProduct).length ? (
    <ProductsGrid container spacing={1}>
      {cartItemsWithIndexes
        .filter((item) => !item.helperProduct)
        .map((item, index) => (
          <CartItem index={item.fixedIndex} productItem={item} formFieldArray={formFieldArray} />
        ))}
    </ProductsGrid>
  ) : (
    <Box>
      <NotificationCard
        icon={<RemoveShoppingCart fontSize="inherit" />}
        message={t('orderCreate.addProduct')}
        error={errors.products?.message}
      />
    </Box>
  );
  const helperProductsGrid = cartItemsWithIndexes.filter((item) => item.helperProduct).length ? (
    <>
      <StyledDivider>{t('order:orderCreate.helperProductDivider')}</StyledDivider>
      <ProductsGrid container spacing={1}>
        {cartItemsWithIndexes
          .filter((item) => item.helperProduct)
          .map((item, index) => (
            <CartItem index={item.fixedIndex} productItem={item} formFieldArray={formFieldArray} />
          ))}
      </ProductsGrid>
    </>
  ) : undefined;
  return (
    <MainProductsGrid>
      {regularProductsGrid}
      {helperProductsGrid}
    </MainProductsGrid>
  );
};

const MainProductsGrid = styled(Grid)`
  flex-grow: 1;
`;

const Box = styled(MUIBox)`
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDivider = styled(Divider)(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(2)};
`,
);

const ProductsGrid = styled(Grid)``;

export default CartItems;
