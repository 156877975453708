import React, { useMemo } from 'react';
import { some, isEmpty, isEqual } from 'lodash';
import { styled } from '@mui/material/styles';

import { useWatch } from 'react-hook-form';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import useCheckDeliveryAvailability from '../../../../../state/hooks/useCheckDeliveryAvailability';
import * as Styled from 'pages/Orders/OrderPage/components/OrderList/components/Row/components/OrderRowDetails/styled';
// Props type
type Props = {};

// Component
const CheckDeliveryContainer = (props: Props) => {
  const { t } = useTranslation('order');
  const pickupPoint = useWatch({ name: 'pickupPoint' });
  const client = useWatch({ name: 'client' });
  const companyId = useWatch({ name: 'companyId' });
  const packageSize = useWatch({ name: 'packageSize' });
  const { inProgressState, checkDeliveryAvailability, deliveryAvailability, lastQueryParams } =
    useCheckDeliveryAvailability();

  const deliveryAvailabilityParams = useMemo<{
    canGetEstimation: boolean;
    isEstimationOutdated: boolean;
    companyId: string;
    senderLocation: {
      address: {
        street: string;
        city: string;
        zipCode: string;
      };
    };
    receiverLocation: {
      address: {
        street: string;
        city: string;
        zipCode: string;
      };
    };
    packageSize?: string;
  }>(() => {
    const senderLocation = {
      address: {
        street: pickupPoint?.street ?? '',
        city: pickupPoint?.city ?? '',
        zipCode: pickupPoint?.zipCode ?? '',
      },
    };
    const receiverLocation = {
      address: {
        street: client?.street ?? '',
        city: client?.city ?? '',
        zipCode: client?.zipCode ?? '',
      },
    };
    return {
      canGetEstimation:
        !some(senderLocation.address, isEmpty) && !some(receiverLocation.address, isEmpty),
      isEstimationOutdated:
        !isEqual(senderLocation, lastQueryParams?.senderLocation) ||
        !isEqual(receiverLocation, lastQueryParams?.receiverLocation),
      senderLocation,
      receiverLocation,
      companyId,
      packageSize,
    };
  }, [companyId, pickupPoint, client, packageSize, lastQueryParams]);
  return (
    <>
      <StyledDivider />
      <Grid container>
        {!!deliveryAvailability && (
          <Grid item container spacing={2} justifyContent="space-around">
            <Grid item>
              <Styled.RecordBox>
                <Styled.RecordLabel>
                  {t('checkDeliveryAvailability.labels.available')}:
                </Styled.RecordLabel>
                <Styled.RecordValue>
                  {deliveryAvailability.available
                    ? t('checkDeliveryAvailability.availabilityValues.yes')
                    : t('checkDeliveryAvailability.availabilityValues.no')}
                </Styled.RecordValue>
              </Styled.RecordBox>
            </Grid>
            <Grid item>
              <Styled.RecordBox>
                <Styled.RecordLabel>
                  {t('checkDeliveryAvailability.labels.estimatedDistance')}:
                </Styled.RecordLabel>
                <Styled.RecordValue>
                  {deliveryAvailability.estimatedDistance
                    ? (deliveryAvailability.estimatedDistance / 1000).toFixed(2)
                    : 0}{' '}
                  {t('checkDeliveryAvailability.labels.estimatedDistanceSuffix')}
                </Styled.RecordValue>
              </Styled.RecordBox>
            </Grid>
            <Grid item>
              <Styled.RecordBox>
                <Styled.RecordLabel>
                  {t('checkDeliveryAvailability.labels.estimatedPrice')}:
                </Styled.RecordLabel>
                <Styled.RecordValue>
                  {(deliveryAvailability.price ?? 0).toFixed(2)}
                </Styled.RecordValue>
              </Styled.RecordBox>
            </Grid>
          </Grid>
        )}
        <Grid item container>
          <Grid item>
            {!!deliveryAvailability && deliveryAvailabilityParams.isEstimationOutdated && (
              <WarningText>{t('checkDeliveryAvailability.outdatedEstimationWarning')}</WarningText>
            )}
          </Grid>
          <Grid item>
            <LoadingButton
              type="button"
              onClick={() =>
                checkDeliveryAvailability({
                  companyId: deliveryAvailabilityParams.companyId,
                  receiverLocation: deliveryAvailabilityParams.receiverLocation,
                  senderLocation: deliveryAvailabilityParams.senderLocation,
                  packageSize: deliveryAvailabilityParams.packageSize,
                })
              }
              variant="contained"
              color="primary"
              disabled={
                !deliveryAvailabilityParams.canGetEstimation ||
                (!!deliveryAvailability && !deliveryAvailabilityParams.isEstimationOutdated) ||
                inProgressState.inProgress
              }
              loading={inProgressState.inProgress}
            >
              {t('checkDeliveryAvailability.submit')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const StyledDivider = styled(Divider)(
  ({ theme }) => `
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
`,
);

export const WarningText = styled('span')(
  ({ theme }) => `
  font-weight: bold;
  font-size: 20px;
  color: ${theme.palette.error.main};
`,
);

export const RecordBox = styled('div')(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(1)};
`,
);

export const RecordLabel = styled('span')(
  ({ theme }) => `
  font-weight: normal;
  padding-right: ${theme.spacing(0.5)};
`,
);

export const RecordValue = styled('span')`
  font-weight: lighter;
`;

export default CheckDeliveryContainer;
