import React, { useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { DateRange } from 'utlis/Date';
import * as Slice from '../../state/slice';
import { CompanySelect } from './components/CompanySelect';
import { DateSelect } from './components/DateSelect';
import { ProductSelect } from './components/ProductSelect';
import { RegionSelect } from './components/RegionSelect';
import { SearchInput } from './components/SearchInput';
import { StatusSelect } from './components/StatusSelect';
import { Grid } from '@mui/material';

interface Props {}

function useFilters() {
  const dispatch = useDispatch();
  const filters = useSelector(Slice.selectors.makeSelectOrderListFilters());

  return {
    filters,
    changeFilters(filters: Slice.ActionTypes.SetFilters) {
      dispatch(Slice.actions.setFilters(filters));
    },
    setSearchPhrase(searchPhrase: string) {
      dispatch(Slice.actions.setSearchPhrase({ searchPhrase }));
    },
  };
}

function useCreatedAtDateRange(filters: ReturnType<typeof useFilters>['filters']) {
  const { createdAt } = filters;
  return useMemo(() => {
    const [startDate, endDate] = createdAt;
    return new DateRange(startDate, endDate);
  }, [createdAt]);
}

export function Filters(props: Props) {
  const { filters, changeFilters, setSearchPhrase } = useFilters();
  const selectedDateRange = useCreatedAtDateRange(filters);

  return (
    <Box>
      <Grid container spacing={1} direction="row-reverse" alignItems="center">
        <Grid item>
          <DateSelect
            selectedDateRange={selectedDateRange}
            onDateRangeSelect={(range) => {
              changeFilters({ createdAt: range.toArray() });
            }}
          />
        </Grid>

        <Grid item xs>
          <Grid container spacing={1} alignItems="stretch">
            <Grid item>
              <SearchInput
                searchedValue={filters.searchPhrase}
                onValueChange={(phrase) => setSearchPhrase(phrase)}
              />
            </Grid>
            <Grid item>
              <StatusSelect
                selectedStatuses={filters.statuses}
                onStatusSelect={(statuses) => changeFilters({ statuses })}
              />
            </Grid>
            <Grid item>
              <CompanySelect
                onCompanyChange={(companyId) => {
                  changeFilters({ companyId });
                }}
                selectedCompanyId={filters.companyId}
              />
            </Grid>
            <Grid item>
              <RegionSelect
                onRegionChange={(region) => {
                  changeFilters({ region });
                }}
                selectedRegion={filters.region}
              />
            </Grid>
            <Grid item>
              <ProductSelect
                companyId={filters.companyId}
                selectedProduct={filters.productId}
                onProductChange={(productId) => changeFilters({ productId })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
`,
);
