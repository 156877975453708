import { translate } from 'app/translations/useTranslation';
import { takeLeading } from 'redux-saga/effects';
import * as CompanyService from 'services/companies';
import { addSuccessToast, addErrorToast } from 'services/toast';
import { call, put } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { CompanyApiSaga } from 'api/CompanyApi';
import * as Slice from './slice';

function* activateCompany({ payload }: PA<Slice.ActionTypes.ActivateCompany>) {
  try {
    const response = yield* call(CompanyApiSaga.activateCompany, payload);
    yield call(addSuccessToast, {
      message: translate('company', 'notifications.activated'),
    });
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(CompanyService.state.actions.fetchCompanyList());
  yield put(Slice.actions.activateCompanyFinish());
}

export function* saga() {
  yield takeLeading(Slice.actions.activateCompany, activateCompany);
}
