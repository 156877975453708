import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'app/translations/useTranslation';
import { useDownloadOrderLabel } from 'pages/Orders/OrderPage/state/useDownloadOrderLabel';

interface Props {
  orderId: string;
}

export function DownloadLabelAction(props: Props) {
  const { t } = useTranslation('order');
  const { isDownloadingLabel, downloadOrderLabel } = useDownloadOrderLabel();
  return (
    <LoadingButton
      onClick={() => {
        if (isDownloadingLabel) {
          return;
        }
        downloadOrderLabel({ orderId: props.orderId });
      }}
      variant="contained"
      disabled={isDownloadingLabel}
      loading={isDownloadingLabel}
    >
      {t('actions.downloadLabel')}
    </LoadingButton>
  );
}
