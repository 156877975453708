import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useRejectCorrection() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectRejectCorrectionStatus());

  return {
    rejectCorrection: (params: Slice.ActionTypes.RejectCorrection) => {
      dispatch(Slice.actions.rejectCorrection(params));
    },
    isInProgress: status === AsyncStatus.Pending,
    status,
  };
}
