import { ApiRequestSaga, SimpleGenerator } from './Request/ApiRequestSaga';
import { LocationList } from 'model/Location';
import { Region } from 'model/Region';

interface ListRegionsResponse {
  list: Region[];
}

const listLocations = (query: {
  companyIds?: string[];
  isActive?: boolean;
}): SimpleGenerator<LocationList> => {
  return ApiRequestSaga.Get<LocationList>({
    endpoint: '/region/locations',
    query: {
      companyId: query.companyIds,
      // active: query.isActive,
    },
  });
};

const listActiveLocationsByCompanyId = (companyId: string): SimpleGenerator<LocationList> => {
  return listLocations({ companyIds: [companyId], isActive: true });
};

export const RegionApiSaga = {
  listRegions: (query: { isActive: boolean }) => {
    return ApiRequestSaga.Get<ListRegionsResponse>({
      endpoint: '/region/list',
      query: {
        active: query.isActive,
      },
    });
  },
  listLocations,
  listActiveLocationsByCompanyId,
};
