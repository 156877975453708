import { RouteProps, Route, Redirect } from 'react-router-dom';
import { useAuth } from 'services/authorization';
import { AppRoutes } from 'app/Navigation';

interface Props extends RouteProps {}

/**
 * Use if the given route is not meant to be visited by authorized user
 */
export function NotAuthorizedRoute(props: Props) {
  const { isAuthorized } = useAuth();

  if (isAuthorized) {
    return <Redirect to={AppRoutes.root()} />;
  }

  return <Route {...props} />;
}
