import { styled } from '@mui/material/styles';
import React, { useCallback, useMemo, useState } from 'react';
import { RegisterForm } from './components/RegisterForm/RegisterForm';
import { LogoVersion, XDLogo } from '../../assets/Logo';
import { translate, useTranslation } from '../../app/translations/useTranslation';
import { PrimaryButton } from 'components/Button/Buttons';
import CCCLogo from './components/images/ccc.png';
import DecathlonLogo from './components/images/decathlon.png';
import EobuwieLogo from './components/images/eobuwie.png';
import FurgonetkaLogo from './components/images/furgonetka.png';
import IntercarsLogo from './components/images/intercars.png';
import PlentiLogo from './components/images/plenti.png';
import PmiLogo from './components/images/pmi.png';
import WyjatkowyPrezentLogo from './components/images/wyjatkowyprezent.png';
import Grow from '@mui/material/Grow';
import { TransitionGroup } from 'react-transition-group';

import CaseStudyPdf from './components/CaseStudy.pdf';
import Link from '@mui/material/Link';

import Grid from '@mui/material/Grid';

import Paper from '@mui/material/Paper';
import { AuthApi } from '../../api/AuthApi';
import { SalesForm } from './components/SalesForm/SalesForm';
import { Divider } from '@mui/material';

import Vimeo from '@u-wave/react-vimeo';

interface Props {}

export function SalesPage(props: Props) {
  const { t } = useTranslation('register');
  const [hideLeadForm, setHideLeadForm] = useState<boolean>(false);

  const [showThankYou, setShowThankYou] = useState<boolean>(false);

  const [downloadDisabled, setDownloadDisabled] = useState<boolean>(false);

  const onCaseStudyDownloaded = () => {
    setDownloadDisabled(true);
    setTimeout(() => setDownloadDisabled(false), 5000);
  };

  const submit = useCallback(async (formValues: any) => {
    try {
      await AuthApi.registerLead({
        name: formValues.name,
        email: formValues.email,
        ...(formValues.deliveryProductType && {
          deliveryProductType: formValues.deliveryProductType,
        }),
        source: 'dashboard_sales_landing',
        ...(formValues.city && {
          city: formValues.city,
        }),
        ...(formValues.phone && { phone: formValues.phone }),
      });
    } catch (e) {}
    setHideLeadForm(true);
  }, []);
  const download = async () => {
    let blob = await fetch(CaseStudyPdf).then((r) => r.blob());
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `XpressDelivery_case_study.pdf`);
    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link?.parentNode?.removeChild(link);
    onCaseStudyDownloaded();
  };
  return (
    <Container>
      <LandingContainer>
        <HeaderContainer>
          <LogoBox>
            <XDLogo version={LogoVersion.OneLine} />
          </LogoBox>
        </HeaderContainer>
        <HeadlineContainer>
          <RegularHeader>
            Zobacz jak szybkie dostawy (kilkadziesiąt minut) mogą w Twoim ecommerce
          </RegularHeader>
        </HeadlineContainer>
        <HeadlineContainer>
          <RegularHeader>- Zwiększyć marżę</RegularHeader>
        </HeadlineContainer>
        <HeadlineContainer>
          <RegularHeader>- Zwiększyć lojalność klienta</RegularHeader>
        </HeadlineContainer>
        <HeadlineContainer>
          <RegularHeader>- Zmniejszyć ilość zwrotów</RegularHeader>
        </HeadlineContainer>
        <MainContainer>
          <TransitionContainer>
            {!hideLeadForm && (
              <Grow onExited={() => setShowThankYou(true)}>
                <YellowContainer>
                  <VideoContainer>
                    <Vimeo video="825339452" showTitle={false} />
                  </VideoContainer>
                  <InnerFormContainer>
                    <TopFormContainer>
                      <NormalHeader>
                        Wypełnij formularz i otrzymaj Case study z wdrożeń,{' '}
                        <b>zniżkę "Lifetime Early Bird"</b> oraz Webinar.
                      </NormalHeader>
                    </TopFormContainer>
                    <FormDivider />
                    <SalesForm
                      submit={(formValues: any) => submit(formValues)}
                      submitButton={
                        <SubmitButton type={'submit'}>
                          pobierz case study i oczekuj więcej niebawem
                        </SubmitButton>
                      }
                    />
                  </InnerFormContainer>
                </YellowContainer>
              </Grow>
            )}
            {showThankYou && (
              <Grow>
                <YellowContainer>
                  <InnerContainer>
                    <HeadlineContainer>
                      <RegularHeader>Dziękujemy!</RegularHeader>
                    </HeadlineContainer>
                    <HeadlineContainer>
                      <BottomHeader>Skontaktujemy się z Tobą expressowo!</BottomHeader>
                    </HeadlineContainer>
                    <br />
                    <HeadlineContainer>
                      <BottomHeader>
                        Zobacz również Case Study z wdrożenia dla lidera branży fashion
                      </BottomHeader>
                    </HeadlineContainer>
                    <CaseStudyDownloadButton disabled={downloadDisabled} onClick={download}>
                      Pobierz w formie PDF
                    </CaseStudyDownloadButton>
                  </InnerContainer>
                </YellowContainer>
              </Grow>
            )}
          </TransitionContainer>
          <PrivacyPolicyContainer>
            Administratorem danych osobowych jest Xpress Delivery Sp. z o.o. Twoje dane osobowe będą
            przetwarzane w celu kontaktu oraz przekazania Ci zamówionych materiałów. Pełna
            informacja nt. przetwarzania danych osobowych znajduje się w{' '}
            <Link href={'https://xpress.delivery/polityka-prywatnosci'} target="_blank">
              polityce prywatności
            </Link>
            .
          </PrivacyPolicyContainer>
        </MainContainer>

        <HeadlineContainer>
          <BottomHeader>Oni już korzystają z naszej usługi</BottomHeader>
        </HeadlineContainer>
        <BottomLogoContainer>
          <Grid container spacing={3} justifyContent={'center'}>
            <GridItem item xs={6} sm={4} md={3} lg={1}>
              <Image src={CCCLogo} />
            </GridItem>
            <GridItem item xs={6} sm={4} md={3} lg={1}>
              <Image src={PmiLogo} />
            </GridItem>
            <GridItem item xs={6} sm={4} md={3} lg={1}>
              <Image src={EobuwieLogo} />
            </GridItem>
            <GridItem item xs={6} sm={4} md={3} lg={1}>
              <Image src={DecathlonLogo} />
            </GridItem>
            <GridItem item xs={6} sm={4} md={3} lg={1}>
              <Image src={FurgonetkaLogo} />
            </GridItem>
            <GridItem item xs={6} sm={4} md={3} lg={1}>
              <Image src={IntercarsLogo} />
            </GridItem>
            <GridItem item xs={6} sm={4} md={3} lg={1}>
              <Image src={WyjatkowyPrezentLogo} />
            </GridItem>
            <GridItem item xs={6} sm={4} md={3} lg={1}>
              <Image src={PlentiLogo} />
            </GridItem>
          </Grid>
        </BottomLogoContainer>
      </LandingContainer>
    </Container>
  );
}

const YellowContainer = styled(Paper)(
  ({ theme }) => `
  padding: 0;
  margin-bottom: ${theme.spacing(5)};
  margin-top: ${theme.spacing(5)};
  box-shadow: none;
  border: 2px solid #f1c231;
`,
);
const InnerContainer = styled('div')(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(5)};
`,
);

const InnerFormContainer = styled('div')(
  ({ theme }) => `
  
`,
);

const VideoContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const GridItem = styled(Grid)`
  display: flex;
  align-items: center;
`;

const TransitionContainer = styled(TransitionGroup)`
  max-width: 750px;
  width: 100%;
`;

const MainContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SubmitButton = styled(PrimaryButton)`
  background-color: #f1c232;
  color: white;

  &:hover {
    background-color: #d5a922;
  }
`;

const CaseStudyDownloadButton = styled(PrimaryButton)`
  background-color: #15ce3e;
  color: white;
  margin-top: 24px;
  &:hover {
    background-color: #0fbe36;
  }
`;

const BottomLogoContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 60px;
  margin-bottom: 40px;
`;

const Image = styled('img')`
  max-height: 100%;
  max-width: 100%;
`;

const LandingContainer = styled('div')`
  margin-top: 60px;
  width: 80%;
`;

const TopFormContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px;
  text-align: center;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const RegularHeader = styled('h1')`
  letter-spacing: 2px;
  font-weight: 300;
  color: #111011;
`;
const NormalHeader = styled('h4')`
  letter-spacing: 2px;
  font-weight: 300;
  color: #111011;
`;
const BottomHeader = styled('h3')`
  letter-spacing: 2px;
  font-weight: 300;
  color: #111011;
`;
const Red = styled('span')`
  color: #e00d00;
  font-weight: 600;
`;
const Green = styled('span')`
  color: #15ce3e;
  font-weight: 600;
`;
const Blue = styled('span')`
  color: #1578d3;
  font-weight: 600;
`;
const Yellow = styled('span')`
  color: #f1c232;
  font-weight: 600;
`;
const HeadlineContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
`;

export const LogoBox = styled('div')`
  width: 300px;
  height: 100%;
  align-self: center;
  display: flex;
`;

const Container = styled('div')`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

const PrivacyPolicyContainer = styled('p')`
  font-size: 12px;
  padding: 20px;
  text-align: center;
  color: #a0a0a0;
  max-width: 750px;
  margin-top: -42px;
  margin-bottom: 40px;
`;

export const FormDivider = styled(Divider)(
  ({ theme }) => `
  margin: ${theme.spacing(2)};
`,
);
