import React from 'react';

import { useOrderType } from '../../hooks/useOrderType';
import PickupDelivery from './components/PickupDelivery';
import SimpleArrival from './components/SimpleArrival';
import SimpleDelivery from './components/SimpleDelivery';

const orderTypeMap: { [orderType: string]: React.FC } = {
  pickup_delivery: PickupDelivery,
  simple_delivery: SimpleDelivery,
  simple_arrival: SimpleArrival,
  iqos: SimpleDelivery,
};

// Props type
type Props = {};

// Component
const TypeBasedFields = (props: Props) => {
  const orderType = useOrderType();

  const OrderTypeFieldsComponent = !!orderType && orderTypeMap[orderType];

  return OrderTypeFieldsComponent ? <OrderTypeFieldsComponent /> : null;
};

export default TypeBasedFields;
