import { styled } from '@mui/material/styles';

export const Link = styled('span')(
  ({ theme }) => `
  padding-left: ${theme.spacing(0.5)};
  font-style: italic;
  color: ${theme.palette.primary.main};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`,
);
