import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// Props type
type Props = {};

// Component
const OnHoldIcon = (props: Props & SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zm-4.431-.996a.318.318 0 0 0-.317-.316h-.412a.318.318 0 0 0-.316.316v1.663a.253.253 0 0 1-.506 0v-1.8h-.001V4.736a.318.318 0 0 0-.317-.317H9.79a.318.318 0 0 0-.316.317V7.84a.22.22 0 1 1-.44 0V4.314a.318.318 0 0 0-.318-.317H8.39a.318.318 0 0 0-.316.317V7.84a.22.22 0 0 1-.44.002h-.001V4.736a.318.318 0 0 0-.317-.317h-.327a.317.317 0 0 0-.316.317v3.57a.22.22 0 1 1-.44 0V5.817a.318.318 0 0 0-.317-.317h-.327a.318.318 0 0 0-.317.317v6.152a1.97 1.97 0 0 0 1 1.812v1.067a.318.318 0 0 0 .317.317h2.873a.318.318 0 0 0 .317-.317v-.94a3.508 3.508 0 0 0 2.208-3.113v-.389z" />
    </SvgIcon>
  );
};

export default OnHoldIcon;
