import { useTranslation } from 'app/translations/useTranslation';
import React, { ReactNode, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import * as Styled from './styled';
import { useRegisterForm } from '../../hooks/useRegisterForm';
import { useAuth } from '../../../../services/authorization';
import { AsyncStatus } from '../../../../utlis/State';
import { Trans } from 'react-i18next';
import Link from '@material-ui/core/Link';
import FormHelperText from '@material-ui/core/FormHelperText';

interface Props {
  onSubmit?: () => void;
  submit?: (formValues: any) => void;

  submitButton?: ReactNode;
}

export function RegisterForm(props: Props) {
  const { t } = useTranslation('register');
  const form = useRegisterForm();
  const { isAuthorized, register, registerStatus, registerError } = useAuth();

  const submit = useCallback(
    (formValues: any) => {
      if (props.submit) {
        props.submit(formValues);
      } else {
        register({
          email: formValues.email,
          password: formValues.password,
          name: formValues.name,
          phone: formValues.phone,
          deliveryProductType: formValues.deliveryProductType,
          city: formValues.city,
        });
      }
    },
    [form],
  );
  return (
    <FormProvider {...form}>
      <Styled.Form onSubmit={form.handleSubmit(submit)}>
        <Styled.FieldsContainer container spacing={2} justifyContent={'center'}>
          {registerError && (
            <Styled.FieldContainer item xs={12}>
              <FormHelperText error>{registerError}</FormHelperText>
            </Styled.FieldContainer>
          )}
          <Styled.FieldContainer item xs={12} sm={12} md={6} lg={6}>
            <Styled.Input
              autoComplete="off"
              name={'name'}
              label={t('registration.fields.name.label')}
              placeholder={t('registration.fields.name.placeholder')}
              dataCy={'input-name'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={6} lg={6}>
            <Styled.Input
              autoComplete="off"
              name={'email'}
              label={t('registration.fields.email.label')}
              placeholder={t('registration.fields.email.placeholder')}
              dataCy={'input-email'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={6} lg={6}>
            <Styled.Input
              autoComplete="off"
              name={'phone'}
              label={t('registration.fields.phone.label')}
              placeholder={t('registration.fields.phone.placeholder')}
              dataCy={'input-phone'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={6} lg={6}>
            <Styled.Input
              autoComplete="off"
              name={'city'}
              label={t('registration.fields.city.label')}
              placeholder={t('registration.fields.city.placeholder')}
              dataCy={'input-city'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={12} lg={12}>
            <Styled.Input
              autoComplete="off"
              name={'deliveryProductType'}
              label={t('registration.fields.deliveryProductType.label')}
              placeholder={t('registration.fields.deliveryProductType.placeholder')}
              dataCy={'input-deliveryProductType'}
              fullWidth
            />
          </Styled.FieldContainer>
        </Styled.FieldsContainer>
        <Styled.FormDivider />
        <Styled.FieldsContainer container spacing={2} justifyContent={'center'}>
          <Styled.FieldContainer item xs={12} sm={6} md={6} lg={6}>
            <Styled.Input
              autoComplete="new-password"
              type={'password'}
              name={'password'}
              label={t('registration.fields.password.label')}
              placeholder={t('registration.fields.password.placeholder')}
              dataCy={'input-password'}
              fullWidth
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={6} md={6} lg={6}>
            <Styled.Input
              autoComplete="new-password"
              type={'password'}
              name={'confirmPassword'}
              label={t('registration.fields.confirmPassword.label')}
              placeholder={t('registration.fields.confirmPassword.placeholder')}
              dataCy={'input-confirmPassword'}
              fullWidth
            />
          </Styled.FieldContainer>
        </Styled.FieldsContainer>
        <Styled.FieldsContainer>
          <Styled.FieldContainer item xs={12}>
            <Styled.Checkbox
              name={'confirmPrivacyPolicy'}
              label={
                <Trans t={t} i18nKey="registration.fields.confirmPrivacyPolicy.label">
                  {' '}
                  <Link
                    href={'https://xpress.delivery/polityka-prywatnosci'}
                    target="_blank"
                  />{' '}
                </Trans>
              }
              dataCy={'input-confirmPassword'}
            />
          </Styled.FieldContainer>
        </Styled.FieldsContainer>
        {/*<Styled.FormDivider />*/}
        {!props.submitButton ? (
          <Styled.RegisterButton
            type={'submit'}
            disabled={registerStatus === AsyncStatus.Pending}
            loading={registerStatus === AsyncStatus.Pending}
          >
            {t('registration.registerButton')}
          </Styled.RegisterButton>
        ) : (
          props.submitButton
        )}
      </Styled.Form>
    </FormProvider>
  );
}
