// Core libraries
import React from 'react';

import { Section } from 'components/Section';
import OrderCreateForm from './components/OrderCreateForm/OrderCreateForm';
import { useTranslation } from 'app/translations/useTranslation';
import Alert from '@mui/material/Alert';
import { useAuth } from '../../../services/authorization';
import AlertTitle from '@mui/material/AlertTitle';
import { Trans } from 'react-i18next';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { AppRoutes } from '../../../app/Navigation';

// Props type
type OrderCreatePageProps = {};

// Component
const OrderCreatePage = (props: OrderCreatePageProps) => {
  const { t } = useTranslation(['order', 'auth']);
  const { isActivated } = useAuth();
  return (
    <Section.Box>
      <Section.Header>{t('order:orderCreate.title')}</Section.Header>
      <Section.Divider />
      {!isActivated && (
        <Alert severity={'warning'}>
          <AlertTitle>{t('auth:orderCreationInactive.title')}</AlertTitle>
          <Trans t={t} i18nKey="auth:orderCreationInactive.body">
            <Link component={RouterLink} to={AppRoutes.settings.root()} />
          </Trans>
        </Alert>
      )}

      <OrderCreateForm />
    </Section.Box>
  );
};

export default OrderCreatePage;
