import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import MUIBox from '@mui/material/Box';

import { useLocations } from 'services/regions/state/hooks/useLocations';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { Location } from 'model/Location';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  selectOrderCreateSelectedPickupPoint,
  selectOrderCreateSelectedClient,
} from '../../../../../../../../state/selectors';

// Props type
type Props = {
  companyId: string;
  onChange?: (location?: Location) => void;
  defaultSelectedLocationId?: string;
  setLocationFor?: 'client' | 'pickupPoint';
};

// Component
const LocationSelector = ({
  companyId,
  onChange,
  defaultSelectedLocationId,
  setLocationFor = 'pickupPoint',
}: Props) => {
  const { t } = useTranslation('location');
  const form = useFormContext();
  const { locations } = useLocations(companyId);

  const [selected, setSelected] = useState<string>('empty');
  const [emptyForm, setEmptyForm] = useState<boolean>(false);
  const selectedLocation = useMemo(
    () => locations?.find((loc) => loc.id === selected),
    [selected, locations],
  );

  const [filter, setFilter] = useState<string>('');

  const filteredLocations = useMemo(() => {
    const filterParts = filter
      .split(' ')
      .filter((p) => !!p)
      .map((p) => p.toLowerCase());

    return locations?.filter((loc) => {
      const fields = [
        loc.addressFormatted,
        loc.address.street,
        loc.address.city,
        loc.address.zipCode,
        loc.name,
      ]
        .filter((f) => !!f)
        .map((f) => f?.toLowerCase());

      return filterParts.every((part) => fields.some((f) => f.includes(part)));
    });
  }, [filter, locations, selected]);

  // remove selection if selected location not in filtered locations
  useEffect(() => {
    if (selected && !filteredLocations?.find((loc) => loc.id === selected)) {
      setSelected('empty');
    }
  }, [filteredLocations, selected, setSelected]);
  // Set default selection when provided
  useEffect(() => {
    if (defaultSelectedLocationId) {
      handleChange(defaultSelectedLocationId);
    }
  }, [locations, defaultSelectedLocationId]);

  const handleChange = (locationId: string) => {
    setSelected(locationId || 'empty');
  };

  useEffect(() => {
    if (onChange) {
      onChange(selectedLocation);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (selected === 'empty') {
      setEmptyForm(true);
    }
  }, [selected]);

  const location = useSelector(
    setLocationFor === 'pickupPoint'
      ? selectOrderCreateSelectedPickupPoint
      : selectOrderCreateSelectedClient,
  );

  useEffect(() => {
    if (location || emptyForm) {
      setEmptyForm(false);

      form.setValue(`${setLocationFor}.name`, location?.name);
      form.setValue(`${setLocationFor}.street`, location?.address.street);
      form.setValue(`${setLocationFor}.city`, location?.address.city);
      form.setValue(`${setLocationFor}.zipCode`, location?.address.zipCode);
      form.setValue(`${setLocationFor}.phone`, location && (location.phone || '-'));
      form.setValue(`${setLocationFor}.locationId`, location?.id);
      form.setValue(`${setLocationFor}.notes`, location?.defaultNote);
    }
  }, [form, location]);

  return locations && locations.length ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          label={t('searchLocation')}
          onChange={(e) => setFilter(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <LabeledSelect
            onChange={(e) => handleChange(e.target.value as string)}
            id="location-selector"
            value={selected || 'empty'}
            size="small"
            fullWidth
            label={t('selectLocation', { count: filteredLocations?.length || 0 })}
          >
            <MenuItem value={'empty'}>-</MenuItem>
            {filteredLocations?.map((location) => (
              <MenuItem value={location.id} key={location.id}>
                <Typography variant="inherit" noWrap>
                  {location.name} (
                  {location.addressFormatted ||
                    (location.address && `${location.address.city}, ${location.address.street}`)}
                  )
                </Typography>
              </MenuItem>
            ))}
          </LabeledSelect>
          {selected !== 'empty' && (
            <IconButton>
              <CloseIcon onClick={() => setSelected('empty')} />
            </IconButton>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  ) : null;
};

const Box = styled(MUIBox)({
  display: 'flex',
});

export default LocationSelector;
