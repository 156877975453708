import { WalletEntry as ApiWalletEntry } from 'api/WalletApi';
import { WalletEntry } from '../model/Wallet';

class Service {
  // Correction details
  public mapToWalletEntry(walletEntry: ApiWalletEntry): WalletEntry {
    return {
      id: walletEntry.id,
      driverId: walletEntry.driverId,
      no: walletEntry.no,
      type: walletEntry.type,
      payoutDriver: walletEntry.payoutDriver,
      payoutClient: walletEntry.payoutClient,
      currency: walletEntry.currency,
      description: walletEntry.description,
      createdAt: new Date(walletEntry.createdAt).getTime(),
      createdBy: walletEntry.createdBy,
      createdByEmail: walletEntry.createdByEmail,
      creationComment: walletEntry.creationComment,
      ...(walletEntry.deletedAt && { deletedAt: new Date(walletEntry.deletedAt).getTime() }),
      ...(walletEntry.deletedByEmail && { deletedByEmail: walletEntry.deletedByEmail }),
      deletedBy: walletEntry.deletedBy,
      deletionReason: walletEntry.deletionReason,
      deletionComment: walletEntry.deletionComment,
    };
  }
}

export const ApiWalletMapper = new Service();
