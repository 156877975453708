import { format } from 'date-fns';
import { Divider } from '@mui/material';
import { useTranslation } from 'app/translations/useTranslation';
import { Renewal } from 'pages/Orders/model/Order';
import * as Styled from './../styled';
import { TimestampsSubsection } from '../../../../TimestampsSubsection';
import { XDelivererSubsection } from '../../../../XDelivererSubsection';
interface Props {
  renewal: Renewal;
}

function formatDate(timestamp?: number): string {
  if (timestamp === undefined) {
    return '---';
  }
  return format(timestamp, 'HH:mm dd.MM.RR');
}

export const RenewalItem = (props: Props) => {
  const { renewal } = props;
  const { t } = useTranslation('order');

  return (
    <Styled.Box>
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.renewals.renewedAt')}:</Styled.RecordLabel>
        <Styled.RecordValue>{formatDate(renewal.renewedAt)}</Styled.RecordValue>
      </Styled.RecordBox>
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.renewals.renewalReason')}:</Styled.RecordLabel>
        <Styled.RecordValue>
          {t(`labels.renewals.renewalReasons.${renewal.renewalReason}`)}
        </Styled.RecordValue>
      </Styled.RecordBox>
      {renewal.cancellationReason && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.renewals.cancellationReason')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {t(`statusReason.ids.${renewal.cancellationReason}`)}
          </Styled.RecordValue>
        </Styled.RecordBox>
      )}

      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.renewals.renewalComment')}:</Styled.RecordLabel>
        <Styled.RecordValue>{renewal.renewalComment}</Styled.RecordValue>
      </Styled.RecordBox>
      <XDelivererSubsection order={props.renewal.orderData} />
      <TimestampsSubsection order={props.renewal.orderData} />
      <Divider />
    </Styled.Box>
  );
};
