import { createBrowserHistory } from 'history';
import { LinkFactory as LoginRoutes } from 'pages/LoginPage';
import { LinkFactory as OrderRoutes } from 'pages/Orders';
import { LinkFactory as CompaniesRoutes } from 'pages/Companies';
import { LinkFactory as ReportRoutes } from 'pages/ReportPage';
import { LinkFactory as UserRoutes } from 'pages/UserPage';
import { LinkFactory as SettingsRoutes } from 'pages/SettingsPage';
import { LinkFactory as DriverRoutes } from 'pages/DriverPage';
import { LinkFactory as WarehouseRoutes } from 'pages/WarehousePage';
import { LinkFactory as StockRoutes } from 'pages/StockPage';
import { LinkFactory as MultiplierRoutes } from 'pages/MultiplierPage';
import { LinkFactory as CorrectionRoutes } from 'pages/CorrectionPage';
import { LinkFactory as WalletRoutes } from 'pages/WalletPage';
import { LinkFactory as RegisterRoutes } from 'pages/RegisterPage';

export const History = createBrowserHistory();

export function navigate(url: string) {
  History.push(url);
}

export function openNewTab(url: string) {
  window.open(url, '_blank');
}

export const AppRoutes = {
  root: () => '/',
  login: LoginRoutes,
  order: OrderRoutes,
  companies: CompaniesRoutes,
  report: ReportRoutes,
  user: UserRoutes,
  settings: SettingsRoutes,
  driver: DriverRoutes,
  warehouse: WarehouseRoutes,
  stock: StockRoutes,
  multiplier: MultiplierRoutes,
  correction: CorrectionRoutes,
  wallet: WalletRoutes,
  register: RegisterRoutes,
};
