import {
  Correction as ApiCorrection,
  PendingCorrection as ApiPendingCorrection,
} from 'api/CorrectionApi';
import { Correction } from '../model/Correction';
import { PendingCorrection } from '../model/PendingCorrection';

class Service {
  // Correction details
  public mapToCorrection(correction: ApiCorrection): Correction {
    return {
      id: correction.id,
      driverId: correction.driverId,
      driverEmail: correction.driverEmail,
      orderNo: correction.orderNo,
      orderId: correction.orderId,
      createdAt: new Date(correction.createdAt).getTime(),
      data: correction.data,
      category: correction.category,
      status: correction.status,
      estimatedPayoutDriver: correction.estimatedPayoutDriver,
      payoutDriver: correction.payoutDriver,
      currency: correction.currency,
      estimatedDistance: correction.estimatedDistance,
      recalculatedDistance: correction.recalculatedDistance,
      suggestedCorrectionPay: correction.suggestedCorrectionPay,
      packageSize: correction.packageSize,
      actualPackageSize: correction.data.actualPackageSize,
      clientsAddress: correction.clientsAddress,
      driversComment: correction.driversComment,
      attachmentUrl: correction.attachmentUrl,
    };
  }
  // list
  public mapToPendingCorrection(correction: ApiPendingCorrection): PendingCorrection {
    return {
      id: correction.id,
      companyImageUrl: correction.companyImageUrl,
      companyName: correction.companyName,
      orderNo: correction.orderNo,
      createdAt: new Date(correction.createdAt).getTime(),
      driverEmail: correction.driverEmail,
    };
  }
}

export const ApiCorrectionMapper = new Service();
