import { styled } from '@mui/material/styles';
import { useAuth } from 'services/authorization';
import React, { useCallback, useState } from 'react';
import { useTranslation } from '../../app/translations/useTranslation';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LeftBgTheme from './components/bg-theme-left.png';
import RightBgTheme from './components/bg-theme-right.png';
import { LocationsManager } from '../SettingsPage/components/Location/LocationsManager';
import { Location } from '../../model/Location';
import { PrimaryButton } from '../../components/Button/Buttons';
import { AsyncStatus } from '../../utlis/State';

interface Props {}

export function LocationsPage(props: Props) {
  const { t } = useTranslation('register');
  const [tempLocations, setTempLocations] = useState<Partial<Location>[]>([]);
  const addTempLocation = useCallback(
    (newLocation) => {
      return setTempLocations([...tempLocations, newLocation]);
    },
    [tempLocations],
  );

  const removeTempLocation = useCallback(
    (tempLocationIndex: number) => {
      return setTempLocations(
        tempLocations.filter((tempLocation, index) => index !== tempLocationIndex),
      );
    },
    [tempLocations],
  );
  const { isAuthorized, addLocations, addLocationsStatus } = useAuth();
  const classes = {};

  const submitLocations = useCallback(() => {
    addLocations({ locations: tempLocations });
  }, [tempLocations]);

  return (
    <Container>
      <InnerContainer>
        <Alert severity="info">
          <AlertTitle>{t('stepLocations.infoAlert.title')}</AlertTitle>
          {t('stepLocations.infoAlert.body')}
        </Alert>
        <MainContainer>
          <HeaderContainer>
            <HeadlineContainer>
              <MainHeader>{t('stepLocations.header')}</MainHeader>
            </HeadlineContainer>
          </HeaderContainer>
          <LocationsManager
            locations={tempLocations}
            onAddLocation={addTempLocation}
            onDeleteLocation={removeTempLocation}
            max={10}
          />
          <ActionContainer>
            {tempLocations?.length > 0 ? (
              <SuccessSubmitButton
                fullWidth
                color={'success'}
                variant={'contained'}
                onClick={submitLocations}
                disabled={addLocationsStatus === AsyncStatus.Pending}
                loading={addLocationsStatus === AsyncStatus.Pending}
              >
                {t('stepLocations.successSubmitButton')}
              </SuccessSubmitButton>
            ) : (
              <SubmitButton
                fullWidth
                color={'success'}
                variant={'outlined'}
                onClick={submitLocations}
                disabled={addLocationsStatus === AsyncStatus.Pending}
                loading={addLocationsStatus === AsyncStatus.Pending}
              >
                {t('stepLocations.submitButton')}
              </SubmitButton>
            )}
          </ActionContainer>
        </MainContainer>
      </InnerContainer>
    </Container>
  );
}

const SuccessSubmitButton = styled(PrimaryButton)`
  margin-top: 30px;
`;

const SubmitButton = styled(PrimaryButton)`
  margin-top: 30px;
`;

const ActionContainer = styled('div')``;
const MainContainer = styled('div')`
  margin-top: 20px;
  width: 100%;
`;

const InnerContainer = styled('div')(
  ({ theme }) => `
  width: 100%;
  padding: 20px;
  @media (min-width: 975px) {
      width: 70%;  
      padding: 60px;
  }
 
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`,
);

const Container = styled('div')(
  ({ theme }) => `
  padding: 60px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url('${LeftBgTheme}'), url('${RightBgTheme}');
  background-size: auto;
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
`,
);

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 60px;
`;
const MainHeader = styled('h1')`
  letter-spacing: 2px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
`;

const HeadlineContainer = styled('div')`
  display: flex;
  align-items: center;
`;
