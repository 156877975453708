import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useCompanyData() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectUpdateCompanyDataStatus());

  return {
    updateCompanyData: (params: Slice.ActionTypes.UpdateCompanyData) => {
      dispatch(Slice.actions.updateCompanyData(params));
    },
    isInProgress: status === AsyncStatus.Pending,
  };
}
