import React from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';

import { navigate } from 'app/Navigation';
import { AppConfig } from 'app/Config';

import { useCompany } from 'services/companies/state/useCompanies';
import Page from 'components/Page/Page';
import NotificationCard from 'components/Cards/NotificationCard';
import LinkFactory from '../LinkFactory';
import OrderSettings from './show/OrderSettings';
import DeliverySettings from './show/DeliverySettings';
import MobileAppSettings from './show/MobileAppSettings';
import ActivationSettings from './show/ActivationSettings';

// Props type
type Props = {};

// Component
const CompanyPage = (props: Props) => {
  const { t } = useTranslation('company');
  const { companyId } = useParams() as { companyId: string };

  const company = useCompany(companyId);

  return (
    <>
      <Page>
        <Page.Header>
          {t('company.title', { name: company?.name || companyId })}
          {!!company?.imageFile && (
            <LogoImage src={`${AppConfig.backendUrl}/static/images/${company.imageFile}`} />
          )}
        </Page.Header>

        {company && (
          <Grid container spacing={2}>
            {/*<Grid item>
              <OrderSettings company={company} />
            </Grid>*/}
            <Grid item>
              <ActivationSettings company={company} />
            </Grid>
            <Grid item>
              <DeliverySettings company={company} />
            </Grid>
            {/*<Grid item>
              <MobileAppSettings company={company} />
            </Grid>*/}
          </Grid>
        )}
      </Page>

      {!company && (
        <NotificationCard
          icon={<DomainDisabledIcon fontSize="inherit" />}
          message={t('company.companyNotFound')}
          actionCaption={t('common:goBackTo', { destination: t('list.title') })}
          onAction={() => navigate(LinkFactory.list())}
        />
      )}
      {/*<pre>{JSON.stringify(company, undefined, 2)}</pre>*/}
    </>
  );
};

const Box = styled(MUIBox)({});

const LogoImage = styled('img')(
  ({ theme }) => `
  margin-left: ${theme.spacing(1)}; 
  vertical-align: middle;
  max-height: 1.5em;
`,
);

export default CompanyPage;
