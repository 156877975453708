import { styled } from '@mui/material/styles';
import { useAuth } from 'services/authorization';
import React, { useMemo, useState } from 'react';
import { RegisterForm } from './components/RegisterForm/RegisterForm';
import { LogoVersion, XDLogo } from '../../assets/Logo';
import { useTranslation } from '../../app/translations/useTranslation';
import { Trans } from 'react-i18next';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import IconButton from '@mui/material/IconButton';
import { ProductForm } from './components/ProductForm/ProductForm';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LeftBgTheme from './components/bg-theme-left.png';
import RightBgTheme from './components/bg-theme-right.png';
import { LanguageSwitcher } from '../../components/Layouts/components/Menu/components/LanguageSwitcher';
import Link from '@mui/material/Link';

interface Props {}

export function ProductPage(props: Props) {
  const { t } = useTranslation('register');
  const { isAuthorized } = useAuth();
  const classes = {};

  return (
    <Container>
      <InnerContainer>
        <Alert severity="success">
          <AlertTitle>{t('stepProductSelect.successAlert.title')}</AlertTitle>
          <Trans t={t} i18nKey="stepProductSelect.successAlert.body">
            <Link href={'mailto:support@xpress.delivery'} />
            <strong />
          </Trans>
        </Alert>
        <MainContainer>
          <HeaderContainer>
            <HeadlineContainer>
              <MainHeader>{t('stepProductSelect.header')}</MainHeader>
            </HeadlineContainer>
          </HeaderContainer>
          <ProductForm />
        </MainContainer>
        {/*<LanguageSwitchContainer>
          <LanguageSwitcher />
        </LanguageSwitchContainer>*/}
      </InnerContainer>
    </Container>
  );
}

const MainContainer = styled('div')`
  margin-top: 60px;
  width: 100%;
  padding-bottom: 60px;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 60px;
`;
const MainHeader = styled('h1')`
  letter-spacing: 2px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
`;

const HeadlineContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const InnerContainer = styled('div')(
  ({ theme }) => `
 
  width: 100%;
  padding: 20px;
  @media (min-width: 1200px) {
      width: 70%;  
      padding: 60px;
  }
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`,
);

const Container = styled('div')(
  ({ theme }) => `
  padding: 60px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url('${LeftBgTheme}'), url('${RightBgTheme}');
  background-size: auto;
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
`,
);
