import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useDeactivateUser() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectDeactivateUserStatus());

  return {
    deactivateUser: (params: Slice.ActionTypes.DeactivateUser) => {
      dispatch(Slice.actions.deactivateUser(params));
    },
    isInProgress: status === AsyncStatus.Pending,
  };
}
