import { createSlice } from '@reduxjs/toolkit';

import { State } from './model/State';
import * as reducers from './reducers';
import initialState from './initialState';

const slice = createSlice<State, typeof reducers>({
  name: 'orderCreate',
  initialState,
  reducers: { ...reducers },
});

export const { name, actions, reducer } = slice;

export const selectState = (state: any) => state[name] as State;
