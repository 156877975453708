import { useState } from 'react';
import { MenuProps, Menu } from './Menu';

export function useMenu() {
  const [isExtended, setIsExtended] = useState<boolean>(false);
  const menuProps: MenuProps = {
    isExtended,
  };

  return {
    Menu,
    menuProps,
    toggleMenu: () => setIsExtended((prev) => !prev),
    isExtended,
  };
}
