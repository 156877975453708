import React from 'react';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import { Button } from 'components/Button/Buttons';

interface Props {
  onGenerate?: VoidFunction;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export function ActionBox(props: Props) {
  const { t } = useTranslation('report');

  return (
    <Box>
      <StyledButton
        disabled={props.isDisabled || props.isLoading}
        loading={props.isLoading}
        onClick={props.onGenerate}
        variant="contained"
        color="primary"
      >
        {t('actions.generate')}
      </StyledButton>
    </Box>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1)} ${theme.spacing(3.5)};
  display: flex;
  justify-content: right;
`,
);

const StyledButton = styled(Button)`
  width: 200px;
  height: 50px;
`;
