import React from 'react';

import { styled } from '@mui/material/styles';
import LogoImagePath from './logo.png';
import Logo2RowsImagePath from './logo_2_rows.png';

export enum LogoVersion {
  OneLine,
  TwoLine,
}

function getImageSource(version: LogoVersion) {
  return version === LogoVersion.OneLine ? LogoImagePath : Logo2RowsImagePath;
}

export function XDLogo(props: { version?: LogoVersion }) {
  const { version = LogoVersion.OneLine } = props;

  return <Image src={getImageSource(version)} alt={'Logo'} />;
}

const Image = styled('img')`
  max-height: 100%;
  max-width: 100%;
`;
