import { useTranslation } from 'app/translations/useTranslation';
import { Trans } from 'react-i18next';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import * as Styled from './styled';
import { useProductForm } from '../../hooks/useProductForm';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import IconButton from '@mui/material/IconButton';
import { PrimaryButton } from '../../../../components/Button/Buttons';
import { useAuth } from '../../../../services/authorization';
import { AsyncStatus } from '../../../../utlis/State';

interface Props {
  onSubmit?: () => void;
  submit?: (formValues: any) => void;

  submitButton?: ReactNode;
}

export function ProductForm(props: Props) {
  const { t, i18n } = useTranslation('register');
  const { selectProduct, selectProductStatus } = useAuth();
  const form = useProductForm();
  const submit = useCallback(
    (formValues: any) => {
      selectProduct({
        defaultPackageSize: formValues.defaultPackageSize,
      });
    },
    [form],
  );

  const packageSizes: { value: string; component?: JSX.Element; display?: string }[] =
    useMemo(() => {
      return [
        {
          value: 'S',
          component: (
            <PackageSizeItem>
              <Typography>{t('stepProductSelect.packageSizes.S.label')}</Typography>
              <Typography variant={'body2'}>
                {t('stepProductSelect.packageSizes.S.dimensions')}
              </Typography>
              <Typography variant={'body2'}>
                {t('stepProductSelect.packageSizes.S.weight')}
              </Typography>
            </PackageSizeItem>
          ),
        },
        {
          value: 'M',
          component: (
            <PackageSizeItem>
              <Typography>{t('stepProductSelect.packageSizes.M.label')}</Typography>
              <Typography variant={'body2'}>
                {t('stepProductSelect.packageSizes.M.dimensions')}
              </Typography>
              <Typography variant={'body2'}>
                {t('stepProductSelect.packageSizes.M.weight')}
              </Typography>
            </PackageSizeItem>
          ),
        },
        {
          value: 'L',
          component: (
            <PackageSizeItem>
              <Typography>{t('stepProductSelect.packageSizes.L.label')}</Typography>
              <Typography variant={'body2'}>
                {t('stepProductSelect.packageSizes.L.dimensions')}
              </Typography>
              <Typography variant={'body2'}>
                {t('stepProductSelect.packageSizes.L.weight')}
              </Typography>
            </PackageSizeItem>
          ),
        },
      ];
    }, [t]);
  return (
    <FormProvider {...form}>
      <Styled.Form onSubmit={form.handleSubmit(submit)}>
        <Grid container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <SelectedCard>
                <CardHeader
                  disableTypography={true}
                  title={
                    <SelectedProductContainer>
                      <SelectedProductIcon />{' '}
                      <Typography variant={'subtitle2'}>
                        {t('stepProductSelect.products.selected')}
                      </Typography>
                    </SelectedProductContainer>
                  }
                ></CardHeader>

                <CardContent>
                  <Box>
                    <Typography variant="h4" gutterBottom={true}>
                      {t('stepProductSelect.products.pickup.title')}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {t('stepProductSelect.products.pickup.body')}
                    </Typography>
                    <Styled.Select
                      name={'defaultPackageSize'}
                      label={t('stepProductSelect.fields.defaultProductSize.label')}
                      dataCy={'input-city'}
                      values={packageSizes}
                      defaultValue={'M'}
                      fullWidth
                    />
                  </Box>
                </CardContent>
              </SelectedCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  disableTypography={true}
                  title={<SelectedProductContainer />}
                ></CardHeader>

                <CardContent>
                  <Box>
                    <Typography variant="h4" gutterBottom={true}>
                      {t('stepProductSelect.products.simple.title')}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {t('stepProductSelect.products.simple.body')}
                    </Typography>
                  </Box>
                </CardContent>
                <CardContent>
                  <PrimaryButton href={'mailto:support@xpress.delivery'} endIcon={<SendIcon />}>
                    <Trans t={t} i18nKey="stepProductSelect.products.simple.contactButton">
                      <strong />
                    </Trans>
                  </PrimaryButton>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {/*<Styled.FormDivider />*/}
        <SubmitButton
          color={'success'}
          variant={'contained'}
          loading={selectProductStatus === AsyncStatus.Pending}
          disabled={selectProductStatus === AsyncStatus.Pending}
          type={'submit'}
          dataCy={'button-select_product'}
        >
          {t('stepProductSelect.submitButton')}
        </SubmitButton>
      </Styled.Form>
    </FormProvider>
  );
}

const SelectedCard = styled(Card)`
  border: #f1c232 3px solid;
`;
const SelectedProductContainer = styled('div')`
  display: flex;
  flex-direction: row;
  color: #f1c232;
  justify-content: flex-start;
  align-items: center;
  min-height: 24px;
`;

const SelectedProductIcon = styled(CheckCircleOutlinedIcon)`
  margin-right: 6px;
`;

const PackageSizeItem = styled('div')``;

export const SubmitButton = styled(PrimaryButton)(
  ({ theme }) => `
  margin-top: ${theme.spacing(4)};
`,
);

const Box = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
