import { AuthApi } from 'api/AuthApi';
import { SimpleGenerator } from 'api/Request/ApiRequestSaga';
import { HttpError } from 'api/Request/RequestErrors';
import { AppRoutes, navigate } from 'app/Navigation';
import { translate } from 'app/translations/useTranslation';
import { delay, fork, takeLatest } from 'redux-saga/effects';
import { addErrorToast, addInfoToast, addSuccessToast } from 'services/toast';
import { put, select, call } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { RootReducerActions } from 'app/state/RootReducerActions';
import { StoredToken } from '../StoredToken';
import { ApiTokenToPermissionsMapper } from './ApiTokenToPermissionsMapper';
import * as Slice from './slice';
import { AuthorizedInitSteps, LogoutSteps } from './StepRegister';
import { ApiTokenToSettings } from './ApiTokenToSettings';
import { ApiTokenToRegistrationSteps } from './ApiTokenToRegistrationSteps';

function* initialize() {
  const { isUserSessionValid } = yield* initializePreAuthorizedStage();
  if (isUserSessionValid === true) {
    yield initializeAuthorizedStage();
  }

  yield put(Slice.actions.initializeAppSuccess());
}

function* initializePreAuthorizedStage() {
  let isUserSessionValid = false;
  if (StoredToken.isSet() === true) {
    const result = yield* refreshToken();
    isUserSessionValid = result.hasValidToken;
  }

  yield put(Slice.actions.initializePreAuthorizedStageSuccess());
  return { isUserSessionValid };
}

function* initializeAuthorizedStage() {
  try {
    yield* AuthorizedInitSteps.execute();

    const token = StoredToken.getValueOrThrow();
    const permissions = ApiTokenToPermissionsMapper.map(token);
    const settings = ApiTokenToSettings.map(token);
    const registrationSteps = ApiTokenToRegistrationSteps.map(token);

    yield put(
      Slice.actions.initializeAuthorizedStageSuccess({ permissions, settings, registrationSteps }),
    );
  } catch (e) {
    console.log(e);
    yield call(addErrorToast, {
      id: 'authorized_stage_failure',
      message: translate('auth', 'notifications.init.authorizedStageFailure'),
    });
    yield put(Slice.actions.initializeAuthorizedStageError());
  }
}

function* reinitializeAuthorizedStage() {
  yield* initializeAuthorizedStage();
  yield put(Slice.actions.reinitializeAuthorizedFinished());
}

const REFRESH_TOKEN_INTERVAL = 10 * 60 * 1000; // 10 min
function* refreshTokenCyclically() {
  for (;;) {
    yield delay(REFRESH_TOKEN_INTERVAL);
    const isAuthorized = yield* select(Slice.selectors.makeSelectIsAuthorized());
    if (isAuthorized === false) {
      continue;
    }

    const result = yield* refreshToken();
    if (result.hasValidToken === false) {
      yield put(
        Slice.actions.logOut({
          message: {
            content: translate('auth', 'notifications.tokenExpired'),
            id: 'token_expired',
          },
        }),
      );
    }
  }
}

function* refreshToken(): SimpleGenerator<{ hasValidToken: boolean }> {
  try {
    const response = yield* call(AuthApi.refreshToken);
    StoredToken.setValue(response.token);
    return { hasValidToken: true };
  } catch (e: unknown) {
    if (e instanceof HttpError && e.status === 401) {
      return { hasValidToken: false };
    } else {
      throw e;
    }
  }
}

function* logIn(action: PA<Slice.ActionTypes.LogIn>) {
  try {
    const response = yield* call(AuthApi.logIn, action.payload);
    StoredToken.setValue(response.token);
    yield call(addSuccessToast, {
      message: translate('auth', 'notifications.loggedIn'),
      id: 'logging_in',
    });
    yield put(Slice.actions.logInSuccess());
  } catch (e) {
    if (e instanceof HttpError && (e.status === 404 || e.status === 401)) {
      yield call(addErrorToast, {
        message: translate('auth', 'notifications.invalidCredentials'),
        id: 'invalid_credentials',
      });
    } else {
      console.log(e);
    }
    yield put(Slice.actions.logInError());
  }
}

function* logOut(action: PA<Slice.ActionTypes.LogOut>) {
  yield* LogoutSteps.execute();

  try {
    yield call(AuthApi.logOut);
  } catch (e: unknown) {
    if (e instanceof HttpError === false) {
      throw e;
    }
  }

  const message = action.payload.message;
  StoredToken.unset();
  yield call(addInfoToast, { message: message.content, id: message.id });
  yield put(Slice.actions.logOutSuccess());
  yield call(navigate, AppRoutes.login.root());
}

function* redirectFromRestrictedZone() {
  yield call(addInfoToast, {
    message: translate('auth', 'notifications.restrictedArea'),
    id: 'restricted_area',
  });
  yield call(navigate, AppRoutes.login.root());
}

function* triggerAuthorizedStageReinitialization() {
  yield put(Slice.actions.reinitializeAuthorizedStage());
}

function* resetState() {
  yield put(RootReducerActions.resetState());
}

function replaceToken({ payload }: PA<Slice.ActionTypes.ReplaceToken>) {
  StoredToken.setValue(payload.token);
}

function* registerClient(action: PA<Slice.ActionTypes.RegisterClient>) {
  try {
    const response = yield* call(AuthApi.registerClient, {
      email: action.payload.email,
      name: action.payload.name,
      phone: action.payload.phone,
      city: action.payload.city,
      deliveryProductType: action.payload.deliveryProductType,
      password: action.payload.password,
    });
    StoredToken.setValue(response.token);
    yield call(addSuccessToast, {
      message: translate('auth', 'notifications.registered'),
      id: 'registered',
    });
    yield put(Slice.actions.registerClientSuccess());
    // yield call(navigate, AppRoutes.register.selectProduct());
  } catch (e) {
    let errMsg;
    if (e instanceof HttpError && (e.status === 404 || e.status === 401)) {
      yield call(addErrorToast, {
        message: translate('auth', 'notifications.unknownRegistrationError'),
        id: 'unknown_registration_error',
      });
    } else {
      errMsg = e.response?.errorCode
        ? translate('register', `registration.fields.email.errors.${e.response.errorCode}`, {
            defaultValue: translate('register', 'registration.errors.unknown'),
          })
        : '';
    }
    yield put(Slice.actions.registerClientError({ error: errMsg }));
  }
}

function* redirectToProductSelectPage() {
  yield call(navigate, AppRoutes.register.selectProduct());
}

function* redirectToLocationsPage() {
  yield call(navigate, AppRoutes.register.addLocations());
}

function* redirectToMainPage() {
  yield call(navigate, AppRoutes.root());
}

function* selectProduct(action: PA<Slice.ActionTypes.SelectProduct>) {
  try {
    const response = yield* call(AuthApi.selectProduct, {
      defaultPackageSize: action.payload.defaultPackageSize,
    });
    /*yield call(addSuccessToast, {
      message: translate('auth', 'notifications.productSelected'),
      id: 'registered',
    });*/
    yield put(Slice.actions.selectProductSuccess());
    yield call(navigate, AppRoutes.register.addLocations());
  } catch (e) {
    let errMsg;
    if (e instanceof HttpError && (e.status === 404 || e.status === 401)) {
      yield call(addErrorToast, {
        message: translate('auth', 'notifications.unknownRegistrationError'),
        id: 'unknown_select_product_error',
      });
    } else {
      yield call(addErrorToast, {
        message: translate('auth', 'notifications.unknownRegistrationError'),
        id: 'unknown_select_product_error',
      });
      /*errMsg = e.response?.errorCode
        ? translate('register', `registration.fields.email.errors.${e.response.errorCode}`, {
            defaultValue: translate('register', 'registration.errors.unknown'),
          })
        : '';*/
    }
    yield put(Slice.actions.selectProductError({ error: errMsg }));
  }
}

function* addLocations(action: PA<Slice.ActionTypes.AddLocations>) {
  try {
    yield* call(AuthApi.addLocations, {
      locations: action.payload.locations,
    });
    /*yield call(addSuccessToast, {
      message: translate('auth', 'notifications.productSelected'),
      id: 'registered',
    });*/
    yield put(Slice.actions.addLocationsSuccess());
    yield call(navigate, AppRoutes.root());
  } catch (e) {
    let errMsg;
    if (e instanceof HttpError && (e.status === 404 || e.status === 401)) {
      yield call(addErrorToast, {
        message: translate('auth', 'notifications.unknownRegistrationError'),
        id: 'unknown_select_product_error',
      });
    } else {
      /*errMsg = e.response?.errorCode
        ? translate('register', `registration.fields.email.errors.${e.response.errorCode}`, {
            defaultValue: translate('register', 'registration.errors.unknown'),
          })
        : '';*/
    }
    yield put(Slice.actions.addLocationsError({ error: errMsg }));
  }
}

export function* saga() {
  yield takeLatest(Slice.actions.initialize, initialize);
  yield takeLatest(Slice.actions.reinitializeAuthorizedStage, reinitializeAuthorizedStage);
  yield takeLatest(Slice.actions.logIn, logIn);
  yield takeLatest(Slice.actions.logOut, logOut);
  yield takeLatest(Slice.actions.logInSuccess, triggerAuthorizedStageReinitialization);
  yield takeLatest(Slice.actions.logOutSuccess, resetState);
  yield takeLatest(Slice.actions.replaceToken, replaceToken);
  yield takeLatest(Slice.actions.registerClient, registerClient);
  yield takeLatest(Slice.actions.registerClientSuccess, triggerAuthorizedStageReinitialization);
  yield takeLatest(Slice.actions.selectProduct, selectProduct);
  yield takeLatest(Slice.actions.addLocations, addLocations);

  // redirects
  yield takeLatest(Slice.actions.redirectFromRestrictedZone, redirectFromRestrictedZone);
  yield takeLatest(Slice.actions.redirectToLocationsPage, redirectToLocationsPage);
  yield takeLatest(Slice.actions.redirectToProductSelectPage, redirectToProductSelectPage);
  yield takeLatest(Slice.actions.redirectToMainPage, redirectToMainPage);

  yield fork(refreshTokenCyclically);
}
