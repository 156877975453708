import MenuItem from '@mui/material/MenuItem';
import { default as MuiSelect } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { useLanguageManager } from 'app/translations/useLanguageManager';
import { useTranslation } from 'app/translations/useTranslation';

interface Props {
  className?: string;
}

export function LanguageSwitcher(props: Props) {
  const { t } = useTranslation('common');
  const { supportedLangs, changeLang, selectedLang } = useLanguageManager();

  return (
    <Box className={props.className}>
      <div />
      <Select
        id={'select-change_language'}
        value={selectedLang}
        onChange={(e) => {
          const value = e.target.value;
          if (typeof value === 'string') {
            changeLang(value);
          }
        }}
      >
        {supportedLangs.map((lang) => {
          const label = t(`languages.${lang}.label`);
          return (
            <MenuItem key={lang} value={lang} data-label={label}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      <div />
    </Box>
  );
}

const Box = styled('div')`
  display: grid;
  grid-template-columns: 60px auto 60px;
`;

const Select = styled(MuiSelect)`
  margin: 0 auto;
`;
