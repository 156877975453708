import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';

import useMUIDataTableDefaultOptions from 'utlis/MUIDataTables/useMUIDataTableDefaultOptions';

import TableFiltersProvider from '../../../../components/dataTables/filters/TableFiltersProvider';
import TableFiltersToolbar from '../../../../components/dataTables/filters/TableFiltersToolbar';
import { useDrivers } from '../../state/useDrivers';
import { Filters } from '../Filters/Filters';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { DriverRowDetails } from './components/Row/components/DriverRowDetails/DriverRowDetails';

interface Props {}

const DriverList = (props: Props) => {
  const { t } = useTranslation('driver');

  const { driverListing, reloadDriverListing } = useDrivers();
  const drivers = useMemo(
    () =>
      driverListing?.list.map((driver) => ({
        ...driver,
        fullName: `${driver.firstName} ${driver.lastName}`,
      })),
    [driverListing],
  );

  const defaultOptions = useMUIDataTableDefaultOptions();
  const options = useMemo<MUIDataTableOptions>(
    () => ({
      ...defaultOptions,
      searchOpen: false,
      searchAlwaysOpen: false,
      search: false,
      download: true,
      viewColumns: false,
      filter: false,
      expandableRows: true,
      expandableRowsOnClick: true,
      expandableRowsHeader: false,
      renderExpandableRow: (rowData, rowMeta) => (
        <TableRow>
          <TableCell colSpan={rowData.length + 1}>
            <DriverRowDetails driver={drivers[rowMeta.dataIndex]} />
          </TableCell>
        </TableRow>
      ),
      customSearchRender: () => <></>,
    }),
    [defaultOptions, drivers],
  );

  const columns = useMemo<MUIDataTableColumn[]>(
    () => [
      {
        name: 'status',
        label: t('list.headers.status'),
      },
      {
        name: 'fullName',
        label: t('list.headers.name'),
      },
      {
        name: 'email',
        label: t('list.headers.email'),
      },
      {
        name: 'region',
        label: t('list.headers.region'),
      },
      {
        name: 'phone',
        label: t('list.headers.phone'),
      },
      {
        name: 'lastKnownLocation.loggedAt',
        label: t('list.headers.lastKnownLocationDate'),
        options: {
          customBodyRender: (timestamp: number) =>
            timestamp ? t('common:datetime.datetime', { date: new Date(timestamp) }) : '-',
        },
      },
      {
        name: 'isAmbassador',
        label: t('list.headers.isAmbassador'),
        options: {
          customBodyRender: (isAmbassador: boolean) =>
            isAmbassador ? t('driverType.ambassador') : t('driverType.driver'),
        },
      },
      {
        name: 'priorityDriver',
        label: t('list.headers.priorityDriver'),
        options: {
          customBodyRender: (priorityDriver: boolean) =>
            priorityDriver ? t('list.headers.priorityDriver') : '-',
        },
      },
    ],
    [t],
  );

  return (
    <>
      <TableFiltersProvider filters={<Filters />}>
        <MUIDataTable
          title=""
          data={drivers}
          columns={columns}
          options={options}
          components={{ TableToolbar: TableFiltersToolbar }}
        />
      </TableFiltersProvider>
    </>
  );
};

export default DriverList;
