import React, { useMemo } from 'react';

import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Styled from './styled';
import LoadingButton from '@mui/lab/LoadingButton';

import { AsyncStatus } from 'utlis/State';
import { useTranslation } from 'app/translations/useTranslation';

import { Input } from 'components/ReactHookForm/Input/Input';
import {
  DeleteWalletEntryFormValues,
  useDeleteWalletEntryForm,
  useDeleteWalletEntryFormReset,
} from './hooks/useDeleteWalletEntryForm';
import { useDeleteWalletEntry } from '../../../../../../state/useDeleteWalletEntry';
import { WalletEntry } from '../../../../../../model/Wallet';
import { useWallet } from '../../../../../../state/useWallet';

interface Props {
  walletEntryId: string;
}

export function DeleteWalletEntryForm(props: Props) {
  const { t } = useTranslation('wallet');
  const { deleteWalletEntry, status } = useDeleteWalletEntry();
  const { getWalletEntry } = useWallet();
  const walletEntry = getWalletEntry(props.walletEntryId);

  const form = useDeleteWalletEntryForm();
  const reset = useDeleteWalletEntryFormReset();

  const onSubmit: SubmitHandler<DeleteWalletEntryFormValues> = (formValues) => {
    deleteWalletEntry({
      comment: formValues.comment,
      reason: formValues.reason,
      walletEntryId: props.walletEntryId,
    });
  };

  const onReset = () => {
    reset();
  };
  return (
    <Styled.Box>
      <Styled.DetailsBox>
        {t('walletEntry.deleteModal.body', { no: walletEntry?.no })}
      </Styled.DetailsBox>
      <FormProvider {...form}>
        <Styled.Form
          onSubmit={form.handleSubmit<DeleteWalletEntryFormValues>(onSubmit)}
          onReset={onReset}
        >
          <Styled.DetailsBox>
            <Input
              name={'comment'}
              label={t('walletEntry.deleteModal.form.fields.deletionComment')}
              dataCy={'input-comment'}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <Input
              name={'reason'}
              label={t('walletEntry.deleteModal.form.fields.deletionReason')}
              dataCy={'input-reason'}
            />
          </Styled.DetailsBox>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={status === AsyncStatus.Pending}
            loading={status === AsyncStatus.Pending}
          >
            {t('walletEntry.deleteModal.form.actions.submit')}
          </LoadingButton>
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
