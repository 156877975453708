import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { useCompanies } from '../../../../../services/companies/state/useCompanies';

export type CompanyDataFormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  headquartersIn: string;
  nip: string;
  regon: string;
  street: string;
  zipCode: string;
  city: string;
};

export const useDefaultValues = () => {
  const { companies } = useCompanies();

  return useMemo(() => {
    const company = companies.length === 1 ? companies[0] : undefined;
    if (company && company.contractData) {
      return {
        regon: company.contractData?.regon,
        nip: company.contractData?.nip,
        firstName: company.contractData?.firstName,
        lastName: company.contractData?.lastName,
        companyName: company.contractData?.name,
        street: company.contractData?.street,
        zipCode: company.contractData?.zipCode,
        city: company.contractData?.city,
        headquartersIn: company.contractData?.headquartersIn,
      };
    }
    return {};
  }, [companies]);
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      companyName: yup.string().required(),
      headquartersIn: yup.string().required(),
      nip: yup.string().required(),
      regon: yup.string().required(),
      street: yup.string().required(),
      zipCode: yup.string().required(),
      city: yup.string().required(),
    });
  }, [t]);
}

export function useCompanyDataForm() {
  const defaultValues = useDefaultValues();
  const validationSchema = useValidationSchema();
  return useForm<CompanyDataFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  });
}
