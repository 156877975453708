import React from 'react';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';

// Props type
type Props = {
  icon?: React.ReactNode;
  title?: string;
  message?: string;
  error?: React.ReactNode;
  children?: React.ReactNode;
  actionCaption?: string;
  onAction?: VoidFunction;
};

// Component
const NotificationCard = ({
  icon,
  title,
  message,
  children,
  error,
  actionCaption,
  onAction,
}: Props) => {
  return (
    <Box>
      {!!icon && <IconWrapper>{icon}</IconWrapper>}
      {!!title && (
        <Typography variant="h5" component="div">
          {title}
        </Typography>
      )}
      {!!message && (
        <Typography variant="subtitle1" component="div">
          {message}
        </Typography>
      )}
      {!!error && <FormHelperText error>{error}</FormHelperText>}
      {children}
      {actionCaption && onAction && (
        <ActionButton onClick={onAction} variant="contained" color="primary">
          {actionCaption}
        </ActionButton>
      )}
    </Box>
  );
};

const Box = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const IconWrapper = styled(MUIBox)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '3em',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export default NotificationCard;
