import React from 'react';

import { useTranslation } from 'app/translations/useTranslation';
import { Link } from 'components/Link/Link';

interface Props {
  message?: string;
  onRefresh?: VoidFunction;
}

export function SthWentWrong({ message, onRefresh }: Props) {
  const { t } = useTranslation('common');

  return (
    <>
      {message || t('prompts.sthWentWrongTryAgainLater')}
      {!!onRefresh && <Link onClick={onRefresh}>[{t('refresh')}]</Link>}
    </>
  );
}
