import { useWatch } from 'react-hook-form';
import { useCompanies } from 'services/companies/state/useCompanies';

const useOrderCompany = () => {
  const companyId = useWatch({ name: 'companyId' });
  const { companies } = useCompanies();

  return companyId && companies.find((company) => company.id === companyId);
};

export default useOrderCompany;
