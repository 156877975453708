import { useTranslation } from 'app/translations/useTranslation';
import { Input } from 'components/ReactHookForm/Input/Input';
import { useTransferStock } from 'pages/StockPage/state/useTransferStock';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useCreateForm, CreateFormValues } from './hooks/useCreateForm';
import * as Styled from './styled';

interface Props {
  productId: string;
  stockAmount: number;
}


export function TransferStockForm(props: Props) {
  const { t } = useTranslation('stock');
  const { transferStock, isInProgress: isInProgressTransferStock } = useTransferStock();

  const form = useCreateForm({
    stockAmount: props.stockAmount,
  });
  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.Form onSubmit={form.handleSubmit((formValues: CreateFormValues) => {
          transferStock({
            amount: formValues.amount,
            productId: props.productId,
          });
        })}>
          <Input
            name={'amount'}
            type={'number'}
            label={t('stockTransfer.labels.amount')}
            dataCy={'input-product-amount'}
          />
          <Styled.SaveButton
            type={'submit'}
            dataCy={'button-transfer_stock'}
            isInProgress={isInProgressTransferStock}
          >
            {t('buttons.transferStock')}
          </Styled.SaveButton>
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}

