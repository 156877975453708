import { all, call, put, takeLatest } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { actions } from '../slice';
import OrderCreateActionTypes from '../model/OrderCreateActionTypes';
import { OrderApiSaga } from 'api/OrderApi';
import { addErrorToast, addSuccessToast } from 'services/toast';
import { translate } from 'app/translations/useTranslation';
import { ApiDeliveryAvailabilityMapper } from '../ApiDeliveryAvailabilityMapper';

function* checkDeliveryAvailability({
  payload,
}: PA<OrderCreateActionTypes.CheckDeliveryAvailability>) {
  try {
    const availability = yield* OrderApiSaga.checkDeliveryAvailability(payload);
    yield put(
      actions.checkDeliveryAvailabilitySuccess({
        deliveryAvailability: ApiDeliveryAvailabilityMapper.mapToDeliveryAvailability(availability),
        lastQueryParams: payload,
      }),
    );
    yield call(addSuccessToast, {
      id: 'check_delivery_availability_success',
      message: translate('order', `checkDeliveryAvailability.success`),
    });
  } catch (error) {
    const code = error?.response?.errorCode || 'unknown';
    yield call(addErrorToast, {
      id: 'check_delivery_availability_failure',
      message: translate(
        'order',
        `checkDeliveryAvailability.errors.${code}`,
        translate('order', 'checkDeliveryAvailability.errors.unknown'),
      ),
    });
    yield put(
      actions.checkDeliveryAvailabilityFailure({ code: error?.response?.data?.error || 'unknown' }),
    );
  }
}

export function* saga() {
  yield all([takeLatest(actions.checkDeliveryAvailability, checkDeliveryAvailability)]);
}
