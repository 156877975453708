import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'app/translations/useTranslation';
import { VehicleProduct } from 'pages/Orders/model/Order';
import * as Styled from '../styled';

interface Props {
  products: VehicleProduct[];
}

export function VehicleProductList(props: Props) {
  const { t } = useTranslation('order');
  return (
    <Styled.Table>
      <TableHead>
        <TableRow>
          <Styled.NameHead>{t('labels.miscData.name')}</Styled.NameHead>
          <Styled.HeadCell>{t('labels.miscData.code')}</Styled.HeadCell>
          <Styled.HeadCell>{t('labels.miscData.vehicle')}</Styled.HeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.products.map((product, index) => (
          <TableRow key={index}>
            <Styled.ContentCell>{product.name}</Styled.ContentCell>
            <Styled.ContentCell>{product.code}</Styled.ContentCell>
            <Styled.ContentCell>{product.vehicle ? `${product.vehicle.mark || ''} ${product.vehicle.model || ''} (${product.vehicle.registrationPlate || ''} ${product.vehicle.vinNumber || ''})` : '-'}</Styled.ContentCell>
          </TableRow>
        ))}
      </TableBody>
    </Styled.Table>
  );
}
