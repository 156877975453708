import { translate } from 'app/translations/useTranslation';
import { takeLeading } from 'redux-saga/effects';
import * as AuthService from 'services/authorization';
import * as CompanyService from 'services/companies';
import { addSuccessToast, addErrorToast } from 'services/toast';
import { call, put } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { MeApi } from 'api/MeApi';
import { CompanyApiSaga } from 'api/CompanyApi';
import { HttpError, BadRequestError } from 'api/Request/RequestErrors';
import * as Auth from 'services/authorization';
import * as Slice from './slice';

import FileSaver from 'file-saver';

function* changePassword({ payload }: PA<Slice.ActionTypes.ChangePassword>) {
  try {
    const response = yield* call(MeApi.Requests.changePassword, {
      newPassword: payload.newPassword,
      oldPassword: payload.oldPassword,
    });
    yield put(AuthService.actions.replaceToken({ token: response.token }));

    yield call(addSuccessToast, {
      message: translate('settings', 'notifications.passwordChanged'),
    });
  } catch (e) {
    if (
      e instanceof BadRequestError &&
      e.response.errorCode === MeApi.ErrorCodes.PasswordNotMatch
    ) {
      yield call(addErrorToast, {
        message: translate('settings', 'notifications.passwordDoesNotMatch'),
      });
    } else if (e instanceof HttpError && e.status === 401) {
      /**
       * Mostly this logic is done by the saga wrapper used in the Api layer, but because 401 is also thrown when the given old password
       * is invalid we cannot rely on it and we had to move the logic here.
       */
      yield put(
        Auth.actions.logOut({
          message: { content: translate('auth', 'notifications.restrictedArea') },
        }),
      );
    } else {
      yield call(addErrorToast, {
        message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
      });
      console.error(e);
    }
  }
  yield put(Slice.actions.changePasswordFinish());
}

function* updateCompanyData({ payload }: PA<Slice.ActionTypes.UpdateCompanyData>) {
  try {
    const response = yield* call(CompanyApiSaga.updateCompanyData, payload);
    // yield put(AuthService.actions.replaceToken({ token: response.token }));

    yield call(addSuccessToast, {
      message: translate('settings', 'notifications.companyDataUpdated'),
    });
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(CompanyService.state.actions.fetchCompanyList());
  yield put(Slice.actions.updateCompanyDataFinish());
}

function* downloadContract() {
  try {
    const file = yield* CompanyApiSaga.getContract();
    FileSaver.saveAs(file, `contract.pdf`);
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('settings', 'notifications.downloadContractFailure'),
    });
  }
  yield put(CompanyService.state.actions.fetchCompanyList());
  yield put(Slice.actions.downloadContractFinished());
}

export function* saga() {
  yield takeLeading(Slice.actions.changePassword, changePassword);
  yield takeLeading(Slice.actions.updateCompanyData, updateCompanyData);
  yield takeLeading(Slice.actions.downloadContract, downloadContract);
}
