import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

// Props type
type Props = {};

// Component
const PackageWarning = (props: Props) => {
  return (
    <SvgIcon {...props}>
      <path d="m12 2c-0.21 0-0.41031 0.059688-0.57031 0.17969l-7.9004 4.4395c-0.32 0.17-0.5293 0.50086-0.5293 0.88086v9c0 0.38 0.2093 0.71086 0.5293 0.88086l7.0293 3.9492c0.00218-0.00387 0.003621-0.00786 0.005859-0.011719l2.4355-4.207v-4.5312l6-3.3691v6.375l1.1465 1.9785 0.32422-0.18359c0.32-0.17 0.5293-0.50086 0.5293-0.88086v-9c0-0.38-0.2093-0.71086-0.5293-0.88086l-7.9004-4.4395c-0.16-0.12-0.36031-0.17969-0.57031-0.17969zm0 2.1504 5.9609 3.3496-1.9609 1.1094-5.8906-3.3887 1.8906-1.0703zm-3.9199 2.1992 5.8809 3.4004-1.9609 1.0996-5.9609-3.3496 2.041-1.1504zm-3.0801 2.8613 6 3.3691v6.709l-6-3.3789v-6.6992zm11.441 3.9512-5.0117 8.6582h10.025l-5.0137-8.6582zm0.001953 1.8242 3.4297 5.9219h-6.8613l3.4316-5.9219zm-0.45703 1.8223v1.8223h0.91211v-1.8223h-0.91211zm0 2.7344v0.91016h0.91211v-0.91016h-0.91211z" />
    </SvgIcon>
  );
};

export default PackageWarning;
