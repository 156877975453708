import React, { useEffect, useMemo } from 'react';
import { RouteProps, Route, useLocation } from 'react-router-dom';
import { useAuth } from 'services/authorization';
import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';
import { AppRoutes } from '../Navigation';

interface Props extends RouteProps {}

export function AuthorizedRoute(props: Props) {
  const {
    isAuthorized,
    isActivated,
    redirectFromRestrictedZone,
    registrationSteps,
    redirectToProductSelectPage,
    redirectToLocationsPage,
    redirectToMainPage,
  } = useAuth();
  const location = useLocation();
  /**
   * We need to wait a bit as there may be a redirection in progress
   */
  useEffect(() => {
    const delayedRedirection = setTimeout(() => {
      if (isAuthorized === false) {
        redirectFromRestrictedZone();
      }
    }, 300);

    return () => {
      clearTimeout(delayedRedirection);
    };
  }, [isAuthorized, redirectFromRestrictedZone]);
  if (isAuthorized === false) {
    return <LoadingScreen />;
  }
  if (registrationSteps && !isActivated) {
    if (location.pathname !== '/select-product' && !registrationSteps.productSelectionCompleted) {
      redirectToProductSelectPage();
    }

    if (
      location.pathname !== '/add-locations' &&
      !!registrationSteps.productSelectionCompleted &&
      !registrationSteps.locationsCompleted
    ) {
      redirectToLocationsPage();
    }
    if (
      ['/product-select', '/add-locations'].includes(location.pathname) &&
      registrationSteps.productSelectionCompleted &&
      registrationSteps.locationsCompleted
    ) {
      redirectToMainPage();
    }
  }

  return <Route {...props} />;
  /*return isAuthorized === false ? <LoadingScreen /> : <Route {...props} />;*/
}
