import { useTranslation } from 'app/translations/useTranslation';
import { useUpdateUser } from 'pages/UserPage/state/useUpdateUser';
import { User, UserPermission } from 'pages/UserPage/User';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useCompanies } from 'services/companies/state/useCompanies';
import { useRegions } from 'services/regions/state/useRegions';
import { PermissionManager } from './components/PermissionManager';
import { useEditForm, EditFormValues } from './hooks/useEditForm';
import * as Styled from './styled';
import { Select } from 'components/ReactHookForm/Select/Select';
import { AccountType } from 'api/OperatorApi';
import { ShowChangeOperatorPasswordModalAction } from '../ChangePasswordModal/ShowChangeOperatorPasswordModalAction';
import { ShowDeactivateUserModalAction } from '../DeactivateUserModal/ShowDeactivateUserModalAction';

interface Props {
  user: User;
}

export function UserEditor(props: Props) {
  const { t } = useTranslation('user');
  const { updateUser, isInProgress: isInProgressUpdateUser } = useUpdateUser();
  const { companies } = useCompanies();
  const { regions } = useRegions();
  const form = useEditForm({
    user: props.user,
    companies,
    regions,
  });
  return (
    <Styled.Box>
      <ShowChangeOperatorPasswordModalAction operatorId={props.user.id} />
      <ShowDeactivateUserModalAction operatorId={props.user.id} />
      <FormProvider {...form}>
        <Styled.Form
          onSubmit={form.handleSubmit((formValues: EditFormValues) => {
            updateUser({
              id: props.user.id,
              accountType: formValues.accountType,
              permissions: Object.values(formValues.permissions).filter(
                (v) => !!v,
              ) as UserPermission[],
              assignedCompanyIds: Object.values(formValues.assignedCompanyIds).filter(
                (value) => !!value,
              ) as string[],
              assignedRegionNames: Object.values(formValues.assignedRegionNames).includes('all')
                ? ['*']
                : (Object.values(formValues.assignedRegionNames).filter(
                    (value) => !!value,
                  ) as string[]),
              canView: {
                pinCode: !!formValues.canView.pinCode,
                cancellation: !!formValues.canView.cancellation,
                sensitiveData: !!formValues.canView.sensitiveData,
                holdOrder: !!formValues.canView.holdOrder,
                orderEstimations: !!formValues.canView.orderEstimations,
                orderRoute: !!formValues.canView.orderRoute,
                comments: !!formValues.canView.comments,
                changeDriver: !!formValues.canView.changeDriver,
                driverInfo: !!formValues.canView.driverInfo,
                driverPayout: !!formValues.canView.driverPayout,
              },
            });
          })}
        >
          <Styled.SaveButton
            type={'submit'}
            dataCy={'button-update_user'}
            isInProgress={isInProgressUpdateUser}
          >
            {t('buttons.update')}
          </Styled.SaveButton>
          <Styled.DetailsBox>
            <Select
              name={'accountType'}
              label={t('labels.accountType')}
              values={Object.values(AccountType)
                .filter((accountType) => accountType !== AccountType.Deactivated)
                .map((accountType) => ({
                  value: accountType,
                  display: t(`labels.accountTypeValues.${accountType}`),
                }))}
              defaultValue={props.user.accountType}
            />
          </Styled.DetailsBox>
          <PermissionManager
            companies={companies}
            regions={regions}
            errors={form.formState.errors}
            selectedRegions={form.watch('assignedRegionNames')}
          />
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
