import { SimpleGenerator } from 'api/Request/ApiRequestSaga';

export type EventHandler<Payload = any> = (payload: Payload) => SimpleGenerator<void>;

class Service {
  private handlers = new Map<string, EventHandler[]>();

  public register(eventName: string, handler: EventHandler): void {
    const handlers = this.handlers.get(eventName) ?? [];
    handlers.push(handler);

    this.handlers.set(eventName, handlers);
  }

  public getForEvent(eventName: string): EventHandler[] {
    return this.handlers.get(eventName) ?? [];
  }
}

export const HandlerRegister = new Service();
