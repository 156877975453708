import { useTranslation } from 'app/translations/useTranslation';
import { DateRangeSelect, DateRangePresets } from 'components/DateRangeSelect';
import { Section } from 'components/Section';
import { useState } from 'react';
import { DateRange } from 'utlis/Date';
import { useReports } from 'pages/ReportPage/state/useReports';
import { ActionBox } from '../ActionBox';
import { SettingsBox } from '../styled';
import { RegionSelect } from '../Filters/components/RegionSelect';
import { CompanySelect } from '../Filters/components/CompanySelect';

interface Props {}

export function OrdersFullReport(props: Props) {
  const [dateRange, setDateRange] = useState<DateRange>(DateRange.CreateLastMonthRange());
  const [region, setRegion] = useState<string | null>(null);
  const [company, setCompany] = useState<string | null>(null);
  const { t } = useTranslation('report');
  const { isGeneratingReport, generateOrdersFullReport } = useReports();

  return (
    <>
      <SettingsBox>
        <DateRangeSelect
          label={t('driverActivity.date')}
          selectedDateRange={dateRange}
          onDateRangeSelect={setDateRange}
          dateRangePresets={[DateRangePresets.LastWeek, DateRangePresets.LastMonth]}
          maxDateSpreadInDays={120}
          alignPickerTo={'left'}
        />
        <RegionSelect selectedRegion={region} onRegionChange={setRegion} />
        <CompanySelect selectedCompanyId={company} onCompanyChange={setCompany} />
      </SettingsBox>
      <Section.Divider />
      <ActionBox
        onGenerate={() => {
          generateOrdersFullReport({
            dateFrom: dateRange.getStartDate(),
            dateTo: dateRange.getEndDate(),
            region,
            companyId: company,
          });
        }}
        isLoading={isGeneratingReport}
      />
    </>
  );
}
