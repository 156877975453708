import { pick } from 'lodash';
import { ReactComponent as MarkerAsset } from './marker.svg';

interface Props {
  className?: string;
}

export function MapMarker(props: Props) {
  return (
    <MarkerAsset {...pick(props, 'className')} />
  );
}
