import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';
import { PendingCorrection } from '../model/PendingCorrection';

export interface PendingCorrectionsListing {
  list: PendingCorrection[];
  hasError: boolean;
  isLoading: boolean;
}

export function usePendingCorrections() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Slice.actions.fetchPendingCorrectionsList());
  }, [dispatch]);

  const corrections = useSelector(Slice.selectors.makeSelectPendingCorrectionsList());
  const loadingStatus = useSelector(Slice.selectors.makeSelectPendingCorrectionsListStatus());

  const pendingCorrectionsListing: PendingCorrectionsListing = {
    list: corrections,
    hasError: loadingStatus === AsyncStatus.Error,
    isLoading: [AsyncStatus.Pending, AsyncStatus.NotStarted].includes(loadingStatus),
  };

  return {
    pendingCorrectionsListing,
    reloadPendingCorrectionsList: () => {
      dispatch(Slice.actions.fetchPendingCorrectionsList());
    },
  };
}
