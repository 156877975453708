import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AsyncStatus, PA } from 'utlis/State';
import { WalletEntry, WalletEntryType } from '../model/Wallet';
import { endOfToday, startOfToday, startOfYesterday } from 'date-fns';

export interface State {
  createWalletEntryStatus: AsyncStatus;
  deleteWalletEntryStatus: AsyncStatus;
  wallet: {
    data: WalletEntry[];
    filters: {
      dateFrom: Date;
      dateTo: Date;
      driverId?: string;
    };
    status: AsyncStatus;
  };
}

const initialState: State = {
  createWalletEntryStatus: AsyncStatus.NotStarted,
  deleteWalletEntryStatus: AsyncStatus.NotStarted,
  wallet: {
    data: [],
    filters: {
      dateFrom: startOfYesterday(),
      dateTo: endOfToday(),
    },
    status: AsyncStatus.NotStarted,
  },
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    createWalletEntry(state, _action: PA<ActionTypes.CreateWalletEntry>) {
      state.createWalletEntryStatus = AsyncStatus.Pending;
    },
    createWalletEntryFinish(state) {
      state.createWalletEntryStatus = AsyncStatus.Success;
    },
    deleteWalletEntry(state, _action: PA<ActionTypes.DeleteWalletEntry>) {
      state.deleteWalletEntryStatus = AsyncStatus.Pending;
    },
    deleteWalletEntryFinish(state) {
      state.deleteWalletEntryStatus = AsyncStatus.Success;
    },
    getWallet(state) {
      state.wallet.status = AsyncStatus.Pending;
    },
    getWalletSuccess(state, { payload }: PA<ActionTypes.GetWalletSuccess>) {
      state.wallet.status = AsyncStatus.Success;
      state.wallet.data = payload.wallet;
    },
    getWalletError(state) {
      state.wallet.status = AsyncStatus.Error;
    },
    setWalletFilters(state, { payload }: PA<ActionTypes.SetWalletFilters>) {
      if (payload.dateFrom !== undefined) {
        state.wallet.filters.dateFrom = payload.dateFrom;
      }
      if (payload.dateTo !== undefined) {
        state.wallet.filters.dateTo = payload.dateTo;
      }
    },
    setWalletDriverId(state, { payload }: PA<ActionTypes.SetWalletFilters>) {
      state.wallet.filters.driverId = payload.driverId !== undefined ? payload.driverId : undefined;
    },
  },
});

export declare namespace ActionTypes {
  export interface SetWalletFilters {
    dateFrom?: Date;
    dateTo?: Date;
    driverId?: string;
  }
  export interface DeleteWalletEntry {
    walletEntryId: string;
    comment?: string;
    reason: string;
  }
  export interface CreateWalletEntry {
    payoutDriver: number;
    payoutClient?: number;
    currency: string;
    comment?: string;
    type: WalletEntryType;
    driverId: string;
  }
  export interface GetWallet {}
  export interface GetWalletSuccess {
    wallet: WalletEntry[];
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectCreateWalletEntryStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.createWalletEntryStatus;
    }),
  makeSelectDeleteWalletEntryStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.deleteWalletEntryStatus;
    }),
  makeSelectWallet: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.wallet.data;
    }),
  makeSelectWalletStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.wallet.status;
    }),
  makeSelectWalletFilters: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.wallet.filters;
    }),
};
