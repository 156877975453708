const rootUrl = '/companies';

const LinkFactory = {
  root: () => rootUrl,
  list: () => `${rootUrl}/list`,
  company: (companyId?: string) => `${rootUrl}/company/${companyId || ':companyId'}`,
  // orderCreate: () => `${rootUrl}/new`,
};

export default LinkFactory;
