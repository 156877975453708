import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'socket',
  initialState: {},
  reducers: {
    connect() {},
    disconnect() {},
  },
});

export const { name, reducer, actions } = slice;
