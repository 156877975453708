import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import Grid from '@mui/material/Grid';

import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useCorrection } from '../../../state/useCorrection';
import { LoadingScreen } from '../../../../../components/LoadingScreen/LoadingScreen';
import { AsyncStatus } from '../../../../../utlis/State';
import { CorrectionCategory } from '../../../../../api/CorrectionApi';
import { Select } from 'components/ReactHookForm/Select/Select';
import {
  CorrectionModalFormValues,
  useCorrectionModalForm,
  useCorrectionModalFormReset,
} from './hooks/useCorrectionModalForm';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Styled from './styled';
import { Input } from '../../../../../components/ReactHookForm/Input/Input';
import { Divider as MuiDivider } from '@mui/material';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import { useRejectCorrection } from '../../../state/useRejectCorrection';
import { useAcceptCorrection } from '../../../state/useAcceptCorrection';
import { StockMovementType } from '../../../../ReportPage/components/ReportGenerator/components/Filters/components/AuditStockMovementTypeSelect';
import { Correction } from '../../../model/Correction';
import ImageViewer from 'react-simple-image-viewer';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  correctionId: string | null;
}

const formatDistanceNumber = (number: number, suffix: string = ''): string => {
  return (number / 1000).toFixed(2) + ' ' + suffix;
};

export function CorrectionModal({ isOpen, onClose, correctionId }: Props) {
  const { t } = useTranslation('correction');
  const { getCorrectionInfo, getCorrectionInfoStatus, correctionInfo } = useCorrection();
  const { rejectCorrection, status: rejectCorrectionStatus } = useRejectCorrection();
  const { acceptCorrection, status: acceptCorrectionStatus } = useAcceptCorrection();
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const form = useCorrectionModalForm();
  const reset = useCorrectionModalFormReset();
  const onSubmit: SubmitHandler<CorrectionModalFormValues> = (formValues) => {
    try {
      if (formValues.correctionAction === 'accept' && correctionId) {
        acceptCorrection({
          correctionId,
          amount: formValues.amount ?? 0,
          comment: formValues.comment,
        });
      }

      if (formValues.correctionAction === 'reject' && correctionId) {
        rejectCorrection({
          correctionId,
          rejectionReason: formValues.rejectionReason ?? '',
          comment: formValues.comment,
        });
      }
      resetAndClose();
    } catch (e) {}
  };

  const resetAndClose = useCallback(() => {
    form.setValue('comment', '');
    form.setValue('amount', undefined);
    form.setValue('correctionAction', undefined);
    onClose();
  }, [form, onClose]);

  const onReset = () => {
    reset();
  };

  const selectedAction = form.watch('correctionAction');

  useEffect(() => {
    if (correctionId && isOpen) {
      getCorrectionInfo(correctionId);
    }
  }, [correctionId, isOpen]);
  useEffect(() => {
    if (correctionInfo?.suggestedCorrectionPay && selectedAction === 'accept') {
      form.setValue('amount', correctionInfo?.suggestedCorrectionPay);
    }
  }, [form, correctionInfo, selectedAction]);

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={'lg'}>
        <DialogTitle>
          {t('details.title')}
          {correctionInfo?.orderNo}
        </DialogTitle>

        {getCorrectionInfoStatus === AsyncStatus.Pending && (
          <DialogContent>
            <LoadingScreen />
          </DialogContent>
        )}
        {correctionInfo && getCorrectionInfoStatus === AsyncStatus.Success && (
          <FormProvider {...form}>
            <Styled.Form
              onSubmit={form.handleSubmit<CorrectionModalFormValues>(onSubmit)}
              onReset={onReset}
            >
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item lg={3}>
                    <RecordLabel>{t('details.fields.category')}</RecordLabel>
                    <RecordValue>{t(`details.reasons.${correctionInfo.category}`)}</RecordValue>
                  </Grid>
                  <Grid item lg={3}>
                    <RecordLabel>{t('details.fields.driversComment')}</RecordLabel>
                    <RecordValue>{correctionInfo.driversComment}</RecordValue>
                  </Grid>
                  <Grid item lg={3}>
                    <RecordLabel>{t('details.fields.estimatedPayoutDriver')}</RecordLabel>
                    <RecordValue>{correctionInfo.estimatedPayoutDriver}</RecordValue>
                  </Grid>
                  <Grid item lg={3}>
                    <RecordLabel>{t('details.fields.payoutDriver')}</RecordLabel>
                    <RecordValue>{correctionInfo.payoutDriver}</RecordValue>
                  </Grid>
                  {correctionInfo.category === CorrectionCategory.IncorrectDistance && (
                    <Grid item container spacing={2}>
                      <Grid item lg={3}>
                        <RecordLabel>{t('details.fields.estimatedDistance')}</RecordLabel>
                        <RecordValue>
                          {formatDistanceNumber(correctionInfo.estimatedDistance, 'km')}
                        </RecordValue>
                      </Grid>
                      <Grid item lg={3}>
                        <RecordLabel>{t('details.fields.driversSuggestedDistance')}</RecordLabel>
                        <RecordValue>
                          {formatDistanceNumber(
                            correctionInfo.estimatedDistance +
                              (correctionInfo.data.extraDistance ?? 0) * 1000,
                            'km',
                          )}{' '}
                          (+
                          {formatDistanceNumber(
                            (correctionInfo.data.extraDistance ?? 0) * 1000,
                            'km',
                          )}
                          )
                        </RecordValue>
                      </Grid>
                      {correctionInfo.recalculatedDistance && (
                        <Grid item lg={3}>
                          <RecordLabel>{t('details.fields.newGoogleDistance')}</RecordLabel>
                          <RecordValue>
                            {formatDistanceNumber(correctionInfo.recalculatedDistance, 'km')}
                          </RecordValue>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {correctionInfo.category === CorrectionCategory.IncorrectSize && (
                    <Grid item container spacing={2}>
                      <Grid item lg={6}>
                        <RecordLabel>{t('details.fields.orderPackageSize')}</RecordLabel>
                        <RecordValue>{correctionInfo.packageSize}</RecordValue>
                      </Grid>
                      <Grid item lg={3}>
                        <RecordLabel>{t('details.fields.actualPackageSize')}</RecordLabel>
                        <RecordValue>{correctionInfo.data.actualPackageSize}</RecordValue>
                      </Grid>
                    </Grid>
                  )}
                  {correctionInfo.category === CorrectionCategory.DifferentDeliveryAddress && (
                    <Grid item container spacing={2}>
                      <Grid item lg={4}>
                        <RecordLabel>{t('details.fields.ordersAddress')}</RecordLabel>
                        <RecordValue>{correctionInfo.clientsAddress}</RecordValue>
                      </Grid>
                      <Grid item lg={4}>
                        <RecordLabel>{t('details.fields.driversSuggestedAddress')}</RecordLabel>
                        <RecordValue>
                          {correctionInfo.data.clientAddress?.street},{' '}
                          {correctionInfo.data.clientAddress?.zipCode}{' '}
                          {correctionInfo.data.clientAddress?.city}
                        </RecordValue>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item lg={3}>
                    <RecordLabel>{t('details.fields.suggestedPayout')}</RecordLabel>
                    <RecordValue>
                      {correctionInfo.suggestedCorrectionPay} {correctionInfo.currency}
                    </RecordValue>
                  </Grid>
                  <Grid item lg={3}>
                    {correctionInfo.attachmentUrl && [
                      <RecordLabel>{t('details.fields.attachment')}</RecordLabel>,
                      <img
                        src={correctionInfo.attachmentUrl}
                        onClick={() => setIsViewerOpen(true)}
                        width="300"
                        style={{ margin: '2px' }}
                        alt=""
                      />,
                    ]}
                    {isViewerOpen && correctionInfo.attachmentUrl && (
                      <ImageViewer
                        src={[correctionInfo.attachmentUrl]}
                        currentIndex={0}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={() => setIsViewerOpen(false)}
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <Grid container item lg={12}>
                  <Select
                    id={'correctionAction'}
                    name={'correctionAction'}
                    label={t('details.actions.selectAction.label')}
                    fullWidth
                    placeholder={t('details.actions.selectAction.placeholder')}
                    values={[
                      {
                        value: 'accept',
                        display: t('details.actions.selectAction.options.accept'),
                      },
                      {
                        value: 'reject',
                        display: t('details.actions.selectAction.options.reject'),
                      },
                    ]}
                  />
                  {selectedAction === 'accept' && (
                    <Styled.DetailsBox>
                      <Input
                        name={'amount'}
                        label={t('details.actions.form.fields.amount')}
                        dataCy={'input-amount'}
                      />
                    </Styled.DetailsBox>
                  )}
                  {selectedAction === 'reject' && (
                    <Styled.DetailsBox>
                      <Input
                        name={'rejectionReason'}
                        label={t('details.actions.form.fields.rejectionReason')}
                        dataCy={'input-rejectionReason'}
                      />
                    </Styled.DetailsBox>
                  )}
                  <Styled.DetailsBox>
                    <Input
                      name={'comment'}
                      label={t('details.actions.form.fields.comment')}
                      dataCy={'input-comment'}
                    />
                  </Styled.DetailsBox>
                </Grid>
              </DialogContent>
              <DialogActions>
                {!selectedAction && (
                  <NoSelectedActionText>
                    {t('details.actions.noSelectedActionWarning')}
                  </NoSelectedActionText>
                )}
                {selectedAction === 'reject' && (
                  <RejectLoadingButton
                    type="submit"
                    variant="contained"
                    disabled={rejectCorrectionStatus === AsyncStatus.Pending}
                    loading={rejectCorrectionStatus === AsyncStatus.Pending}
                  >
                    {t('details.actions.form.rejectSubmitButton')}
                  </RejectLoadingButton>
                )}
                {selectedAction === 'accept' && (
                  <AcceptLoadingButton
                    type="submit"
                    variant="contained"
                    disabled={acceptCorrectionStatus === AsyncStatus.Pending}
                    loading={acceptCorrectionStatus === AsyncStatus.Pending}
                  >
                    {t('details.actions.form.acceptSubmitButton')}
                  </AcceptLoadingButton>
                )}
              </DialogActions>
            </Styled.Form>
          </FormProvider>
        )}
      </Dialog>
    </>
  );
}

const ModalContentBox = styled('div')`
  height: 80%;
`;

const DialogActions = styled(MuiDialogActions)`
  padding: 20px;
`;

const Dialog = styled(MuiDialog)`
  padding: 20px;
`;

const NoSelectedActionText = styled('p')`
  font-size: 20px;
`;

const Divider = styled(MuiDivider)`
  padding-top: 20px;
  margin-bottom: 20px;
`;

const RecordLabel = styled('p')(
  ({ theme }) => `
  font-weight: 'bold';
  `,
);
const RecordValue = styled('p')(
  ({ theme }) => `
  
  `,
);

const AcceptLoadingButton = styled(MuiLoadingButton)(
  ({ theme }) => `
    background: ${theme.colors.experimental.success};
    color: ${theme.colors.experimental.buttonText.success};
    &:hover {
      background: ${theme.colors.experimental.hover.success};
    }
  `,
);
const RejectLoadingButton = styled(MuiLoadingButton)(
  ({ theme }) => `
    background: ${theme.colors.experimental.error};
    color: ${theme.colors.experimental.buttonText.error};
    &:hover {
      background: ${theme.colors.experimental.hover.error};
    }
  `,
);

const GridItem = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(6)};
  background: ${theme.colors.primary.background};
  border-radius: 5px;
`,
);
