import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { SelectFilter as Styled } from './styled';
import { useMemo } from 'react';

export enum StockMovementType {
  CompanyToWarehouse = 'companyToWarehouse',
  WarehouseToAmbassador = 'warehouseToAmbassador',
  AmbassadorToDriver = 'ambassadorToDriver',
  DriverToOrder = 'driverToOrder',
}

interface Props {
  selectedMovementType: string | null;
  onMovementTypeChange: (movementType: string | null) => void;
}

const notSelectedValue = 'notSelectedValue';

export function AuditStockMovementTypeSelect(props: Props) {
  const { t } = useTranslation('report');

  const values = useMemo(
    () => [
      {
        display: t('auditStockMovement.stockMovementType.values.companyToWarehouse'),
        value: StockMovementType.CompanyToWarehouse,
      },
      {
        display: t('auditStockMovement.stockMovementType.values.warehouseToAmbassador'),
        value: StockMovementType.WarehouseToAmbassador,
      },
      {
        display: t('auditStockMovement.stockMovementType.values.ambassadorToDriver'),
        value: StockMovementType.AmbassadorToDriver,
      },
      {
        display: t('auditStockMovement.stockMovementType.values.driverToOrder'),
        value: StockMovementType.DriverToOrder,
      },
    ],
    [t],
  );

  return (
    <Styled.Box>
      <LabeledSelect
        id={'select-movementType'}
        onChange={MuiHandlers.Select.onChange((selectedValue) => {
          props.onMovementTypeChange(selectedValue === notSelectedValue ? null : selectedValue);
        })}
        label={t('auditStockMovement.stockMovementType.label')}
        value={props.selectedMovementType ?? notSelectedValue}
      >
        <MenuItem value={notSelectedValue} key={notSelectedValue}>
          {'---'}
        </MenuItem>
        {values.map((stockMovementType) => (
          <MenuItem value={stockMovementType.value} key={stockMovementType.value}>
            {stockMovementType.display}
          </MenuItem>
        ))}
      </LabeledSelect>
    </Styled.Box>
  );
}
