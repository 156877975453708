import React from 'react';

import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as Slice from '../../state/slice';
import { DriverSelect } from './components/DriverSelect';
import { Grid } from '@mui/material';

interface Props {}

function useFilters() {
  const dispatch = useDispatch();
  const filters = useSelector(Slice.selectors.makeSelectStockTransferFilters());

  return {
    filters,
    changeFilters(filters: Slice.ActionTypes.SetFilters) {
      dispatch(Slice.actions.setFilters(filters));
    },
  };
}

export function Filters(props: Props) {
  const { filters, changeFilters } = useFilters();

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item>
          <DriverSelect
            target={'givingDriver'}
            omitDriverId={null}
            onDriverChange={(driverId) => {
              changeFilters({ givingDriverId: driverId });
            }}
            selectedDriverId={filters.givingDriverId}
          />
        </Grid>
        <Grid item>
          <DriverSelect
            omitDriverId={filters.givingDriverId}
            target={'receivingDriver'}
            onDriverChange={(driverId) => {
              changeFilters({ receivingDriverId: driverId });
            }}
            selectedDriverId={filters.receivingDriverId}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
`,
);

const FilterBox = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
