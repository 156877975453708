import { useSelector } from 'react-redux';
import { Permissions } from '../Permissions';
import * as Slice from '../state/slice';

export function usePermissions<Namespace extends keyof Permissions>(
  namespace: Namespace,
): Permissions[Namespace] {
  const authorization = useSelector(Slice.selectors.makeSelectAuthorization());

  return authorization.permissions[namespace];
}
