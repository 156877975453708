import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { SelectFilter as Styled } from './styled';

interface Props {
  selectedStatus: string | null;
  onStatusSelect: (newStatus: string) => void;
}

export function ActiveSelect(props: Props) {
  const { t } = useTranslation('driver');
  const availableStatuses = ['true', 'false'];

  return (
    <Styled.Box>
      <LabeledSelect
        id={'select-status'}
        onChange={MuiHandlers.Select.onChange<string>((status) => props.onStatusSelect(status))}
        value={
          typeof props.selectedStatus === 'undefined' ? availableStatuses[0] : props.selectedStatus
        }
        label={t('labels.active')}
        isMultiSelector={false}
        size="small"
      >
        {availableStatuses.map((status) => (
          <MenuItem value={status} key={status}>
            {t(`active.${status}`)}
          </MenuItem>
        ))}
      </LabeledSelect>
    </Styled.Box>
  );
}
