import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';

export type ChangeOrderDriverFormValues = {
  driverId: string;
  comment?: string;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object().shape({
      driverId: yup.string().required(t('required')),
      comment: yup.string().optional(),
    });
  }, [t]);
}

export const useChangeOrderDriverFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    form.reset();
  }, [form]);
};

export function useChangeOrderDriverForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<ChangeOrderDriverFormValues>([
      simpleResolver<ChangeOrderDriverFormValues>(),
      yupResolver(validationSchema),
    ]),
    [validationSchema],
  );
  return useForm<ChangeOrderDriverFormValues>({
    resolver,
    mode: 'onSubmit',
  });
}
