import React, { useMemo } from 'react';

import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Styled from './styled';
import LoadingButton from '@mui/lab/LoadingButton';

import { AsyncStatus } from 'utlis/State';
import { useTranslation } from 'app/translations/useTranslation';

import { Input } from 'components/ReactHookForm/Input/Input';
import { Select } from 'components/ReactHookForm/Select/Select';
import { Checkbox } from 'components/ReactHookForm/Checkbox/Checkbox';

import {
  AddLocationFormValues,
  useAddLocationForm,
  useAddLocationFormReset,
} from '../hooks/useAddLocationForm';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@mui/material/Alert';
import { Location } from '../../../../../model/Location';

interface Props {
  onSubmit?: (location: Partial<Location>) => void;
  onSubmitFinish?: VoidFunction;
}

export function AddLocationForm({ onSubmit, onSubmitFinish }: Props) {
  const { t } = useTranslation('settings');
  // const { cancelOrder, cancelOrderStatus } = useCancelOrder();

  const form = useAddLocationForm();
  const reset = useAddLocationFormReset();

  const submit: SubmitHandler<AddLocationFormValues> = (formValues) => {
    if (onSubmit) {
      onSubmit({
        name: formValues.name,
        address: {
          city: formValues.city,
          street: formValues.street,
          zipCode: formValues.zipCode,
        },
        phone: formValues.phone,
      });
    } else {
      // handle default API add location call
    }
    if (onSubmitFinish) {
      onSubmitFinish();
    }
  };

  const submitError = useMemo(() => {
    return undefined;
  }, []);

  const onReset = () => {
    reset();
  };
  return (
    <FormProvider {...form}>
      <Styled.Form onSubmit={form.handleSubmit<AddLocationFormValues>(submit)} onReset={onReset}>
        <Styled.FieldsContainer container spacing={2} justifyContent={'center'}>
          {submitError && (
            <Styled.FieldContainer item xs={12}>
              <FormHelperText error>{submitError}</FormHelperText>
            </Styled.FieldContainer>
          )}
          <Styled.FieldContainer item xs={12} sm={12} md={6} lg={6}>
            <Styled.Input
              autoComplete="off"
              name={'name'}
              label={t('locations.form.fields.name.label')}
              placeholder={t('locations.form.fields.name.placeholder')}
              dataCy={'input-name'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={6} lg={6}>
            <Styled.Input
              autoComplete="off"
              name={'phone'}
              label={t('locations.form.fields.phone.label')}
              placeholder={t('locations.form.fields.phone.placeholder')}
              dataCy={'input-phone'}
            />
            <Alert severity={'info'}>{t('locations.form.fields.phone.helperText')}</Alert>
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={4} lg={4}>
            <Styled.Input
              autoComplete="off"
              name={'street'}
              label={t('locations.form.fields.street.label')}
              placeholder={t('locations.form.fields.street.placeholder')}
              dataCy={'input-street'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={4} lg={4}>
            <Styled.Input
              autoComplete="off"
              name={'zipCode'}
              label={t('locations.form.fields.zipCode.label')}
              placeholder={t('locations.form.fields.zipCode.placeholder')}
              dataCy={'input-zipCode'}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer item xs={12} sm={12} md={4} lg={4}>
            <Styled.Input
              autoComplete="off"
              name={'city'}
              label={t('locations.form.fields.city.label')}
              placeholder={t('locations.form.fields.city.placeholder')}
              dataCy={'input-city'}
            />
          </Styled.FieldContainer>
        </Styled.FieldsContainer>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          /*disabled={cancelOrderStatus === AsyncStatus.Pending}
          loading={cancelOrderStatus === AsyncStatus.Pending}*/
        >
          {t('locations.form.submitButton')}
        </LoadingButton>
      </Styled.Form>
    </FormProvider>
  );
}
