import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { styled } from '@mui/material/styles';
import * as Slice from '../../state/slice';
import { CompanySelect } from './components/CompanySelect';
import { Grid } from '@mui/material';

interface Props {}

function useFilters() {
  const dispatch = useDispatch();
  const filters = useSelector(Slice.selectors.makeSelectWarehouseListFilters());

  return {
    filters,
    changeFilters(filters: Slice.ActionTypes.SetFilters) {
      dispatch(Slice.actions.setFilters(filters));
    },
  };
}

export function Filters(props: Props) {
  const { filters, changeFilters } = useFilters();

  return (
    <Box>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <CompanySelect
            onCompanyChange={(companyId) => {
              changeFilters({ companyId });
            }}
            selectedCompanyId={filters.companyId}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(2.5)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`,
);

const FilterBox = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
