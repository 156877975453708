import React, { useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import { useProduct } from 'services/products/state/useProduct';
import { OrderType } from 'api/OrderApi';
import { ProductCode } from 'api/ProductApi';

import ProductFormProps from './components/ProductFormProps';

import CardTitled from 'components/Cards/CardTitled';
import PickupDelivery from './components/PickupDelivery';
import VehicleInspection from './components/VehicleInspection';

const supportedProductTypesMap: { [productType: string]: React.FC<ProductFormProps> } = {
  [OrderType.PickupDelivery]: PickupDelivery,
};

const supportedProductCodesMap: { [productCode: string]: React.FC<ProductFormProps> } = {
  [ProductCode.VehicleInspection]: VehicleInspection,
};

// Props type
type Props = {
  fieldName: string;
  productId: string;
  onDelete: () => void;
};

// Component
const ProductForm = ({ fieldName, productId, onDelete }: Props) => {
  const form = useFormContext();

  const product = useProduct(productId);

  const ProductTypeFormComponent = useMemo(
    () => product && supportedProductTypesMap[product.type],
    [product],
  );

  const ProductCodeFormComponent = useMemo(
    () => product && supportedProductCodesMap[product.code],
    [product],
  );

  return (
    <>
      {!!product && (
        <>
          <input
            {...form.register(`${fieldName}.productId` as const)}
            type={'hidden'}
            defaultValue={productId}
          />
          <CardTitled
            title={product.name}
            actions={
              <TitleIconButton onClick={onDelete}>
                <DeleteIcon fontSize="small" />
              </TitleIconButton>
            }
          >
            {(!!ProductTypeFormComponent || !!ProductCodeFormComponent) && (
              <>
                {!!ProductTypeFormComponent && (
                  <ProductTypeFormComponent product={product} fieldGroupName={fieldName} />
                )}
                {!!ProductCodeFormComponent && (
                  <ProductCodeFormComponent product={product} fieldGroupName={fieldName} />
                )}
              </>
            )}
          </CardTitled>
        </>
      )}
    </>
  );
};

const TitleIconButton = styled(IconButton)(
  ({ theme }) => `
  color: ${theme.palette.primary.contrastText};
`,
);

export default ProductForm;
