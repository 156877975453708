// Core libraries
import React from 'react';

// External libs and components
import { styled } from '@mui/material/styles';
import { TableToolbar } from 'mui-datatables';

// Internal libs
import { useTableFilter } from './TableFiltersProvider';

// Internal components

// Component
const TableFiltersToolbar = (props: any) => {
  const filters = useTableFilter();
  return (
    <Box>
      {!!filters && <FilterBox>{filters}</FilterBox>}
      <TableToolbarBox>
        <TableToolbar {...props} />
      </TableToolbarBox>
    </Box>
  );
};

const Box = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'top',
});

const TableToolbarBox = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderLeft: `1px solid ${theme.palette.divider}`,
}));

const FilterBox = styled('div')({
  flexGrow: 1,
});

export default TableFiltersToolbar;
