import { useTranslation } from 'app/translations/useTranslation';
import React, { useState } from 'react';
import Alert from '@mui/material/Alert';

interface Props {}

export function ActivationStatus() {
  const { t } = useTranslation('settings');
  return <Alert severity="info">{t('companyData.status.pendingCompanyDataForm')}</Alert>;
}
