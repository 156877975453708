import { useTranslation } from 'app/translations/useTranslation';
import { DateRangeSelect, DateRangePresets } from 'components/DateRangeSelect';
import { Section } from 'components/Section';
import { useState } from 'react';
import { DateRange } from 'utlis/Date';
import { useReports } from 'pages/ReportPage/state/useReports';
import { ActionBox } from '../ActionBox';
import { SettingsBox } from '../styled';

interface Props {
}

export function DriverActivityReport(props: Props) {
  const [dateRange, setDateRange] = useState<DateRange>(DateRange.CreateLastMonthRange());
  const { t } = useTranslation('report');
  const { isGeneratingReport, generateXDelivererActivityReport } = useReports();

  return (
    <>
      <SettingsBox>
        <DateRangeSelect
          label={t('driverActivity.date')}
          selectedDateRange={dateRange}
          onDateRangeSelect={setDateRange}
          dateRangePresets={[
            DateRangePresets.LastWeek,
            DateRangePresets.LastMonth,
          ]}
          maxDateSpreadInDays={366}
          alignPickerTo={'left'}
        />
      </SettingsBox>
      <Section.Divider />
      <ActionBox
        onGenerate={() => {
          generateXDelivererActivityReport({ dateSince: dateRange.getStartDate(), dateTo: dateRange.getEndDate() });
        }}
        isLoading={isGeneratingReport}
      />
    </>
  );
}
