import { AccountType } from 'api/OperatorApi';

export enum UserPermission {
  Reports = 'report',
  Orders = 'orders',
  Operators = 'operators',
  SuperAdmin = 'superAdmin',

  OrdersRead = 'orders:read',
  Drivers = 'drivers',
  ReportOffers = 'reports:offers',
  ReportFull = 'reports:full',
  ReportDriverActivity = 'reports:driverActivity',
  ReportDriversStock = 'reports:driversStock',
  ReportAuditStockMovement = 'reports:auditStockMovement',
  ReportDriversFeedback = 'reports:driversFeedback',
  ReportCorrections = 'reports:corrections',
  ReportStockExchange = 'reports:stockExchange',
  Notifications = 'notifications',
  Warehouse = 'warehouse',
  Stock = 'stock',
  Corrections = 'corrections',
  Wallet = 'wallet',
}

export const AccountTypeSuggestedPermissions: {
  [accountType in AccountType]: {
    canView: {
      sensitiveData: boolean;
      pinCode: boolean;
      cancellation: boolean;
      holdOrder: boolean;
      orderEstimations: boolean;
      orderRoute: boolean;
      comments: boolean;
      changeDriver: boolean;
      driverInfo: boolean;
      driverPayout: boolean;
    };
  };
} = {
  [AccountType.Api]: {
    canView: {
      sensitiveData: false,
      pinCode: true,
      cancellation: true,
      holdOrder: true,
      orderEstimations: true,
      orderRoute: true,
      comments: false,
      changeDriver: false,
      driverInfo: false,
      driverPayout: false,
    },
  },
  [AccountType.ApiUser]: {
    canView: {
      sensitiveData: false,
      pinCode: false,
      cancellation: false,
      holdOrder: false,
      orderEstimations: false,
      orderRoute: false,
      comments: false,
      changeDriver: false,
      driverInfo: false,
      driverPayout: false,
    },
  },
  [AccountType.XDEmployee]: {
    canView: {
      sensitiveData: true,
      pinCode: true,
      cancellation: true,
      holdOrder: true,
      orderEstimations: true,
      orderRoute: true,
      comments: true,
      changeDriver: true,
      driverInfo: true,
      driverPayout: true,
    },
  },
  [AccountType.XDCoordinator]: {
    canView: {
      sensitiveData: false,
      pinCode: true,
      cancellation: false,
      holdOrder: true,
      orderEstimations: true,
      orderRoute: true,
      comments: true,
      changeDriver: false,
      driverInfo: false,
      driverPayout: false,
    },
  },
  [AccountType.Other]: {
    canView: {
      sensitiveData: false,
      pinCode: false,
      cancellation: false,
      holdOrder: false,
      orderEstimations: true,
      orderRoute: true,
      comments: false,
      changeDriver: false,
      driverInfo: false,
      driverPayout: false,
    },
  },
  [AccountType.Deactivated]: {
    canView: {
      sensitiveData: false,
      pinCode: false,
      cancellation: false,
      holdOrder: false,
      orderEstimations: false,
      orderRoute: false,
      comments: false,
      changeDriver: false,
      driverInfo: false,
      driverPayout: false,
    },
  },
};

export type UserCanView = {
  sensitiveData: boolean;
  pinCode: boolean;
  cancellation: boolean;
  holdOrder: boolean;
  orderEstimations: boolean;
  orderRoute: boolean;
  comments: boolean;
  changeDriver: boolean;
  driverInfo: boolean;
  driverPayout: boolean;
};

export type User = {
  id: string;
  email: string;
  permissions: UserPermission[];
  assignedCompanies: {
    ids: string[];
    toAll: boolean;
  };
  assignedRegions: {
    names: string[];
    toAll: boolean;
  };
  accountType: AccountType;
  createdAt: Date;
  canView: UserCanView;
};

export type CreateUserParams = {
  email: string;
  password: string;
  permissions: UserPermission[];
  assignedCompanyIds: string[];
  assignedRegionNames: string[];
  accountType: AccountType;
  canView: UserCanView;
};

export type UpdateUserParams = {
  id: string;
  permissions: UserPermission[];
  assignedCompanyIds: string[];
  assignedRegionNames: string[];
  accountType: AccountType;
  canView: UserCanView;
};

export type ChangeOperatorPasswordParams = {
  operatorId: string;
  password: string;
};

export type DeactivateUserParams = {
  operatorId: string;
};
