import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import type { StockExchange } from '../StockExchange';
import * as Slice from './slice';

export interface PendingStockListing {
  list: StockExchange[],
  hasError: boolean,
  isLoading: boolean,
}

export function useStockPendingList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Slice.actions.fetchPendingStockList());
  }, [dispatch]);

  const pendingStock = useSelector(Slice.selectors.makeSelectPendingStockList());
  const loadingStatus = useSelector(Slice.selectors.makeSelectPendingStockListLoadingStatus());
  const pendingStockListing: PendingStockListing = {
    list: pendingStock,
    hasError: loadingStatus === AsyncStatus.Error,
    isLoading: [AsyncStatus.Pending, AsyncStatus.NotStarted].includes(loadingStatus),
  };

  return {
    pendingStockListing,
    reloadPendingStockListing: () => {
      dispatch(Slice.actions.fetchPendingStockList());
    },
  };
}
