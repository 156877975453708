import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Slice from './slice';

export function useCorrection() {
  const dispatch = useDispatch();
  const getCorrectionInfoStatus = useSelector(Slice.selectors.makeSelectGetCorrectionInfoStatus());
  const correctionInfo = useSelector(Slice.selectors.makeSelectGetCorrectionInfo());
  return {
    getCorrectionInfo: (correctionId: string) => {
      return dispatch(Slice.actions.getCorrectionInfo({ correctionId }));
    },
    getCorrectionInfoStatus,
    correctionInfo,
  };
}
