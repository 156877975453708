import { Driver as ApiDriver } from 'api/DriverApi';
import { Driver, DriverCar, DriverMobileApp, DriverStock } from '../Driver';

class Service {
  public mapToDriver(driver: ApiDriver): Driver {
    return {
      id: driver.id,
      firstName: driver.firstName,
      lastName: driver.lastName,
      email: driver.email,
      phone: driver.phone,
      status: driver.status,
      isAmbassador: driver.isAmbassador,
      region: driver.region,
      lastKnownLocation: driver.lastKnownLocation
        ? {
            ...driver.lastKnownLocation,
            loggedAt: new Date(driver.lastKnownLocation.loggedAt).getTime(),
          }
        : undefined,
      allowedCompanies: driver.allowedCompanies || [],
      car: this.extractCarData(driver),
      lastLogin: driver.lastLogin ? new Date(driver.lastLogin).getTime() : undefined,
      blocked: !!driver.blocked,
      active: !!driver.active,
      mobile: this.extractMobileAppData(driver),
      stock: this.extractStockData(driver),
    };
  }

  private extractCarData(driver: ApiDriver): DriverCar {
    return {
      registrationPlate: driver.carRegistrationPlate || '',
      model: driver.car || '',
      color: driver.carColor || '',
    };
  }

  private extractMobileAppData(driver: ApiDriver): DriverMobileApp {
    return {
      version: driver.mobileAppVersion || undefined,
      osType: driver.osType || undefined,
    };
  }

  private extractStockData(driver: ApiDriver): DriverStock[] {
    const stock: DriverStock[] = [];
    if (!driver.stock) return stock;
    for (const productId of Object.keys(driver.stock)) {
      stock.push({
        productId,
        amount: driver.stock[productId]?.amount || 0,
        items: driver.stock[productId].items,
      });
    }
    return stock;
  }
}

export const ApiDriverMapper = new Service();
