import { default as LeftArrowIcon } from '@mui/icons-material/ArrowLeft';
import { default as RightArrowIcon } from '@mui/icons-material/ArrowRight';
import { useLanguageManager } from 'app/translations/useLanguageManager';
import { endOfToday } from 'date-fns';
import { useMemo } from 'react';
import { CalendarDates } from '../CalendarDates';
import * as Styled from '../styled';

interface Props {
  calendarDates: CalendarDates,
  onDateSelect: (date: Date) => void
}

const todayEnd = endOfToday();

export function CalendarDateSelector(props: Props) {
  const { selectedLang } = useLanguageManager();
  const dates = useMemo(() => props.calendarDates.toArray(), [props.calendarDates]);
  return (
    <Styled.Calendar
      showDoubleView={true}
      view={'month'}
      maxDetail={'month'}
      minDetail={'month'}
      prevLabel={<LeftArrowIcon />}
      nextLabel={<RightArrowIcon />}
      prev2Label={null}
      next2Label={null}
      value={dates}
      locale={selectedLang}
      maxDate={todayEnd}
      onChange={function (selectedDate) {
        if (selectedDate instanceof Date) {
          props.onDateSelect(selectedDate);
        } else {
          console.warn('Not supported returnValue setting', selectedDate);
        }
      }}
    />
  );
}
