import { State } from './model/State';

const initialState: State = {
  orderCreate: {
    status: { inProgress: false },
  },
  checkDeliveryAvailability: {
    status: { inProgress: false },
  },
};

export default initialState;
