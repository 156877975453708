import { useTranslation } from 'app/translations/useTranslation';
import { Section } from 'components/Section';
import { CreateMultiplier } from './components/CreateMultiplier/CreateMultiplier';
import { MultiplierCalendar } from './components/MultiplierCalendar/MultiplierCalendar';
import Grid from '@mui/material/Grid';

interface Props {}

export function MultiplierCalendarPage(props: Props) {
  const { t } = useTranslation('multiplierCalendar');

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={8}>
          <Section.Box>
            <Section.Header>{t('headers.main')}</Section.Header>
            <Section.Divider />
            <MultiplierCalendar />
          </Section.Box>
        </Grid>
        <Grid item lg={4}>
          <Section.Box>
            <CreateMultiplier />
          </Section.Box>
        </Grid>
      </Grid>
    </>
  );
}
