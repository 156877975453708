import { useTranslation } from 'app/translations/useTranslation';
import { Section } from 'components/Section';
import { PasswordChanger } from './components/PasswordChanger/PasswordChanger';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Card, Divider, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import { ContractDataTab } from './components/ContractDataTab';
import BaseLinkerLogo from './components/baselinker.png';
import AlertTitle from '@mui/material/AlertTitle';
import { Trans } from 'react-i18next';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { AppRoutes } from '../../app/Navigation';
import { useAuth } from '../../services/authorization';
// import { Image } from '@mui/icons-material';

interface Props {}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function ActivationStatus() {
  const { t } = useTranslation('settings');
  return <Alert severity="info">{t('companyData.status.pendingCompanyDataForm')}</Alert>;
}

function TabPanel(props: { children: any; index: number; value: number }) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function SettingsPage(props: Props) {
  const { t } = useTranslation('settings');
  const [value, setValue] = useState<number>(0);
  const { isActivated } = useAuth();

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Section.Box>
      <Section.Header>{t('headers.settings')}</Section.Header>
      <Section.Divider />
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          height: '100%',
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', minWidth: '200px' }}
        >
          <Tab label={t('menu.general')} {...a11yProps(0)} />
          <Tab label={t('menu.companyData')} {...a11yProps(1)} />
          <Tab label={t('menu.changePassword')} {...a11yProps(2)} />
        </Tabs>

        <Grid container>
          <StyledTabPanel value={value} index={0}>
            {!isActivated && (
              <Alert severity="info">
                <Trans t={t} i18nKey="general.inactiveMainInfo">
                  <Link onClick={() => setValue(1)} />
                  <Link target="_blank" href={'https://staging.dashboard.xpress.delivery'} />
                </Trans>
                <br />
                <br />
                {/*Zaloguj się swoim adresem email oraz tymczasowym hasłem:{' '}
                <strong>VXMP56BJKWTEUKGFXMLB</strong>
                <br />
                <br />
                Pamiętaj aby dla bezpieczeństwa zmienić hasło po pierwszym logowaniu.*/}
              </Alert>
            )}

            <Divider style={{ marginTop: 30, marginBottom: 30 }} />
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Card style={{ padding: 30 }}>
                  <Typography variant="h5" component="div">
                    {t('general.integrations.api.title')}
                  </Typography>
                  <Trans t={t} i18nKey="general.integrations.api.body">
                    <Link target="_blank" href={'https://api.xpress.delivery/apidocs'} />
                    <Link target="_blank" href={'mailto:it@xpress.delivery'} />
                  </Trans>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card style={{ padding: 30 }}>
                  <Image src={BaseLinkerLogo} />
                  <br />
                  <Trans t={t} i18nKey="general.integrations.baselinker.body" />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <FooterContainer>
                  <Trans t={t} i18nKey="general.integrations.footer.body">
                    <Link target="_blank" href={'mailto:it@xpress.delivery'} />
                  </Trans>
                </FooterContainer>
              </Grid>
            </Grid>
          </StyledTabPanel>
          <StyledTabPanel value={value} index={1}>
            {!isActivated && <ContractDataTab />}
          </StyledTabPanel>
          <StyledTabPanel value={value} index={2}>
            <Section.SubHeader>{t('headers.changePassword')}</Section.SubHeader>
            <PasswordChanger />
          </StyledTabPanel>
        </Grid>
      </Box>
    </Section.Box>
  );
}

export const StyledTabPanel = styled(TabPanel)({});

const Image = styled('img')({
  height: '1.75em',
  verticalAlign: 'center',
});

const FooterContainer = styled('p')({
  textAlign: 'center',
  marginTop: '40px',
});
