import { yupResolver } from '@hookform/resolvers/yup';
import { User, UserCanView, UserPermission } from 'pages/UserPage/User';
import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Region } from 'model/Region';
import * as yup from 'yup';
import {
  CheckboxAtLeastOneSelectedTest,
  CheckboxesObjectAtLeastOneSelectedTest,
} from 'app/validation/validationTests';
import { Company } from 'model/Company';
import { AccountType } from 'api/OperatorApi';

export type EditFormValues = {
  accountType: AccountType;
  permissions: { [key: string]: UserPermission };
  assignedCompanyIds: { [key: string]: string };
  assignedRegionNames: { [key: string]: string };
  canView: UserCanView;
};

function useValidationSchema() {
  return useMemo(() => {
    return yup.object({
      accountType: yup.string().required(),
      assignedCompanyIds: yup
        .object()
        .required()
        .test(...CheckboxesObjectAtLeastOneSelectedTest),
      assignedRegionNames: yup
        .object()
        .required()
        .test(...CheckboxesObjectAtLeastOneSelectedTest),
      permissions: yup
        .object()
        .required()
        .shape(
          Object.values(UserPermission).reduce<{ [key: string]: any }>((shape, permission) => {
            shape[permission.replace('.', '_')] = yup.string().nullable();
            return shape;
          }, {}),
        )
        .test(...CheckboxesObjectAtLeastOneSelectedTest),
      canView: yup
        .object()
        .shape({
          pinCode: yup.boolean().nullable().optional(),
          cancellation: yup.boolean().nullable().optional(),
          sensitiveData: yup.boolean().nullable().optional(),
          holdOrder: yup.boolean().nullable().optional(),
          orderEstimations: yup.boolean().nullable().optional(),
          orderRoute: yup.boolean().nullable().optional(),
          comments: yup.boolean().nullable().optional(),
          changeDriver: yup.boolean().nullable().optional(),
          driverInfo: yup.boolean().nullable().optional(),
          driverPayout: yup.boolean().nullable().optional(),
        })
        .optional(),
    });
  }, []);
}

interface UseDefaultValuesParams {
  companies: Company[];
  regions: Region[];
  user: User;
}

function useDefaultValues(params: UseDefaultValuesParams) {
  const { user, companies, regions } = params;
  const defaultValues = useMemo(() => {
    const userCompanies =
      user.assignedCompanies.toAll === true
        ? companies.map((company) => company.id)
        : user.assignedCompanies.ids;
    const userRegions = user.assignedRegions.names;

    /**
     * Beware that this is very important to render all the checkbox exactly in the same order as the default values are defined
     * This is a drawback of using react-hook-form field[index] notation.
     */
    return {
      permissions: Object.values(UserPermission).reduce<{ [key: string]: UserPermission }>(
        (ret, permission) => {
          if (user.permissions.includes(permission)) {
            ret[permission.replace('.', '_')] = permission;
          }
          return ret;
        },
        {},
      ),
      assignedCompanyIds: companies.reduce<{ [key: string]: string }>((ret, company) => {
        if (userCompanies.includes(company.id)) {
          ret[company.id] = company.id;
        }
        return ret;
      }, {}),
      assignedRegionNames: user.assignedRegions.toAll
        ? { all: 'all' }
        : regions.reduce<{ [key: string]: string }>((ret, region) => {
            if (userRegions.includes(region.name)) {
              ret[region.name] = region.name;
            }
            return ret;
          }, {}),
      accountType: user.accountType,
      canView: {
        pinCode: !!user.canView?.pinCode,
        cancellation: !!user.canView?.cancellation,
        sensitiveData: !!user.canView?.sensitiveData,
        holdOrder: !!user.canView?.holdOrder,
        orderEstimations: !!user.canView?.orderEstimations,
        orderRoute: !!user.canView?.orderRoute,
        comments: !!user.canView?.comments,
        changeDriver: !!user.canView?.changeDriver,
        driverInfo: !!user.canView?.driverInfo,
        driverPayout: !!user.canView?.driverPayout,
      },
    };
  }, [user, companies, regions]);
  return {
    defaultValues,
  };
}

export function useEditForm(params: UseDefaultValuesParams) {
  const { defaultValues } = useDefaultValues(params);
  const validationSchema = useValidationSchema();
  const form = useForm<EditFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  });
  const { reset: resetForm, setValue } = form;

  useEffect(() => {
    setValue('assignedRegionNames', defaultValues.assignedRegionNames);
    setValue('assignedCompanyIds', defaultValues.assignedCompanyIds);
    setValue('permissions', defaultValues.permissions);
    setValue('accountType', defaultValues.accountType);
    setValue('canView', defaultValues.canView);
  }, [resetForm, setValue, defaultValues]);
  return form;
}
