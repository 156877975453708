import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useTransferStock() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectTransferStockStatus());
  const filters = useSelector(Slice.selectors.makeSelectStockTransferFilters());
  return {
    transferStock: (params: Slice.ActionTypes.TransferStock['data']) => {
      dispatch(Slice.actions.transferStock({ data: {
        ...params,
        givingDriverId: filters.givingDriverId,
        receivingDriverId: filters.receivingDriverId,
      } }));
    },
    isInProgress: status === AsyncStatus.Pending,
  };
}

