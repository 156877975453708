import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export type CreateFormValues = {
  productId: string;
  amount: number;
  stockAmount?: number;
}

function useValidationSchema() {
  return yup.object({
    amount: yup.number().required().max(yup.ref('stockAmount')).min(1),
  });
}

interface UseDefaultValuesParams {
  stockAmount: number,
}

function useDefaultValues(params: UseDefaultValuesParams) {
  return {
    defaultValues: {
      ...params,
    },
  };
}


export function useCreateForm(params: UseDefaultValuesParams) {
  const { defaultValues } = useDefaultValues(params);

  const validationSchema = useValidationSchema();
  const form = useForm<CreateFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  });

  return form;
}
