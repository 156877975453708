import { useDispatch, useSelector } from 'react-redux';
import * as Slice from './slice';

export function useDownloadOrderLabel() {
  const dispatch = useDispatch();
  const isDownloadingLabel = useSelector(Slice.selectors.makeSelectIsDownloadingLabel());

  return {
    isDownloadingLabel,
    downloadOrderLabel(params: Slice.ActionTypes.DownloadOrderLabel) {
      dispatch(Slice.actions.downloadOrderLabel(params));
    },
  };
}
