export class StoredToken {
  private static tokenKey = 'xd-token';

  public static setValue(token: string): void {
    localStorage.setItem(StoredToken.tokenKey, token);
  }

  public static getValue(): string | null {
    return localStorage.getItem(StoredToken.tokenKey);
  }

  public static getValueOrThrow(): string {
    const token = this.getValue();
    if (token === null) {
      throw new Error('Token is not set!');
    }
    return token;
  }

  public static isSet(): boolean {
    const token = localStorage.getItem(StoredToken.tokenKey);
    return token !== null;
  }

  public static unset(): void {
    localStorage.removeItem(StoredToken.tokenKey);
  }
}
