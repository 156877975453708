import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import type { SimplifiedStock } from '../Stock';
import * as Slice from './slice';

export interface ReceivingDriverStockListing {
  list: SimplifiedStock[],
  hasError: boolean,
  isLoading: boolean,
  driverSelected: boolean,
}

export function useReceivingDriverStockList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Slice.actions.fetchReceivingDriverStockList());
  }, [dispatch]);
  const filters = useSelector(Slice.selectors.makeSelectStockTransferFilters());
  const stockItems = useSelector(Slice.selectors.makeSelectReceivingDriverStockList());
  const loadingStatus = useSelector(Slice.selectors.makeSelectReceivingDriverStockListLoadingStatus());
  const stockListing: ReceivingDriverStockListing = {
    list: stockItems || [],
    driverSelected: !!filters.receivingDriverId,
    hasError: loadingStatus === AsyncStatus.Error,
    isLoading: [AsyncStatus.Pending, AsyncStatus.NotStarted].includes(loadingStatus),
  };

  return {
    stockListing,
    reloadStockListing: () => {
      dispatch(Slice.actions.fetchReceivingDriverStockList());
    },
  };
}
