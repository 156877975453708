import { setLocale } from 'yup';
import { createTranslator } from '../translations/useTranslation';

const translate = createTranslator('validationErrors');
/**
 * @see https://github.com/jquense/yup/blob/877f7770ca54c0abea885230ffa307c1047226a3/src/locale.ts
 */
setLocale({
  mixed: {
    // default?: Message;
    required: () => translate('required'),
    // oneOf?: Message<{ values: any }>;
    // notOneOf?: Message<{ values: any }>;
    // notType?: Message;
    // defined?: Message;
  },
  string: {
    // length?: Message<{ length: number }>;
    min: (params: { min: number }) => translate('tooShort', { count: params.min }),
    // max?: Message<{ max: number }>;
    // matches?: Message<{ regex: RegExp }>;
    email: (_params: { regex: RegExp }) => translate('email'),
    // url?: Message<{ regex: RegExp }>;
    // uuid?: Message<{ regex: RegExp }>;
    // trim?: Message;
    // lowercase?: Message;
    // uppercase?: Message;
  },
  number: {
    // min?: Message<{ min: number }>;
    // max?: Message<{ max: number }>;
    // lessThan?: Message<{ less: number }>;
    // moreThan?: Message<{ more: number }>;
    // positive?: Message<{ more: number }>;
    // negative?: Message<{ less: number }>;
    // integer?: Message;
  },
  date: {
    // min?: Message<{ min: Date | string }>;
    // max?: Message<{ max: Date | string }>;
  },
  object: {
    // noUnknown?: Message;
  },
  array: {
    // length?: Message<{ length: number }>;
    // min?: Message<{ min: number }>;
    // max?: Message<{ max: number }>;
  },
  boolean: {
    // isValue?: Message;
  },
});
