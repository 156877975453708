import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Slice from '../state/slice';

export function useAuthService() {
  const dispatch = useDispatch();
  const { isPreAuthorized, isInProgress } = useSelector(Slice.selectors.makeSelectAppInitialization());

  useEffect(() => {
    if (isPreAuthorized === false && isInProgress === false) {
      dispatch(Slice.actions.initialize());
    }
  }, [dispatch, isPreAuthorized, isInProgress]);


  return {
    isInitializationInProgress: isInProgress,
  };
}
