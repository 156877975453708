import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';

export type CancelOrderFormValues = {
  comment?: string;
  cancellationReason: string;
  renewal: string;
  forceCancel?: string;
  createReturn?: string;
  payable?: string;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object().shape({
      comment: yup.string().optional(),
      cancellationReason: yup.string().required(t('required')),
      renewal: yup.string().required(t('required')),
      createReturn: yup.string().required(t('required')),
      payable: yup.string().required(t('required')),
    });
  }, [t]);
}

export const useCancelOrderFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    form.setValue('renewal', 'default');
    form.setValue('createReturn', 'default');
    form.setValue('comment', '');
    form.setValue('forceCancel', undefined);
    form.setValue('payable', undefined);
  }, [form]);
};

export function useCancelOrderForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<CancelOrderFormValues>([
      simpleResolver<CancelOrderFormValues>(),
      yupResolver(validationSchema),
    ]),
    [validationSchema],
  );
  const form = useForm<CancelOrderFormValues>({
    resolver,
    mode: 'onSubmit',
  });

  return form;
}
