import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AsyncStatus, PA } from 'utlis/State';
import { NotAuthorizedPermissions, Permissions } from '../Permissions';
import { NotAuthorizedSettings, Settings } from '../Settings';
import { NotAuthorizedRegistrationSteps, RegistrationSteps } from '../RegistrationSteps';
import { PackageSize } from '../../../pages/Orders/model/Order';
import { Location } from '../../../model/Location';

export interface State {
  /**
   * Divide the app initialization into two stages.
   * The first, "preAuthorized" is executed at the app boot up.
   * The second, "authorized" is executed every time user logs in or enters the application and his session didn't expire
   */
  appInitialization: {
    isPreAuthorized: boolean;
    isInProgress: boolean;
    authorization: {
      isAuthorized: boolean;
      permissions: Permissions;
      settings: Settings;
      registrationSteps?: RegistrationSteps;
    };
  };
  logInStatus: AsyncStatus;
  registerClientStatus: AsyncStatus;
  registerClientError?: string;
  selectProductStatus: AsyncStatus;
  addLocationsStatus: AsyncStatus;
}

const initialState: State = {
  appInitialization: {
    isPreAuthorized: false,
    isInProgress: false,
    authorization: {
      isAuthorized: false,
      permissions: NotAuthorizedPermissions,
      settings: NotAuthorizedSettings,
    },
  },
  logInStatus: AsyncStatus.NotStarted,
  registerClientStatus: AsyncStatus.NotStarted,
  registerClientError: undefined,
  selectProductStatus: AsyncStatus.NotStarted,
  addLocationsStatus: AsyncStatus.NotStarted,
};

const slice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    initialize(state) {
      state.appInitialization.isPreAuthorized = false;
      state.appInitialization.authorization = {
        isAuthorized: false,
        permissions: NotAuthorizedPermissions,
        settings: NotAuthorizedSettings,
      };
      state.appInitialization.isInProgress = true;
    },
    initializeAppSuccess(state) {
      state.appInitialization.isInProgress = false;
    },
    initializePreAuthorizedStageSuccess(state) {
      state.appInitialization.isPreAuthorized = true;
    },
    initializeAuthorizedStageSuccess(
      state,
      { payload }: PA<ActionTypes.InitializeAuthorizedStageSuccess>,
    ) {
      state.appInitialization.authorization = {
        isAuthorized: true,
        permissions: payload.permissions,
        settings: payload.settings,
        registrationSteps: payload.registrationSteps,
      };
    },
    initializeAuthorizedStageError(state) {
      state.appInitialization.authorization = {
        isAuthorized: false,
        permissions: NotAuthorizedPermissions,
        settings: NotAuthorizedSettings,
      };
    },
    reinitializeAuthorizedStage(state) {
      state.appInitialization.isInProgress = true;
    },
    reinitializeAuthorizedFinished(state) {
      state.appInitialization.isInProgress = false;
    },
    logIn(state, _action: PA<ActionTypes.LogIn>) {
      state.logInStatus = AsyncStatus.Pending;
    },
    logInSuccess(state) {
      state.logInStatus = AsyncStatus.Success;
    },
    logInError(state) {
      state.logInStatus = AsyncStatus.Error;
    },
    logOut(_state, _action: PA<ActionTypes.LogOut>) {},
    logOutSuccess(state) {
      state.appInitialization.authorization = {
        isAuthorized: false,
        permissions: NotAuthorizedPermissions,
        settings: NotAuthorizedSettings,
        registrationSteps: NotAuthorizedRegistrationSteps,
      };
    },
    replaceToken(_state, _action: PA<ActionTypes.ReplaceToken>) {},
    redirectFromRestrictedZone() {},
    redirectToLocationsPage() {},
    redirectToProductSelectPage() {},
    redirectToMainPage() {},
    registerClient(state, _action: PA<ActionTypes.RegisterClient>) {
      state.registerClientStatus = AsyncStatus.Pending;
      state.registerClientError = undefined;
    },
    registerClientSuccess(state) {
      state.registerClientStatus = AsyncStatus.Success;
    },
    registerClientError(state, _action: PA<ActionTypes.RegisterClientError>) {
      state.registerClientStatus = AsyncStatus.Error;
      state.registerClientError = _action.payload.error;
    },
    selectProduct(state, _action: PA<ActionTypes.SelectProduct>) {
      state.selectProductStatus = AsyncStatus.Pending;
    },
    selectProductSuccess(state) {
      state.selectProductStatus = AsyncStatus.Success;
      state.appInitialization.authorization = {
        ...state.appInitialization.authorization,
        registrationSteps: {
          ...state.appInitialization.authorization.registrationSteps,
          productSelectionCompleted: new Date().getTime(),
        },
      };
    },
    selectProductError(state, _action: PA<ActionTypes.RegisterClientError>) {
      state.selectProductStatus = AsyncStatus.Error;
    },
    addLocations(state, _action: PA<ActionTypes.AddLocations>) {
      state.addLocationsStatus = AsyncStatus.Pending;
    },
    addLocationsSuccess(state) {
      state.addLocationsStatus = AsyncStatus.Success;
      state.appInitialization.authorization = {
        ...state.appInitialization.authorization,
        registrationSteps: {
          ...state.appInitialization.authorization.registrationSteps,
          locationsCompleted: new Date().getTime(),
          /*activated: true,*/
        },
      };
    },
    addLocationsError(state, _action: PA<ActionTypes.RegisterClientError>) {
      state.addLocationsStatus = AsyncStatus.Error;
    },
  },
});

export declare namespace ActionTypes {
  export interface LogOut {
    message: {
      content: string;
      id?: string;
    };
  }

  export interface LogIn {
    username: string;
    password: string;
  }

  export interface RegisterClient {
    email: string;
    password: string;
    name: string;
    phone?: string;
    deliveryProductType?: string;
    city?: string;
  }

  export interface RegisterClientError {
    error?: string;
  }

  export interface SelectProduct {
    defaultPackageSize: PackageSize;
  }

  export interface AddLocations {
    locations: Partial<Location>[];
  }

  export interface SelectProductError {
    error?: string;
  }

  export interface InitializeAuthorizedStageSuccess {
    permissions: Permissions;
    settings: Settings;
    registrationSteps: RegistrationSteps;
  }

  export interface ReplaceToken {
    token: string;
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectIsAuthorized: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.appInitialization.authorization.isAuthorized;
    }),
  makeSelectAppInitialization: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.appInitialization;
    }),
  makeSelectAuthorization: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.appInitialization.authorization;
    }),
  makeSelectRegisterStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.registerClientStatus;
    }),
  makeSelectRegisterClientError: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.registerClientError;
    }),
  makeSelectSelectProductStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.selectProductStatus;
    }),
  makeSelectRegistrationSteps: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.appInitialization.authorization.registrationSteps;
    }),
  makeSelectAddLocationsStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.addLocationsStatus;
    }),
};
