import { useTranslation } from 'app/translations/useTranslation';
import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import { Company, ContractData } from '../../../../model/Company';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { Trans } from 'react-i18next';
import * as Styled from '../../../RegisterPage/components/RegisterForm/styled';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { PrimaryButton } from '../../../../components/Button/Buttons';
import { useDownloadContract } from './hooks/useDownloadContract';

interface Props {
  company?: Company;
}

export function GenerateContract({ company }: Props) {
  const { t } = useTranslation('settings');
  const { isDownloadingContract, downloadContract } = useDownloadContract();
  const [termsOfServiceAccepted, setTermsOfServiceAccepted] = useState<boolean>(false);
  if (!company?.contractData) {
    return <Alert severity="warning">{t('generateContract.status.pending')}</Alert>;
  }

  const onChange = (event: any) => {
    setTermsOfServiceAccepted(event.target.checked);
  };

  /*const downloadContract = () => {

  };*/

  if (company?.contractData) {
    return (
      <ContractGeneratorContainer>
        <Alert severity={!company?.contract ? 'warning' : 'success'}>
          <Trans
            t={t}
            i18nKey={
              !company?.contract
                ? 'generateContract.status.notGenerated'
                : 'generateContract.status.generated'
            }
          >
            <Link target="_blank" href={'mailto:support@xpress.delivery'} />
          </Trans>
        </Alert>

        <ContractGenerator>
          <FormGroup>
            <FormControlLabel
              checked={termsOfServiceAccepted}
              control={<Checkbox />}
              onChange={onChange}
              label={
                <Trans t={t} i18nKey="companyData.form.fields.termsOfService.label">
                  {' '}
                  <Link href={'https://xpress.delivery/regulamin'} target="_blank" />{' '}
                </Trans>
              }
            />
          </FormGroup>
          <DownloadContractButton
            color={'success'}
            fullWidth
            onClick={() => {
              if (isDownloadingContract) {
                return;
              }
              downloadContract();
            }}
            variant="contained"
            disabled={isDownloadingContract || !termsOfServiceAccepted}
            loading={isDownloadingContract}
          >
            {t('generateContract.generateContractButton')}
          </DownloadContractButton>
        </ContractGenerator>
      </ContractGeneratorContainer>
    );
  }
  return <></>;
}

const ContractGeneratorContainer = styled('div')``;
const ContractGenerator = styled('div')`
  padding: 20px;
`;

const DownloadContractButton = styled(PrimaryButton)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
