// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { Redirect, RedirectProps, Route, RouteProps } from 'react-router';
import HasRoles from 'services/authorization/components/HasRoles';

// Component props type
type RoleRoutePros = {
  roles: string | string[];
  redirect?: string | RedirectProps;
  // loading?: React.ReactNode;
} & RouteProps;

// Component
const RoleRoute = ({ redirect, roles, /* loading, */ ...rest }: RoleRoutePros) => {
  const redirectProps = useMemo(
    () => (typeof redirect === 'string' ? { to: redirect } : redirect),
    [redirect],
  );

  return (
    <HasRoles
      roles={roles}
      // loading={loading || <LinearProgress />}
      {...(redirectProps ? { noAccess: <Redirect {...redirectProps} /> } : {})}
    >
      <Route {...rest} />
    </HasRoles>
  );
};

export default RoleRoute;
