import { takeLatest } from 'redux-saga/effects';
import { AuthorizedInitSteps } from 'services/authorization';
import { put } from 'typed-redux-saga';
import { ProductApiSaga } from 'api/ProductApi';
import * as Slice from './slice';

function* fetchProductList() {
  const response = yield* ProductApiSaga.listProducts();
  yield put(
    Slice.actions.fetchProductListSuccess({
      products: response.products.map((product) => ({
        id: product.id,
        name: product.name,
        companyId: product.companyId,
        type: product.type,
        code: product.code,
        dimensionsAlgorithm: product.dimensionsAlgorithm,
        maximumDimensions: product.maximumDimensions,
        productSettings: product.productSettings,
      })),
    }),
  );
}

export function* saga() {
  yield takeLatest(Slice.actions.fetchProductList, fetchProductList);
  AuthorizedInitSteps.addStep(fetchProductList);
}
