import React from 'react';

import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';

import { SimplifiedStock } from '../../../Stock';
import { StockTransferRowDetails } from './Row/components/StockTransferRowDetails/StockTransferRowDetails';
import { StockExchangeRowDetails } from './Row/components/StockExchangeRowDetails/StockExchangeRowDetails';

// Props type
type Props = {
  stock: SimplifiedStock;
  showTransferStock: boolean;
  showPendingStockList: boolean;
};

// Component
const ExpandedContent: React.FC<Props> = ({ stock, showTransferStock, showPendingStockList }) => {
  return (
    <>
      {showTransferStock && (
        <StockTransferRowDetails
          productId={stock.productId}
          stockAmount={stock.amount}
          canTransferStock={stock.canTransferStock}
        />
      )}
      {showPendingStockList && <StockExchangeRowDetails productId={stock.productId} />}
    </>
  );
};

const Box = styled(MUIBox)({});

export default ExpandedContent;
