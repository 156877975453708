import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Slice from './slice';

export function useCancelOrder() {
  const dispatch = useDispatch();
  const cancelOrderStatus = useSelector(Slice.selectors.makeSelectCancelOrderStatus());

  return {
    cancelOrderStatus,
    cancelOrder(params: Slice.ActionTypes.CancelOrder) {
      dispatch(Slice.actions.cancelOrder(params));
    },
  };
}
