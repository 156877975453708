import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';

export type DeleteWalletEntryFormValues = {
  comment?: string;
  reason: string;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object().shape({
      comment: yup.string().optional(),
      reason: yup.string().required(t('required')),
    });
  }, [t]);
}

export const useDeleteWalletEntryFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    form.setValue('comment', undefined);
    form.setValue('reason', undefined);
  }, [form]);
};

export function useDeleteWalletEntryForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<DeleteWalletEntryFormValues>([
      simpleResolver<DeleteWalletEntryFormValues>(),
      yupResolver(validationSchema),
    ]),
    [validationSchema],
  );
  const form = useForm<DeleteWalletEntryFormValues>({
    resolver,
    mode: 'onSubmit',
  });

  return form;
}
