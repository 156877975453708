import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
// import { styled } from '@mui/material/styles';
import { Input as FormInput } from 'components/ReactHookForm/Input/Input';

// Props type
type Props = {
  fieldGroupName: string;
};

// Component
const AmountToPay = ({ fieldGroupName }: Props) => {
  const { t } = useTranslation(['order', 'validationErrors']);

  const rules = useMemo(
    () => ({
      required: t('validationErrors:required'),
      min: { value: 0.01, message: t('validationErrors:minAmount') },
    }),
    [t],
  );

  return (
    <FormInput
      name={`${fieldGroupName}.amountToPayOnPickUp`}
      label={t('labels.productFields.amountToPayOnPickUp')}
      rules={rules}
      size="small"
    />
  );
};

export default AmountToPay;
