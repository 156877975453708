import React, { FormEventHandler } from 'react';

import { find } from 'lodash';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';

import { useProducts } from '../../../../../services/products/state/useProducts';
import useOrderCompany from '../OrderCreateForm/hooks/useOrderCompany';
import LoadingButton from '@mui/lab/LoadingButton';
import useOrderCreate from '../../../state/hooks/useOrderCreate';

interface Props {
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
  onSubmit: VoidFunction;
}

export function SuggestedProductsPrompt(props: Props) {
  const { t } = useTranslation('order');
  const company = useOrderCompany();
  const { products } = useProducts({ companyId: company?.id || undefined, hideOnOrderForm: false });
  const { inProgressState } = useOrderCreate();
  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={() => props.toggleOpen(false)}
        scroll="paper"
        maxWidth="lg"
      >
        <DialogContent>
          <Box>{t('orderCreate.suggestedProductsPrompt.body')}</Box>
          <List dense>
            {(company.orderSettings?.orderFormSuggestedProducts ?? []).map((productId: string) => {
              const product = find(products, (product) => product.id === productId);
              return (
                <ListItem key={productId}>
                  <ListItemText primary={product?.name} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={() => {
              props.toggleOpen(false);
            }}
            variant="outlined"
            color="primary"
            disabled={inProgressState.inProgress}
            loading={inProgressState.inProgress}
          >
            {t('orderCreate.suggestedProductsPrompt.cancel')}
          </LoadingButton>
          <LoadingButton
            onClick={() => {
              props.onSubmit();
              props.toggleOpen(false);
            }}
            variant="contained"
            color="primary"
            disabled={inProgressState.inProgress}
            loading={inProgressState.inProgress}
          >
            {t('orderCreate.suggestedProductsPrompt.submit')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
  background: ${theme.colors.primary.background};
`,
);
