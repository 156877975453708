import MenuIcon from '@mui/icons-material/Menu';
import { XDLogo, LogoVersion, Logo } from 'assets/Logo';
import React, { ReactChild } from 'react';
import { useMenu } from './components/Menu/useMenu';
import * as Styled from './styled';
import { useSettings } from '../../services/authorization/hooks/useSettings';
import { useAuth } from '../../services/authorization';
import { useTranslation } from '../../app/translations/useTranslation';

interface Props {
  children: ReactChild[] | ReactChild;
}

export function UserLayout(props: Props) {
  const { t } = useTranslation('auth');
  const { toggleMenu, menuProps } = useMenu();
  const settings = useSettings();
  const { isActivated } = useAuth();
  return (
    <Styled.Box>
      <Styled.AppBar color="inherit">
        <Styled.IconButton onClick={toggleMenu} data-cy={'button-extend_menu'}>
          <MenuIcon />
        </Styled.IconButton>
        <Styled.AppBarContainer>
          <Styled.LogoBox>
            {settings.dashboardImage ? (
              <Logo src={settings.dashboardImage} />
            ) : (
              <XDLogo version={LogoVersion.OneLine} />
            )}
          </Styled.LogoBox>
          <Styled.AccountStatusBox>
            {isActivated ? (
              <Styled.AccountStatusActive>{t('isActivated')}</Styled.AccountStatusActive>
            ) : (
              <Styled.AccountStatusInactive>{t('notActivated')}</Styled.AccountStatusInactive>
            )}
          </Styled.AccountStatusBox>
        </Styled.AppBarContainer>
      </Styled.AppBar>
      <Styled.Menu {...menuProps} />
      <Styled.ContentBox>{props.children}</Styled.ContentBox>
    </Styled.Box>
  );
}
