import React, { useEffect } from 'react';

import { FormProvider } from 'react-hook-form';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'app/translations/useTranslation';
import { PrimaryButton } from 'components/Button/Buttons';
import { Input } from 'components/ReactHookForm/Input/Input';
import { useChangePassword } from 'pages/SettingsPage/state/useChangePassword';
import { useCompanyDataForm, CompanyDataFormValues } from './hooks/useCompanyDataForm';
import { Divider, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Checkbox as FormCheckbox } from '../../../../components/ReactHookForm/Checkbox/Checkbox';
import { Trans } from 'react-i18next';
import Link from '@material-ui/core/Link';
import * as Styled from '../../../RegisterPage/components/RegisterForm/styled';
import { useCompanyData } from '../../state/useCompanyData';
import { useCompanies } from '../../../../services/companies/state/useCompanies';

interface Props {}

export function CompanyDataForm(props: Props) {
  const { t } = useTranslation('settings');
  const { updateCompanyData, isInProgress } = useCompanyData();
  const { company } = useCompanies();
  const form = useCompanyDataForm();
  useEffect(() => {
    if (!isInProgress) {
      form.reset();
    }
  }, [isInProgress]);
  return (
    <FormContainer>
      <FormProvider {...form}>
        <Form
          onSubmit={form.handleSubmit((formValues: CompanyDataFormValues) => {
            updateCompanyData({
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              name: formValues.companyName,
              headquartersIn: formValues.headquartersIn,
              nip: formValues.nip,
              regon: formValues.regon,
              street: formValues.street,
              zipCode: formValues.zipCode,
              city: formValues.city,
            });
          })}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Input
                name={'firstName'}
                label={t('companyData.form.fields.firstName.label')}
                placeholder={t('companyData.form.fields.firstName.placeholder')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name={'lastName'}
                label={t('companyData.form.fields.lastName.label')}
                placeholder={t('companyData.form.fields.lastName.placeholder')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name={'companyName'}
                label={t('companyData.form.fields.companyName.label')}
                placeholder={t('companyData.form.fields.companyName.placeholder')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name={'headquartersIn'}
                label={t('companyData.form.fields.headquartersIn.label')}
                placeholder={t('companyData.form.fields.headquartersIn.placeholder')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name={'nip'}
                readOnly={!!company?.contractData?.nip}
                label={t('companyData.form.fields.nip.label')}
                placeholder={t('companyData.form.fields.nip.placeholder')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name={'regon'}
                label={t('companyData.form.fields.regon.label')}
                placeholder={t('companyData.form.fields.regon.placeholder')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                name={'street'}
                label={t('companyData.form.fields.street.label')}
                placeholder={t('companyData.form.fields.street.placeholder')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Input
                name={'zipCode'}
                label={t('companyData.form.fields.zipCode.label')}
                placeholder={t('companyData.form.fields.zipCode.placeholder')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Input
                name={'city'}
                label={t('companyData.form.fields.city.label')}
                placeholder={t('companyData.form.fields.city.placeholder')}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {/* <Styled.Checkbox
                name={'confirmTermsOfService'}
                label={
                  <Trans t={t} i18nKey="companyData.form.fields.termsOfService.label">
                    {' '}
                    <Link href={'https://xpress.delivery/regulamin'} target="_blank" />{' '}
                  </Trans>
                }
                dataCy={'input-confirmTermsOfService'}
              />*/}
            </Grid>
          </Grid>
          <SaveButton isInProgress={isInProgress} type="submit">
            {t('companyData.form.submitButton')}
          </SaveButton>
        </Form>
      </FormProvider>
    </FormContainer>
  );
}

const SaveButton = styled(PrimaryButton)`
  margin-top: 15px;
`;
const FormContainer = styled('div')`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Form = styled('form')``;
export const Checkbox = styled(FormCheckbox)(
  ({ theme }) => `
  margin: 0;
  width: 100%;
`,
);
