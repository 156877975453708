import React, { useEffect, useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';

import { useTranslation } from 'app/translations/useTranslation';
import { PrimaryButton } from 'components/Button/Buttons';
import { Location } from '../../../../model/Location';
import { Card, Grid } from '@mui/material';
import { LocationListItem } from './LocationListItem';
import { AddLocationModal } from './AddLocationModal/AddLocationModal';

interface Props {
  onSubmit: (location: Partial<Location>) => void;
}

export function AddLocationListItem({ onSubmit }: Props) {
  const { t } = useTranslation('settings');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <AddLocationListItemContainer container spacing={2}>
      <Grid item xs={6} md={9}>
        <LocationListItem />
      </Grid>
      <Grid item xs={6} md={3}>
        <AddLocationButton onClick={() => setIsOpen(true)}>
          {t('locations.addLocationButton')}
        </AddLocationButton>
        <AddLocationModal isOpen={isOpen} onClose={() => setIsOpen(false)} onSubmit={onSubmit} />
      </Grid>
    </AddLocationListItemContainer>
  );
}

const AddLocationListItemContainer = styled(Grid)`
  margin-top: 15px;
`;

const AddLocationButton = styled(PrimaryButton)``;

const Form = styled('form')`
  max-width: 500px;
`;
