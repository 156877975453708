export type Coordinates = {
  lat: number,
  lng: number
}

export enum MarkerType {
  LastLocation = 'LastLocation',
  StartLocation = 'StartLocation',
  Destination = 'Destination',
  PickupPoint = 'PickupPoint'
}

export type Marker = {
  type: MarkerType,
  location: Coordinates,
}

export interface Route {
  markers: Marker[],
  orderId: string,
  history: Coordinates[]
}
