import React from 'react';

import { useTranslation } from 'app/translations/useTranslation';
import CompaniesList from './CompaniesList';
import Page from 'components/Page/Page';

// Props type
type Props = {};

// Component
const CompaniesListPage = (props: Props) => {
  const { t } = useTranslation('company');

  return (
    <Page>
      <Page.Header>{t('list.title')}</Page.Header>
      <CompaniesList />
    </Page>
  );
};

export default CompaniesListPage;
