import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AsyncStatus, PA } from 'utlis/State';

export interface State {
  activateCompanyStatus: AsyncStatus;
}

const initialState: State = {
  activateCompanyStatus: AsyncStatus.NotStarted,
};

const slice = createSlice({
  name: 'companiesPage',
  initialState,
  reducers: {
    activateCompany(state, _action: PA<ActionTypes.ActivateCompany>) {
      state.activateCompanyStatus = AsyncStatus.Pending;
    },
    activateCompanyFinish(state) {
      state.activateCompanyStatus = AsyncStatus.Success;
    },
  },
});

export declare namespace ActionTypes {
  export interface ActivateCompany {
    name: string;
    companyId: string;
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectActivateCompanyStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.activateCompanyStatus;
    }),
};
