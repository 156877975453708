import { useCallback, useMemo } from 'react';
import { FieldValues, useForm, useFormContext } from 'react-hook-form';
import { useCompanies } from 'services/companies/state/useCompanies';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';
import { useTranslation } from 'app/translations/useTranslation';
import { PackageSize } from '../../model/Order';

export const useDefaultValues = () => {
  const { companies } = useCompanies();

  return useMemo(
    () => ({
      companyId: (companies[0] && companies[0].id) || undefined,
    }),
    [companies],
  );
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    const addressShape = {
      name: yup.string().required(t('required')),
      street: yup.string().required(t('required')),
      zipCode: yup
        .string()
        .required(t('required'))
        .matches(/^\d{2}-\d{3}$/, t('zipCodePattern')),
      city: yup.string().required(t('required')),
      phone: yup
        .string()
        .required(t('required'))
        .matches(/^(((((\([+]?\d{1,3}\))|[+]\d{1,3})[ ]?)?\d([ ]?\d){8})|-)$/, t('pattern')),
      paymentDetails: yup
        .object()
        .shape({
          paymentCode: yup
            .string()
            .required()
            .matches(/^\d+$/, t('pattern'))
            .length(20, t('validationErrors:length', { count: 20 })),
        })
        .optional()
        .default(undefined),
    };

    return yup.object().shape({
      companyId: yup.string().required(),
      products: yup
        .array()
        .required(t('order.noProducts'))
        .min(1, t('order.noProducts'))
        .of(
          yup.object().shape({
            vehicle: yup
              .object()
              .shape({
                mark: yup.string().required(t('required')),
                model: yup.string().required(t('required')),
                registrationPlate: yup
                  .string()
                  .optional()
                  .matches(/^[A-z0-9]+$/, t('pattern')),
                color: yup.string().optional(),
                vinNumber: yup
                  .string()
                  .optional()
                  .matches(/^[A-z0-9]{17}$/, t('pattern')),
              })
              .notRequired()
              .default(undefined),
          }),
        ),
      client: yup.object().optional().required().shape(addressShape),
      pickupPoint: yup.object().shape(addressShape).notRequired().default(undefined),
      packageSize: yup.string().notRequired(),
      externalId: yup
        .string()
        .notRequired()
        .max(32, t('validationErrors:maxSize', { count: 32 })),
    });
  }, [t]);
}

export const useOrderCreateFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    const companyId = form.getValues('companyId');

    form.reset({ companyId });
  }, [form]);
};

export function useOrderCreateForm() {
  const defaultValues = useDefaultValues();

  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<FieldValues>([yupResolver(validationSchema), simpleResolver()]),
    [validationSchema],
  );

  const form = useForm({
    resolver,
    mode: 'onSubmit',
    defaultValues,
  });

  return form;
}
