import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';

export type ChangeOperatorPasswordFormValues = {
  password: string;
  operatorId: string;
};

function useValidationSchema() {
  const { t } = useTranslation('settings');
  return useMemo(() => {
    return yup.object({
      password: yup.string().min(7).required(),
      passwordConfirmation: yup
        .string()
        .required()
        .oneOf([yup.ref('password')], t('fields.validation.passwordNotTheSame')),
    });
  }, [t]);
}

export function useChangeOperatorPasswordForm() {
  const validationSchema = useValidationSchema();
  return useForm<ChangeOperatorPasswordFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });
}
