import React from 'react';

import Page from 'components/Page/Page';

import { Filters } from './components/Filters/Filters';
import { WarehouseList } from './components/WarehouseList/WarehouseList';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';

interface Props {}

export function WarehousePage(props: Props) {
  const { t } = useTranslation('warehouse');
  return (
    <Page>
      <Page.Header>{t('list.title')}</Page.Header>
      <Card>
        <WarehouseList />
      </Card>
    </Page>
  );
}
