import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useCreateWalletEntry() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectCreateWalletEntryStatus());

  return {
    create: (params: Slice.ActionTypes.CreateWalletEntry) => {
      dispatch(Slice.actions.createWalletEntry(params));
    },
    isInProgress: status === AsyncStatus.Pending,
    status,
  };
}
