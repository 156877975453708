import { useSelector } from 'react-redux';
import * as Slice from '../state/slice';
import { useMemo } from 'react';

type PermissionsTree = { [key: string]: PermissionsTree | boolean };

export const useIsPermissionGranted = (path: string | string[]): boolean => {
  const { permissions } = useSelector(Slice.selectors.makeSelectAuthorization());

  return useMemo(() => {
    const paths = Array.isArray(path) ? path : [path];

    return paths.every((p) => {
      const pathParts = p.split('.');

      let permission: PermissionsTree | boolean | undefined = permissions;
      let pathPart;
      while ((pathPart = pathParts.shift())) {
        if (typeof permission !== 'object') {
          return false;
        }
        permission = permission[pathPart];
      }

      return permission === true;
    });
  }, [path, permissions]);
};
