import { startOfToday, endOfToday, startOfYesterday, endOfYesterday, endOfWeek, startOfWeek, subDays, startOfMonth, endOfMonth } from 'date-fns';
import { DateRange } from 'utlis/Date';

export type DateRangePreset = {
  getDateRange: () => DateRange,
  key: string,
}

export const DateRangePresets = {
  Today: { getDateRange: () => new DateRange(startOfToday(), endOfToday()), key: 'today' },
  Yesterday: { getDateRange: () => new DateRange(startOfYesterday(), endOfYesterday()), key: 'yesterday' },
  LastWeek: {
    getDateRange: () => {
      const lastWeekDay = subDays(new Date(), 7);
      return new DateRange(startOfWeek(lastWeekDay, { weekStartsOn: 1 }), endOfWeek(lastWeekDay, { weekStartsOn: 1 }));
    },
    key: 'lastWeek',
  },
  LastMonth: {
    getDateRange: () => {
      const lastDayOfLastMonth = subDays(startOfMonth(new Date()), 1);
      return new DateRange(startOfMonth(lastDayOfLastMonth), endOfMonth(lastDayOfLastMonth));
    },
    key: 'lastMonth',
  },
};
