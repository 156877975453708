import { State } from '../model/State';
import { ActionFailureError, PA } from 'utlis/State';
import OrderCreateActionTypes from '../model/OrderCreateActionTypes';
import { Location } from 'model/Location';

export const orderCreate = (state: State, action: PA<OrderCreateActionTypes.OrderCreate>) => {
  state.orderCreate.status = { inProgress: true };
};

export const orderCreateSuccess = (state: State) => {
  state.orderCreate.status = { inProgress: false, success: true };
};

export const orderCreateFailure = (state: State, action: PA<ActionFailureError>) => {
  state.orderCreate.status = { inProgress: false, success: false, error: action.payload };
};

export const setSelectedPickupPoint = (state: State, action: PA<Location | undefined>) => {
  state.orderCreate.selectedPickupPoint = action.payload;
};

export const setSelectedClient = (state: State, action: PA<Location | undefined>) => {
  state.orderCreate.selectedClient = action.payload;
};
