export enum ReportType {
  DriverActivity = 'driverActivity',
  DriverAvailability = 'driverAvailability',
  OrderOffers = 'orderOffers',
  OrdersFull = 'ordersFull',
  DriversStock = 'driversStock',
  Corrections = 'corrections',
  DriversFeedback = 'driversFeedback',
  AuditStockMovement = 'auditStockMovement',
  StockExchange = 'stockExchange',
}
