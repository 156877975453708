import { useTranslation } from 'app/translations/useTranslation';
import { Section } from 'components/Section';
import Grid from '@mui/material/Grid';
import PendingList from './components/PendingList/PendingList';

interface Props {}

export function CorrectionPage(props: Props) {
  const { t } = useTranslation('correction');

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={12}>
          <Section.Box>
            <Section.Header>{t('headers.main')}</Section.Header>
            <Section.Divider />
            <PendingList />
          </Section.Box>
        </Grid>
      </Grid>
    </>
  );
}
