import React from 'react';

import { styled } from '@mui/material/styles';
import { default as MuiTableBody } from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { default as MuiTableRow } from '@mui/material/TableRow';

import { useTranslation } from 'app/translations/useTranslation';
import { Loader } from 'assets/Loader/Loader';
import { SthWentWrong } from 'components/Statements/SthWentWrong';
import { User } from 'pages/UserPage/User';

interface Props {
  users: User[];
  hasError: boolean;
  isLoading: boolean;
  onReload: VoidFunction;
  onUserClick: (user: User | null) => void;
  activeUser: User | null;
}

export function TableBody(props: Props) {
  const { t } = useTranslation('user');

  if (props.hasError === true) {
    return (
      <MuiTableBody>
        <MuiTableRow key={'error'}>
          <TableCell colSpan={100}>
            <SthWentWrong onRefresh={props.onReload} />
          </TableCell>
        </MuiTableRow>
      </MuiTableBody>
    );
  }

  if (props.isLoading === true) {
    return (
      <MuiTableBody>
        <MuiTableRow key={'loader'}>
          <TableCell colSpan={100}>
            <LoaderBox>
              <Loader />
            </LoaderBox>
          </TableCell>
        </MuiTableRow>
      </MuiTableBody>
    );
  }

  if (props.users.length === 0) {
    return (
      <MuiTableBody>
        <MuiTableRow key={'noResults'}>
          <TableCell colSpan={100}>{t('list.noResults')}</TableCell>
        </MuiTableRow>
      </MuiTableBody>
    );
  }

  return (
    <MuiTableBody>
      {props.users.map((user) => (
        <TableRow
          onClick={() => {
            if (user.id === props.activeUser?.id) {
              props.onUserClick(null);
            } else {
              props.onUserClick(user);
            }
          }}
          key={user.id}
          $isActive={user.id === props.activeUser?.id}
        >
          <TableCell>{user.email}</TableCell>
        </TableRow>
      ))}
    </MuiTableBody>
  );
}

const LoaderBox = styled('div')`
  height: 50px;
  width: 50px;
  margin: 0 auto;
`;

const TableRow = styled(MuiTableRow)<{ $isActive: boolean }>`
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
  ${(props) =>
    props.$isActive === true &&
    `
    background: rgba(0, 0, 0, 0.06);
  `}
`;
