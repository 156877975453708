import { FunctionComponent } from 'react';
import { ReportType } from '../../ReportType';
import {
  DriverActivityReport,
  DriversStockReport,
  DriverAvailabilityReport,
  OrderOffersReport,
  OrdersFullReport,
  DriversFeedbackReport,
  CorrectionsReport,
  StockExchangeReport,
} from './components/Reports';
import { NotSelectedReport } from './components/Reports/NotSelectedReport';
import { AuditStockMovementReport } from './components/Reports/AuditStockMovementReport';

type FormComponent = FunctionComponent<any>;
const typeToComponentMap: { [key in ReportType]: FormComponent } = {
  [ReportType.DriverAvailability]: DriverAvailabilityReport,
  [ReportType.DriverActivity]: DriverActivityReport,
  [ReportType.OrderOffers]: OrderOffersReport,
  [ReportType.OrdersFull]: OrdersFullReport,
  [ReportType.DriversStock]: DriversStockReport,
  [ReportType.DriversFeedback]: DriversFeedbackReport,
  [ReportType.Corrections]: CorrectionsReport,
  [ReportType.AuditStockMovement]: AuditStockMovementReport,
  [ReportType.StockExchange]: StockExchangeReport,
};

interface Props {
  type: ReportType | null;
}

export function ReportGenerator(props: Props) {
  if (props.type === null) {
    return <NotSelectedReport />;
  }
  const ReportComponent = typeToComponentMap[props.type];
  return <ReportComponent />;
}
