import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import { default as MuiTable } from '@mui/material/Table';
import Box from '@mui/material/Box';

export const Table = styled(MuiTable)`
  width: 400px;
`;

export const Cell = styled(TableCell)(
  ({ theme }) => `
  padding: ${theme.spacing(0.5)};
  font-size: 12px;
  &:not(:first-of-type) {
    padding-left: 10px;
  }
`,
);

export const HeadCell = styled(Cell)`
  font-weight: lighter;
`;

export const NameHead = styled(HeadCell)`
  width: 200px;
`;

export const ContentCell = styled(Cell)``;

export const ContentCellData = styled(Box)``;
