import { useSelector } from 'react-redux';

import * as Slice from './slice';

export function useOrder(orderId: string) {
  const order = useSelector(Slice.selectors.makeSelectOrder(orderId));
  return {
    order,
  };
}
