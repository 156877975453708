import {
  FieldErrors,
  get,
  set,
  RegisterOptions,
  Resolver,
  Field,
  ResolverResult,
  FieldValues,
} from 'react-hook-form';

type Validator = (
  props: { value?: any } & RegisterOptions,
) => { type: string; message?: string } | undefined;

const generateAllNames = (values: any, path = ''): string[] => {
  const names: string[] = [];

  if (Array.isArray(values)) {
    values.forEach((value, index) => {
      names.push(`${path}${index}`);
      names.splice(names.length, 0, ...generateAllNames(value, `${path}${index}.`));
    });
  } else if (typeof values === 'object') {
    Object.keys(values).forEach((key) => {
      names.push(`${path}${key}`);
      names.splice(names.length, 0, ...generateAllNames(values[key], `${path}${key}.`));
    });
  }

  return names;
};

const requiredValidator: Validator = ({ value, required }) => {
  if (required && (value === '' || value === undefined)) {
    return {
      type: 'required',
      ...(typeof required === 'string' ? { message: required } : {}),
    };
  }
};

const maxValidator: Validator = ({ value, max }) => {
  if (!max || (typeof value !== 'number' && !value)) {
    return;
  }

  const maxValue =
    typeof max === 'number' ? max : typeof max === 'string' ? parseFloat(max) : max.value;
  const parsedValue = typeof value === 'number' ? value : parseFloat(value.replace(',', '.'));

  if (isNaN(parsedValue) || parsedValue > maxValue) {
    const message = typeof max === 'object' && max.message;
    return { type: 'max', ...(message ? { message } : {}) };
  }
};

const minValidator: Validator = ({ value, min }) => {
  if (!min || (typeof value !== 'number' && !value)) {
    return;
  }

  const minValue =
    typeof min === 'number' ? min : typeof min === 'string' ? parseFloat(min) : min.value;
  const parsedValue = typeof value === 'number' ? value : parseFloat(value.replace(',', '.'));

  if (isNaN(parsedValue) || parsedValue < minValue) {
    const message = typeof min === 'object' && min.message;
    return { type: 'min', ...(message ? { message } : {}) };
  }
};

const patternValidator: Validator = ({ value, pattern }) => {
  if (!pattern || typeof value !== 'string' || !value) {
    return;
  }

  const patternValue: RegExp = pattern instanceof RegExp ? pattern : pattern.value;

  if (!patternValue.exec(value)) {
    const message = !(pattern instanceof RegExp) && pattern.message;
    return { type: 'pattern', ...(message ? { message } : {}) };
  }
};

const validateValidator: Validator = ({ value, validate }) => {
  if (validate) {
    let result;

    if (validate instanceof Function) {
      result = validate(value);

      if (result === false || typeof result === 'string') {
        return { type: 'validate', ...(typeof result === 'string' ? { message: result } : {}) };
      }
    } else {
      for (const validationName in validate) {
        if (validate.hasOwnProperty(validationName)) {
          result = validate[validationName](value);

          if (result === false || typeof result === 'string') {
            return { type: 'validate', ...(typeof result === 'string' ? { message: result } : {}) };
          }
        }
      }
    }
  }
};

const validateField = async (props: { name: string; value?: any; ref: any } & RegisterOptions) =>
  (await requiredValidator(props)) ||
  (await maxValidator(props)) ||
  (await minValidator(props)) ||
  (await validateValidator(props)) ||
  (await patternValidator(props));

export const simpleResolver =
  <T extends FieldValues = FieldValues>(): Resolver<T> =>
  async (values, context, { names, fields }) => {
    const paths = names ?? generateAllNames(values);

    const fieldErrors = {} as FieldErrors<T>;

    for (const path of paths) {
      // const path = names[index];
      const field = get(fields, path) as Field['_f'] | undefined;
      const value = get(values, path);
      const error = field && (await validateField({ ...field, value }));

      if (error) {
        set(fieldErrors, path, Object.assign(error, { ref: field && field.ref }));
      }
    }

    return Object.keys(fieldErrors).length
      ? { values, errors: fieldErrors }
      : { values, errors: {} };
  };

export const combineResolvers = <TFieldValues extends FieldValues = FieldValues>(
  resolvers: Resolver<TFieldValues>[],
): Resolver<TFieldValues> => {
  return async (values, context, options) => {
    const combinedErrors = {} as FieldErrors<TFieldValues>;

    for (const resolver of resolvers) {
      const result: ResolverResult = await resolver(values, context, options);
      const names = generateAllNames(result.errors);
      names.forEach((name) => {
        const error = get(result.errors, name);
        if (error?.type) {
          set(combinedErrors, name, { message: error.message, type: error.type, ref: error.ref });
        }
      });
    }

    return Object.keys(combinedErrors).length
      ? { values: {}, errors: combinedErrors }
      : { values, errors: {} };
  };
};
