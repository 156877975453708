import React from 'react';

import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { ReportType } from '../../ReportType';

export interface TypeSelectProps {
  availableTypes: {
    name: ReportType;
    canAccess: boolean;
  }[];
  selectedType: ReportType | null;
  onTypeSelect: (type: ReportType | null) => void;
}

const notSelectedValue = 'notSelectedValue';

export function TypeSelect(props: TypeSelectProps) {
  const { t } = useTranslation('report');

  return (
    <div>
      <Label
        id={'select-type'}
        onChange={MuiHandlers.Select.onChange<ReportType | typeof notSelectedValue>(
          (selectedValue) => {
            props.onTypeSelect(selectedValue === notSelectedValue ? null : selectedValue);
          },
        )}
        value={props.selectedType ?? notSelectedValue}
        label={t('labels.type')}
      >
        <MenuItem value={notSelectedValue} key={notSelectedValue}>
          {'---'}
        </MenuItem>
        {props.availableTypes.map(
          (report) =>
            report.canAccess && (
              <MenuItem value={report.name} key={report.name}>
                {t(`types.${report.name}`)}
              </MenuItem>
            ),
        )}
      </Label>
    </div>
  );
}

const Label = styled(LabeledSelect)`
  width: 300px;
`;
