import { all, call, put, takeLatest } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { actions } from '../slice';
import OrderCreateActionTypes from '../model/OrderCreateActionTypes';
import { NewOrder, OrderApiSaga } from 'api/OrderApi';
import { addErrorToast, addSuccessToast } from 'services/toast';
import { translate } from 'app/translations/useTranslation';

const mapOrderCreateDataToApiFormat = (data: OrderCreateActionTypes.OrderCreate): NewOrder => {
  return {
    companyId: data.companyId,
    products: data.products.map((p) => ({
      id: p.productId,
      dimensions: p.dimensions,
      vehicle: p.vehicle,
      receiptNo: p.receiptNo,
      pesel: p.pesel,
      amountToPayOnPickUp: p.amountToPayOnPickUp
        ? parseFloat(p.amountToPayOnPickUp.replace(',', '.'))
        : undefined,
      medicines: p.medicines,
      externalNo: p.externalNo ?? undefined,
    })),
    clientName: data.client.name,
    clientStreet: data.client.street,
    clientCity: data.client.city,
    clientZipCode: data.client.zipCode,
    clientPhone: data.client.phone,
    notes: data.client.notes,
    pickupPoint: data.pickupPoint,
    externalId: data.externalId,
    pmiOrderCode: data.pmiOrderCode,
    pmiOrderCode2: data.pmiOrderCode2,
    swapType: data.swapType,
    scheduledAt: data.scheduledAt,
    ...(!!data.packageSize && { packageSize: data.packageSize }),
    ...(!!data.scheduledAt && { scheduled: true }),
  };
};

function* orderCreate(action: PA<OrderCreateActionTypes.OrderCreate>) {
  try {
    yield* call(OrderApiSaga.orderCreate, mapOrderCreateDataToApiFormat(action.payload));
    yield put(actions.orderCreateSuccess());
    yield call(addSuccessToast, {
      id: 'order_create_failure',
      message: translate('order', `orderCreate.success`),
    });
  } catch (error) {
    const code = error?.response?.errorCode || 'unknown';
    yield call(addErrorToast, {
      id: 'order_create_failure',
      message: translate(
        'order',
        `orderCreate.errors.${code}`,
        translate('order', 'orderCreate.errors.unknown'),
      ),
    });
    yield put(actions.orderCreateFailure({ code: error?.response?.data?.error || 'unknown' }));
  }
}

export function* saga() {
  yield all([takeLatest(actions.orderCreate, orderCreate)]);
}
