import { selectState } from '../slice';
import { createSelector } from '@reduxjs/toolkit';
import { InProgressState } from 'utlis/State';
import { Location } from 'model/Location';

export const selectOrderCreateState = createSelector(selectState, (state) => state.orderCreate);

export const selectOrderCreateStatus = createSelector(
  selectOrderCreateState,
  (state): InProgressState => state.status,
);

export const selectOrderCreateSelectedPickupPoint = createSelector(
  selectOrderCreateState,
  (state): Location | undefined => state.selectedPickupPoint,
);
export const selectOrderCreateSelectedClient = createSelector(
  selectOrderCreateState,
  (state): Location | undefined => state.selectedClient,
);
