import React, { ReactElement } from 'react';
import { Loader } from 'assets/Loader/Loader';
import { styled } from '@mui/material/styles';
import MUIButton from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

interface Props extends React.ComponentProps<typeof LoadingButton> {
  isDisabled?: boolean;
  isInProgress?: boolean;
  loading?: boolean;
  className?: string;
  dataCy?: string;
  href?: string;
}

export function Button({ disabled, loading, dataCy, children, ...rest }: Props) {
  return (
    <MUIButton
      variant="contained"
      disabled={disabled || loading}
      // className={className}
      data-cy={dataCy}
      {...rest}
    >
      {!!loading ? (
        <LoaderBox>
          <StyledLoader />
        </LoaderBox>
      ) : (
        children
      )}
    </MUIButton>
    // </StyledButton>
  );
}

/**
 * @deprecated Use @mui/material/Button directly
 */
export function PrimaryButton(props: React.ComponentProps<typeof Button>) {
  return <LoadingButton color="primary" {...props} />;
}

/**
 * @deprecated Use @mui/material/Button directly
 */
export function SecondaryButton(props: React.ComponentProps<typeof Button>) {
  return <LoadingButton color="secondary" {...props} />;
}

const StyledLoader = styled(Loader)`
  svg {
    stroke: ${(props) => props.theme.colors.primary.mainLight};
  }
`;

const LoaderBox = styled('div')``;

// const StyledButton = styled('button')`
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   padding: 0 25px;
//   font-weight: 500;
//   font-size: 15px;
//   line-height: 18px;
//   height: 40px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
//
// export const PrimaryButton = styled(Button)(({theme}) => ({
//   fontWeight: 'bold',
//   color: theme.palette.primary.contrastText,
//   ${(props) =>
//     props.isDisabled
//       ? `
//     background: ${props.theme.colors.primary.mainDisabled};
//     cursor: default;
//   `
//       : `
//     background: ${props.theme.colors.primary.main};
//   `};
// `}));
//
// export const SecondaryButton = styled(Button)`
//   background: ${(props) => props.theme.colors.primary.background};
//   ${(props) =>
//     props.isDisabled
//       ? `
//     border: solid 1px ${props.theme.colors.primary.mainDisabled};
//     color: ${props.theme.colors.primary.mainDisabled};
//     cursor: default;
//   `
//       : `
//     border: solid 1px ${props.theme.colors.primary.main};
//     color: ${props.theme.colors.primary.main};
//   `};
//   font-weight: normal;
// `;
