import { combineReducers, AnyAction } from '@reduxjs/toolkit';
import { RootReducerActions } from './RootReducerActions';
import { States } from './States';

function getReducerConfigs() {
  return States.reduce(
    (reducerConfig, state) => {
      return ({ ...reducerConfig, [state.name]: state.reducer });
    },
    {},
  );
}

export function createRootReducer(): any {
  const reducers = combineReducers(getReducerConfigs());

  return (state: any, action: AnyAction) => {
    if (action.type === RootReducerActions.resetState.toString()) {
      return reducers(undefined, action);
    }
    return reducers(state, action);
  };
}
