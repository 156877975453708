import { endOfToday, startOfToday, startOfMonth, endOfMonth, sub } from 'date-fns';

export class DateRange {
  private readonly startDate: Date;
  private readonly endDate: Date;

  public constructor(dateA: Date, dateB: Date) {
    if (dateA.getTime() > dateB.getTime()) {
      this.startDate = dateB;
      this.endDate = dateA;
    } else {
      this.startDate = dateA;
      this.endDate = dateB;
    }
  }

  public getStartDate(): Date {
    return this.startDate;
  }

  public getEndDate(): Date {
    return this.endDate;
  }

  public toArray(): [Date, Date] {
    return [this.startDate, this.endDate];
  }

  public static CreateTodayRange() {
    return new DateRange(startOfToday(), endOfToday());
  }

  public static CreateThisMonthRange() {
    return new DateRange(startOfMonth(new Date()), endOfMonth(new Date()));
  }

  public static CreateLastMonthRange() {
    const lastMonthDay = sub(new Date(), { months: 1 });
    return new DateRange(startOfMonth(lastMonthDay), endOfMonth(lastMonthDay));
  }
}
