import { styled } from '@mui/material/styles';
import MuiStack from '@mui/material/Stack';

export const Box = styled('div')`
  display: grid;
  grid-template-columns: 350px;
  grid-template-areas: 
      "summary"
      "technicalData"
      "actions"
      "stock"
      ;
  font-size: 12px;
  padding: 24px;
  border-left: solid 2px ${(props) => props.theme.colors.primary.main};
  @media (min-width: 740px) {
    grid-template-columns: 235px 235px;
    grid-template-areas: 
      "summary technicalData"
      "actions actions"
      "stock stock"
      ;
  }
  @media (min-width: 975px) {
    grid-template-columns: auto auto auto;
    grid-template-areas: 
      grid-template-areas: 
      "summary technicalData"
      "actions actions"
      "stock stock"
      ;
  }
  @media (min-width: 1425px) {
    grid-template-columns: 235px 235px auto 235px;
    grid-template-areas: 
      "summary technicalData stock actions"
      ;
  }
  @media (min-width: 1660px) {
    grid-template-columns: 235px 235px 235px auto 235px;
    grid-template-areas: 
      "summary technicalData stock actions";
  }
`;

export const Header = styled('p')`
  font-size: 14px;
  padding-bottom: 15px;
`;

export const Section = styled('div')`
  margin-right: 15px;
`;

export const TechnicalDataSection = styled(Section)`
  grid-area: technicalData;
`;

export const StockSection = styled(Section)`
  grid-area: stock;
`;

export const AddressSection = styled(Section)`
  grid-area: addresses;
`;

export const SummarySection = styled(Section)`
  grid-area: summary;
  @media (min-width: 975px) {
    border-right: 1px solid ${(props) => props.theme.colors.primary.border};
  }
`;

export const ActionSection = styled(Section)`
  grid-area: actions;
  text-align: center;
`;

export const SubSectionRecordValue = styled('span')`
  font-weight: lighter;
`;

export const Badge = styled('span')`
  padding: 8px 16px;
  text-align: center;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.primary.border};
  color: white;
  margin: 4px;
`;

export const Stack = styled(MuiStack)`
  flex-wrap: wrap;
`;

export const StockTableBox = styled('div')`
  width: calc(100% / 2);
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px ${(props) => props.theme.colors.primary.border};
  border-bottom: solid 1px ${(props) => props.theme.colors.primary.border};
`;
export const StockTableRow = styled('div')`
  display: flex;
  flex-flow: row wrap;
  border-left: solid 1px ${(props) => props.theme.colors.primary.border};
  transition: 0.5s;
  &:first-of-type {
    border-top: solid 1px ${(props) => props.theme.colors.primary.border};
    border-left: solid 1px ${(props) => props.theme.colors.primary.border};
  }
  &:first-of-type ${StockTableBox} {
    background: ${(props) => props.theme.colors.primary.main};
    color: white;
    border-color: ${(props) => props.theme.colors.primary.border};
  }
`;
export const StockTableContainer = styled('div')`
  display: block;
  width: 90%;
`;

export const RecordBox = styled('div')`
  padding-bottom: 10px;
`;

export const RecordLabel = styled('span')`
  font-weight: normal;
  padding-right: 3px;
`;

export const RecordValue = styled('span')`
  font-weight: lighter;
`;

export const RecordBlockValue = styled('p')`
  font-weight: lighter;
`;

export const CommentValue = styled(RecordBlockValue)`
  font-style: italic;
`;

export const Subsection = styled('div')`
  padding-bottom: 7px;
  &:not(:first-of-type) {
    padding-top: 7px;
  }
  ${Header} {
    font-size: 13px;
    padding-bottom: 6px;
  }
  ${RecordBox} {
    padding-bottom: 4px;
  }
`;
