import { WarehouseApiSaga } from 'api/WarehouseApi';
import { takeLatest, takeLeading, call } from 'redux-saga/effects';
import { put, select } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { ApiWarehouseMapper } from './ApiWarehouseMapper';
import * as Slice from './slice';
import { addErrorToast, addSuccessToast } from '../../../services/toast';
import { translate } from '../../../app/translations/useTranslation';

function* fetchWarehouseList() {
  try {
    const filters = yield* select(Slice.selectors.makeSelectWarehouseListFilters());
    const response = yield* WarehouseApiSaga.listWarehouses({
      companyId: filters.companyId || undefined,
    });
    yield put(
      Slice.actions.fetchWarehouseListSuccess({
        warehouses: (response || []).map((warehouse) =>
          ApiWarehouseMapper.mapToWarehouse(warehouse),
        ),
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.fetchWarehouseListError());
  }
}

function* triggerFetchWarehouseList() {
  yield put(Slice.actions.fetchWarehouseList());
}

function* warehouseAddStock({ payload }: PA<Slice.ActionTypes.WarehouseAddStock>) {
  const createDataArr = ApiWarehouseMapper.mapWarehouseAddStockToWarehouseAddStockApi(payload.data);
  for (const stockAdd of createDataArr) {
    try {
      yield* WarehouseApiSaga.warehouseAddStock(stockAdd);
      yield call(addSuccessToast, {
        message: translate('warehouse', 'notifications.warehouseStockAdded'),
      });
    } catch (e) {
      yield call(addErrorToast, {
        message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
      });
      console.error(e);
    }
  }
  yield put(Slice.actions.fetchWarehouseList());
  yield put(Slice.actions.warehouseAddStockFinish());
}

export function* saga() {
  yield takeLatest(Slice.actions.fetchWarehouseList, fetchWarehouseList);
  yield takeLeading(Slice.actions.fetchWarehouseListInBackground, fetchWarehouseList);
  yield takeLatest(Slice.actions.setFilters, triggerFetchWarehouseList);
  yield takeLeading(Slice.actions.warehouseAddStock, warehouseAddStock);
}
