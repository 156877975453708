import React from 'react';

import { Section } from 'components/Section';
import { Filters } from './components/Filters/Filters';
import { StockList } from './components/StockList/StockList';
import { useGivingDriverStockList } from './state/useGivingDriverStockList';
import { useReceivingDriverStockList } from './state/useReceivingDriverStockList';
import * as Styled from './styled';
import Page from '../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

interface Props {}

export function StockPage(props: Props) {
  const { t } = useTranslation('stock');

  const {
    stockListing: givingDriverStockListing,
    reloadStockListing: reloadGivingDriverStockListing,
  } = useGivingDriverStockList();
  const {
    stockListing: receivingDriverStockListing,
    reloadStockListing: reloadReceivingDriverStockListing,
  } = useReceivingDriverStockList();

  return (
    <Page>
      <Page.Header>{t('list.title')}</Page.Header>
      <Card>
        <Filters />
        {/*<Divider />*/}
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <StockList
              stock={givingDriverStockListing}
              reload={reloadGivingDriverStockListing}
              showTransferStock={true}
              label={t('labels.givingDriver')}
            />
          </Grid>
          <Grid item xs={12} sm>
            <StockList
              stock={receivingDriverStockListing}
              reload={reloadReceivingDriverStockListing}
              showPendingStockList={true}
              label={t('labels.receivingDriver')}
            />
          </Grid>
        </Grid>
      </Card>
    </Page>
  );
}
