import React from 'react';

// import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';

// Props type
type Props = {
  value: boolean;
  label: string;
  tooltip?: string;
};

// Component
const Boolean = ({ value, label, tooltip }: Props) => {
  return tooltip ? (
    <Tooltip title={tooltip}>
      <FormControlLabel control={<Checkbox checked={value} />} label={label} />
    </Tooltip>
  ) : (
    <FormControlLabel control={<Checkbox checked={value} />} label={label} />
  );
};

export default Boolean;
