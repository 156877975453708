import React, { useCallback } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import { Multiplier } from '../../../model/Multiplier';

import { format } from 'date-fns';
import * as Styled from '../../../../Orders/OrderPage/components/OrderList/components/Row/components/OrderRowDetails/styled';
import { useCompanies } from '../../../../../services/companies/state/useCompanies';
import { useDeactivateMultiplier } from '../../../state/useDeactivateMultiplier';
import { AsyncStatus } from '../../../../../utlis/State';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMultipliers } from '../../../state/useMultipliers';

function formatDate(timestamp?: number): string {
  if (timestamp === undefined) {
    return '---';
  }
  return format(timestamp, 'HH:mm dd.MM.RR');
}

interface Props {
  multiplier: Multiplier | null;
  onClose: VoidFunction;
}

export function ShowMultiplierDetailsModal({ multiplier, onClose }: Props) {
  const { t } = useTranslation('multiplierCalendar');
  const { deactivateMultiplier, status } = useDeactivateMultiplier();
  const { reloadMultiplierList } = useMultipliers();
  const { companies } = useCompanies();
  const mappedCompanies = multiplier?.companyIds?.map((companyId) => {
    return companies.find((company) => company.id === companyId)?.name ?? companyId;
  });

  const handleDeactivateMultiplier = useCallback(() => {
    if (multiplier) {
      deactivateMultiplier({
        multiplierId: multiplier.id,
      });
      onClose();
      reloadMultiplierList(new Date(multiplier.dateFrom));
    }
  }, [multiplier]);
  return (
    <>
      <Dialog open={!!multiplier} onClose={onClose} scroll="paper" maxWidth="lg">
        <DialogContent>
          <Box>
            <DialogTitle id="alert-dialog-title">
              {t('multiplierDetails.header', {
                dateFrom: formatDate(multiplier?.dateFrom),
                dateTo: formatDate(multiplier?.dateTo),
              })}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {multiplier?.deactivatedAt && (
                  <Deactivated>{t('multiplierDetails.deactivated')}</Deactivated>
                )}
                <Styled.RecordBox>
                  <Styled.RecordLabel>{t('multiplierDetails.multiplier')}:</Styled.RecordLabel>
                  <Styled.RecordValue>x{multiplier?.multiplier}</Styled.RecordValue>
                </Styled.RecordBox>
                <Styled.RecordBox>
                  <Styled.RecordLabel>{t('multiplierDetails.description')}:</Styled.RecordLabel>
                  <Styled.RecordValue>{multiplier?.description ?? '-'}</Styled.RecordValue>
                </Styled.RecordBox>
                <Styled.RecordBox>
                  <Styled.RecordLabel>{t('multiplierDetails.regions')}:</Styled.RecordLabel>
                  <Styled.RecordValue>{multiplier?.regions?.join(', ')}</Styled.RecordValue>
                </Styled.RecordBox>
                <Styled.RecordBox>
                  <Styled.RecordLabel>{t('multiplierDetails.companies')}:</Styled.RecordLabel>
                  <Styled.RecordValue>{mappedCompanies?.join(', ')}</Styled.RecordValue>
                </Styled.RecordBox>
                <Styled.RecordBox>
                  <Styled.RecordValue>
                    {t('multiplierDetails.createdBy', {
                      email: multiplier?.createdBy?.email || '',
                      createdAt: formatDate(multiplier?.createdAt),
                    })}
                  </Styled.RecordValue>
                </Styled.RecordBox>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {multiplier && !multiplier?.deactivatedAt && (
                <LoadingButton
                  type="submit"
                  onClick={() => handleDeactivateMultiplier()}
                  variant="contained"
                  color="secondary"
                  disabled={status === AsyncStatus.Pending}
                  loading={status === AsyncStatus.Pending}
                >
                  {t('deactivateMultiplier.submitButton')}
                </LoadingButton>
              )}
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
  background: ${theme.colors.primary.background};
`,
);

const Deactivated = styled('p')(
  ({ theme }) => `
    color: ${theme.palette.secondary.main}
  `,
);
