import { PayloadAction } from '@reduxjs/toolkit';
import { LocationActionTypes } from '../model/LocationActionTypes';
import { State } from '../model/State';

export const fetchLocations = (
  state: State,
  action: PayloadAction<LocationActionTypes.fetchLocationsList>,
) => {};

export const fetchLocationsSuccess = (
  state: State,
  { payload }: PayloadAction<LocationActionTypes.fetchLocationsListSuccess>,
) => {
  state.locations[payload.companyId] = payload.locations?.locations;
};

export const getLocationsFailure = (
  state: State,
  action: PayloadAction<LocationActionTypes.fetchLocationsListFailure>,
) => {};
