// Core libraries
import React, { useContext } from 'react';

// Context
const TableFilterContext = React.createContext<React.ReactNode | undefined>(undefined);

// Props type
type TableFilterProviderProps = { children: React.ReactNode; filters: React.ReactNode };

// Component
const TableFiltersProvider = ({ filters, children }: TableFilterProviderProps) => {
  return <TableFilterContext.Provider value={filters} children={children} />;
};

export const useTableFilter = () => useContext(TableFilterContext);

export default TableFiltersProvider;
