import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '../selectors';
import { actions } from '../slice';
import { useCallback } from 'react';
import CheckDeliveryAvailabilityActionTypes from '../model/CheckDeliveryAvailabilityActionTypes';

const useCheckDeliveryAvailability = () => {
  const dispatch = useDispatch();
  const inProgressState = useSelector(selectors.selectCheckDeliveryAvailabilityStatus);

  const lastQueryParams = useSelector(selectors.selectCheckDeliveryAvailabilityLastQueryParams);

  const checkDeliveryAvailability = useCallback(
    (data: CheckDeliveryAvailabilityActionTypes.CheckDeliveryAvailability) => {
      dispatch(actions.checkDeliveryAvailability(data));
    },
    [dispatch],
  );

  const clear = useCallback(() => {
    dispatch(actions.checkDeliveryAvailabilityClear());
  }, [dispatch]);

  const deliveryAvailability = useSelector(selectors.selectCheckDeliveryAvailabilityResponse);

  return {
    checkDeliveryAvailability,
    deliveryAvailability,
    lastQueryParams,
    clear,
    inProgressState,
  };
};

export default useCheckDeliveryAvailability;
