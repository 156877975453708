import { PayloadAction } from '@reduxjs/toolkit';

export type PA<T> = PayloadAction<T>;

export enum AsyncStatus {
  NotStarted = 'not_started',
  Pending = 'pending',
  Error = 'error',
  Success = 'success',
}

export interface ActionFailureError {
  code: string;
  message?: string;
}

export interface InProgressState {
  inProgress: boolean;
  success?: boolean;
  error?: ActionFailureError;
}
