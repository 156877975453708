import React from 'react';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Input } from 'components/ReactHookForm/Input/Input';

// Props type
type Props = {
  fieldGroupName: string;
  index: number;
  onDelete?: () => void;
};

// Component
const Medicine: React.FC<Props> = ({ fieldGroupName, index, onDelete }) => {
  const { t } = useTranslation('order');

  return (
    <Root>
      <FieldBox>
        <Input
          name={`${fieldGroupName}.details`}
          label={`${index + 1}. ${t('labels.medicines.details')}`}
          size="small"
          fullWidth
          multiline
          rules={{ required: t('validationErrors:required') as string }}
          shouldUnregister
        />
      </FieldBox>
      {onDelete && (
        <Actions>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Actions>
      )}
    </Root>
  );
};

const Root = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));

const FieldBox = styled(MUIBox)({
  flexGrow: 1,
});

const Actions = styled(MUIBox)(({ theme }) => ({
  margin: `${theme.spacing(0.5)} 0 ${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
}));

export default Medicine;
