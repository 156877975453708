import React, { useEffect, useState } from 'react';

import { FormProvider } from 'react-hook-form';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'app/translations/useTranslation';
import { PrimaryButton } from 'components/Button/Buttons';
import { Input } from 'components/ReactHookForm/Input/Input';
import { useCreateMultiplier } from 'pages/MultiplierPage/state/useCreateMultiplier';
import { Select } from 'components/ReactHookForm/Select/Select';
import {
  useCreateMultiplierForm,
  CreateMultiplierFormValues,
} from './hooks/useCreateMultiplierForm';
import { DateTimeSelect } from 'components/DateTimeSelect';
import { Grid } from '@mui/material';
import { useRegions } from 'services/regions/state/useRegions';
import { useCompanies } from 'services/companies/state/useCompanies';

interface Props {}

export function CreateMultiplier(props: Props) {
  const { t } = useTranslation('multiplierCalendar');
  const { regions } = useRegions();
  const { companies } = useCompanies();
  const { createMultiplier, isInProgress } = useCreateMultiplier();
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const form = useCreateMultiplierForm();

  useEffect(() => {
    if (!isInProgress) {
      form.reset();
      setDateFrom(null);
      setDateTo(null);
    }
  }, [isInProgress]);
  const dateFromState = form.getFieldState('dateFrom', form.formState);
  const dateToState = form.getFieldState('dateTo', form.formState);

  const onChangeDateFrom = (newDate: Date | null) => {
    if (newDate) {
      form.setValue('dateFrom', newDate);
      setDateFrom(newDate);
    }
  };

  const onChangeDateTo = (newDate: Date | null) => {
    if (newDate) {
      form.setValue('dateTo', newDate);
      setDateTo(newDate);
    }
  };

  return (
    <div>
      <FormProvider {...form}>
        <Form
          onSubmit={form.handleSubmit((formValues: CreateMultiplierFormValues) => {
            createMultiplier({
              dateFrom: formValues.dateFrom,
              dateTo: formValues.dateTo,
              regions: formValues.regions,
              companyIds: formValues.companyIds,
              description: formValues.description,
              multiplier: formValues.multiplier,
            });
            // multiplier.reloadMultiplierList(new Date());
          })}
        >
          <Grid container spacing={1}>
            <Grid item xs>
              <DateTimeSelect
                label={t('createMultiplier.form.fields.dateFrom')}
                onChange={onChangeDateFrom}
                name={'dateFrom'}
                fieldState={dateFromState}
                value={dateFrom}
              />
            </Grid>
            <Grid item xs>
              <DateTimeSelect
                label={t('createMultiplier.form.fields.dateTo')}
                onChange={onChangeDateTo}
                name={'dateTo'}
                fieldState={dateToState}
                value={dateTo}
              />
            </Grid>
          </Grid>

          <Input
            name={'description'}
            multiline
            label={t('createMultiplier.form.fields.description')}
            type={'text'}
          />
          <Input
            name={'multiplier'}
            label={t('createMultiplier.form.fields.multiplier')}
            type={'number'}
            defaultValue={'1'}
            step={'0.1'}
          />
          <Select
            name={'regions'}
            label={t('createMultiplier.form.fields.regions')}
            values={regions?.map((region) => ({ value: region.name }))}
            defaultValue={[]}
            type={'chip'}
            multiple
          />
          <Select
            name={'companyIds'}
            label={t('createMultiplier.form.fields.companies')}
            values={companies?.map((company) => ({ value: company.id, display: company.name }))}
            defaultValue={[]}
            multiple
          />
          <SaveButton isInProgress={isInProgress} type="submit">
            {t('createMultiplier.form.submitButton')}
          </SaveButton>
        </Form>
      </FormProvider>
    </div>
  );
}

const SaveButton = styled(PrimaryButton)`
  margin-top: 15px;
`;

const Form = styled('form')`
  max-width: 500px;
`;
