import { ReactComponent as LoaderAsset } from './loader.svg';
import { styled } from '@mui/material/styles';

interface Props {
  className?: string;
}

export function Loader(props: Props) {
  return (
    <Box className={props.className}>
      <LoaderAsset />
    </Box>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  svg {
    height: 100%;
    width: 95%;
    stroke: ${theme.palette.primary.main};
  }
`,
);
