import { ApiRequestSaga } from './Request/ApiRequestSaga';
import { WalletEntryType } from '../pages/WalletPage/model/Wallet';

interface WalletResponse {
  wallet: WalletEntry[];
}

interface WalletParams {
  dateFrom: Date;
  dateTo: Date;
  driverId?: string;
}

export interface CreateWalletEntryParams {
  driverId: string;
  payoutDriver: number;
  payoutClient?: number;
  type: WalletEntryType;
  currency: string;
  comment?: string;
}
export interface DeleteWalletEntryParams {
  walletEntryId: string;
  reason: string;
  comment?: string;
}

export interface WalletEntry {
  id: string;
  externalId?: string;
  no: string;
  driverId: string;
  type: WalletEntryType;
  payoutDriver: number;
  payoutClient: number;

  currency: string;

  description?: string;

  createdAt: Date;
  createdBy: string;
  createdByEmail: string;
  creationComment?: string;

  deletedAt?: Date;
  deletedBy?: string;
  deletedByEmail?: string;
  deletionReason?: string;
  deletionComment?: string;
}

export const WalletApiSaga = {
  getWallet: (params: WalletParams) => {
    return ApiRequestSaga.Get<WalletResponse>({
      endpoint: '/wallet/list',
      query: {
        dateFrom: params.dateFrom.toISOString(),
        dateTo: params.dateTo.toISOString(),
        ...(params.driverId ? { driverId: params.driverId } : {}),
      },
    });
  },
  createWalletEntry(params: CreateWalletEntryParams) {
    return ApiRequestSaga.Post({
      endpoint: '/wallet/create',
      data: {
        payoutDriver: params.payoutDriver,
        ...(params.payoutClient && { payoutClient: params.payoutClient }),
        currency: params.currency,
        ...(params.comment && { comment: params.comment }),
        type: params.type,
        driverId: params.driverId,
      },
    });
  },
  deleteWalletEntry(params: DeleteWalletEntryParams) {
    return ApiRequestSaga.Post({
      endpoint: '/wallet/delete',
      data: {
        walletEntryId: params.walletEntryId,
        reason: params.reason,
        comment: params.comment,
      },
    });
  },
};
