import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useActivateCompany() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectActivateCompanyStatus());

  return {
    activateCompany: (params: Slice.ActionTypes.ActivateCompany) => {
      dispatch(Slice.actions.activateCompany(params));
    },
    isInProgress: status === AsyncStatus.Pending,
  };
}
