import { State } from '../model/State';
import { ActionFailureError, PA } from 'utlis/State';
import OrderCreateActionTypes from '../model/OrderCreateActionTypes';

export const checkDeliveryAvailability = (
  state: State,
  action: PA<OrderCreateActionTypes.CheckDeliveryAvailability>,
) => {
  state.checkDeliveryAvailability.status = { inProgress: true };
};

export const checkDeliveryAvailabilityClear = (state: State) => {
  state.checkDeliveryAvailability.deliveryAvailability = undefined;
  state.checkDeliveryAvailability.lastQueryParams = undefined;
};

export const checkDeliveryAvailabilitySuccess = (
  state: State,
  { payload }: PA<OrderCreateActionTypes.CheckDeliveryAvailabilitySuccess>,
) => {
  state.checkDeliveryAvailability.status = { inProgress: false, success: true };
  state.checkDeliveryAvailability.deliveryAvailability = payload.deliveryAvailability;
  state.checkDeliveryAvailability.lastQueryParams = payload.lastQueryParams;
};

export const checkDeliveryAvailabilityFailure = (state: State, action: PA<ActionFailureError>) => {
  state.checkDeliveryAvailability.status = {
    inProgress: false,
    success: false,
    error: action.payload,
  };
};
