import React from 'react';
import { useTranslation } from 'app/translations/useTranslation';
import { SecondaryButton } from 'components/Button/Buttons';
import { useState } from 'react';
import { DeleteWalletEntryModal } from './DeleteWalletEntryModal';

interface Props {
  walletEntryId: string;
}

export function ShowDeleteWalletEntryModal(props: Props) {
  const { t } = useTranslation('wallet');

  const [isModalOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <SecondaryButton onClick={() => setModalIsOpen(true)}>
        {t('actions.deleteWalletEntry')}
      </SecondaryButton>
      <DeleteWalletEntryModal
        isOpen={isModalOpen}
        onClose={() => setModalIsOpen(false)}
        walletEntryId={props.walletEntryId}
      />
    </>
  );
}
