import React from 'react';
import { styled } from '@mui/material/styles';
import { Loader } from 'assets/Loader/Loader';

interface Props {}

export function LoadingScreen(props: Props) {
  return (
    <LoaderBox>
      <Loader />
    </LoaderBox>
  );
}

const LoaderBox = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    height: 120px;
    width: 120px;
  }
`;
