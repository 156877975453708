import { styled } from '@mui/material/styles';

export type AlignTo = 'left' | 'right';
export const CalendarBox = styled('div')<{ alignTo: AlignTo }>(
  ({ alignTo, theme }) => `
  position: absolute;
  ${
    alignTo === 'left'
      ? `
    left: 0;
  `
      : `
    right: 0;
  `
  };
  margin-top: ${theme.spacing(0.5)};
  z-index: 2;
`,
);

export const Box = styled('div')`
  position: relative;
  z-index: 101;
`;

export const DateRange = styled('span')(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
`,
);

export const Label = styled('span')(
  ({ theme }) => `
  padding-right: ${theme.spacing(0.5)};
`,
);
