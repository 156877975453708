import { translate } from 'app/translations/useTranslation';
import { takeLeading } from 'redux-saga/effects';
import { addSuccessToast, addErrorToast } from 'services/toast';
import { call, put, select } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { CorrectionApiSaga } from 'api/CorrectionApi';
import * as Slice from './slice';
import { ApiCorrectionMapper } from './ApiCorrectionMapper';

function* acceptCorrection({ payload }: PA<Slice.ActionTypes.AcceptCorrection>) {
  try {
    yield* call(CorrectionApiSaga.acceptCorrection, {
      correctionId: payload.correctionId,
      amount: payload.amount,
      comment: payload.comment,
    });

    yield call(addSuccessToast, {
      message: translate('correction', 'notifications.correctionAccepted'),
    });
    yield fetchPendingCorrectionsList();
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.acceptCorrectionFinish());
}

function* fetchPendingCorrectionsList() {
  try {
    const response = yield* CorrectionApiSaga.listPendingCorrections({});
    yield put(
      Slice.actions.fetchPendingCorrectionsListSuccess({
        corrections: response.corrections.map((pendingCorrection) =>
          ApiCorrectionMapper.mapToPendingCorrection(pendingCorrection),
        ),
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.fetchPendingCorrectionsListError());
  }
}

function* getCorrectionInfo({ payload }: PA<Slice.ActionTypes.GetCorrectionInfo>) {
  try {
    const response = yield* CorrectionApiSaga.getCorrectionInfo(payload.correctionId);
    yield put(
      Slice.actions.getCorrectionInfoSuccess({
        correction: ApiCorrectionMapper.mapToCorrection(response),
      }),
    );
    // return ApiCorrectionMapper.mapToCorrection(response);
  } catch (e) {
    console.error(e);
    yield put(Slice.actions.getCorrectionInfoError());
  }
}

function* rejectCorrection({ payload }: PA<Slice.ActionTypes.RejectCorrection>) {
  try {
    yield* call(CorrectionApiSaga.rejectCorrection, {
      correctionId: payload.correctionId,
      rejectionReason: payload.rejectionReason,
      comment: payload.comment,
    });

    yield call(addSuccessToast, {
      message: translate('correction', 'notifications.correctionRejected'),
    });
    yield fetchPendingCorrectionsList();
  } catch (e) {
    yield call(addErrorToast, {
      message: translate('common', 'prompts.sthWentWrongTryAgainLater'),
    });
    console.error(e);
  }
  yield put(Slice.actions.rejectCorrectionFinish());
}

export function* saga() {
  yield takeLeading(Slice.actions.acceptCorrection, acceptCorrection);
  yield takeLeading(Slice.actions.fetchPendingCorrectionsList, fetchPendingCorrectionsList);
  yield takeLeading(Slice.actions.rejectCorrection, rejectCorrection);
  yield takeLeading(Slice.actions.getCorrectionInfo, getCorrectionInfo);
}
