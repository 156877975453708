import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Input } from 'components/ReactHookForm/Input/Input';
import { PaymentType } from 'model/Company';

// Props type
type Props = {
  fieldGroupName: string;
};

// Component
const EprufCode: React.FC<Props> & { type: PaymentType } = ({ fieldGroupName }) => {
  const { t } = useTranslation('payment');
  const { register, unregister, setValue } = useFormContext();

  useEffect(() => {
    const fieldName = `${fieldGroupName}.paymentType`;
    register(fieldName);
    setValue(fieldName, EprufCode.type);

    return () => unregister(fieldName);
  }, [fieldGroupName]);

  const rules = useMemo(
    () => ({
      required: t('validationErrors:required') as string,
      maxLength: { value: 32, message: t('validationErrors:maxSize', { count: 32 }) },
    }),
    [t],
  );

  return (
    <Input
      name={`${fieldGroupName}.paymentCode`}
      size="small"
      shouldUnregister
      label={t('methods.epruf_code.code')}
      rules={rules}
    />
  );
};
EprufCode.type = 'epruf_code';

export default EprufCode;
