import { format } from 'date-fns';
import { useTranslation } from 'app/translations/useTranslation';
import { OrderComment } from 'pages/Orders/model/Order';
import * as Styled from './../styled';
interface Props {
  commentItem: OrderComment;
}

function formatDate(timestamp?: number): string {
  if (timestamp === undefined) {
    return '---';
  }
  return format(timestamp, 'HH:mm dd.MM.RR');
}

export const OrderCommentItem = (props: Props) => {
  const { commentItem } = props;
  const { t } = useTranslation('order');

  return (
    <Styled.Box>
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.createdAt')}:</Styled.RecordLabel>
        <Styled.RecordValue>
          {commentItem.createdAt ? formatDate(commentItem.createdAt) : '-'}
        </Styled.RecordValue>
      </Styled.RecordBox>
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.comment')}:</Styled.RecordLabel>
        <Styled.RecordValue>{commentItem.comment ?? '-'}</Styled.RecordValue>
      </Styled.RecordBox>
    </Styled.Box>
  );
};
