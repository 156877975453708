import { styled } from '@mui/material/styles';
import { SecondaryButton } from 'components/Button/Buttons';
import { default as RCCalendar } from 'react-calendar';

export const Box = styled('div')(
  ({ theme }) => `
  background: ${theme.colors.primary.background};
  box-shadow: 0 ${theme.spacing(0.5)} ${theme.spacing(2)} rgba(35, 35, 35, 0.2);
  border-radius: ${theme.spacing(1)};
  overflow: hidden;
`,
);

export const ContentBox = styled('div')(
  ({ theme }) => `
  display: flex;
  border-bottom: 1px solid ${theme.colors.primary.borderLight};
`,
);

export const ActionsBox = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
  display: flex;
  justify-content: space-between;
`,
);

export const ActionButton = styled(SecondaryButton)(
  ({ theme }) => `
  padding: ${theme.spacing(0.75)} ${theme.spacing(3)};
`,
);

export const ApplyWarning = styled('span')(
  ({ theme }) => `
  color: ${theme.colors.primary.error};
  font-size: 13px;
  margin-right: ${theme.spacing(1.5)};
`,
);

export const Preset = styled('div')(
  ({ theme }) => `
  cursor: pointer;
  padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
  color: ${theme.colors.primary.main};
  font-weight: bolder;
  &:hover {
    opacity: 0.8;
  }
`,
);

export const PresetHeader = styled('div')(
  ({ theme }) => `
  font-weight: 500;
  padding: ${theme.spacing(1)};
`,
);

export const PresetsBox = styled('div')(
  ({ theme }) => `
  flex-shrink: 0;
  width: 150px;
  font-size: 14px;
  border-left: 1px solid ${theme.colors.primary.borderLight};
`,
);

export const Calendar = styled(RCCalendar)(
  ({ theme }) => `
  max-width: 100%;
  background: white;
  line-height: 1.125em;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  & button {
    margin: 0;
    border: 0;
    outline: none;
  }
  & button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
    border-bottom: 1px solid ${theme.colors.primary.borderLight};
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    color: black;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    /* background-color: #e6e6e6; */
  }
  .react-calendar__navigation button[disabled] {
    /* background-color: #f0f0f0; */
  }

  .react-calendar__navigation__label {
    cursor: auto;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
  }

  .react-calendar__viewContainer {
    margin: ${theme.spacing(2)};
    display: flex;
  }

  .react-calendar__month-view {
    width: 245px;
  }

  .react-calendar__month-view:first-child {
    margin-right: ${theme.spacing(2)};
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: capitalize;
    /* font-weight: bold; */
    font-size: 10px;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    text-decoration: none;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    color: #666666;
  }

  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    & > * {
      color: #959595;
    }
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    flex-basis: auto !important;
    height: 35px;
    width: 35px;
    max-width: 100%;
    text-align: center;
    /* padding: 0.75em 0.5em; */
    background: none;
    font-size: 12px;
    margin-bottom: ${theme.spacing(0.5)};
  }

  .react-calendar__tile {
    border-radius: 50%;
  }

  .react-calendar__tile--rangeBothEnds,
  .react-calendar__tile--rangeStart:nth-child(7n),
  .react-calendar__tile--rangeEnd:nth-child(7n + 1) {
    border-radius: 50% !important;
  }

  .react-calendar__tile--range:nth-child(7n):not(.react-calendar__tile--rangeStart),
  .react-calendar__tile--rangeEnd {
    border-radius: 0 50% 50% 0;
  }

  .react-calendar__tile--range:nth-child(7n
      + 1):not(.react-calendar__tile--rangeEnd),
  .react-calendar__tile--rangeStart {
    border-radius: 50% 0 0 50%;
  }

  .react-calendar__tile--range:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd):not(:nth-child(7n)):not(:nth-child(7n
        + 1)) {
    border-radius: 0;
  }

  .react-calendar__tile:disabled {
    background-color: #c7c7c7;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile--hasActive
   {
    background-color: ${theme.colors.primary.main};
    & > * {
      color: white;
      font-weight: bold;
    }
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: ${theme.colors.primary.main};
  }
  .react-calendar__tile--hasActive:enabled:hover {
    background: ${theme.colors.primary.main};
  }
  .react-calendar__tile--active {
    background: ${theme.colors.primary.mainLight};
  }
  .react-calendar__tile--active:enabled:hover {
    background: ${theme.colors.primary.main};
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
  }
`,
);
