export enum SocketEvent {
  AcceptOrder = 'acceptOrder',
  FinishOrder = 'finishOrder',
  CancelOrder = 'cancelOrder',
  RenewOrder = 'renewOrder',
  NewOrder = 'newOrder',
  EditOrder = 'editOrder',
  DelayingDelivery = 'delayingDelivery',
  DeliverOrder = 'deliverOrder',
  ArriveOrder = 'arriveOrder',
  NotAcceptedOrder = 'notAcceptedOrder',
  CancelledOrderClientContacted = 'cancelledOrderClientContacted',
  DriverLocationChange = 'driverLocationChange',
  DeactivatedOrderMultiplier = 'deactivatedOrderMultiplier',
}

export type SocketEventPayload = {
  [SocketEvent.AcceptOrder]: unknown;
  [SocketEvent.FinishOrder]: unknown;
  [SocketEvent.CancelOrder]: unknown;
  [SocketEvent.RenewOrder]: unknown;
  [SocketEvent.NewOrder]: unknown;
  [SocketEvent.EditOrder]: unknown;
  [SocketEvent.DelayingDelivery]: unknown;
  [SocketEvent.DeliverOrder]: unknown;
  [SocketEvent.ArriveOrder]: unknown;
  [SocketEvent.NotAcceptedOrder]: unknown;
  [SocketEvent.CancelledOrderClientContacted]: unknown;
  [SocketEvent.DriverLocationChange]: {
    orderId: string;
    lat: number;
    lon: number;
  };
  [SocketEvent.DeactivatedOrderMultiplier]: unknown;
};
