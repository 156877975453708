import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';
import { Multiplier } from '../model/Multiplier';

export interface MultiplierListing {
  list: Multiplier[];
  hasError: boolean;
  isLoading: boolean;
}

export function useMultipliers() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Slice.actions.fetchMultiplierList({ date: new Date() }));
  }, [dispatch]);

  const multipliers = useSelector(Slice.selectors.makeSelectMultiplierList());
  const loadingStatus = useSelector(Slice.selectors.makeSelectMultiplierListStatus());
  const multiplierListing: MultiplierListing = {
    list: multipliers,
    hasError: loadingStatus === AsyncStatus.Error,
    isLoading: [AsyncStatus.Pending, AsyncStatus.NotStarted].includes(loadingStatus),
  };

  return {
    multiplierListing,
    reloadMultiplierList: (date: Date) => {
      dispatch(Slice.actions.fetchMultiplierList({ date }));
    },
  };
}
