import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '../selectors';
import { actions } from '../slice';
import { useCallback } from 'react';
import OrderCreateActionTypes from '../model/OrderCreateActionTypes';

const useOrderCreate = () => {
  const dispatch = useDispatch();
  const inProgressState = useSelector(selectors.selectOrderCreateStatus);

  const orderCreate = useCallback(
    (data: OrderCreateActionTypes.OrderCreate) => {
      dispatch(actions.orderCreate(data));
    },
    [dispatch],
  );

  return {
    orderCreate,
    inProgressState,
  };
};

export default useOrderCreate;
