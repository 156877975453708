import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './selectors';
import { actions } from './slice';
import { useCallback, useMemo } from 'react';
import { selectors as companySelectors } from './index';

export const useCompanies = () => {
  const dispatch = useDispatch();

  const companies = useSelector(selectors.selectCompaniesList);
  const fetchCompaniesState = useSelector(selectors.selectFetchCompaniesState);

  const refreshCompanies = useCallback(() => {
    dispatch(actions.fetchCompanyList());
  }, [dispatch]);

  return {
    company: companies?.length === 1 ? companies[0] : undefined,
    companies,
    fetchCompaniesState,
    refreshCompanies,
  };
};

export const useCompany = (companyId: string) => {
  const selectCompany = useMemo(() => companySelectors.makeSelectCompany(companyId), [companyId]);

  return useSelector(selectCompany);
};
