import { format } from 'date-fns';
import { useTranslation } from 'app/translations/useTranslation';
import { OnHoldHistory } from 'pages/Orders/model/Order';
import * as Styled from './../styled';
interface Props {
  historyItem: OnHoldHistory;
}

function formatDate(timestamp?: number): string {
  if (timestamp === undefined) {
    return '---';
  }
  return format(timestamp, 'HH:mm dd.MM.RR');
}

export const OnHoldHistoryItem = (props: Props) => {
  const { historyItem } = props;
  const { t } = useTranslation('order');

  return (
    <Styled.Box>
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.onHoldFrom')}:</Styled.RecordLabel>
        <Styled.RecordValue>
          {historyItem.createdAt ? formatDate(historyItem.createdAt) : '-'}{' '}
          {t('labels.onHoldByJoin')}{' '}
          <b>
            {historyItem.operatorId ? t('labels.onHoldByOperator') : t('labels.onHoldByDriver')}
          </b>
        </Styled.RecordValue>
      </Styled.RecordBox>
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.onHoldTo')}:</Styled.RecordLabel>
        <Styled.RecordValue>
          {historyItem.holdUntil ? formatDate(historyItem.holdUntil) : '-'}
        </Styled.RecordValue>
      </Styled.RecordBox>
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.onHoldReleasedAt')}:</Styled.RecordLabel>
        <Styled.RecordValue>
          {historyItem.releasedAt ? formatDate(historyItem.releasedAt) : '-'}{' '}
          {t('labels.onHoldByJoin')}{' '}
          <b>
            {historyItem.releasedByOperator
              ? t('labels.onHoldByOperator')
              : historyItem.releasedByDriver
              ? t('labels.onHoldByDriver')
              : t('labels.onHoldByTimeElapsed')}
          </b>
        </Styled.RecordValue>
      </Styled.RecordBox>
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.onHoldDriversNote')}:</Styled.RecordLabel>
        <Styled.RecordValue>{historyItem.notes ?? '-'}</Styled.RecordValue>
      </Styled.RecordBox>
    </Styled.Box>
  );
};
