import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

// Props type
type Props = {};

// Component
const DialpadOff = (props: Props) => {
  return (
    <SvgIcon {...props}>
      <path d="m6 1a2 2 0 0 0-1.623 0.83789l2.7871 2.7852a2 2 0 0 0 0.83594-1.623 2 2 0 0 0-2-2zm6 0a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2zm6 0a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2zm-14.975 2.0254-1.2695 1.2695 3.082 3.082a2 2 0 0 0-0.83789 1.623 2 2 0 0 0 2 2 2 2 0 0 0 1.623-0.83789l3.2148 3.2148a2 2 0 0 0-0.83789 1.623 2 2 0 0 0 2 2 2 2 0 0 0 1.623-0.83789l6.082 6.082 1.2695-1.2793-17.949-17.939zm8.9746 3.9746a2 2 0 0 0-1.623 0.83398l2.7891 2.7891a2 2 0 0 0 0.83398-1.623 2 2 0 0 0-2-2zm6 0a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2zm-12 6a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2zm12 0a2 2 0 0 0-1.6211 0.83203l2.7891 2.7891a2 2 0 0 0 0.83203-1.6211 2 2 0 0 0-2-2zm-6 6a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2z" />
    </SvgIcon>
  );
};

export default DialpadOff;
