import { useState } from 'react';
import { ReportType } from '../../ReportType';
import { TypeSelect, TypeSelectProps } from './TypeSelect';
import { usePermissions } from '../../../../services/authorization/hooks/usePermissions';

export function useTypeSelect() {
  const [selectedType, setSelectedType] = useState<ReportType | null>(null);
  const reportPermissions = usePermissions('report');
  const typeSelectProps: TypeSelectProps = {
    selectedType,
    onTypeSelect: setSelectedType,
    availableTypes: [
      {
        name: ReportType.DriverActivity,
        canAccess: reportPermissions.reports.canAccessDriverActivityReport,
      },
      {
        name: ReportType.DriverAvailability,
        canAccess: reportPermissions.reports.canAccessDriverActivityReport,
      },
      {
        name: ReportType.OrdersFull,
        canAccess: reportPermissions.reports.canAccessFullReport,
      },
      {
        name: ReportType.OrderOffers,
        canAccess: reportPermissions.reports.canAccessOffersReport,
      },
      {
        name: ReportType.DriversStock,
        canAccess: reportPermissions.reports.canAccessDriversStockReport,
      },
      {
        name: ReportType.Corrections,
        canAccess: reportPermissions.reports.canAccessCorrectionsReport,
      },
      {
        name: ReportType.DriversFeedback,
        canAccess: reportPermissions.reports.canAccessDriversFeedbackReport,
      },
      {
        name: ReportType.AuditStockMovement,
        canAccess: reportPermissions.reports.canAccessAuditStockMovementReport,
      },
      {
        name: ReportType.StockExchange,
        canAccess: reportPermissions.reports.canAccessStockExchangeReport,
      },
    ],
  };

  return {
    selectedType,
    typeSelectProps,
    TypeSelect,
  };
}
