import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import { default as MuiFormLabel } from '@mui/material/FormLabel';
import { FieldErrors, FieldError } from 'react-hook-form';

type Error = {
  message?: string;
  type: string;
};

interface Props {
  label: string;
  children: ReactElement[];
  className?: string;
  error?: Error;
}

export function getErrorForField(formErrors: FieldErrors, field: string): FieldError | undefined {
  const error = formErrors[field];
  if (Array.isArray(error) === true) {
    return error[0];
  }

  return error;
}

export function CheckboxGroup(props: Props) {
  const errorMessage = props.error?.message ?? props.error?.type;
  return (
    <Box className={props.className}>
      {/* Don't know why the compiler complains, without styling it everything is OK */}
      <Label>{props.label}</Label>
      <div>{props.children}</div>
      <ErrorBox>{errorMessage}</ErrorBox>
    </Box>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0 ${theme.spacing(0.5)};
  margin: ${theme.spacing(1)};
`,
);

const Label = styled(MuiFormLabel)(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
  border-bottom: 1px solid ${theme.colors.primary.borderLight};
`,
);

const ErrorBox = styled('div')(
  ({ theme }) => `
  border-top: 1px solid ${theme.colors.primary.borderLight};
  color: ${theme.colors.primary.error};
  padding: ${theme.spacing(1)} ${theme.spacing(0.5)};
  font-weight: 400;
  font-size: 0.9rem;
  min-height: ${theme.spacing(5)};
`,
);
