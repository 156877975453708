import { styled } from '@mui/material/styles';
import { default as MuiDivider } from '@mui/material/Divider';
import Paper from '@mui/material/Paper';

export namespace Section {
  export const Box = styled(Paper)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginBottom: 0,
    marginRight: theme.spacing(4),
    padding: theme.spacing(2),
    boxShadow: 'rgba(145, 158, 171, 0.24) 0 0 2px 0, rgba(145, 158, 171, 0.24) 0 16px 32px -4px',
  }));

  export const Header = styled('p')({
    fontWeight: 500,
    fontSize: '1.2em',
  });

  export const SubHeader = styled('p')({
    fontWeight: 500,
    fontSize: '1em',
    marginBottom: 10,
  });

  export const Divider = styled(MuiDivider)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }));
}
