import { ApiRequestSaga } from './Request/ApiRequestSaga';
import { DriverStatus, OsType } from '../pages/DriverPage/Driver';
import { StockItemInfo } from '../pages/WarehousePage/Warehouse';

export interface DriverLocationApi {
  lat: number;
  lng: number;
  loggedAt: Date;
}

export interface Driver {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  status: DriverStatus;
  isAmbassador?: boolean;
  region: string;
  mobileAppVersion?: string;
  driverType?: string;
  lastKnownLocation?: DriverLocationApi;
  allowedCompanies?: string[];
  carRegistrationPlate?: string;
  car?: string;
  carColor?: string;
  lastLogin?: Date;
  blocked?: boolean;
  active?: boolean;
  osType?: OsType;
  stock?: {
    [key: string]: StockItemInfo;
  };
}

export interface ListDriversResponse {
  drivers: Driver[];
}

interface ListDriversParams {
  status?: string;
  companyIds?: string[];
  region?: string;
  active?: boolean;
}

export interface SendNotificationParams {
  regions?: string[];
  status?: DriverStatus;
  driverId?: string;
  short: {
    title: string;
    body: string;
  };
  long?: {
    title: string;
    body: string;
  };
}

export const DriverApiSaga = {
  listDrivers: (params: ListDriversParams) => {
    return ApiRequestSaga.Get<ListDriversResponse>({
      endpoint: '/driver/list',
      query: {
        ...params,
      },
    });
  },
  sendNotification: (params: SendNotificationParams) => {
    return ApiRequestSaga.Post<void>({
      endpoint: '/notification/send',
      data: params,
    });
  },
};
