import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import { Product } from 'model/Product';
import toNumber from 'utlis/ReactHookForm/transformers/toNumber';
import useWeight from './useWeight';
import useDimensions from './useDimensions';

import { Input as FormInput } from 'components/ReactHookForm/Input/Input';

// Props type
type Props = {
  fieldGroupName: string;
  product: Product;
};

// Component
const Dimensions: React.FC<Props> & { useWillRender: (product: Product) => boolean } = ({
  fieldGroupName,
  product,
}) => {
  const { t } = useTranslation('order');
  const weight = useWeight(product);
  const dimensions = useDimensions(product, fieldGroupName);

  return Dimensions.useWillRender(product) ? (
    <Grid container>
      {dimensions.isAvailable.width && (
        <Grid item xs={12}>
          <FormInput
            name={`${fieldGroupName}.width`}
            label={t('labels.dimensions.widthCm')}
            rules={dimensions.rules.width}
            transform={toNumber}
          />
        </Grid>
      )}
      {dimensions.isAvailable.height && (
        <Grid item xs={12}>
          <FormInput
            name={`${fieldGroupName}.height`}
            label={t('labels.dimensions.heightCm')}
            rules={dimensions.rules.height}
            transform={toNumber}
          />
        </Grid>
      )}
      {dimensions.isAvailable.length && (
        <Grid item xs={12}>
          <FormInput
            name={`${fieldGroupName}.length`}
            label={t('labels.dimensions.lengthCm')}
            rules={dimensions.rules.length}
            transform={toNumber}
          />
        </Grid>
      )}
      {weight.isAvailable && (
        <Grid item xs={12}>
          <FormInput
            name={`${fieldGroupName}.weight`}
            label={t('labels.dimensions.weightKg')}
            rules={weight.rules}
            transform={toNumber}
          />
        </Grid>
      )}
    </Grid>
  ) : null;
};
Dimensions.useWillRender = (product) =>
  useMemo<boolean>(
    () =>
      !!product.productSettings?.productFormFields?.some((field) =>
        field.startsWith('dimensions.'),
      ),
    [product],
  );

export default Dimensions;
