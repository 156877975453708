import React, { useMemo } from 'react';

// import { styled } from '@mui/material/styles';
import usePeselValidator from 'utlis/ReactHookForm/validators/peselValidator';
import { useTranslation } from 'react-i18next';
import { Input as FormInput } from 'components/ReactHookForm/Input/Input';

// Props type
type Props = {
  fieldGroupName: string;
};

// Component
const PeselField = ({ fieldGroupName }: Props) => {
  const { t } = useTranslation(['order', 'validationErrors']);

  const peselValidator = usePeselValidator(t('validationErrors:pesel'));

  const rules = useMemo(
    () => ({
      required: t('validationErrors:required'),
      validate: {
        pesel: peselValidator,
      },
    }),
    [t, peselValidator],
  );

  return (
    <FormInput
      name={`${fieldGroupName}.pesel`}
      label={t('labels.productFields.pesel')}
      rules={rules}
      size="small"
    />
  );
};

export default PeselField;
