import React from 'react';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import { Section } from 'components/Section';
import { useCompanySelect } from './components/CompanySelect';
import { UserCreator } from './components/UserForms/UserCreator';
import { UserEditor } from './components/UserForms/UserEditor';
import { useUserTable } from './components/UserTable/useUserTable';
import { useAccountTypeSelect } from './components/AccountTypeSelect';
import { ShowChangeOperatorPasswordModalAction } from './components/ChangePasswordModal/ShowChangeOperatorPasswordModalAction';

interface Props {}

export function UserManagerPage(props: Props) {
  const { t } = useTranslation('user');
  const { selectedCompanyId, CompanySelect, companySelectProps } = useCompanySelect();
  const { selectedAccountType, AccountTypeSelect, accountTypeSelectProps } = useAccountTypeSelect();
  const { selectedUser, UserTable, userTableProps } = useUserTable({
    filters: { companyId: selectedCompanyId, accountType: selectedAccountType },
  });

  return (
    <SectionBox>
      <Section.Header>{t('headers.userList')}</Section.Header>
      <Section.Divider />
      <ContentBox>
        <ListBox>
          <CompanySelect {...companySelectProps} />
          <AccountTypeSelect {...accountTypeSelectProps} />
          <UserTable {...userTableProps} />
        </ListBox>
        {selectedUser === null ? <UserCreator /> : <UserEditor user={selectedUser} />}
      </ContentBox>
    </SectionBox>
  );
}

const SectionBox = styled(Section.Box)``;

const ContentBox = styled('div')`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1080px) {
    flex-wrap: nowrap;
  }
`;

const ListBox = styled('div')`
  width: 400px;
  flex-shrink: 0;
`;
