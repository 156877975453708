import React, { useEffect } from 'react';

import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Styled from './styled';
import LoadingButton from '@mui/lab/LoadingButton';

import { useTranslation } from 'app/translations/useTranslation';

import { Input } from 'components/ReactHookForm/Input/Input';

import { useChangeOperatorPassword } from '../../../../state/useChangeOperatorPassword';
import {
  useChangeOperatorPasswordForm,
  ChangeOperatorPasswordFormValues,
} from './hooks/useChangeOperatorPasswordForm';

interface Props {
  operatorId: string;
  onSuccess: VoidFunction;
}

export function ChangeOperatorPasswordForm(props: Props) {
  const { t } = useTranslation(['user', 'settings']);
  const { changeOperatorPassword, isInProgress } = useChangeOperatorPassword();

  const form = useChangeOperatorPasswordForm();
  useEffect(() => {
    if (!isInProgress) {
      form.reset();
    }
  }, [isInProgress]);
  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.Form
          onSubmit={form.handleSubmit((formValues: ChangeOperatorPasswordFormValues) => {
            changeOperatorPassword({
              password: formValues.password,
              operatorId: props.operatorId,
            });
          })}
        >
          <Styled.DetailsBox>
            <Input name={'password'} label={t('settings:fields.newPassword')} type={'password'} />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <Input
              name={'passwordConfirmation'}
              label={t('settings:fields.newPasswordConfirmation')}
              type={'password'}
            />
          </Styled.DetailsBox>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={isInProgress}
            loading={isInProgress}
          >
            {t('user:buttons.changePassword')}
          </LoadingButton>
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
