import { styled } from '@mui/material/styles';

export const Box = styled('div')(
  ({ theme }) => `
  display: flex;
`,
);

export const Header = styled('p')(
  ({ theme }) => `
  font-size: 14px;
  padding-bottom: ${theme.spacing(2)};
`,
);

export const Section = styled('div')(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};
`,
);

export const DeletionSection = styled(Section)``;

export const SummarySection = styled(Section)(
  ({ theme }) => `
`,
);

export const ActionSection = styled(Section)`
  text-align: center;
`;

export const RecordBox = styled('div')(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(1)};
`,
);

export const RecordLabel = styled('span')(
  ({ theme }) => `
  font-weight: normal;
  padding-right: ${theme.spacing(0.5)};
`,
);

export const RecordValue = styled('span')`
  font-weight: lighter;
`;

export const RecordBlockValue = styled('p')`
  font-weight: lighter;
`;

export const CommentValue = styled(RecordBlockValue)`
  font-style: italic;
`;
