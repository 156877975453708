import { useTranslation } from 'app/translations/useTranslation';
import { Input } from 'components/ReactHookForm/Input/Input';
import { Select } from 'components/ReactHookForm/Select/Select';
import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useRegions } from 'services/regions/state/useRegions';
import { useSendPushNotificationForm, CreateFormValues } from './hooks/useSendPushNotificationForm';
import * as Styled from './styled';
import { useSendNotification } from 'pages/DriverPage/state/useSendNotification';
import { LoadingButton } from '@mui/lab';
import { DriverStatus } from '../../../../../../../../Driver';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {}

const allDriverStatuses = 'all';

export function PushNotificationForm(props: Props) {
  const { t } = useTranslation('driver');
  const { regions } = useRegions();
  const { sendNotification, isInProgress: isInProgressSendNotification } = useSendNotification();

  const [longBody, setLongBody] = useState('');

  const form = useSendPushNotificationForm();
  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.Form
          onSubmit={form.handleSubmit((formValues: CreateFormValues) => {
            sendNotification({
              ...(formValues.regions && { regions: formValues.regions }),
              status: formValues.status === allDriverStatuses ? undefined : formValues.status,
              short: {
                title: formValues.shortTitle,
                body: formValues.shortBody,
              },
              ...(!!longBody &&
                formValues.longTitle && {
                  long: {
                    title: formValues.longTitle,
                    body: longBody,
                  },
                }),
            });
            form.reset({});
          })}
        >
          <Styled.DetailsBox>
            <p>{t('sendNotification.fields.regionTitle')}</p>
            <Select
              multiple
              name={'regions'}
              label={t('sendNotification.fields.region')}
              values={regions.map((region) => ({
                value: region.name,
                display: region.name,
              }))}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <Select
              name={'status'}
              label={t('sendNotification.fields.status')}
              values={[
                {
                  value: allDriverStatuses,
                  display: t('status.all'),
                },
                {
                  value: DriverStatus.Online,
                  display: t('status.online'),
                },
                {
                  value: DriverStatus.Offline,
                  display: t('status.offline'),
                },
              ]}
              defaultValue={allDriverStatuses}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <h3>{t('sendNotification.shortNotificationHeader')}</h3>
            <Input
              name={'shortTitle'}
              label={t('sendNotification.fields.title')}
              dataCy={'input-title'}
            />
            <Input
              name={'shortBody'}
              label={t('sendNotification.fields.message')}
              dataCy={'input-message'}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <h3>{t('sendNotification.longNotificationHeader')}</h3>
            <Input
              name={'longTitle'}
              label={t('sendNotification.fields.title')}
              dataCy={'input-title'}
            />
            <ReactQuill theme="snow" value={longBody} onChange={setLongBody} />
          </Styled.DetailsBox>
          <LoadingButton
            type={'submit'}
            disabled={isInProgressSendNotification}
            loading={isInProgressSendNotification}
          >
            {t('sendNotification.buttons.send')}
          </LoadingButton>
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
