import { map } from 'lodash';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';

import { useTranslation } from 'app/translations/useTranslation';
import { PackageProduct } from 'pages/Orders/model/Order';
import * as Styled from '../styled';
import Typography from '@mui/material/Typography';

interface Props {
  products: PackageProduct[];
}

export function PackageProductList(props: Props) {
  const { t } = useTranslation('order');
  return (
    <Styled.Table>
      <TableHead>
        <TableRow>
          <Styled.NameHead>{t('labels.miscData.name')}</Styled.NameHead>
          <Styled.HeadCell>{t('labels.miscData.productCode')}</Styled.HeadCell>
          <Styled.HeadCell>{t('labels.miscData.dimensions')}</Styled.HeadCell>
          <Styled.HeadCell>{t('labels.rest')}</Styled.HeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.products.map((product, index) => (
          <TableRow key={index}>
            <Styled.ContentCell>{product.name}</Styled.ContentCell>
            <Styled.ContentCell>{product.code}</Styled.ContentCell>
            <Styled.ContentCell>
              {product.dimensions
                ? `${product.dimensions.weight || '-'}kg ${product.dimensions.width || '-'}x${
                    product.dimensions.height || '-'
                  }x${product.dimensions.length || '-'}`
                : '-'}
            </Styled.ContentCell>
            <Styled.ContentCell>
              {!!product.externalNo && (
                <Styled.ContentCellData>
                  <Typography variant={'caption'}>
                    {t('labels.productFields.externalNo')}:
                  </Typography>
                  <Typography variant={'body2'}>{product.externalNo}</Typography>
                </Styled.ContentCellData>
              )}
              {!!product.pesel && (
                <Styled.ContentCellData>
                  <Typography variant={'caption'}>{t('labels.productFields.pesel')}:</Typography>
                  <Typography variant={'body2'}>{product.pesel}</Typography>
                </Styled.ContentCellData>
              )}
              {!!product.receiptNo && (
                <Styled.ContentCellData>
                  <Typography variant={'caption'}>
                    {t('labels.productFields.receiptNo')}:
                  </Typography>
                  <Typography variant={'body2'}>{product.receiptNo}</Typography>
                </Styled.ContentCellData>
              )}
              {!!product.amountToPayOnPickUp && (
                <Styled.ContentCellData>
                  <Typography variant={'caption'}>
                    {t('labels.productFields.amountToPayOnPickUp')}:
                  </Typography>
                  <Typography variant={'body2'}>{product.amountToPayOnPickUp}</Typography>
                </Styled.ContentCellData>
              )}
              {!!product.medicines && (
                <Styled.ContentCellData>
                  <List
                    subheader={<ListSubheader>{t('labels.productFields.medicines')}</ListSubheader>}
                    dense={true}
                  >
                    {map(product.medicines, (medicine) => (
                      <ListItem>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={!!medicine.pickedUp}
                            disableRipple
                            disabled
                          />
                        </ListItemIcon>
                        <ListItemText primary={medicine.details} />
                      </ListItem>
                    ))}
                  </List>
                  <Typography variant={'body2'}>{product.pesel}</Typography>
                </Styled.ContentCellData>
              )}
            </Styled.ContentCell>
          </TableRow>
        ))}
      </TableBody>
    </Styled.Table>
  );
}
