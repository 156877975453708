import React, { useMemo } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MUIDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import DriverIcon from '@mui/icons-material/RecentActors';
import WarehouseIcon from '@mui/icons-material/EmojiTransportation';
import StockIcon from '@mui/icons-material/AddShoppingCart';
import ListIcon from '@mui/icons-material/List';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LocalPlayIcon from '@mui/icons-material/LocalPlay';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import WalletIcon from '@mui/icons-material/AccountBalance';

import { AppRoutes } from 'app/Navigation';
import { useTranslation } from 'app/translations/useTranslation';
import { useAuth } from 'services/authorization';
import { PackageIcon } from 'assets/Package/PackageIcon';
import { usePermissions } from 'services/authorization/hooks/usePermissions';
import { extendedMenuWidth, shrunkMenuWidth, topBarHeight } from '../../styled';
import { LanguageSwitcher } from './components/LanguageSwitcher';
import { MenuItem } from './components/MenuItem';
import { useCompanies } from '../../../../services/companies/state/useCompanies';
import { CompanyStatus } from '../../../../model/Company';

export interface MenuProps {
  isExtended: boolean;
  className?: string;
}

export function Menu(props: MenuProps) {
  const { logOut } = useAuth();
  const { t } = useTranslation('common');

  const userPermissions = usePermissions('user');
  const reportPermissions = usePermissions('report');
  const orderPermissions = usePermissions('order');
  const companyPermissions = usePermissions('company');
  const driverPermissions = usePermissions('driver');
  const warehousePermissions = usePermissions('warehouse');
  const stockPermissions = usePermissions('stock');
  const multiplierPermissions = usePermissions('multiplier');
  const correctionPermissions = usePermissions('correction');
  const walletPermissions = usePermissions('wallet');

  const theme = useTheme();
  const isDrawerPermanent = useMediaQuery(theme.breakpoints.up('sm'));

  const { companies } = useCompanies();

  const pendingCompanies = useMemo(() => {
    return companies?.filter((company) => company.status === CompanyStatus.Pending)?.length ?? 0;
  }, [companies]);

  return (
    <Drawer
      variant={isDrawerPermanent ? 'persistent' : 'temporary'}
      open={isDrawerPermanent || props.isExtended}
      extended={props.isExtended}
    >
      {orderPermissions.canAccessPage && (
        <MenuItem
          content={t('menu.orders.root')}
          icon={<PackageIcon />}
          redirectTo={AppRoutes.order.root()}
        >
          <MenuItem
            content={t('menu.orders.orderList')}
            icon={<ListIcon />}
            redirectTo={AppRoutes.order.orderList()}
            dataCy={'button-navigate_orders'}
          />
          {orderPermissions.canWrite && (
            <MenuItem
              content={t('menu.orders.orderCreate')}
              icon={<AddBoxIcon />}
              redirectTo={AppRoutes.order.orderCreate()}
              dataCy={'button-navigate_order_create'}
            />
          )}
        </MenuItem>
      )}

      {companyPermissions.canAccessPage && (
        <MenuItem
          content={t('menu.companies')}
          icon={<BusinessIcon />}
          redirectTo={AppRoutes.companies.root()}
          dataCy={'button-navigate_companies'}
          badge={pendingCompanies}
        />
      )}

      {reportPermissions.canAccessPage && (
        <MenuItem
          content={t('menu.reports')}
          icon={<AssignmentIcon />}
          redirectTo={AppRoutes.report.root()}
          dataCy={'button-navigate_orders'}
        />
      )}
      {driverPermissions.canAccessPage && (
        <MenuItem
          content={t('menu.drivers')}
          icon={<DriverIcon />}
          redirectTo={AppRoutes.driver.root()}
          dataCy={'button-navigate_orders'}
        />
      )}
      {walletPermissions.canAccessPage && (
        <MenuItem
          content={t('menu.wallet')}
          icon={<WalletIcon />}
          redirectTo={AppRoutes.wallet.root()}
          dataCy={'button-navigate_wallet'}
        />
      )}
      {warehousePermissions.canAccessPage && (
        <MenuItem
          content={t('menu.warehouses')}
          icon={<WarehouseIcon />}
          redirectTo={AppRoutes.warehouse.root()}
          dataCy={'button-navigate_warehouses'}
        />
      )}
      {stockPermissions.canAccessPage && (
        <MenuItem
          content={t('menu.stock')}
          icon={<StockIcon />}
          redirectTo={AppRoutes.stock.root()}
          dataCy={'button-navigate_stock'}
        />
      )}
      {userPermissions.canAccessPage && (
        <MenuItem
          content={t('menu.users')}
          icon={<GroupIcon />}
          redirectTo={AppRoutes.user.root()}
          dataCy={'button-navigate_orders'}
        />
      )}
      {multiplierPermissions.canAccessPage && (
        <MenuItem
          content={t('menu.multiplier')}
          icon={<LocalPlayIcon />}
          redirectTo={AppRoutes.multiplier.root()}
          dataCy={'button-navigate_multiplier'}
        />
      )}
      {correctionPermissions.canAccessPage && (
        <MenuItem
          content={t('menu.correction')}
          icon={<PriceChangeIcon />}
          redirectTo={AppRoutes.correction.root()}
          dataCy={'button-navigate_multiplier'}
        />
      )}
      <Divider />
      <MenuItem
        content={t('menu.settings')}
        icon={<SettingsIcon />}
        redirectTo={AppRoutes.settings.root()}
        dataCy={'button-navigate_settings'}
      />
      <MenuItem
        content={t('menu.logout')}
        icon={<LogoutIcon />}
        onSelect={logOut}
        dataCy={'button-log_out'}
      />
      <StyledLanguageSwitcher />
    </Drawer>
  );
}

const Drawer = styled(MUIDrawer)<{ open: boolean; extended: boolean }>(({ theme, extended }) => {
  const transitionIn = {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  };

  const transitionOut = {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  };

  const drawerStyle = extended
    ? `{
        width: ${extendedMenuWidth}px;
        transition: ${theme.transitions.create('width', transitionIn)};
      }`
    : `{
        overflow-x: hidden;
        width: ${shrunkMenuWidth + 1}px;
        transition: ${theme.transitions.create('width', transitionOut)};
      }`;

  return `  
      flex-shrink: 0;
      white-space: nowrap;
      z-index: ${theme.zIndex.drawer} !important;
      
      & .MuiDrawer-paper {
        padding-top: ${topBarHeight}px;
        flex-grow: 1;
        overflow: hidden;
      }
      
      & ${drawerStyle} 
      
      & .MuiDrawer-paper ${drawerStyle}
    `;
});

const StyledLanguageSwitcher = styled(LanguageSwitcher)(
  ({ theme }) => `
  margin-top: auto;
  margin-bottom: ${theme.spacing(5)};
`,
);
