import { PayloadAction } from '@reduxjs/toolkit';
import { State } from '../model/State';
import { CompanyActionTypes } from '../model/CompanyActionTypes';

export const fetchCompanyList = (state: State) => {
  state.fetchCompaniesState = { inProgress: true };
};

export const fetchCompanyListSuccess = (
  state: State,
  { payload }: PayloadAction<CompanyActionTypes.fetchCompanyListSuccess>,
) => {
  state.fetchCompaniesState = { inProgress: false, success: true };
  state.companies = payload.companies;
};

export const fetchCompanyListFailure = (
  state: State,
  { payload }: PayloadAction<CompanyActionTypes.fetchCompanyListFailure>,
) => {
  state.fetchCompaniesState = {
    inProgress: false,
    success: false,
    error: payload,
  };
  state.companies = [];
};

/* Fetch company details */
export const fetchCompanyDetails = (
  state: State,
  { payload }: PayloadAction<CompanyActionTypes.fetchCompanyDetails>,
) => {};

export const fetchCompanyDetailsSuccess = (
  state: State,
  { payload }: PayloadAction<CompanyActionTypes.fetchCompanyDetailsSuccess>,
) => {
  const index = state.companies.findIndex((company) => company.id === payload.id);
  if (index !== -1) {
    state.companies[index] = payload;
  } else {
    state.companies.push(payload);
  }
};
