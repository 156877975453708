import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';

export type CorrectionModalFormValues = {
  comment?: string;
  amount?: number;
  correctionAction?: 'accept' | 'reject';
  rejectionReason?: string;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object().shape({
      comment: yup.string().optional(),
      amount: yup.number().when('correctionAction', {
        is: 'accept',
        then: yup.number().required(),
        otherwise: yup.number().optional(),
      }),
      rejectionReason: yup.string().when('correctionAction', {
        is: 'reject',
        then: yup.string().required(),
        otherwise: yup.string().optional(),
      }),
    });
  }, [t]);
}

export const useCorrectionModalFormReset = () => {
  const form = useFormContext();

  return useCallback(() => {
    form.setValue('comment', '');
    form.setValue('amount', undefined);
    form.setValue('correctionAction', undefined);
    form.setValue('rejectionReason', '');
  }, [form]);
};

export function useCorrectionModalForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<CorrectionModalFormValues>([
      simpleResolver<CorrectionModalFormValues>(),
      yupResolver(validationSchema),
    ]),
    [validationSchema],
  );
  const form = useForm<CorrectionModalFormValues>({
    resolver,
    mode: 'onSubmit',
  });

  return form;
}
