import React from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { default as MuiTableContainer } from '@mui/material/TableContainer';
import { User } from 'pages/UserPage/User';
import { TableBody } from './components/TableBody';
import { TableHeaders } from './components/TableHeaders';

export interface UserTableProps {
  users: User[];
  isLoading: boolean;
  selectedUser: User | null;
  onSelectedUserChange: (user: User | null) => void;
}

export function UserTable(props: UserTableProps) {
  return (
    <TableContainer>
      <Table stickyHeader={true}>
        <TableHeaders />
        <TableBody
          users={props.users}
          hasError={false}
          isLoading={props.isLoading}
          onReload={() => {}}
          onUserClick={(user) => props.onSelectedUserChange(user)}
          activeUser={props.selectedUser}
        />
      </Table>
    </TableContainer>
  );
}

const TableContainer = styled(MuiTableContainer)`
  height: 65vh;
`;
