import i18n, { TOptions } from 'i18next';
import { useTranslation as i18nUseTranslation } from 'react-i18next';
import { Namespace } from './resources';

export function useTranslation(namespace?: Namespace | Namespace[]) {
  return i18nUseTranslation<Namespace | Namespace[]>(namespace);
}

export function createTranslator(namespace: string) {
  return i18n.getFixedT(null, namespace);
}

export function translate(namespace: string, key: string, options?: TOptions | string) {
  const translator = createTranslator(namespace);
  return translator(key, options);
}
