import React, { useEffect, useMemo } from 'react';

import { FormProvider } from 'react-hook-form';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'app/translations/useTranslation';

import { Location } from '../../../../model/Location';
import { Card } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';

import Skeleton from '@mui/material/Skeleton';
interface Props {
  location?: Partial<Location>;
  onDelete?: (locationIndex: number) => void;
}

export function LocationListItem({ location }: Props) {
  const { t } = useTranslation('settings');

  const temporary = useMemo(() => {
    return location && !location.id;
  }, [location]);
  if (!location) {
    return (
      <Card>
        <CardHeader
          title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      </Card>
    );
  }
  return (
    <LocationItemContainer>
      <CardHeader
        title={location.name}
        subheader={`${location.address?.street}, ${location.address?.zipCode} ${
          location.address?.city
        } ${location.phone ? `(${location.phone})` : ''}`}
      ></CardHeader>
    </LocationItemContainer>
  );
}

const LocationItemContainer = styled(Card)`
  margin-top: 15px;
`;

const Form = styled('form')`
  max-width: 500px;
`;
