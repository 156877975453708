import TableCell from '@mui/material/TableCell';
// import { useTranslation } from 'app/translations/useTranslation';
import * as Styled from './styled';
import { StockExchange } from 'pages/StockPage/StockExchange';

interface Props {
  stockExchange: StockExchange;
}

export function ListRow(props: Props) {
  // const { t } = useTranslation('stock');

  return (
    <Styled.TableBox>
      <Styled.TableRowHead data-cy={'table_row-stock_exchange_list'}>
        <TableCell data-driver-name={props.stockExchange.givingDriverName}>
          {props.stockExchange.givingDriverName}
        </TableCell>
        <TableCell data-name={props.stockExchange.productName}>
          {props.stockExchange.productName}
        </TableCell>
        <TableCell data-amount={props.stockExchange.amount}>{props.stockExchange.amount}</TableCell>
      </Styled.TableRowHead>
    </Styled.TableBox>
  );
}
