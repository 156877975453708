import { useEffect } from 'react';

/**
 * If one missing, add it according to the doc https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
 */
export enum KeyboardKeys {
  Esc = 'Escape'
}

export function useOnKeyUp({ keyboardKey, callback }: { keyboardKey: KeyboardKeys, callback: VoidFunction }) {
  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === keyboardKey) {
        callback();
      }
    };
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [keyboardKey, callback]);
}
