import React from 'react';
import * as Login from 'pages/LoginPage';
import * as Order from 'pages/Orders';
import * as Report from 'pages/ReportPage';
import * as Settings from 'pages/SettingsPage';
import * as User from 'pages/UserPage';
import * as Driver from 'pages/DriverPage';
import * as Warehouse from 'pages/WarehousePage';
import * as Stock from 'pages/StockPage';
import * as Companies from 'pages/Companies';
import * as Multiplier from 'pages/MultiplierPage';
import * as Correction from 'pages/CorrectionPage';
import * as Wallet from 'pages/WalletPage';
import * as Register from 'pages/RegisterPage';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthorizedRoute } from './components/AuthorizedRoute';
import { NotAuthorizedRoute } from './components/NotAuthorizedRoute';
import { AppRoutes } from './Navigation';
import RoleRoute from './components/RoleRoute';
import { UserLayout } from '../components/Layouts/UserLayout';

interface Props {}

export function Routing(props: Props) {
  return (
    <Switch>
      <NotAuthorizedRoute exact={true} path={AppRoutes.login.root()} component={Login.LoginPage} />
      <NotAuthorizedRoute
        exact
        path={AppRoutes.register.root()}
        component={Register.RegisterPage}
      />
      <NotAuthorizedRoute
        exact={true}
        path={AppRoutes.register.sales()}
        component={Register.SalesPage}
      />

      <AuthorizedRoute path={'/'}>
        <Switch>
          <Route exact path={AppRoutes.register.selectProduct()} component={Register.ProductPage} />
          <Route
            exact
            path={AppRoutes.register.addLocations()}
            component={Register.LocationsPage}
          />
          <UserLayout>
            <Switch>
              <RoleRoute
                roles="order.canAccessPage"
                path={AppRoutes.order.root()}
                component={Order.Routing}
                // Order page is a default page, but when user is not allowed to see it
                // redirect to settings, which is allowed for all authorized users
                redirect={AppRoutes.settings.root()}
              />

              <RoleRoute
                roles="company.canAccessPage"
                path={AppRoutes.companies.root()}
                component={Companies.Routing}
                // Companies page is a default page, but when user is not allowed to see it
                // redirect to settings, which is allowed for all authorized users
                redirect={AppRoutes.settings.root()}
              />
              <RoleRoute
                roles="companies.canAccessPage"
                path={AppRoutes.companies.root()}
                component={Companies.Routing}
              />
              <RoleRoute
                roles="report.canAccessPage"
                exact
                path={AppRoutes.report.root()}
                component={Report.ReportPage}
              />
              <RoleRoute
                roles="user.canAccessPage"
                path={AppRoutes.user.root()}
                component={User.UserPage}
              />
              <RoleRoute
                roles="multiplier.canAccessPage"
                path={AppRoutes.multiplier.root()}
                component={Multiplier.MultiplierCalendarPage}
              />
              <RoleRoute
                roles="driver.canAccessPage"
                path={AppRoutes.driver.root()}
                component={Driver.DriverPage}
              />
              <RoleRoute
                roles="wallet.canAccessPage"
                path={AppRoutes.wallet.root()}
                component={Wallet.WalletPage}
              />
              <RoleRoute
                roles="stock.canAccessPage"
                path={AppRoutes.stock.root()}
                component={Stock.StockPage}
              />
              <RoleRoute
                roles="warehouse.canAccessPage"
                path={AppRoutes.warehouse.root()}
                component={Warehouse.WarehousePage}
              />
              <RoleRoute
                roles="correction.canAccessPage"
                path={AppRoutes.correction.root()}
                component={Correction.CorrectionPage}
              />
              <Route exact path={AppRoutes.settings.root()} component={Settings.SettingsPage} />

              <Redirect to={AppRoutes.order.root()} />
            </Switch>
          </UserLayout>
        </Switch>
      </AuthorizedRoute>

      <Redirect to={AppRoutes.login.root()} />
    </Switch>
  );
}
