import React, { useMemo } from 'react';

import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Styled from './styled';
import LoadingButton from '@mui/lab/LoadingButton';

import { AsyncStatus } from 'utlis/State';
import { useTranslation } from 'app/translations/useTranslation';

import { Input } from 'components/ReactHookForm/Input/Input';
import { Select } from 'components/ReactHookForm/Select/Select';
import { Checkbox } from 'components/ReactHookForm/Checkbox/Checkbox';

import {
  CancelOrderFormValues,
  useCancelOrderForm,
  useCancelOrderFormReset,
} from './hooks/useCancelOrderForm';
import { useCancelOrder } from '../../../../../../../../../../state/useCancelOrder';
import {
  CancellationReasonsMap,
  OrderStage,
  OrderStatus,
} from '../../../../../../../../../../../model/Order';

interface Props {
  orderId: string;
  cancellationReasons?: CancellationReasonsMap;
  orderStage?: OrderStage;
}

export function CancelOrderForm(props: Props) {
  const { t } = useTranslation('order');
  const { cancelOrder, cancelOrderStatus } = useCancelOrder();

  const form = useCancelOrderForm();
  const reset = useCancelOrderFormReset();

  const onSubmit: SubmitHandler<CancelOrderFormValues> = (formValues) => {
    cancelOrder({
      comment: formValues.comment,
      cancellationReason: formValues.cancellationReason,
      // @ts-ignore
      renewOrder: renewValues.find((val) => val.value == formValues.renewal)?.submitValue,
      forceCancel: !!formValues.forceCancel,
      payable: payableValues.find((val) => val.value == formValues.payable)?.submitValue,
      createReturn: createReturnValues.find((val) => val.value == formValues.createReturn)
        ?.submitValue,
      orderId: props.orderId,
    });
  };

  const onReset = () => {
    reset();
  };

  const payableValues: {
    value: string;
    display: string;
    submitValue: boolean | undefined;
  }[] = [
    {
      value: 'default',
      display: t('cancelOrder.form.values.payable.default'),
      submitValue: undefined,
    },
    {
      value: 'noPayment',
      display: t('cancelOrder.form.values.payable.noPayment'),
      submitValue: false,
    },
    {
      value: 'fullPayment',
      display: t('cancelOrder.form.values.payable.fullPayment'),
      submitValue: true,
    },
  ];

  const createReturnValues: {
    value: string;
    display: string;
    submitValue: boolean | undefined;
  }[] = [
    {
      value: 'default',
      display: t('cancelOrder.form.values.createReturn.default'),
      submitValue: undefined,
    },
    {
      value: 'noReturn',
      display: t('cancelOrder.form.values.createReturn.noReturn'),
      submitValue: false,
    },
  ];

  const renewValues: {
    value: string;
    display: string;
    submitValue: boolean | undefined;
  }[] = [
    {
      value: 'default',
      display: t('cancelOrder.form.values.renewal.default'),
      submitValue: undefined,
    },
    {
      value: 'renew',
      display: t('cancelOrder.form.values.renewal.renew'),
      submitValue: true,
    },
    {
      value: 'noRenewal',
      display: t('cancelOrder.form.values.renewal.noRenewal'),
      submitValue: false,
    },
  ];
  const defaultCancellationValues = [
    {
      value: 'CALL_CENTER_REQUEST',
      display: t('statusReason.ids.CALL_CENTER_REQUEST'),
    },
    {
      value: 'CLIENTS_REQUEST',
      display: t('statusReason.ids.CLIENTS_REQUEST'),
    },
    {
      value: 'NO_STOCK_IN_STORE',
      display: t('statusReason.ids.NO_STOCK_IN_STORE'),
    },
    {
      value: 'WRONG_ADDRESS',
      display: t('statusReason.ids.WRONG_ADDRESS'),
    },
    {
      value: 'CLIENT_DIDNT_SHOW_UP',
      display: t('statusReason.ids.CLIENT_DIDNT_SHOW_UP'),
    },
  ];
  const cancellationValues = useMemo(() => {
    if (!props.cancellationReasons) {
      return defaultCancellationValues;
    }
    return Object.entries(props.cancellationReasons)
      .filter(
        ([, properties]) =>
          props.orderStage?.inDelivery == properties.inDelivery ||
          props.orderStage?.toBePickedUp == properties.toBePickedUp ||
          /**
           * TODO: In case status SCHEDULED should have separate cancellation reasons then
           * new flags are needed to handle. For now we are checking if order is scheduled then showing all reasons
           */
          (props.orderStage?.status === OrderStatus.Scheduled && properties.toBePickedUp),
      )
      .map(([name, properties]) => ({
        value: name,
        display: t(`statusReason.ids.${name}`),
      }));
  }, [props.cancellationReasons, props.orderStage]);

  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.Form
          onSubmit={form.handleSubmit<CancelOrderFormValues>(onSubmit)}
          onReset={onReset}
        >
          <Styled.DetailsBox>
            <Select
              name={'cancellationReason'}
              label={t('cancelOrder.form.fields.cancellationReason')}
              values={cancellationValues}
              defaultValue={cancellationValues[0]?.value}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <Select
              name={'payable'}
              label={t('cancelOrder.form.fields.payable')}
              values={payableValues}
              defaultValue={payableValues[0].value}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <Input
              name={'comment'}
              label={t('cancelOrder.form.fields.comment')}
              dataCy={'input-comment'}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <Select
              id={'renewal'}
              name={'renewal'}
              label={t('cancelOrder.form.fields.renew')}
              fullWidth
              values={renewValues}
              defaultValue={renewValues[0].value}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <Select
              id={'createReturn'}
              name={'createReturn'}
              label={t('cancelOrder.form.fields.createReturn')}
              fullWidth
              values={createReturnValues}
              defaultValue={createReturnValues[0].value}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <Checkbox
              label={t('cancelOrder.form.fields.forceCancel')}
              name={'forceCancel'}
              value={'forceCancel'}
            />
          </Styled.DetailsBox>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={cancelOrderStatus === AsyncStatus.Pending}
            loading={cancelOrderStatus === AsyncStatus.Pending}
          >
            {t('cancelOrder.form.submitButton')}
          </LoadingButton>
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
