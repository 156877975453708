import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Slice from './slice';

export function useHoldOrder() {
  const dispatch = useDispatch();
  const holdOrderStatus = useSelector(Slice.selectors.makeSelectHoldOrderStatus());
  const releaseHeldOrderStatus = useSelector(Slice.selectors.makeSelectReleaseHeldOrderStatus());
  return {
    holdOrderStatus,
    releaseHeldOrderStatus,
    holdOrder(params: Slice.ActionTypes.HoldOrder) {
      dispatch(Slice.actions.holdOrder(params));
    },
    releaseHeldOrder(params: Slice.ActionTypes.ReleaseHeldOrder) {
      dispatch(Slice.actions.releaseHeldOrder(params));
    },
  };
}
