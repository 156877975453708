import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Slice from './slice';

export function useXDelivererRoute(orderId: string) {
  const dispatch = useDispatch();
  const route = useSelector(Slice.selectors.makeSelectXDelivererRoute());

  useEffect(() => {
    dispatch(Slice.actions.fetchXDelivererRoute({ orderId }));
  }, [orderId, dispatch]);

  return {
    route,
    retryFetching: () => {
      dispatch(Slice.actions.fetchXDelivererRoute({ orderId }));
    },
  };
}
