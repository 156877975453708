import { createSelector, createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { AsyncStatus, PA } from 'utlis/State';
import type { Driver } from '../Driver';
import { CreateUserParams } from '../../UserPage/User';
import { SendNotificationParams } from '../../../api/DriverApi';

interface Filters {
  statuses: string[];
  companyId: string | null;
  region: string | null;
  active: string | null;
}

export interface State {
  driverList: {
    filters: Filters;
    data: Driver[];
    status: AsyncStatus;
  };
  sendNotificationStatus: AsyncStatus;
}

const initialState: State = {
  driverList: {
    filters: {
      statuses: [],
      companyId: null,
      region: null,
      active: null,
    },
    data: [],
    status: AsyncStatus.NotStarted,
  },
  sendNotificationStatus: AsyncStatus.NotStarted,
};

const slice = createSlice({
  name: 'driverList',
  initialState,
  reducers: {
    fetchDriverList(state) {
      state.driverList.status = AsyncStatus.Pending;
    },
    fetchDriverListInBackground(_state) {},
    fetchDriverListSuccess(state, { payload }: PA<ActionTypes.FetchDriverListSuccess>) {
      state.driverList.status = AsyncStatus.Success;
      state.driverList.data = orderBy(payload.drivers, ['region', 'firstName'], ['asc', 'asc']);
    },
    fetchDriverListError(state) {
      state.driverList.status = AsyncStatus.Error;
    },
    setFilters(state, { payload }: PA<ActionTypes.SetFilters>) {
      if (payload.statuses !== undefined) {
        state.driverList.filters.statuses = payload.statuses;
      }
      if (payload.companyId !== undefined) {
        state.driverList.filters.companyId = payload.companyId;
      }
      if (payload.region !== undefined) {
        state.driverList.filters.region = payload.region;
      }
      if (payload.active !== undefined) {
        state.driverList.filters.active = payload.active;
      }
    },
    sendNotification(state, _action: PA<ActionTypes.SendNotification>) {
      state.sendNotificationStatus = AsyncStatus.Pending;
    },
    sendNotificationFinish(state) {
      state.sendNotificationStatus = AsyncStatus.Success;
    },
  },
});

export declare namespace ActionTypes {
  export interface FetchDriverListSuccess {
    drivers: Driver[];
  }

  export interface SetFilters extends Partial<Filters> {}

  export interface SendNotification {
    data: SendNotificationParams;
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectDriverList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.driverList.data;
    }),
  makeSelectDriverListLoadingStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.driverList.status;
    }),
  makeSelectDriverListFilters: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.driverList.filters;
    }),
  makeSelectSendNotificationStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.sendNotificationStatus;
    }),
};
