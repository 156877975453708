import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';

export type ChangePasswordFormValues = {
  oldPassword: string;
  newPassword: string;
};

function useValidationSchema() {
  const { t } = useTranslation('settings');
  return useMemo(() => {
    return yup.object({
      oldPassword: yup.string().min(7).required(),
      newPassword: yup.string().min(7).required(),
      newPasswordConfirmation: yup
        .string()
        .required()
        .oneOf([yup.ref('newPassword')], t('fields.validation.passwordNotTheSame')),
    });
  }, [t]);
}

export function usePasswordChangeForm() {
  const validationSchema = useValidationSchema();
  return useForm<ChangePasswordFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });
}
