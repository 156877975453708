import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import type { SimplifiedStock } from '../Stock';
import * as Slice from './slice';

export interface GivingDriverStockListing {
  list: SimplifiedStock[],
  hasError: boolean,
  isLoading: boolean,
  driverSelected: boolean,
}

export function useGivingDriverStockList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Slice.actions.fetchGivingDriverStockList());
  }, [dispatch]);

  const filters = useSelector(Slice.selectors.makeSelectStockTransferFilters());
  const stockItems = useSelector(Slice.selectors.makeSelectGivingDriverStockList());
  const loadingStatus = useSelector(Slice.selectors.makeSelectGivingDriverStockListLoadingStatus());
  const stockListing: GivingDriverStockListing = {
    list: stockItems || [],
    driverSelected: !!filters.givingDriverId,
    hasError: loadingStatus === AsyncStatus.Error,
    isLoading: [AsyncStatus.Pending, AsyncStatus.NotStarted].includes(loadingStatus),
  };

  return {
    stockListing,
    reloadStockListing: () => {
      dispatch(Slice.actions.fetchGivingDriverStockList());
    },
  };
}
