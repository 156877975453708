import { useTranslation } from 'app/translations/useTranslation';
import { Order } from 'pages/Orders/model/Order';
import * as Styled from '../styled';

interface Props {
  order: Order | Partial<Order>;
}

export function XDelivererSubsection(props: Props) {
  const { t } = useTranslation('order');

  if (props.order.xDeliverer === undefined) {
    return (
      <Styled.Subsection>
        <Styled.Header>{t('labels.xDeliverer')}: </Styled.Header>
        <Styled.RecordValue>{t('list.values.none')}</Styled.RecordValue>
      </Styled.Subsection>
    );
  }

  return (
    <Styled.Subsection>
      <Styled.Header>{t('labels.xDeliverer')}:</Styled.Header>
      {props.order.xDeliverer.firstName && (
        <Styled.RecordBox>
          <Styled.RecordValue>
            {props.order.xDeliverer.firstName}
            {props.order.xDeliverer.lastName && ` ${props.order.xDeliverer.lastName}`}
          </Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.order.xDeliverer.phone && (
        <Styled.RecordBox>
          <Styled.RecordValue>{props.order.xDeliverer.phone}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.order.xDeliverer.email && (
        <Styled.RecordBox>
          <Styled.RecordValue>{props.order.xDeliverer.email}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.order.xDeliverer.car.type !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.xDelivererCar')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.order.xDeliverer.car?.type}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.order.xDeliverer.car.registrationPlate !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.xDelivererRegistrationPlate')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.order.xDeliverer.car?.registrationPlate}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.order.xDeliverer.car.vehicle !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.xDelivererVehicle')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {t(`labels.vehicle.types.${props.order.xDeliverer.car?.vehicle}`)}
          </Styled.RecordValue>
        </Styled.RecordBox>
      )}
    </Styled.Subsection>
  );
}
