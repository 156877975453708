import React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FormHelperText } from '@mui/material';
import { SelectProps, default as MuiSelect } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { FieldError } from 'react-hook-form';

interface Props
  extends Pick<SelectProps, 'children' | 'onChange' | 'value' | 'size' | 'fullWidth' | 'title'> {
  id?: string;
  label?: string;
  isMultiSelector?: boolean;
  className?: string;
  fullWidth?: boolean;
  title?: string;
  name?: string;
  defaultValue?: string;
  error?: any; // FIXME: add proper type - FieldError generated mismatch
}

function Select({
  id,
  label,
  isMultiSelector,
  className,
  fullWidth,
  title,
  error,
  ...selectProps
}: Props) {
  return (
    <FormControl variant={'outlined'} fullWidth={fullWidth} title={title} size={selectProps.size}>
      {label && <InputLabel id={`${id}-label`}>{label}</InputLabel>}
      <MuiSelect
        id={id}
        {...(label ? { label } : {})}
        variant={'outlined'}
        multiple={isMultiSelector}
        className={className}
        error={error}
        {...selectProps}
        fullWidth={fullWidth}
      />
      {error ? <FormHelperText error>{error?.message}</FormHelperText> : null}
    </FormControl>
  );
}

export const LabeledSelect = styled(Select)`
  min-width: 200px;
`;
