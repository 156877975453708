import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export type UserListFilters = {
  companyId: string | null;
  accountType: string | null;
};

export function useUserList(filters: UserListFilters) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Slice.actions.fetchUserList());
  }, [dispatch]);

  const userList = useSelector(Slice.selectors.makeSelectUserList());

  const filteredUsers = userList.data.filter((user) => {
    if (user.assignedCompanies.toAll === true) {
      return true;
    }

    if (filters.companyId !== null) {
      return user.assignedCompanies.ids.includes(filters.companyId);
    }

    if (filters.accountType !== null) {
      return user.accountType === filters.accountType;
    }

    return true;
  });

  return {
    isLoading: userList.status === AsyncStatus.Pending,
    hasError: userList.status === AsyncStatus.Error,
    data: filteredUsers,
  };
}
