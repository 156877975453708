import React from 'react';

// import { styled } from '@mui/material/styles';
import Boolean from './Boolean';
import String from './String';
import Number from './Number';
import Date from './Date';

// Props type
type Props = {
  value: boolean | string | number | Date | undefined;
  label: string;
  fallback?: boolean | string | number | Date;
  tooltip?: string;
  unit?: string;
};

// Component
const Data = ({ value, label, fallback = '-', tooltip, unit }: Props) => {
  switch (typeof value) {
    case 'boolean':
      return <Boolean value={value} label={label} tooltip={tooltip} />;
    case 'string':
      return <String value={value} label={label} tooltip={tooltip} unit={unit} />;
    case 'number':
      return <Number value={value} label={label} tooltip={tooltip} unit={unit} />;
  }

  if (value instanceof Date) {
    return <Date value={value} label={label} tooltip={tooltip} />;
  }

  if (fallback) {
    return <Data value={fallback} label={label} tooltip={tooltip} />;
  }

  return null;

  // return typeof value === 'boolean' ? (
  //   <Boolean value={value} label={label} tooltip={tooltip} />
  // ) : typeof value === 'string' ? (
  //   <String value={value} label={label} tooltip={tooltip} unit={unit} />
  // ) : typeof value === 'number' ? (
  //   <Number value={value} label={label} tooltip={tooltip} unit={unit} />
  // ) : value instanceof Date ? (
  //   <Date value={value} label={label} tooltip={tooltip} />
  // ) : fallback ? (
  //   <Data value={fallback} label={label} tooltip={tooltip} />
  // ) : null;
};

export default Data;
