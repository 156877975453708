import { useTranslation } from 'app/translations/useTranslation';
import * as Styled from '../styled';
import { useProducts } from 'services/products/state/useProducts';
import { Driver } from 'pages/DriverPage/Driver';

interface Props {
  driver: Driver;
}

export function StockSubsection(props: Props) {
  const { t } = useTranslation('driver');
  const { products } = useProducts({
    companyId: undefined,
  });
  return (
    <Styled.Subsection>
      <Styled.Header>{t('details.stock')}:</Styled.Header>
      <Styled.StockTableContainer>
        <Styled.StockTableRow>
          <Styled.StockTableBox>{t('details.stockList.name')}</Styled.StockTableBox>
          <Styled.StockTableBox>{t('details.stockList.amount')}</Styled.StockTableBox>
        </Styled.StockTableRow>
        {props.driver.stock.map((stock) => {
          const product = products.find((prod) => prod.id === stock.productId);
          return (
            <Styled.StockTableRow>
              <Styled.StockTableBox>{product?.name || ''}</Styled.StockTableBox>
              <Styled.StockTableBox>{stock.amount}</Styled.StockTableBox>
            </Styled.StockTableRow>
          );
        })}
      </Styled.StockTableContainer>
    </Styled.Subsection>
  );
}
