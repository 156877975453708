import { useTranslation } from 'app/translations/useTranslation';
// import { format } from 'date-fns';
import { Driver } from 'pages/DriverPage/Driver';
import { StockSubsection } from './components/StockSubsection';
import { AllowedCompaniesSubsection } from './components/AllowedCompaniesSubsection';
import * as Styled from './styled';
import { ActionSection } from './components/ActionSection';

interface Props {
  driver: Driver;
}

// function formatDate(timestamp?: number): string {
//   if (timestamp === undefined) {
//     return '---';
//   }
//   return format(timestamp, 'HH:mm dd.MM.RR');
// }

export function DriverRowDetails(props: Props) {
  const { t } = useTranslation('driver');
  return (
    <Styled.Box>
      <Styled.SummarySection>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('details.firstName')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.driver?.firstName ?? '-'}</Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('details.lastName')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.driver?.lastName ?? '-'}</Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('details.region')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.driver?.region ?? '-'}</Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('details.email')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.driver?.email ?? '-'}</Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('details.phone')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.driver?.phone ?? '-'}</Styled.RecordValue>
        </Styled.RecordBox>
      </Styled.SummarySection>
      <Styled.TechnicalDataSection>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('details.mobile.version')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {props.driver?.mobile?.version
              ? `${props.driver.mobile.version} (${props.driver?.mobile.osType || ''})`
              : t('details.mobile.none')}
          </Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('details.blocked')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {props.driver?.blocked ? t('common:yes') : t('common:no')}
          </Styled.RecordValue>
        </Styled.RecordBox>
        <AllowedCompaniesSubsection driver={props.driver} />
      </Styled.TechnicalDataSection>
      <Styled.StockSection>
        <StockSubsection driver={props.driver} />
      </Styled.StockSection>
      <ActionSection driver={props.driver} />
    </Styled.Box>
  );
}
