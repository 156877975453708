import React, { useEffect } from 'react';

import { FormProvider } from 'react-hook-form';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'app/translations/useTranslation';
import { PrimaryButton } from 'components/Button/Buttons';
import { Input } from 'components/ReactHookForm/Input/Input';
import { useChangePassword } from 'pages/SettingsPage/state/useChangePassword';
import { usePasswordChangeForm, ChangePasswordFormValues } from './hooks/usePasswordChangeForm';

interface Props {}

export function PasswordChanger(props: Props) {
  const { t } = useTranslation('settings');
  const { changePassword, isInProgress } = useChangePassword();
  const form = usePasswordChangeForm();
  useEffect(() => {
    if (!isInProgress) {
      form.reset();
    }
  }, [isInProgress]);
  return (
    <div>
      <FormProvider {...form}>
        <Form
          onSubmit={form.handleSubmit((formValues: ChangePasswordFormValues) => {
            changePassword({
              oldPassword: formValues.oldPassword,
              newPassword: formValues.newPassword,
            });
          })}
        >
          <Input name={'oldPassword'} label={t('fields.oldPassword')} type={'password'} />
          <Input name={'newPassword'} label={t('fields.newPassword')} type={'password'} />
          <Input
            name={'newPasswordConfirmation'}
            label={t('fields.newPasswordConfirmation')}
            type={'password'}
          />
          <SaveButton isInProgress={isInProgress} type="submit">
            {t('buttons.changePassword')}
          </SaveButton>
        </Form>
      </FormProvider>
    </div>
  );
}

const SaveButton = styled(PrimaryButton)`
  margin-top: 15px;
`;

const Form = styled('form')`
  max-width: 500px;
`;
