import React from 'react';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { useTranslation } from 'app/translations/useTranslation';
import * as Styled from '../styled';
import { Driver } from 'pages/DriverPage/Driver';
import { useCompanies } from 'services/companies/state/useCompanies';

interface Props {
  driver: Driver;
}

export function AllowedCompaniesSubsection(props: Props) {
  const { t } = useTranslation('driver');
  const { companies } = useCompanies();
  return (
    <Styled.Subsection>
      <Styled.Header>{t('details.allowedCompanies')}: </Styled.Header>
      <Styled.RecordBox>
        <Styled.Stack direction="row" spacing={1}>
          {(props.driver?.allowedCompanies ?? []).map((companyId) => {
            const company = companies.find((company) => company.id === companyId);
            return company ? (
              <Chip label={company?.name} key={companyId} variant="outlined" />
            ) : undefined;
          })}
        </Styled.Stack>
      </Styled.RecordBox>
    </Styled.Subsection>
  );
}
