import React from 'react';

import ProductFormProps from './ProductFormProps';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Input as FormInput } from 'components/ReactHookForm/Input/Input';

// Props type
type Props = ProductFormProps;

// Component
const VehicleInspection = ({ product, fieldGroupName }: Props) => {
  const { t } = useTranslation('order');

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormInput name={`${fieldGroupName}.vehicle.mark`} label={t('labels.vehicle.mark')} />
      </Grid>
      <Grid item xs={12}>
        <FormInput name={`${fieldGroupName}.vehicle.model`} label={t('labels.vehicle.model')} />
      </Grid>
      <Grid item xs={12}>
        <FormInput
          name={`${fieldGroupName}.vehicle.registrationPlate`}
          label={t('labels.vehicle.registrationPlate')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInput name={`${fieldGroupName}.vehicle.color`} label={t('labels.vehicle.color')} />
      </Grid>
      <Grid item xs={12}>
        <FormInput
          name={`${fieldGroupName}.vehicle.vinNumber`}
          label={t('labels.vehicle.vinNumber')}
        />
      </Grid>
    </Grid>
  );
};

export default VehicleInspection;
