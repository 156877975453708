import React from 'react';

import { styled } from '@mui/material/styles';
import { ReactComponent as PackageAsset } from './package.svg';

interface Props {}

export function PackageIcon(props: Props) {
  return <StyledPackageAsset />;
}

const StyledPackageAsset = styled(PackageAsset)({
  fill: 'currentColor',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fontSize: '1.5rem',
  flexShrink: 0,
});
