import { OrderLocationHistory as ApiOrderLocationHistory } from 'api/OrderApi';
import { maxBy, minBy } from 'lodash';
import { Route, Marker, MarkerType } from '../../model/Route';

class Service {
  public mapToRoute(order: ApiOrderLocationHistory): Route {
    return {
      orderId: order.id,
      markers: this.extractRouteMarkers(order),
      history: (order.driverLocationLog ?? []).map((log) => ({
        lng: log.lng,
        lat: log.lat,
      })),
    };
  }

  private extractRouteMarkers(order: ApiOrderLocationHistory): Marker[] {
    const markers: Marker[] = [];

    const startPoint = minBy(order.driverLocationLog, 'loggedAt');
    if (startPoint !== undefined) {
      markers.push({
        type: MarkerType.StartLocation,
        location: { lat: startPoint.lat, lng: startPoint.lng },
      });
    }

    const currentPoint = maxBy(order.driverLocationLog, 'loggedAt');
    if (currentPoint !== undefined) {
      markers.push({
        type: MarkerType.LastLocation,
        location: { lat: currentPoint.lat, lng: currentPoint.lng },
      });
    }

    const pickupPoint = order.pickupPoint;
    if (pickupPoint !== undefined) {
      markers.push({
        type: MarkerType.PickupPoint,
        location: { lat: pickupPoint.address.lat, lng: pickupPoint.address.lng },
      });
    }

    markers.push({
      type: MarkerType.Destination,
      location: { lat: order.clientAddress.lat, lng: order.clientAddress.lng },
    });

    return markers;
  }
}

export const ApiOrderLocationHistoryMapper = new Service();
