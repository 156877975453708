import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { last } from 'lodash';
import { DriverStatus } from 'pages/DriverPage/Driver';
import { SelectFilter as Styled } from './styled';

interface Props {
  selectedStatuses: string[];
  onStatusSelect: (newStatuses: string[]) => void;
}

const ALL_VALUE = 'all';

export function StatusSelect(props: Props) {
  const { t } = useTranslation('driver');
  const availableStatuses = [DriverStatus.Online, DriverStatus.Offline];

  return (
    <Styled.Box>
      <LabeledSelect
        id={'select-status'}
        onChange={MuiHandlers.Select.onChange<string[]>((statuses) => {
          if (statuses.length === 0 || last(statuses) === ALL_VALUE) {
            props.onStatusSelect([]);
          } else if (statuses.includes(ALL_VALUE)) {
            const statusesWithoutAll = statuses.filter((status) => status !== ALL_VALUE);
            props.onStatusSelect(statusesWithoutAll);
          } else {
            props.onStatusSelect(statuses);
          }
        })}
        value={props.selectedStatuses.length === 0 ? [ALL_VALUE] : props.selectedStatuses}
        label={t('labels.status')}
        isMultiSelector={true}
        size="small"
      >
        <MenuItem value={ALL_VALUE} key={'all'}>
          {t('status.all')}
        </MenuItem>
        {availableStatuses.map((status) => (
          <MenuItem value={status} key={status}>
            {t(`status.${status}`)}
          </MenuItem>
        ))}
      </LabeledSelect>
    </Styled.Box>
  );
}
