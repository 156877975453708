import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import Grid from '@mui/material/Grid';

import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { FormProvider, SubmitHandler } from 'react-hook-form';
import * as Styled from './styled';
import { Input } from '../../../../../components/ReactHookForm/Input/Input';
import { Divider as MuiDivider } from '@mui/material';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import {
  ActivateCompanyFormValues,
  useActivateCompanyForm,
  useActivateCompanyFormReset,
} from './hooks/useActivateCompanyForm';
import { Company } from '../../../../../model/Company';
import { useActivateCompany } from '../../../state/useActivateCompany';

interface Props {
  company: Company;
}

export function ActivateCompanyForm({ company }: Props) {
  const { t } = useTranslation('company');
  const { activateCompany, isInProgress } = useActivateCompany();

  const form = useActivateCompanyForm();
  const reset = useActivateCompanyFormReset(form);
  const onSubmit: SubmitHandler<ActivateCompanyFormValues> = useCallback(
    (formValues) => {
      try {
        activateCompany({
          name: formValues.name,
          companyId: company.id,
        });
        resetAndClose();
      } catch (e) {
        console.log(e);
      }
    },
    [company],
  );

  const resetAndClose = () => {
    reset();
  };
  return (
    <FormProvider {...form}>
      <Form
        onSubmit={form.handleSubmit<ActivateCompanyFormValues>(onSubmit)}
        onReset={resetAndClose}
      >
        <DialogContent>
          <Divider />
          <Grid container item lg={12}>
            <Input
              name={'name'}
              label={t('activationSettings.form.fields.name.label')}
              placeholder={t('activationSettings.form.fields.name.placeholder')}
              dataCy={'input-name'}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isInProgress}
            loading={isInProgress}
          >
            {t('activationSettings.form.submitButton')}
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormProvider>
  );
}
const Form = styled('form')`
  display: flex;
  flex-direction: column;
`;
const ModalContentBox = styled('div')`
  height: 80%;
`;

const DialogActions = styled(MuiDialogActions)`
  padding: 20px;
`;

const Dialog = styled(MuiDialog)`
  padding: 20px;
`;

const Divider = styled(MuiDivider)`
  padding-top: 20px;
  margin-bottom: 20px;
`;

const RecordLabel = styled('p')(
  ({ theme }) => `
  font-weight: 'bold';
  `,
);
const RecordValue = styled('p')(
  ({ theme }) => `
  
  `,
);

const LoadingButton = styled(MuiLoadingButton)(
  ({ theme }) => `
    background: ${theme.colors.experimental.success};
    color: ${theme.colors.experimental.buttonText.success};
    &:hover {
      background: ${theme.colors.experimental.hover.success};
    }
  `,
);

const GridItem = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(6)};
  background: ${theme.colors.primary.background};
  border-radius: 5px;
`,
);
