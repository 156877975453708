import React from 'react';

// import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import PickupPointForm from './components/PickupPointForm';
import DestinationForm from './components/DestinationForm';
import { useOrderProduct } from '../../../hooks/useOrderProduct';

// Props type
type Props = {};

// Component
const PickupDelivery = (props: Props) => {
  const product = useOrderProduct();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <PickupPointForm
          showLocationsSelect={!product?.productSettings?.showLocationsSelectForReceiverOnOrderForm}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DestinationForm
          showLocationsSelect={product?.productSettings?.showLocationsSelectForReceiverOnOrderForm}
        />
      </Grid>
    </Grid>
  );
};

export default PickupDelivery;
