import { useTranslation } from 'app/translations/useTranslation';
import { format } from 'date-fns';
import { Order } from 'pages/Orders/model/Order';
import { ActionSection } from './components/ActionSection';
import { EstimationsSubsection } from './components/EstimationsSubsection';
import { MiscDataSubsection } from './components/MiscDataSubsection';
import { PickupPointSubsection } from './components/PickupPointSubsection';
import { ReceiverSubsection } from './components/ReceiverSubsection';
import { RestSubsection } from './components/RestSubsection';
import { XDelivererSubsection } from './components/XDelivererSubsection';
import * as Styled from './styled';
import { TimestampsSubsection } from './components/TimestampsSubsection';

interface Props {
  order: Order;
}

function formatDate(timestamp?: number): string {
  if (timestamp === undefined) {
    return '---';
  }
  return format(timestamp, 'HH:mm dd.MM.RR');
}

export function OrderRowDetails(props: Props) {
  const { t } = useTranslation('order');
  return (
    <Styled.Box>
      <Styled.SummarySection>
        <Styled.Header>
          {t('labels.orderNo')}: {props.order.no}
        </Styled.Header>
        {props.order.onHold.currentlyOnHold && (
          <>
            <Styled.HeaderOnHold>{t('labels.onHold')}</Styled.HeaderOnHold>
            <Styled.RecordBox>
              <Styled.RecordLabel>{t('labels.onHoldFrom')}:</Styled.RecordLabel>
              <Styled.RecordValue>
                {props.order.onHold.heldAt ? formatDate(props.order.onHold.heldAt) : '-'}{' '}
                {t('labels.onHoldToJoin')}{' '}
                {props.order.onHold.holdUntil ? formatDate(props.order.onHold.holdUntil) : '-'}
              </Styled.RecordValue>
            </Styled.RecordBox>
            <Styled.RecordBox>
              <Styled.RecordLabel>{t('labels.onHoldDriversNote')}:</Styled.RecordLabel>
              <Styled.RecordValue>
                {props.order.onHold.driversNote ?? t('list.values.none')}
              </Styled.RecordValue>
            </Styled.RecordBox>
          </>
        )}
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.externalId')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.order.externalId ?? t('list.values.none')}</Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.status')}:</Styled.RecordLabel>
          <Styled.RecordValue>{t(`status.${props.order.stage.status}`)}</Styled.RecordValue>
          {props.order.stage.statusReason !== undefined && (
            <Styled.RecordBlockValue>
              {t(`statusReason.ids.${props.order.stage?.statusReason?.id}`)}
            </Styled.RecordBlockValue>
          )}
        </Styled.RecordBox>
        <TimestampsSubsection order={props.order} />
      </Styled.SummarySection>
      <Styled.AddressSection>
        {props.order.pickupPoint && <PickupPointSubsection pickupPoint={props.order.pickupPoint} />}
        <ReceiverSubsection order={props.order} />
        <XDelivererSubsection order={props.order} />
      </Styled.AddressSection>
      <Styled.RestSection>
        {props.order.estimations && (
          <EstimationsSubsection
            estimations={props.order.estimations}
            multiplier={props.order.multiplier}
          />
        )}
        <RestSubsection order={props.order} />
      </Styled.RestSection>
      <Styled.MiscDataSection>
        <MiscDataSubsection miscData={props.order.misc} />
      </Styled.MiscDataSection>
      <ActionSection order={props.order} />
    </Styled.Box>
  );
}
