import * as ReactNotifications from 'react-notifications-component';
import { ToastProps as ToastComponentProps, createToast } from './Toast';
import { ToastType } from './ToastType';

const toastConfig: ReactNotifications.ReactNotificationOptions = {
  container: 'top-right',
  animationIn: ['animation-in'],
  animationOut: ['animation-out'],
  width: 300,
  slidingEnter: {
    duration: 100,
  },
  slidingExit: {
    duration: 100,
  },
};

type ToastParams = Pick<ToastComponentProps, 'id' | 'title' | 'message'>;

function simpleToast(type: ToastType) {
  return (params: ToastParams) =>
    ReactNotifications.store.addNotification({
      ...toastConfig,
      content: createToast({ ...params, type }),
      dismiss: {
        duration: 5000,
      },
    });
}

export const addSuccessToast = simpleToast(ToastType.Success);
export const addErrorToast = simpleToast(ToastType.Error);
export const addWarningToast = simpleToast(ToastType.Warning);
export const addInfoToast = simpleToast(ToastType.Info);
