import React, { useEffect } from 'react';

import { FormProvider } from 'react-hook-form';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'app/translations/useTranslation';
import { PrimaryButton } from 'components/Button/Buttons';
import { Input } from 'components/ReactHookForm/Input/Input';
import { useChangePassword } from 'pages/SettingsPage/state/useChangePassword';
import { useAddLocationForm, AddLocationFormValues } from './hooks/useAddLocationForm';
import TrashIcon from '@mui/icons-material/DeleteForever';
import { Grid, ListItem } from '@mui/material';
import { LocationListItem } from './LocationListItem';
import { Location } from '../../../../model/Location';
import { TransitionGroup } from 'react-transition-group';
import Collapse from '@mui/material/Collapse';

interface Props {
  locations?: Partial<Location>[];
  onDelete?: (locationIndex: number) => void;
}

export function LocationList({ locations, onDelete }: Props) {
  const { t } = useTranslation(['settings', 'validationErrors']);

  return (
    <LocationListContainer container>
      {(locations ?? []).map((location, index) => {
        return (
          <Collapse>
            <LocationListMainContainer item container>
              <LocationListItemContainer item xs={onDelete ? 6 : 12} md={onDelete ? 9 : 12}>
                <LocationListItem location={location} />
              </LocationListItemContainer>
              {onDelete && (
                <LocationListActionContainer item xs={6} md={3}>
                  <PrimaryButton
                    loadingPosition="start"
                    onClick={() => {
                      onDelete(index);
                    }}
                    color="secondary"
                    startIcon={<TrashIcon />}
                    variant="text"
                  >
                    {t('locations.deleteLocationButton')}
                  </PrimaryButton>
                </LocationListActionContainer>
              )}
            </LocationListMainContainer>
          </Collapse>
        );
      })}
    </LocationListContainer>
  );
}

const LocationListContainer = styled(TransitionGroup)`
  margin-top: 15px;
`;

const LocationListMainContainer = styled(Grid)`
  align-items: center;
`;

const LocationListItemContainer = styled(Grid)``;

const LocationListActionContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(PrimaryButton)``;

const Form = styled('form')`
  max-width: 500px;
`;
