import Leaflet from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { styled } from '@mui/material/styles';
import { MapMarker } from 'assets/MapMarker/MapMarker';

export const createLeafletMapMarker = (params: { hueRotate: number } = { hueRotate: 0 }) => {
  return new Leaflet.DivIcon({
    html: ReactDOMServer.renderToString(<MarkerIcon hueRotate={params.hueRotate} />),
    iconSize: new Leaflet.Point(28, 80),
    className: '',
  });
};

const MarkerIcon = styled(MapMarker)<{ hueRotate: number }>`
  filter: ${(props) => `hue-rotate(${props.hueRotate}deg)`};
  width: 28px;
  height: 41px;
`;
