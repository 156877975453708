import React, { useMemo } from 'react';

import Grid from '@mui/material/Grid';
import ProductFormProps from '../ProductFormProps';
import ReceiptNoField from './ReceiptNoField';
import PeselField from './PeselField';
import AmountToPay from './AmountToPay';
import Medicines from './Medicines';
import Dimensions from './Dimensions';
import ExternalNoField from './ExternalNo';

// Props type
type Props = ProductFormProps;

// Component
const PickupDelivery = ({ product, fieldGroupName }: Props) => {
  const availableFields = useMemo(
    () => ({
      externalNo: product.productSettings?.productFormFields?.includes('externalNo'),
      receiptNo: product.productSettings?.productFormFields?.includes('receiptNo'),
      pesel: product.productSettings?.productFormFields?.includes('pesel'),
      amountToPayOnPickUp:
        product.productSettings?.productFormFields?.includes('amountToPayOnPickUp'),
      medicines: product.productSettings?.productFormFields?.includes('medicines'),
      dimensions: product.code !== 'katzman_package',
    }),
    [product],
  );

  return (
    <Grid container spacing={0.5}>
      {availableFields.externalNo && (
        <Grid item xs={12}>
          <ExternalNoField fieldGroupName={fieldGroupName} />
        </Grid>
      )}

      {availableFields.receiptNo && (
        <Grid item xs={12}>
          <ReceiptNoField fieldGroupName={fieldGroupName} />
        </Grid>
      )}
      {availableFields.pesel && (
        <Grid item xs={12}>
          <PeselField fieldGroupName={fieldGroupName} />
        </Grid>
      )}
      {availableFields.amountToPayOnPickUp && (
        <Grid item xs={12}>
          <AmountToPay fieldGroupName={fieldGroupName} />
        </Grid>
      )}

      {Dimensions.useWillRender(product) && (
        <Dimensions fieldGroupName={`${fieldGroupName}.dimensions`} product={product} />
      )}

      {availableFields.medicines && (
        <Grid item xs={12}>
          <Medicines fieldGroupName={fieldGroupName} />
        </Grid>
      )}
    </Grid>
  );
};

export default PickupDelivery;
