import { ApiRequestSaga } from './Request/ApiRequestSaga';
import { Product } from 'model/Product';

export enum ProductCode {
  VehicleInspection = 'vehicle_inspection',
}

interface ListProductsResponse {
  products: Product[];
}

export const ProductApiSaga = {
  listProducts: () => {
    return ApiRequestSaga.Get<ListProductsResponse>({
      endpoint: '/product/list',
    });
  },
};
