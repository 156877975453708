import { ApiRequestSaga } from './Request/ApiRequestSaga';
import { PackageSize } from '../pages/Orders/model/Order';
import { Multiplier } from './MultiplierApi';

export enum OrderType {
  Iqos = 'iqos',
  PickupDelivery = 'pickup_delivery',
  SimpleDelivery = 'simple_delivery',
  SimpleArrival = 'simple_arrival',
}

export enum OrderStatus {
  New = 'new',
  Accepted = 'accepted',
  PickedUp = 'picked_up',
  Arrived = 'arrived',
  Delivered = 'delivered',
  Cancelled = 'cancelled',
  Finished = 'finished',
  Scheduled = 'scheduled',
}

export enum CancellationReason {
  ClientDidntShowUp = 'CLIENT_DIDNT_SHOW_UP',
  ClientsRequest = 'CLIENTS_REQUEST',
  DriversFailure = 'DRIVERS_FAILURE',
  WrongAddress = 'WRONG_ADDRESS',
  CallCenterRequest = 'CALL_CENTER_REQUEST',
  OrderNotAcceptedIn5Min = 'ORDER_NOT_ACCEPTED_IN_5_MIN',
  OrderNotAcceptedInXMin = 'ORDER_NOT_ACCEPTED_IN_X_MIN',
  ProductCodesDidntWork = 'PRODUCT_CODES_DIDNT_WORK',
  ClientIsUnder13 = 'CLIENT_IS_UNDER_13',
  ClientWithoutId = 'CLIENT_WITHOUT_ID',
  ClientNotInPerson = 'CLIENT_NOT_IN_PERSON',
  ClientWithIncorrectData = 'CLIENT_WITH_INCORRECT_DATA',
  NoStockInStore = 'NO_STOCK_IN_STORE',
  DamagedProduct = 'DAMAGED_PRODUCT',
  Other = 'OTHER',
}

export interface PaymentDetails {
  paymentType: PaymentType;
  paymentCode?: string;
  limit?: number;
  viewed?: PaymentViewed[];
}

export interface PaymentViewed {
  driverId: string;
  viewedAt: string;
}

export enum PaymentType {
  GenericPrepaid = 'generic_prepaid',
  EprufCode = 'epruf_code',
  BlikManual = 'blik_manual',
}

export interface AdditionalServices {
  doorToDoor?: boolean;
  doorPickUp?: boolean;
  doorDelivery?: boolean;
}

export function isDeviceProduct(product: any): product is DeviceProduct {
  return product.id !== undefined && product.oldDeviceCode !== undefined;
}

export interface Dimensions {
  weight?: number; // kg
  length?: number; // cm
  width?: number; // cm
  height?: number; // cm
}

interface BasicProductData {
  id: string;
  name: string;
  code: string;
  dimensions?: Dimensions;
}

export interface DeviceProduct extends BasicProductData {
  smsName?: string;
  oldDeviceCode: string;
}

interface PackageProduct extends BasicProductData, PharmacyProduct {
  pesel?: string;
  receiptNo?: string;
  amountToPayOnPickUp?: number;
  externalNo?: string;
}

interface PharmacyProduct {
  medicines?: { details?: string; pickedUp?: boolean };
}

export interface VehicleData {
  model?: string;
  mark?: string;
  registrationPlate?: string;
  color?: string;
  vinNumber?: string;
}

interface VehicleInspectionProduct extends BasicProductData {
  vehicle?: VehicleData;
}

type Product = DeviceProduct | PackageProduct | VehicleInspectionProduct;

interface DeliveredProduct {
  name: string;
  productId: string;
  productCode: string;
  serialNumber: string;
}

export interface NewOrder {
  companyId: string;
  products: Array<{
    id: string;
    dimensions?: {
      weight?: number;
      width?: number;
      height?: number;
      length?: number;
    };
    vehicle?: {
      mark?: string;
      brand?: string;
      color?: string;
      registrationPlate?: string;
      vinNumber?: string;
    };
    receiptNo?: string;
    pesel?: string;
    amountToPayOnPickUp?: number;
    medicines?: {
      details?: string;
    }[];
  }>;

  clientName: string;
  clientStreet: string;
  clientCity: string;
  clientZipCode: string;
  clientPhone: string;
  notes?: string;
  pickupPoint?: {
    name: string;
    street?: string;
    city: string;
    zipCode: string;
    phone: string;
    notes?: string;
    locationId?: string;
    orderDetails?: {
      discounts?: string;
    };
  };

  // Additional fields
  externalId?: string;
  pmiOrderCode?: string;
  pmiOrderCode2?: string;
  swapType?: string;
  additionalServices?: {
    doorPickUp?: boolean;
    doorDelivery?: boolean;
    doorToDoor?: boolean;
  };

  scheduledAt?: string;
  scheduled?: boolean;
}

export interface Order {
  id: string;
  no: string;
  companyId: string;
  type: OrderType;
  externalId?: string;
  clientName: string;
  clientPhone: string;
  clientStreet: string;
  clientCity: string;
  clientZipCode: string;
  notes?: string;
  status: OrderStatus;
  region: string;
  createdAt: string;
  plannedAt?: string;
  acceptedAt?: string;
  deliveredAt?: string;
  driverArrivedAt?: string;
  pickedUpAt?: string;
  scheduledAt?: string;
  scheduledSlotRange?: [string, string];
  cancelledAt?: string;
  cancellationReason?: CancellationReason;
  pickupPoint?: {
    name: string;
    city: string;
    street: string;
    zipCode: string;
    phone: string;
    notes?: string;
    paymentDetails?: PaymentDetails;
    orderDetails?: {
      discounts?: string;
    };
    workingHours?: PickupPointWorkingHours;
  };
  packageSize: PackageSize;
  additionalServices?: AdditionalServices;
  driverFirstName?: string;
  driverName?: string;
  driverLastName?: string;
  driverPhone?: string;
  driverCar?: string;
  driverVehicleType?: string;
  driverRegistrationPlate?: string;
  driverEmail?: string;
  products: Product[];
  deliveredProducts?: DeliveredProduct[];
  hashedSensitiveData?: {
    pickupPointData?: string[];
    clientData?: string[];
    paymentDetails?: string[];
  };
  pickUpNotes?: string;
  deliveryComment?: string;
  cancellationComment?: string;
  verificationCode?: string;
  noVerificationCodeReason?: string;
  estimatedDistance?: number; // In meters, eg. 9860
  estimatedPayout?: number; // In PLN, eg. 23.43
  estimatedPayoutDriver?: number;
  currency: string;
  vatRate?: number;
  onHold?: boolean;
  onHoldUntil?: Date;
  onHoldHistory?: OnHoldHistory[];
  emergencyDelivery?: boolean;
  comments?: OrderComments[];
  payable?: boolean; // set when order is cancelled and we want to force not paying for order
  orderSettings: OrderSettings;
  renewals: Renewal[];
  multiplier?: Multiplier;
}

export interface Renewal {
  id: string;
  renewedBy: string;
  renewedAt: string;
  renewalReason: any;
  renewalComment: string;
  cancellationReason?: CancellationReason;
  oldOrderData: Partial<Order>;
}

export interface OrderSettings {
  // TODO: Add other options, added options only that are used
  operatorCancellationReasons?: CancellationReasonsMap;
}
export type CancellationReasonsMap = {
  [key: string]: CancellationReasonProperties;
};

export interface CancellationReasonProperties {
  clientVerified?: boolean; // show only after contact with client/client verified
  inDelivery?: boolean;
  toBePickedUp?: boolean;
}

export interface WorkingHours {
  from: { hour: number; minute: number };
  to: { hour: number; minute: number };
}

export interface PickupPointWorkingHours {
  [dateString: string]: WorkingHours | null;
}

interface OrderComments {
  createdBy: string;
  comment: string;
  createdAt: Date;
}

interface OnHoldHistory {
  driverId?: string;
  operatorId?: string;
  createdAt: Date;
  holdUntil?: Date;
  notes?: string;
  releasedAt?: Date;
  releasedById?: string;
  releasedByOperator?: boolean;
  releasedByDriver?: boolean;
}

interface ListOrdersResponse {
  totalCount: number;
  orders: Order[];
}

interface ListOrdersParams {
  id?: string;
  globalIdSearch?: string;
  status?: string;
  dateFrom: Date;
  dateTo: Date;
  companyIds: string[];
  region?: string;
  productId?: string;
}

interface CancelOrderParams {
  orderId: string;
  comment?: string;
  cancellationReason: string;
  forceCancel?: boolean;
  renewOrder?: boolean;
  createReturn?: boolean;
  payable?: boolean;
}

interface ChangeOrderDriverParams {
  orderId: string;
  driverId: string;
  comment?: string;
}

interface SetOnHoldOrderParams {
  orderId: string;
  notes?: string;
  holdOrderTill?: Date;
}

interface AddCommentParams {
  orderId: string;
  comment: string;
}

interface ReleaseOnHoldOrderParams {
  orderId: string;
}

export interface DeliveryAvailabilityParams {
  companyId: string;
  senderLocation: {
    address: {
      street: string;
      city: string;
      zipCode: string;
    };
  };
  receiverLocation: {
    address: {
      street: string;
      city: string;
      zipCode: string;
    };
  };
  packageSize?: string;
}

export interface DeliveryAvailability {
  available: boolean;
  price?: number;
  region?: string;
  estimatedDistance?: number;
}

export interface OrderLocationHistory {
  id: string;
  pickupPoint?: {
    address: {
      lat: number;
      lng: number;
    };
  };
  clientAddress: {
    lat: number;
    lng: number;
  };
  driverLocationLog?: {
    lat: number;
    lng: number;
    loggedAt: string;
  }[];
}

function orderCreate(newOrderData: NewOrder) {
  return ApiRequestSaga.Post({ endpoint: '/order/create', data: { ...newOrderData } });
}

export const OrderApiSaga = {
  listOrders: (params: ListOrdersParams) => {
    return ApiRequestSaga.Get<ListOrdersResponse>({
      endpoint: '/order/list',
      query: {
        ...params,
        dateFrom: params.dateFrom.toISOString(),
        dateTo: params.dateTo.toISOString(),
      },
    });
  },
  getOrderLabel(orderId: string) {
    return ApiRequestSaga.GetFile({
      endpoint: `/order/labels/${orderId}`,
    });
  },
  cancelOrder(params: CancelOrderParams) {
    return ApiRequestSaga.Post<{
      orderId: string;
      comment?: string;
      cancellationReason: string;
      forceCancel?: boolean;
      renewOrder?: boolean;
      createReturn?: boolean;
      payable?: boolean;
    }>({
      endpoint: '/order/cancel',
      data: {
        orderId: params.orderId,
        reason: params.cancellationReason,
        ...(params.comment && { comment: params.comment }),
        ...(params.forceCancel && { forceCancel: params.forceCancel }),
        ...(params.renewOrder !== undefined && { renewOrder: params.renewOrder }),
        ...(params.createReturn !== undefined && { createReturn: params.createReturn }),
        ...(params.payable !== undefined && { payable: params.payable }),
      },
    });
  },
  changeOrderDriver(params: ChangeOrderDriverParams) {
    return ApiRequestSaga.Post<{
      orderId: string;
      driverId: string;
      comment?: string;
    }>({
      endpoint: '/order/change-driver',
      data: {
        orderId: params.orderId,
        driverId: params.driverId,
        ...(params.comment && { comment: params.comment }),
      },
    });
  },
  setOnHoldOrder(params: SetOnHoldOrderParams) {
    return ApiRequestSaga.Post<{
      orderId: string;
      notes?: string;
      holdOrderTill?: Date;
    }>({
      endpoint: '/order/set-on-hold',
      data: {
        orderId: params.orderId,
        ...(params.notes && { notes: params.notes }),
        ...(params.holdOrderTill && { holdOrderTill: params.holdOrderTill }),
      },
    });
  },
  releaseOnHoldOrder(params: ReleaseOnHoldOrderParams) {
    return ApiRequestSaga.Post<{
      orderId: string;
    }>({
      endpoint: '/order/release-on-hold',
      data: {
        orderId: params.orderId,
      },
    });
  },
  addComment(params: AddCommentParams) {
    return ApiRequestSaga.Post<{
      orderId: string;
      comment: string;
    }>({
      endpoint: '/order/add-comment',
      data: {
        orderId: params.orderId,
        comment: params.comment,
      },
    });
  },
  *checkDeliveryAvailability(params: DeliveryAvailabilityParams) {
    return yield* ApiRequestSaga.Post<DeliveryAvailability>({
      endpoint: '/order/check-delivery-availability',
      data: {
        ...params,
      },
    });
  },
  *fetchOrderLocationHistory(orderId: string) {
    const response = yield* ApiRequestSaga.Get<{ order: OrderLocationHistory }>({
      endpoint: '/order/location-history',
      query: {
        orderId,
        locationHistory: true,
      },
    });

    return response.order;
  },
  orderCreate,
};
