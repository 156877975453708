import React from 'react';

import { FormProvider } from 'react-hook-form';
import OrderCreateFormContent from './OrderCreateFormContent';
import { useOrderCreateForm } from '../../hooks/useOrderCreateForm';

// Props type
type Props = {};

// Component
const OrderCreateForm = (props: Props) => {
  const form = useOrderCreateForm();

  return (
    <FormProvider {...form}>
      <OrderCreateFormContent />
    </FormProvider>
  );
};

export default OrderCreateForm;
