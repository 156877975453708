import FileSaver from 'file-saver';
import { takeLatest } from 'redux-saga/effects';
import { put, call } from 'typed-redux-saga';
import { PA } from 'utlis/State';
import { ReportApiSaga } from 'api/ReportApi';
import { translate } from 'app/translations/useTranslation';
import { addErrorToast } from 'services/toast';
import * as Slice from './slice';

function* generateXDelivererActivityReport({
  payload,
}: PA<Slice.ActionTypes.GenerateXDelivererActivityReport>) {
  try {
    const file = yield* ReportApiSaga.downloadDriverActivityReport(payload);
    FileSaver.saveAs(file, 'xdeliverer_activity_report.csv');
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('report', 'notifications.reportGeneratingFailure'),
    });
  }
  yield put(Slice.actions.generateXDelivererActivityFinished());
}

function* generateXDelivererAvailabilityReport({
  payload,
}: PA<Slice.ActionTypes.GenerateXDelivererAvailabilityReport>) {
  try {
    const file = yield* ReportApiSaga.downloadDriverAvailabilityReport(payload);
    FileSaver.saveAs(file, 'xdeliverer_availability_report.csv');
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('report', 'notifications.reportGeneratingFailure'),
    });
  }
  yield put(Slice.actions.generateXDelivererAvailabilityReportFinished());
}

function* generateOrderOffersReport({ payload }: PA<Slice.ActionTypes.GenerateOrderOffersReport>) {
  try {
    const file = yield* ReportApiSaga.downloadOrderOffersReport(payload);
    FileSaver.saveAs(file, 'order_offers.csv');
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('report', 'notifications.reportGeneratingFailure'),
    });
  }
  yield put(Slice.actions.generateOrderOffersFinished());
}

function* generateOrdersFullReport({ payload }: PA<Slice.ActionTypes.GenerateOrdersFullReport>) {
  try {
    const file = yield* ReportApiSaga.downloadOrdersFullReport(payload);
    FileSaver.saveAs(file, 'orders.csv');
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('report', 'notifications.reportGeneratingFailure'),
    });
  }
  yield put(Slice.actions.generateOrderOffersFinished());
}

function* generateDriversStockReport({
  payload,
}: PA<Slice.ActionTypes.GenerateDriversStockReport>) {
  try {
    const file = yield* ReportApiSaga.downloadDriversStockReport(payload);
    FileSaver.saveAs(file, 'drivers_stock.csv');
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('report', 'notifications.reportGeneratingFailure'),
    });
  }
  yield put(Slice.actions.generateOrderOffersFinished());
}

function* generateCorrectionsReport({ payload }: PA<Slice.ActionTypes.GenerateCorrectionsReport>) {
  try {
    const file = yield* ReportApiSaga.downloadCorrectionsReport(payload);
    FileSaver.saveAs(file, 'corrections.csv');
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('report', 'notifications.reportGeneratingFailure'),
    });
  }
  yield put(Slice.actions.generateCorrectionsFinished());
}

function* generateDriversFeedbackReport({
  payload,
}: PA<Slice.ActionTypes.GenerateDriversFeedbackReport>) {
  try {
    const file = yield* ReportApiSaga.downloadDriversFeedbackReport(payload);
    FileSaver.saveAs(file, 'feedback.csv');
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('report', 'notifications.reportGeneratingFailure'),
    });
  }
  yield put(Slice.actions.generateDriversFeedbackFinished());
}

function* generateAuditStockMovementReport({
  payload,
}: PA<Slice.ActionTypes.GenerateAuditStockMovementReport>) {
  try {
    const file = yield* ReportApiSaga.downloadAuditStockMovementReport(payload);
    FileSaver.saveAs(file, 'stock_movement.csv');
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('report', 'notifications.reportGeneratingFailure'),
    });
  }
  yield put(Slice.actions.generateAuditStockMovementFinished());
}

function* generateStockExchangeReport({
  payload,
}: PA<Slice.ActionTypes.GenerateStockExchangeReport>) {
  try {
    const file = yield* ReportApiSaga.downloadStockExchangeReport(payload);
    FileSaver.saveAs(file, 'stock_exchange.csv');
  } catch (e) {
    console.error(e);
    yield call(addErrorToast, {
      message: translate('report', 'notifications.reportGeneratingFailure'),
    });
  }
  yield put(Slice.actions.generateStockExchangeReportFinished());
}

export function* saga() {
  yield takeLatest(
    Slice.actions.generateXDelivererActivityReport,
    generateXDelivererActivityReport,
  );
  yield takeLatest(
    Slice.actions.generateXDelivererAvailabilityReport,
    generateXDelivererAvailabilityReport,
  );
  yield takeLatest(Slice.actions.generateOrderOffersReport, generateOrderOffersReport);
  yield takeLatest(Slice.actions.generateOrdersFullReport, generateOrdersFullReport);
  yield takeLatest(Slice.actions.generateDriversStockReport, generateDriversStockReport);
  yield takeLatest(Slice.actions.generateCorrectionsReport, generateCorrectionsReport);
  yield takeLatest(Slice.actions.generateDriversFeedbackReport, generateDriversFeedbackReport);
  yield takeLatest(
    Slice.actions.generateAuditStockMovementReport,
    generateAuditStockMovementReport,
  );
  yield takeLatest(Slice.actions.generateStockExchangeReport, generateStockExchangeReport);
}
