import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { DriverStatus } from '../../../../../../../../../Driver';

export type CreateFormValues = {
  shortTitle: string;
  shortBody: string;
  longTitle?: string;
  regions: string[];
  status: DriverStatus | 'all';
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object({
      shortTitle: yup.string().required().min(5),
      shortBody: yup.string().required().min(10),
      longTitle: yup.string().optional().min(5),
      status: yup.string().optional(),
      regions: yup.array().min(1, t('required')).required(),
    });
  }, [t]);
}

export function useSendPushNotificationForm() {
  const validationSchema = useValidationSchema();
  const form = useForm<CreateFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  return form;
}
