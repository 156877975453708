import { call, put } from 'redux-saga/effects';
import * as Auth from 'services/authorization';
import { translate } from '../../app/translations/useTranslation';
import { getRequest, postRequest, patchRequest, putRequest, deleteRequest, GetRequestParams, DeleteRequestParams, PostRequestParams, PatchRequestParams, PutRequestParams, GetFileRequestParams, getFileRequest } from './ApiRequest';
import { HttpError } from './RequestErrors';

export type SimpleGenerator<ReturnType> = Generator<any, ReturnType, any>;

export namespace ApiRequestSaga {
  export function* Get<T>(params: GetRequestParams): SimpleGenerator<T> {
    return yield request(() => getRequest(params));
  }

  export function* GetFile(params: GetFileRequestParams): SimpleGenerator<Blob> {
    return yield request(() => getFileRequest(params));
  }

  export function* Post<T>(params: PostRequestParams): SimpleGenerator<T> {
    return yield request(() => postRequest(params));
  }

  export function* Patch<T>(params: PatchRequestParams): SimpleGenerator<T> {
    return yield request(() => patchRequest(params));
  }

  export function* Put<T>(params: PutRequestParams): SimpleGenerator<T> {
    return yield request(() => putRequest(params));
  }

  export function* Delete<T>(params: DeleteRequestParams): SimpleGenerator<T> {
    return yield request(() => deleteRequest(params));
  }

  function* request<T>(callback: () => Promise<any>): SimpleGenerator<T> {
    try {
      return yield call(callback);
    } catch (e) {
      if (e instanceof HttpError && e.status === 401) {
        yield put(Auth.actions.logOut({
          message: { content: translate('auth', 'notifications.restrictedArea') },
        }));
      }
      throw e;
    }
  }
}
