import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'app/translations/useTranslation';
import { MuiHandlers } from 'externals/MaterialUI/Handlers';
import { LabeledSelect } from 'externals/MaterialUI/LabeledSelect';
import { useProducts } from 'services/products/state/useProducts';
import { SelectFilter as Styled } from './styled';

interface Props {
  companyId: string | null;
  selectedProduct: string | null;
  onProductChange: (product: string | null) => void;
}

const notSelectedValue = 'notSelectedValue';

export function ProductSelect(props: Props) {
  const { products } = useProducts({ companyId: props.companyId ?? undefined });
  const { t } = useTranslation('order');

  return (
    <Styled.Box>
      <LabeledSelect
        id={'select-region'}
        onChange={MuiHandlers.Select.onChange((selectedValue) => {
          props.onProductChange(selectedValue === notSelectedValue ? null : selectedValue);
        })}
        label={t('labels.product')}
        value={props.selectedProduct ?? notSelectedValue}
        size="small"
      >
        <MenuItem value={notSelectedValue} key={notSelectedValue}>
          {'---'}
        </MenuItem>
        {products.map((product) => (
          <MenuItem value={product.id} key={product.id}>
            {product.name}
          </MenuItem>
        ))}
      </LabeledSelect>
    </Styled.Box>
  );
}
