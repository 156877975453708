import {
  Order as ApiOrder,
  OrderSettings as ApiOrderSettings,
  OrderType,
  isDeviceProduct,
  DeviceProduct,
  OrderStatus,
  CancellationReason,
  PaymentViewed as PaymentViewedApi,
  PaymentDetails as PaymentDetailsApi,
} from 'api/OrderApi';
import { Multiplier as ApiMultiplier, MultiplierCreatorType } from 'api/MultiplierApi';
import { Multiplier } from '../../../MultiplierPage/model/Multiplier';
import { differenceInMinutes } from 'date-fns';
import { last, orderBy } from 'lodash';
import InvalidArgumentException from 'utlis/exceptions/InvalidArgumentException';
import {
  XDeliverer,
  MiscData,
  MiscDataType,
  Order,
  Receiver,
  OrderStage,
  MiscDataBase,
  OrderPickupPoint,
  StatusReasonGroup,
  OnHold,
  PackageProduct,
  PaymentDetails,
  Comments,
  OrderSettings,
  Renewal,
} from '../../model/Order';

class Service {
  public mapToOrder(order: ApiOrder): Order {
    return {
      id: order.id,
      no: order.no,
      companyId: order.companyId,
      externalId: order.externalId,
      region: order.region,
      receiver: this.extractReceiver(order),
      xDeliverer: this.extractXDeliverer(order),
      createdAt: new Date(order.createdAt).getTime(),
      plannedAt: order.plannedAt ? new Date(order.plannedAt).getTime() : undefined,
      acceptedAt: order.acceptedAt ? new Date(order.acceptedAt).getTime() : undefined,
      pickedUpAt: order.pickedUpAt ? new Date(order.pickedUpAt).getTime() : undefined,
      scheduledAt: order.scheduledAt ? new Date(order.scheduledAt).getTime() : undefined,
      cancelledAt: order.cancelledAt ? new Date(order.cancelledAt).getTime() : undefined,
      scheduledSlotRange: order.scheduledSlotRange
        ? [
            new Date(order.scheduledSlotRange[0]).getTime(),
            new Date(order.scheduledSlotRange[1]).getTime(),
          ]
        : undefined,
      xDelivererArrivedAt: order.driverArrivedAt
        ? new Date(order.driverArrivedAt).getTime()
        : undefined,
      deliveredAt: order.deliveredAt ? new Date(order.deliveredAt).getTime() : undefined,
      misc: this.extractMiscData(order),
      comment: order.deliveryComment ?? order.cancellationComment,
      pickUpNotes: order.pickUpNotes,
      stage: this.extractStage(order),
      pickupPoint: this.extractPickupPoint(order),
      verificationCode: order.verificationCode,
      noVerificationCodeReason: order.noVerificationCodeReason,
      estimations: this.extractEstimations(order),
      realizedWithin: this.getRealizedWithin(order),
      onHold: this.extractOnHoldData(order),
      emergencyDelivery: order.emergencyDelivery,
      vatRate: order.vatRate,
      comments: this.extractCommentsData(order),
      orderSettings: this.extractOrderSettings(order.orderSettings),
      renewals: this.extractOrderRenewals(order),
      packageSize: order.packageSize,
      multiplier: this.extractMultiplier(order.multiplier),
    };
  }

  private extractOrderRenewals(order: ApiOrder): Renewal[] {
    return (order.renewals ?? []).map((renewal) => ({
      id: renewal.id,
      renewedAt: new Date(renewal.renewedAt).getTime(),
      renewedBy: renewal.renewedBy,
      renewalReason: renewal.renewalReason,
      renewalComment: renewal.renewalComment,
      cancellationReason: renewal.cancellationReason,
      orderData: {
        xDeliverer: this.extractXDeliverer(renewal.oldOrderData),
        createdAt: renewal.oldOrderData.createdAt
          ? new Date(renewal.oldOrderData.createdAt).getTime()
          : undefined,
        plannedAt: renewal.oldOrderData.plannedAt
          ? new Date(renewal.oldOrderData.plannedAt).getTime()
          : undefined,
        acceptedAt: renewal.oldOrderData.acceptedAt
          ? new Date(renewal.oldOrderData.acceptedAt).getTime()
          : undefined,
        pickedUpAt: renewal.oldOrderData.pickedUpAt
          ? new Date(renewal.oldOrderData.pickedUpAt).getTime()
          : undefined,
        scheduledAt: renewal.oldOrderData.scheduledAt
          ? new Date(renewal.oldOrderData.scheduledAt).getTime()
          : undefined,
        cancelledAt: renewal.oldOrderData.cancelledAt
          ? new Date(renewal.oldOrderData.cancelledAt).getTime()
          : undefined,
        xDelivererArrivedAt: renewal.oldOrderData.driverArrivedAt
          ? new Date(renewal.oldOrderData.driverArrivedAt).getTime()
          : undefined,
      },
    }));
  }

  private extractOrderSettings(orderSettings: ApiOrderSettings): OrderSettings {
    return {
      operatorCancellationReasons: orderSettings?.operatorCancellationReasons,
    };
  }

  private extractMultiplier(multiplier?: ApiMultiplier): Multiplier | undefined {
    if (!multiplier) {
      return undefined;
    }
    return {
      id: multiplier.id,
      createdAt: new Date(multiplier.createdAt).getTime(),
      dateFrom: new Date(multiplier.dateFrom).getTime(),
      dateTo: new Date(multiplier.dateTo).getTime(),
      description: multiplier.description,
      multiplier: multiplier.multiplier,
      regions: multiplier.regions,
      companyIds: multiplier.companyIds,
      createdBy: multiplier.createdBy,
      ...(multiplier.deactivatedAt && {
        deactivatedAt: new Date(multiplier.deactivatedAt).getTime(),
        deactivationReason: multiplier.deactivationReason,
      }),
    };
  }

  private extractOnHoldData(order: ApiOrder): OnHold {
    const lastOnHoldHistoryRecord = last(order.onHoldHistory);
    return {
      currentlyOnHold: order.onHold,
      driversNote: lastOnHoldHistoryRecord ? lastOnHoldHistoryRecord.notes : undefined,
      heldAt: lastOnHoldHistoryRecord
        ? new Date(lastOnHoldHistoryRecord.createdAt).getTime()
        : undefined,
      holdUntil: order.onHoldUntil ? new Date(order.onHoldUntil).getTime() : undefined,
      history: orderBy(
        (order.onHoldHistory ?? []).map((record) => {
          return {
            driverId: record.driverId ?? undefined,
            operatorId: record.operatorId ?? undefined,
            notes: record.notes,
            createdAt: new Date(record.createdAt).getTime(),
            holdUntil: record.holdUntil ? new Date(record.holdUntil).getTime() : undefined,
            releasedAt: record.releasedAt ? new Date(record.releasedAt).getTime() : undefined,
            releasedById: record.releasedById ?? undefined,
            releasedByOperator: record.releasedByOperator ?? undefined,
            releasedByDriver: record.releasedByDriver ?? undefined,
          };
        }),
        ['createdAt'],
        ['desc'],
      ),
    };
  }

  private extractCommentsData(order: ApiOrder): Comments {
    const comments = (order.comments ?? []).map((comment) => ({
      createdAt: new Date(comment.createdAt).getTime(),
      createdBy: comment.createdBy,
      comment: comment.comment,
    }));
    const [lastComment] = comments;
    return {
      lastComment: lastComment,
      list: comments,
    };
  }

  private getRealizedWithin(order: ApiOrder): number | undefined {
    if (order.acceptedAt === undefined || order.driverArrivedAt === undefined) {
      return undefined;
    }
    return differenceInMinutes(
      this.deserializeDate(order.driverArrivedAt),
      this.deserializeDate(order.acceptedAt),
    );
  }

  private extractMiscData(order: ApiOrder): MiscData {
    const miscDataBase: MiscDataBase = {
      deliveredProducts: (order.deliveredProducts ?? []).map((product) => ({
        name: product.name,
        productCode: product.productCode,
        serialNumber: product.serialNumber,
      })),
      additionalServices: order.additionalServices,
    };

    switch (order.type) {
      case OrderType.Iqos:
        const deviceProducts = order.products.filter((product) =>
          isDeviceProduct(product),
        ) as DeviceProduct[];
        return {
          ...miscDataBase,
          type: MiscDataType.Iqos,
          products: deviceProducts.map((product) => ({
            name: product.name,
            smsName: product.smsName ?? '',
            code: product.code,
            oldDeviceCode: product.oldDeviceCode,
          })),
        };
      case OrderType.SimpleDelivery:
      case OrderType.PickupDelivery:
        return {
          ...miscDataBase,
          products: (order.products as PackageProduct[]).map((product) => ({
            name: product.name,
            code: product.code,
            pesel: product.pesel,
            receiptNo: product.receiptNo,
            amountToPayOnPickUp: product.amountToPayOnPickUp,
            medicines: product.medicines,
            externalNo: product.externalNo,
          })),
          type:
            order.type === OrderType.SimpleDelivery
              ? MiscDataType.SimpleDelivery
              : MiscDataType.PickupDelivery,
        };
      case OrderType.SimpleArrival:
        return {
          ...miscDataBase,
          products: order.products.map((product) => ({
            ...product,
          })),
          type: MiscDataType.SimpleArrival,
        };
      default:
        throw new Error(`Order type ${order.type} is not supported`);
    }
  }

  private extractXDeliverer(order: ApiOrder | Partial<ApiOrder>): XDeliverer | undefined {
    const name = this.extractXDelivererName(order);
    if (name === undefined) {
      return undefined;
    }

    return {
      firstName: name.firstName,
      lastName: name.lastName,
      phone: order.driverPhone,
      email: order.driverEmail,
      car: {
        type: order.driverCar ?? undefined,
        registrationPlate: order.driverRegistrationPlate ?? undefined,
        vehicle: order.driverVehicleType ?? undefined,
      },
    };
  }

  private extractXDelivererName(
    order: ApiOrder | Partial<ApiOrder>,
  ): { firstName: string; lastName?: string } | undefined {
    if (order.driverFirstName !== undefined) {
      return {
        firstName: order.driverFirstName,
        lastName: order.driverLastName,
      };
    }
    if (order.driverName !== undefined) {
      const [firstName, lastName] = order.driverName.split(' ');
      return { firstName, lastName };
    }
    return undefined;
  }

  private extractReceiver(order: ApiOrder): Receiver | undefined {
    if (order.clientName === undefined || order.clientPhone === undefined) {
      return undefined;
    }

    return {
      name: order.clientName,
      phone: order.clientPhone,
      hasHashedSensitiveData: order.hashedSensitiveData?.clientData !== undefined,
      address: {
        street: order.clientStreet,
        city: order.clientCity,
        zipCode: order.clientZipCode,
        notes: order.notes,
      },
    };
  }

  private extractStage(order: ApiOrder): OrderStage {
    switch (order.status) {
      case OrderStatus.New:
        return {
          beganAt: this.deserializeDate(order.createdAt).getTime(),
          toBePickedUp: true,
          inDelivery: false,
          isOngoing: true,
          status: order.status,
        };
      case OrderStatus.Accepted:
        return {
          beganAt: this.deserializeDate(order.acceptedAt).getTime(),
          toBePickedUp: !!order.pickupPoint,
          inDelivery: !order.pickupPoint,
          isOngoing: true,
          status: order.status,
        };
      case OrderStatus.PickedUp:
        return {
          beganAt: this.deserializeDate(order.pickedUpAt).getTime(),
          toBePickedUp: false,
          inDelivery: true,
          isOngoing: true,
          status: order.status,
        };
      case OrderStatus.Arrived:
        return {
          beganAt: this.deserializeDate(order.driverArrivedAt).getTime(),
          toBePickedUp: false,
          inDelivery: true,
          isOngoing: true,
          status: order.status,
        };
      case OrderStatus.Finished:
      case OrderStatus.Delivered:
      case OrderStatus.Scheduled:
        return {
          isOngoing: false,
          status: order.status,
          toBePickedUp: false,
          inDelivery: false,
        };
      case OrderStatus.Cancelled:
        return {
          isOngoing: false,
          toBePickedUp: false,
          inDelivery: false,
          status: order.status,
          statusReason: {
            id: order.cancellationReason ?? 'unknown',
            group: this.getStatuReasonGroup(order.cancellationReason),
          },
        };
    }
    throw new InvalidArgumentException(
      `Cannot extract stage from ApiOrder. Not supported status: ${order.status}.`,
    );
  }

  private getStatuReasonGroup(cancellationReason?: CancellationReason): StatusReasonGroup {
    switch (cancellationReason) {
      case CancellationReason.OrderNotAcceptedIn5Min:
      case CancellationReason.OrderNotAcceptedInXMin:
        return StatusReasonGroup.NotAccepted;
      default:
        return StatusReasonGroup.Other;
    }
  }

  private deserializeDate(date: unknown): Date {
    if (typeof date === 'string') {
      return new Date(date);
    }
    throw new InvalidArgumentException('Serialized date must be a string!');
  }

  private extractPickupPoint(order: ApiOrder): OrderPickupPoint | undefined {
    const { pickupPoint } = order;
    if (pickupPoint === undefined) {
      return undefined;
    }

    return {
      name: pickupPoint.name,
      city: pickupPoint.city,
      street: pickupPoint.street,
      zipCode: pickupPoint.zipCode,
      phone: pickupPoint.phone,
      hasHashedSensitiveData: order.hashedSensitiveData?.pickupPointData !== undefined,
      notes: pickupPoint.notes,
      ...(pickupPoint.orderDetails && { orderDetails: pickupPoint.orderDetails }),
      paymentDetails: this.extractPaymentDetails(
        pickupPoint.paymentDetails,
        order.hashedSensitiveData?.paymentDetails !== undefined,
      ),
      workingHours: pickupPoint.workingHours,
    };
  }

  private extractPaymentDetails(
    paymentDetails?: PaymentDetailsApi,
    hasHashedSensitiveData: boolean = false,
  ): PaymentDetails | undefined {
    if (paymentDetails === undefined) {
      return undefined;
    }
    return {
      paymentCode: paymentDetails.paymentCode,
      paymentType: paymentDetails.paymentType,
      limit: paymentDetails.limit,
      paymentViewed: !!paymentDetails.viewed?.length,
      hasHashedSensitiveData,
    };
  }

  private extractEstimations(order: ApiOrder): Order['estimations'] {
    if (order.estimatedDistance === undefined && order.estimatedPayout === undefined) {
      return undefined;
    }

    return {
      payout: order.estimatedPayout,
      payoutDriver: order.estimatedPayoutDriver,
      currency: order.currency,
      distance: order.estimatedDistance,
      payable: order.payable ?? undefined,
    };
  }
}

export const ApiOrderMapper = new Service();
