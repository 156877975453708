import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AsyncStatus, PA } from 'utlis/State';
import { Correction } from '../model/Correction';
import { PendingCorrection } from '../model/PendingCorrection';

export interface State {
  rejectCorrectionStatus: AsyncStatus;
  acceptCorrectionStatus: AsyncStatus;
  pendingCorrectionsList: {
    data: PendingCorrection[];
    status: AsyncStatus;
  };
  getCorrectionInfoStatus: AsyncStatus;
  correctionInfo: Correction | undefined;
}

const initialState: State = {
  rejectCorrectionStatus: AsyncStatus.NotStarted,
  acceptCorrectionStatus: AsyncStatus.NotStarted,
  pendingCorrectionsList: {
    data: [],
    status: AsyncStatus.NotStarted,
  },
  getCorrectionInfoStatus: AsyncStatus.NotStarted,
  correctionInfo: undefined,
};

const slice = createSlice({
  name: 'correction',
  initialState,
  reducers: {
    acceptCorrection(state, _action: PA<ActionTypes.AcceptCorrection>) {
      state.acceptCorrectionStatus = AsyncStatus.Pending;
    },
    acceptCorrectionFinish(state) {
      state.acceptCorrectionStatus = AsyncStatus.Success;
    },
    rejectCorrection(state, _action: PA<ActionTypes.RejectCorrection>) {
      state.rejectCorrectionStatus = AsyncStatus.Pending;
    },
    rejectCorrectionFinish(state) {
      state.rejectCorrectionStatus = AsyncStatus.Success;
    },
    fetchPendingCorrectionsList(state) {
      state.pendingCorrectionsList.status = AsyncStatus.Pending;
    },
    fetchPendingCorrectionsListInBackground(_state) {},
    fetchPendingCorrectionsListSuccess(
      state,
      { payload }: PA<ActionTypes.FetchPendingCorrectionsListSuccess>,
    ) {
      state.pendingCorrectionsList.status = AsyncStatus.Success;
      state.pendingCorrectionsList.data = payload.corrections;
    },
    fetchPendingCorrectionsListError(state) {
      state.pendingCorrectionsList.status = AsyncStatus.Error;
    },
    getCorrectionInfo(state, { payload }: PA<ActionTypes.GetCorrectionInfo>) {
      state.getCorrectionInfoStatus = AsyncStatus.Pending;
    },
    getCorrectionInfoSuccess(state, { payload }: PA<ActionTypes.GetCorrectionInfoSuccess>) {
      state.getCorrectionInfoStatus = AsyncStatus.Success;
      state.correctionInfo = payload.correction;
    },
    getCorrectionInfoError(state) {
      state.getCorrectionInfoStatus = AsyncStatus.Error;
    },
  },
});

export declare namespace ActionTypes {
  export interface RejectCorrection {
    correctionId: string;
    rejectionReason: string;
    comment?: string;
  }
  export interface AcceptCorrection {
    correctionId: string;
    amount: number;
    comment?: string;
  }
  export interface FetchPendingCorrectionsList {}
  export interface FetchPendingCorrectionsListSuccess {
    corrections: PendingCorrection[];
  }
  export interface GetCorrectionInfo {
    correctionId: string;
  }
  export interface GetCorrectionInfoSuccess {
    correction: Correction;
  }
}

export const { name, actions, reducer } = slice;

const makeSelectDomain = () => (state: any) => state[name] as State;
export const selectors = {
  makeSelectAcceptCorrectionStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.acceptCorrectionStatus;
    }),
  makeSelectRejectCorrectionStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.rejectCorrectionStatus;
    }),
  makeSelectPendingCorrectionsList: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.pendingCorrectionsList.data;
    }),
  makeSelectPendingCorrectionsListStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.pendingCorrectionsList.status;
    }),
  makeSelectGetCorrectionInfoStatus: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.getCorrectionInfoStatus;
    }),
  makeSelectGetCorrectionInfo: () =>
    createSelector(makeSelectDomain(), (state) => {
      return state.correctionInfo;
    }),
};
