import { useSelector } from 'react-redux';
import { SelectProductListFilters } from './slice';
import * as Slice from './slice';

type Filters = SelectProductListFilters;

export function useProducts(filters: Filters) {
  const products = useSelector(Slice.selectors.makeSelectProductList(filters));

  return {
    products,
  };
}
