import React from 'react';
import { useIsPermissionGranted } from '../hooks/useIsPermissionGranted';

// Component props type
type HasRolesPros = {
  roles: string | string[];
  noAccess?: React.ReactNode;
  children: React.ReactNode;
};

// Component
const HasRoles = ({ roles, children, noAccess }: HasRolesPros) => {
  const allowed = useIsPermissionGranted(roles);

  return <>{allowed ? children : noAccess || null}</>;
};

export default HasRoles;
