import React from 'react';

import { styled } from '@mui/material/styles';
import MuiModal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from 'app/translations/useTranslation';
import { useState } from 'react';
import { PushNotificationForm } from './components/SendPushNotificationForm/PushNotificationForm';

interface Props {}

export function SendPushNotificationAction(props: Props) {
  const { t } = useTranslation('driver');
  const [isModalOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setModalIsOpen(true)} size="small" variant="contained" color="primary">
        {t('actions.sendNotification')}
      </Button>
      <Modal open={isModalOpen} onClose={() => setModalIsOpen(false)}>
        <ModalContentBox>
          <Box>
            <PushNotificationForm />
          </Box>
        </ModalContentBox>
      </Modal>
    </>
  );
}

const ModalContentBox = styled('div')``;

const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled('div')(
  ({ theme }) => `
  width: 800px;
  height: 100%;
  padding: ${theme.spacing(6)};
  background: ${theme.colors.primary.background};
  border-radius: 5px;
`,
);
