import {
  AdditionalServices,
  CancellationReason,
  CancellationReasonProperties,
  Dimensions,
  OrderStatus,
  PaymentType,
  PickupPointWorkingHours,
  VehicleData,
} from 'api/OrderApi';
import { Multiplier } from '../../MultiplierPage/model/Multiplier';

export { OrderStatus } from 'api/OrderApi';

export interface XDeliverer {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  car: {
    type?: string;
    registrationPlate?: string;
    vehicle?: string;
  };
}

export interface Receiver {
  name: string;
  phone: string;
  hasHashedSensitiveData: boolean;
  address: {
    street: string;
    city: string;
    zipCode: string;
    notes?: string;
  };
}

export enum MiscDataType {
  Iqos = 'Iqos',
  PickupDelivery = 'PickupDelivery',
  SimpleDelivery = 'SimpleDelivery',
  SimpleArrival = 'SimpleArrival',
}

type DeliveredProduct = {
  name: string;
  productCode: string;
  serialNumber: string;
};

export interface MiscDataBase {
  deliveredProducts: DeliveredProduct[];
  additionalServices?: AdditionalServices;
}

export interface IqosProduct {
  name: string;
  smsName?: string;
  oldDeviceCode: string;
  code: string;
}

export interface BaseProduct {
  name: string;
  code: string;
  dimensions?: Dimensions;
  vehicle?: VehicleData;
}

export interface VehicleProduct extends BaseProduct {}

export interface IqosMiscData extends MiscDataBase {
  type: MiscDataType.Iqos;
  products: IqosProduct[];
}

export interface PackageProduct extends BaseProduct, PharmacyProduct {
  pesel?: string;
  receiptNo?: string;
  amountToPayOnPickUp?: number;
  externalNo?: string;
}

export interface Medicines {
  details?: string;
  pickedUp?: boolean;
}

export interface PharmacyProduct {
  medicines?: Medicines[];
}

interface PickupDeliveryMiscData extends MiscDataBase {
  type: MiscDataType.PickupDelivery;
  products: PackageProduct[];
}

interface SimpleDeliveryMiscData extends MiscDataBase {
  type: MiscDataType.SimpleDelivery;
  products: PackageProduct[];
}

interface SimpleArrivalMiscData extends MiscDataBase {
  type: MiscDataType.SimpleArrival;
  products: VehicleProduct[];
}

export type MiscData =
  | IqosMiscData
  | PickupDeliveryMiscData
  | SimpleDeliveryMiscData
  | SimpleArrivalMiscData;

export enum StatusReasonGroup {
  NotAccepted = 'notAccepted',
  Other = 'other',
}

export enum PackageSize {
  Small = 'S',
  Medium = 'M',
  Large = 'L',
}

type StageBase = {
  status: OrderStatus;
  inDelivery: boolean;
  toBePickedUp: boolean;
  statusReason?: {
    id: string;
    group: StatusReasonGroup;
  };
};

export type OrderOnGoingStage = StageBase & {
  isOngoing: true;
  beganAt: number;
};

type IdleStage = StageBase & {
  isOngoing: false;
};

export type OrderStage = OrderOnGoingStage | IdleStage;

export interface PaymentDetails {
  paymentType: PaymentType;
  paymentCode?: string;
  limit?: number;
  paymentViewed: boolean;
  hasHashedSensitiveData: boolean;
}

export interface OrderPickupPoint {
  name: string;
  city: string;
  street: string;
  zipCode: string;
  phone: string;
  hasHashedSensitiveData: boolean;
  notes?: string;
  paymentDetails?: PaymentDetails;
  orderDetails?: {
    discounts?: string;
  };
  workingHours?: PickupPointWorkingHours;
}

export interface OrderEstimations {
  payout?: number;
  payoutDriver?: number;
  distance?: number;
  currency?: string;
  payable?: boolean;
}

export interface OnHoldHistory {
  driverId?: string;
  operatorId?: string;
  notes?: string;
  createdAt: number;
  holdUntil?: number;
  releasedAt?: number;
  releasedById?: string;
  releasedByOperator?: boolean;
  releasedByDriver?: boolean;
}

export interface OnHold {
  currentlyOnHold?: boolean;
  driversNote?: string;
  holdUntil?: number;
  heldAt?: number;
  history?: OnHoldHistory[];
}

export interface OrderComment {
  createdAt: number;
  createdBy: string;
  comment: string;
}

export interface Comments {
  lastComment?: OrderComment;
  list: OrderComment[];
}

export interface Order {
  id: string;
  externalId?: string;
  no: string;
  companyId: string;
  stage: OrderStage;
  region: string;
  receiver?: Receiver;
  xDeliverer?: XDeliverer;
  pickupPoint?: OrderPickupPoint;
  verificationCode?: string;
  createdAt: number;
  acceptedAt?: number;
  plannedAt?: number;
  pickedUpAt?: number;
  scheduledAt?: number;
  scheduledSlotRange?: [number, number];
  xDelivererArrivedAt?: number;
  deliveredAt?: number;
  cancelledAt?: number;
  realizedWithin?: number;
  comment?: string;
  pickUpNotes?: string;
  misc: MiscData;
  estimations?: OrderEstimations;
  onHold: OnHold;
  noVerificationCodeReason?: string;
  emergencyDelivery?: boolean;
  vatRate?: number;
  comments: Comments;
  orderSettings: OrderSettings;
  renewals: Renewal[];
  packageSize: PackageSize;
  multiplier?: Multiplier;
}

export interface Renewal {
  id: string;
  renewedBy: string;
  renewedAt: number;
  renewalReason: string;
  renewalComment: string;
  cancellationReason?: string;
  orderData: Partial<Order>;
}

export interface OrderSettings {
  // TODO: Add other options, added options only that are used
  operatorCancellationReasons?: CancellationReasonsMap;
}
export type CancellationReasonsMap = {
  [key: string]: CancellationReasonProperties;
};

export interface DeliveryAvailability {
  available: boolean;
  price?: number;
  estimatedDistance?: number;
}
