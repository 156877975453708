import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useSendNotification() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectSendNotificationStatus());

  return {
    sendNotification: (params: Slice.ActionTypes.SendNotification['data']) => {
      dispatch(Slice.actions.sendNotification({ data: params }));
    },
    isInProgress: status === AsyncStatus.Pending,
  };
}
