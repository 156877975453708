import { styled } from '@mui/material/styles';
import { CheckboxGroup as CheckboxGroupComponent } from 'components/ReactHookForm/Checkbox/CheckboxGroup';
import { PrimaryButton } from 'components/Button/Buttons';

export const Box = styled('div')(
  ({ theme }) => `
  margin: ${theme.spacing(1)} ${theme.spacing(5)};
`,
);

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
`;

export const PermissionBox = styled('div')(
  ({ theme }) => `
  display: flex;
  margin-bottom: ${theme.spacing(3)};
  flex-wrap: wrap;
`,
);

export const CheckboxGroup = styled(CheckboxGroupComponent)(
  ({ theme }) => `
   border: solid 1px ${theme.colors.primary.borderLight};
   width: 350px;
   margin-left: 0;
   & > :nth-child(2) {
    height: 280px;
    overflow-y: scroll;
   }
`,
);

export const DetailsBox = styled('div')(
  ({ theme }) => `
  max-width: 700px;
  margin-bottom: ${theme.spacing(3)};
`,
);

export const SaveButton = styled(PrimaryButton)`
  width: 200px;
  align-self: end;
`;
