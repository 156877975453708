import React, { ComponentProps } from 'react';

// import { styled } from '@mui/material/styles';
import String from './String';

// Props type
type Props = {
  value: Date;
} & Omit<ComponentProps<typeof String>, 'value'>;

// Component
const Date = ({ value, ...otherProps }: Props) => {
  return <String value={`${value.toISOString()}`} {...otherProps} />;
};

export default Date;
