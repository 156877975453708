export class StoredLanguage {
  public static key = 'language';

  public static setValue(lang: string): void {
    localStorage.setItem(StoredLanguage.key, lang);
  }

  public static getValue(): string | null {
    return localStorage.getItem(StoredLanguage.key);
  }
}
