// import { useTranslation } from 'app/translations/useTranslation';
import { WarehouseAddStock } from './WarehouseForms/WarehouseAddStock';
import * as Styled from './styled';
import { Warehouse } from 'pages/WarehousePage/Warehouse';

interface Props {
  warehouse: Warehouse;
}

export function WarehouseRowDetails(props: Props) {
  // const { t } = useTranslation('warehouse');
  return (
    <Styled.Box>
      <WarehouseAddStock warehouse={props.warehouse} />
    </Styled.Box>
  );
}
