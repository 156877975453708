import React, { useState } from 'react';
import { useTranslation } from 'app/translations/useTranslation';
import { Input } from 'components/ReactHookForm/Input/Input';
import { FormProvider } from 'react-hook-form';
import {
  OnHoldOrderFormValues,
  useOnHoldOrderFormReset,
  useOnHoldOrderForm,
} from './hooks/useOnHoldOrderForm';
import * as Styled from './styled';
import { AsyncStatus } from 'utlis/State';
import LoadingButton from '@mui/lab/LoadingButton';
import { useHoldOrder } from 'pages/Orders/OrderPage/state/useHoldOrder';
import { DateTimeSelect } from 'components/DateTimeSelect';

interface Props {
  orderId: string;
  onHold: boolean;
}

export function HoldOrderForm(props: Props) {
  const { t } = useTranslation('order');
  const { holdOrder, holdOrderStatus } = useHoldOrder();

  const [onHoldTill, setOnHoldTill] = useState<Date | null>(new Date());

  const form = useOnHoldOrderForm();
  const reset = useOnHoldOrderFormReset();

  const onChange = (newDate: Date | null) => {
    form.setValue('holdOrderTill', newDate);
    setOnHoldTill(newDate);
  };

  const onSubmit = (formValues: OnHoldOrderFormValues) => {
    holdOrder({
      notes: formValues.notes,
      holdOrderTill: onHoldTill || undefined,
      orderId: props.orderId,
    });
  };

  const onReset = () => {
    reset();
  };

  const calendarState = form.getFieldState('holdOrderTill');

  return (
    <Styled.Box>
      <FormProvider {...form}>
        <Styled.Form onSubmit={form.handleSubmit(onSubmit)} onReset={onReset}>
          <Styled.DetailsBox>
            <DateTimeSelect
              label={t('onHoldOrder.form.fields.onHoldTill')}
              onChange={onChange}
              value={onHoldTill}
              name={'holdOrderTill'}
              fieldState={calendarState}
              minDateTime={new Date()}
            />
          </Styled.DetailsBox>
          <Styled.DetailsBox>
            <Input
              name={'notes'}
              label={t('onHoldOrder.form.fields.notes')}
              dataCy={'input-notes'}
            />
          </Styled.DetailsBox>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={holdOrderStatus === AsyncStatus.Pending}
            loading={holdOrderStatus === AsyncStatus.Pending}
          >
            {t('onHoldOrder.form.submitButton')}
          </LoadingButton>
        </Styled.Form>
      </FormProvider>
    </Styled.Box>
  );
}
