interface CreateParams<Response> {
  message: string;
  response: Response;
  status: number;
}

export class HttpError<Response = any> extends Error {
  public response: Response;
  public status: number;

  constructor(params: CreateParams<Response>) {
    super(params.message);
    this.response = params.response;
    this.status = params.status;
  }
}

type BadRequestResponse = {
  error: {
    name: string,
    message: string,
    errorCode: string,
  }
}

export function isBadRequestResponse(obj: any): obj is BadRequestResponse {
  const error = obj.error;
  return typeof error?.name === 'string' && typeof error?.message === 'string' && typeof error?.errorCode === 'string';
}

export class BadRequestError extends HttpError<BadRequestResponse['error']> {}
