import { useDispatch, useSelector } from 'react-redux';
import { AsyncStatus } from 'utlis/State';
import * as Slice from './slice';

export function useCreateUser() {
  const dispatch = useDispatch();
  const status = useSelector(Slice.selectors.makeSelectCreateUserStatus());

  return {
    createUser: (params: Slice.ActionTypes.CreateUser['data']) => {
      dispatch(Slice.actions.createUser({ data: params }));
    },
    isInProgress: status === AsyncStatus.Pending,
  };
}

