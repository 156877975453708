import { styled } from '@mui/material/styles';
import { PrimaryButton } from 'components/Button/Buttons';
import { Input as FormInput } from 'components/ReactHookForm/Input/Input';

export const Box = styled('div')(
  ({ theme }) => `
  margin-top: ${theme.spacing(5)};
  width: 400px;
  padding: ${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(5)};
  box-shadow: 0 0 ${theme.spacing(1)} 0 gray;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
`,
);

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
`;

export const LoginButton = styled(PrimaryButton)(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)};
`,
);

export const Input = styled(FormInput)(
  ({ theme }) => `
  margin: ${theme.spacing(1)} 0;
`,
);

export const LogoBox = styled('div')`
  width: 80%;
  align-self: center;
  display: flex;
`;
