import React from 'react';

// import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Company, CompanyStatus } from 'model/Company';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { ActivateCompanyForm } from './components/ActivateCompanyForm';

// Props type
type Props = { company: Company };

// Component
const ActivationSettings = ({ company }: Props) => {
  const { t } = useTranslation('company');

  return (
    <Card>
      <CardHeader title={t('activationSettings.header')} />
      <CardContent>
        {t(`status.${company.status}`)}
        {company.status === CompanyStatus.Pending && company.contract && (
          <ActivateCompanyForm company={company} />
        )}
        {company.status === CompanyStatus.Pending && !company.contract && (
          <p>{t('activationSettings.noContractMessage')}</p>
        )}
        {/*<pre>{JSON.stringify(company.deliverySettings?.offersLimit, undefined, 2)}</pre>*/}
      </CardContent>
    </Card>
  );
};

export default ActivationSettings;
