import * as Auth from 'services/authorization';
import SocketIO from 'socket.io-client';
import makeWildcardPatch from 'socketio-wildcard';
import { AppConfig } from 'app/Config';

const wildcardPatch = makeWildcardPatch(SocketIO.Manager);

export enum SocketNamespace {
  Private = 'private',
}

type Sockets = Map<SocketNamespace, SocketIOClient.Socket>;

class Service {
  private constructor(private sockets: Sockets) { }

  public static Initialize(): Service {
    const namespaces = Object.values(SocketNamespace);
    const sockets: Sockets = new Map();

    namespaces.forEach(namespace => {
      const socket = Service.createNamespaceSocket(namespace);
      sockets.set(namespace, socket);
    });

    return new Service(sockets);
  }

  private static createNamespaceSocket(namespace: SocketNamespace): SocketIOClient.Socket {
    const socketUrl = `${AppConfig.webSocketUrl}/${namespace}`;
    const socket = SocketIO(socketUrl, {
      query: {},
      transports: ['websocket'],
      autoConnect: false,
    });
    wildcardPatch(socket);

    socket.io.on('reconnect_attempt', () => {
      if (socket.io.opts.query) {
        (socket.io.opts.query as any).token = Auth.getToken() ?? '';
      }
    });

    return socket;
  }

  public onAny(handler: (event: { name: string, payload: unknown }) => void): void {
    this.sockets.forEach(socket => {
      socket.on('*', (packet: any) => {
        if (Array.isArray(packet?.data) === false || packet.data.length < 2) {
          console.info('Received not supported event. Ignoring.', packet);
          return;
        }

        const [name, payload] = packet.data;
        handler({ name, payload });
      });
    });
  }

  public connect(): void {
    this.sockets.forEach(socket => {
      if (socket.connected === false) {
        (socket.io.opts.query as any).token = Auth.getToken() ?? '';
        socket.connect();
      }
    });
  }

  public disconnect(): void {
    this.sockets.forEach(socket => {
      socket.disconnect();
      socket.removeAllListeners();
    });
  }
}

export const SocketManager = Service.Initialize();
