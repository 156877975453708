import { DecodedToken } from 'api/AuthApi';
import jwt_decode from 'jwt-decode';
import { Settings } from '../Settings';

class Service {
  public map(token: string): Settings {
    const decodedToken = jwt_decode<DecodedToken>(token);
    return {
      ...(decodedToken.dashboardImage && { dashboardImage: decodedToken.dashboardImage }),
      ...(decodedToken.defaultLocationId && { defaultLocationId: decodedToken.defaultLocationId }),
    };
  }
}

export const ApiTokenToSettings = new Service();
