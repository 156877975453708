import { styled } from '@mui/material/styles';
import { useAuth } from 'services/authorization';
import React, { useCallback, useMemo } from 'react';
import { RegisterForm } from './components/RegisterForm/RegisterForm';
import { LogoVersion, XDLogo } from '../../assets/Logo';
import { useTranslation } from '../../app/translations/useTranslation';
import DeliveryImage from './components/delivery.jpeg';
import LeftMainImage from './components/bg-left.png';
import RightBgTheme from './components/bg-theme-right.png';
import LeftBgTheme from './components/bg-theme-left.png';
import * as Styled from './components/RegisterForm/styled';
import { LanguageSwitcher } from '../../components/Layouts/components/Menu/components/LanguageSwitcher';
interface Props {}

export function RegisterPage(props: Props) {
  const { t } = useTranslation('register');

  return (
    <Container>
      <InnerContainer>
        <HeroImageContainer />
        <RegistrationContainer>
          <HeaderContainer>
            <LogoBox>
              <XDLogo version={LogoVersion.TwoLine} />
            </LogoBox>
            <HeadlineContainer>
              <MainHeader>{t('registration.header.main')}</MainHeader>
              <MainSubheader>{t('registration.header.subheader')}</MainSubheader>
            </HeadlineContainer>
          </HeaderContainer>
          <Styled.Box>
            <RegisterForm />
          </Styled.Box>
        </RegistrationContainer>
      </InnerContainer>
      <LanguageSwitchContainer>
        <LanguageSwitcher />
      </LanguageSwitchContainer>
    </Container>
  );
}

const HeroImageContainer = styled('div')(
  ({ theme }) => `
  background-image: url("${LeftMainImage}");
  height: 100vh;
  width: 35%;
  background-size: contain;
 
    background-position: left center;
    background-repeat: no-repeat;
`,
);

const RegistrationContainer = styled('div')`
  margin-top: 60px;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const MainHeader = styled('h1')`
  color: #f1c231;
  letter-spacing: 2px;
  font-weight: normal;
`;
const MainSubheader = styled('h3')`
  letter-spacing: 2px;
  font-weight: normal;
`;
const HeadlineContainer = styled('div')``;

export const LogoBox = styled('div')`
  width: 40%;
  height: 100%;
  align-self: center;
  display: flex;
`;

const Container = styled('div')(
  ({ theme }) => `
  padding: 60px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: url('${LeftBgTheme}'), url('${RightBgTheme}');
  background-size: auto;
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
`,
);

const InnerContainer = styled('div')(
  ({ theme }) => `
 
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`,
);

const LanguageSwitchContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Box = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerBox = styled('div')``;
