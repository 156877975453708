import { HandlerRegister, EventHandler } from './HandlerRegister';
import { SocketEvent, SocketEventPayload } from './SocketEvent';

export * from './slice';
export * from './saga';
export * from './SocketEvent';

export namespace Socket {
  export function on<Event extends SocketEvent>(eventType: Event, handler: EventHandler<SocketEventPayload[Event]>): void {
    HandlerRegister.register(eventType, handler);
  }
}
