import React from 'react';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import { SecondaryButton } from 'components/Button/Buttons';
import { useState } from 'react';
import { OnHoldHistoryItem } from './components/OnHoldHistoryItem';
import { OnHoldHistory } from 'pages/Orders/model/Order';
import { HoldOrderForm } from './HoldOrderForm';
import { ReleaseOrderAction } from './ReleaseOrderAction';
import { Divider } from '@mui/material';

interface Props {
  orderId: string;
  onHoldHistory?: OnHoldHistory[];
  onHold: boolean;
  canHoldOrder: boolean;
}

export function ShowOnHoldHistoryAction(props: Props) {
  const { t } = useTranslation('order');
  const [isModalOpen, setModalIsOpen] = useState(false);
  const onHoldHistory = props.onHoldHistory ?? [];
  return (
    <>
      <SecondaryButton onClick={() => setModalIsOpen(true)}>
        {t('actions.showOnHoldHistory')}
      </SecondaryButton>
      <Dialog open={isModalOpen} onClose={() => setModalIsOpen(false)} scroll="paper" maxWidth="lg">
        <DialogContent>
          <Box>
            {props.canHoldOrder &&
              (props.onHold ? (
                <ReleaseOrderAction orderId={props.orderId} />
              ) : (
                <HoldOrderForm orderId={props.orderId} onHold={props.onHold} />
              ))}
            <Divider />
            {onHoldHistory.length === 0 && (
              <Typography variant={'h3'}>{t('onHoldOrder.onHoldHistoryEmpty')}</Typography>
            )}
            {onHoldHistory.map((history, index) => (
              <OnHoldHistoryItem historyItem={history} key={`onHoldHistoryItem-${index}`} />
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

const Box = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(3)};
  background: ${theme.colors.primary.background};
`,
);
