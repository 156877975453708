import React from 'react';

import { usePermissions } from 'services/authorization/hooks/usePermissions';
import { SendPushNotificationAction } from './components/SendPushNotification/SendPushNotificationAction';

interface Props {}

export function Actions(props: Props) {
  const driverPermissions = usePermissions('driver');
  return (
    <>
      {driverPermissions.notifications.canAccessSendPushNotification && (
        <SendPushNotificationAction />
      )}
    </>
  );
}
