import { useState } from 'react';
import { AccountTypeSelectProps, AccountTypeSelect } from './AccountTypeSelect';
import { AccountType } from 'api/OperatorApi';

export function useAccountTypeSelect() {
  const [selectedAccountType, setSelectedAccountType] = useState<string | null>(null);
  const accountTypes = Object.values(AccountType);

  const accountTypeSelectProps: AccountTypeSelectProps = {
    selectedAccountType,
    onAccountTypeChange: setSelectedAccountType,
    availableAccountTypes: accountTypes,
  };

  return {
    selectedAccountType,
    accountTypeSelectProps,
    AccountTypeSelect: AccountTypeSelect,
  };
}
