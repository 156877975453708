import { default as MuiSelectField } from '@mui/material/Select';
import React, { ReactElement, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as Styled from './styled';
import { MenuItem } from '@mui/material';
import { LabeledSelect } from '../../../externals/MaterialUI/LabeledSelect';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

type SelectValue = {
  value: string;
  display?: string;
  component?: JSX.Element;
};

type Props = {
  label?: string;
  name: string;
  className?: string;
  dataCy?: string;
  defaultValue?: string | string[];
  type?: 'chip' | 'default';
  values?: SelectValue[];
} & React.ComponentProps<typeof MuiSelectField> &
  Pick<React.ComponentProps<typeof Controller>, 'rules'>;

function ParsedErrorMessage(props: { inputName: string; message: string }) {
  return (
    <Styled.ErrorBox data-cy={`input_error-${props.inputName}`}>
      {props.message.replace(`"${props.inputName}"`, 'Value')}
    </Styled.ErrorBox>
  );
}

export const Select = React.forwardRef<any, Props>(
  ({ label, name, values, className, dataCy, defaultValue, rules, ...inputProps }, ref) => {
    const { control } = useFormContext();
    // const { type = 'text' } = props;
    const selectProps =
      inputProps.type === 'chip'
        ? {
            renderValue: (selected: any) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            ),
          }
        : {};
    return (
      <Styled.Box className={className}>
        <Controller
          name={name}
          control={control}
          render={(fieldProps) => {
            const error = fieldProps.fieldState.error;
            const errorMessage = error?.message;

            return (
              <>
                {!label ? (
                  <>
                    <MuiSelectField
                      {...fieldProps.field}
                      value={fieldProps.field.value ?? (inputProps.multiple ? [] : '')}
                      error={error !== undefined}
                      data-cy={dataCy}
                      {...inputProps}
                      {...selectProps}
                    >
                      {(values || []).map((value) => (
                        <MenuItem value={value.value} key={value.value}>
                          {value.display ?? value.value}
                        </MenuItem>
                      ))}
                    </MuiSelectField>
                    {errorMessage ? (
                      <ParsedErrorMessage inputName={name} message={errorMessage} />
                    ) : undefined}
                  </>
                ) : (
                  <LabeledSelect
                    label={label}
                    {...fieldProps.field}
                    value={fieldProps.field.value ?? (inputProps.multiple ? [] : '')}
                    error={error !== undefined}
                    data-cy={dataCy}
                    {...inputProps}
                    {...selectProps}
                  >
                    {(values || []).map((value) => (
                      <MenuItem value={value.value} key={value.value}>
                        {value.component ?? value.display ?? value.value}
                      </MenuItem>
                    ))}
                  </LabeledSelect>
                )}
                {errorMessage ? (
                  <ParsedErrorMessage inputName={name} message={errorMessage} />
                ) : undefined}
              </>
            );
          }}
          rules={rules}
          {...(defaultValue ? { defaultValue } : {})}
        />
      </Styled.Box>
    );
  },
);
