import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

import { useFormContext } from 'react-hook-form';

import CompanySelect from './components/CompanySelect';
import Cart from './components/Cart/Cart';
import FormActions from './components/FormActions';
import { useOrderType } from './hooks/useOrderType';
import TypeBasedFields from './components/TypeBasedFields/TypeBasedFields';
import CompanyFields from './components/CompanyFields/CompanyFields';
import useOrderCreate from '../../../state/hooks/useOrderCreate';
import { useOrderCreateFormReset } from '../../hooks/useOrderCreateForm';
import TimeSlotSelector from './components/CompanyFields/TimeSlotSelector';
import CheckDeliveryContainer from './components/CheckDelivery/CheckDeliveryContainer';
import useOrderCompany from './hooks/useOrderCompany';
import useCheckDeliveryAvailability from '../../../state/hooks/useCheckDeliveryAvailability';
import { useProducts } from '../../../../../services/products/state/useProducts';
import { find, some } from 'lodash';
import { SuggestedProductsPrompt } from '../ShowSuggestedProductsAction/SuggestedProductsPrompt';

// Props type
type Props = {};

// Component
const OrderCreateFormContent = (props: Props) => {
  const form = useFormContext();
  const reset = useOrderCreateFormReset();
  const company = useOrderCompany();
  const { orderCreate, inProgressState } = useOrderCreate();
  const { clear } = useCheckDeliveryAvailability();

  const productsInOrder = form.watch('products');

  const [suggestedProductsPromptOpen, setSuggestedProductsPromptOpen] = useState<boolean>(false);

  const showSuggestedProductsPrompt = useMemo(() => {
    if (!company.orderSettings?.orderFormSuggestedProducts) {
      return false;
    }
    return some(company.orderSettings?.orderFormSuggestedProducts, (suggestedProduct) => {
      return !find(
        productsInOrder,
        (productInOrder) => productInOrder?.productId === suggestedProduct,
      );
    });
  }, [form, company, productsInOrder]);
  const onSubmit = (data: any) => {
    if (showSuggestedProductsPrompt) {
      setSuggestedProductsPromptOpen(true);
    } else {
      submitForm(data);
    }
  };
  const submitForm = (data: any) => {
    orderCreate(data);
  };

  const onReset = useCallback(() => {
    reset();
    clear();
  }, [clear, reset]);

  const orderType = useOrderType();

  useEffect(() => {
    if (inProgressState.success) {
      reset();
      clear();
    }
  }, [inProgressState]);

  return (
    <Box>
      <form onSubmit={form.handleSubmit(onSubmit)} onReset={onReset}>
        <CompanySelect />
        <CompanyFields />
        <StyledDivider />
        <Cart />
        {orderType && (
          <>
            <StyledDivider />
            <TypeBasedFields />
          </>
        )}
        {company.orderSettings?.showDeliveryEstimationOnForm && <CheckDeliveryContainer />}

        <TimeSlotSelector />
        <StyledDivider />
        <FormActions />
        <SuggestedProductsPrompt
          onSubmit={form.handleSubmit(submitForm)}
          isOpen={suggestedProductsPromptOpen}
          toggleOpen={setSuggestedProductsPromptOpen}
        />
      </form>
    </Box>
  );
};

const Box = styled('div')``;

const StyledDivider = styled(Divider)(
  ({ theme }) => `
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
`,
);

export default OrderCreateFormContent;
