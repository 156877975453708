import React, { useMemo } from 'react';

import { FieldValues } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import MUIBox from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useProducts } from 'services/products/state/useProducts';
import { useOrderType } from '../../../hooks/useOrderType';
import useOrderCompany from '../../../hooks/useOrderCompany';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Props type
type Props = {
  onAddToCart: (productId: string, payload?: { helperProduct?: boolean }) => void;
  cartItems: FieldValues[];
};

// Component
const ProductsList = ({ onAddToCart, cartItems }: Props) => {
  const { t } = useTranslation(['order', 'validationErrors']);
  const company = useOrderCompany();
  const { products } = useProducts({ companyId: company?.id || undefined, hideOnOrderForm: false });

  const limit = company?.orderSettings?.maxOrderProductsAllowed || 0;

  const groupedItems = useMemo(() => {
    return cartItems.reduce<{ [key: string]: number }>((ret, item) => {
      if (!ret.hasOwnProperty(item.productId)) {
        ret[item.productId] = 1;
      } else {
        ret[item.productId]++;
      }

      return ret;
    }, {});
  }, [cartItems]);

  const count = useMemo(() => cartItems.length, [cartItems]);

  const helperProducts = useMemo(
    () => products.filter((product) => product.productSettings?.helperProduct),
    [products],
  );

  const orderType = useOrderType();
  return (
    <Box>
      {products
        .filter((product) => !product.productSettings?.helperProduct)
        .map((product) => (
          <ProductButton
            variant="outlined"
            key={product.id}
            onClick={() => onAddToCart(product.id)}
            disabled={
              (!!orderType && product.type !== orderType) ||
              (!!limit && count >= limit) ||
              (!!product.productSettings?.maxProductsAllowed &&
                (groupedItems[product.id] || 0) >= product.productSettings.maxProductsAllowed)
            }
          >
            {product.name}
          </ProductButton>
        ))}
      {helperProducts?.length ? (
        <>
          <StyledDivider>{t('order:orderCreate.helperProductDivider')}</StyledDivider>
          {helperProducts.map((product) => (
            <ProductButton
              variant="outlined"
              key={product.id}
              onClick={() => onAddToCart(product.id, { helperProduct: true })}
              disabled={
                (!!orderType && product.type !== orderType) ||
                (!!limit && count >= limit) ||
                (!!product.productSettings?.maxProductsAllowed &&
                  (groupedItems[product.id] || 0) >= product.productSettings.maxProductsAllowed)
              }
            >
              {product.name}
            </ProductButton>
          ))}
        </>
      ) : undefined}
    </Box>
  );
};

const Box = styled(MUIBox)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing(-0.5)};
  margin-bottom: ${theme.spacing(-0.5)};
`,
);

const StyledDivider = styled(Divider)(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(1)};
`,
);

const ProductButton = styled(Button)(
  ({ theme }) => `
  margin-top: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(0.5)};
`,
);

export default ProductsList;
