const rootUrl = '/wallet';

export const LinkFactory = {
  root: (driverId?: string) => {
    let query;
    if (driverId) {
      query = `?driverId=${driverId}`;
    }
    return query ? rootUrl + query : rootUrl;
  },
};
