import { useState } from 'react';
import { useUserList, UserListFilters } from 'pages/UserPage/state/useUserList';
import { User } from 'pages/UserPage/User';
import { UserTable, UserTableProps } from './UserTable';

export function useUserTable(params: {
  filters: UserListFilters
}) {
  const userList = useUserList(params.filters);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const userTableProps: UserTableProps = {
    users: userList.data,
    isLoading: userList.isLoading,
    selectedUser,
    onSelectedUserChange: setSelectedUser,
  };

  return {
    userTableProps,
    UserTable,
    selectedUser,
  };
}
