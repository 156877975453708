import React from 'react';
import * as Styled from '../styled';
import { WalletEntry } from '../../../../model/Wallet';
import { ShowDeleteWalletEntryModal } from './components/DeleteWalletEntryModal/ShowDeleteWalletEntryModal';

interface Props {
  walletEntry: WalletEntry;
}

export function ActionSection(props: Props) {
  return (
    <Styled.ActionSection>
      {!props.walletEntry.deletedAt && (
        <ShowDeleteWalletEntryModal walletEntryId={props.walletEntry.id} />
      )}
    </Styled.ActionSection>
  );
}
