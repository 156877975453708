import { useTranslation } from 'app/translations/useTranslation';
import { Order } from 'pages/Orders/model/Order';
import * as Styled from '../styled';
import { format } from 'date-fns';

interface Props {
  order: Partial<Order>;
}

function formatDate(timestamp?: number): string {
  if (timestamp === undefined) {
    return '---';
  }
  return format(timestamp, 'HH:mm dd.MM.RR');
}

export function TimestampsSubsection(props: Props) {
  const { t } = useTranslation('order');

  return (
    <>
      {props.order.plannedAt && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.plannedAt')}:</Styled.RecordLabel>
          <Styled.RecordValue>{formatDate(props.order.plannedAt)}</Styled.RecordValue>
        </Styled.RecordBox>
      )}

      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.createdAt')}:</Styled.RecordLabel>
        <Styled.RecordValue>{formatDate(props.order.createdAt)}</Styled.RecordValue>
      </Styled.RecordBox>
      {props.order.scheduledAt !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.scheduledAt')}:</Styled.RecordLabel>
          <Styled.RecordValue>{formatDate(props.order.scheduledAt)}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.order.scheduledSlotRange !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.scheduledSlotRange')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {formatDate(props.order.scheduledSlotRange[0])} -{' '}
            {formatDate(props.order.scheduledSlotRange[1])}
          </Styled.RecordValue>
        </Styled.RecordBox>
      )}
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.acceptedAt')}:</Styled.RecordLabel>
        <Styled.RecordValue>{formatDate(props.order.acceptedAt)}</Styled.RecordValue>
      </Styled.RecordBox>
      {props.order.pickedUpAt !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.pickedUpAt')}:</Styled.RecordLabel>
          <Styled.RecordValue>{formatDate(props.order.pickedUpAt)}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.arrivedAt')}:</Styled.RecordLabel>
        <Styled.RecordValue>{formatDate(props.order.xDelivererArrivedAt)}</Styled.RecordValue>
      </Styled.RecordBox>
      <Styled.RecordBox>
        <Styled.RecordLabel>{t('labels.deliveredAt')}:</Styled.RecordLabel>
        <Styled.RecordValue>{formatDate(props.order.deliveredAt)}</Styled.RecordValue>
      </Styled.RecordBox>
      {props.order.cancelledAt !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.cancelledAt')}:</Styled.RecordLabel>
          <Styled.RecordValue>{formatDate(props.order.cancelledAt)}</Styled.RecordValue>
        </Styled.RecordBox>
      )}
    </>
  );
}
