import { styled } from '@mui/material/styles';

export const Box = styled('div')(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 350px;
  grid-template-areas: 
      "summary"
      "addresses"
      "rest"
      "miscData"
      "actions"
      ;
  font-size: 12px;
  padding: ${theme.spacing(3)};
  border-left: solid 2px ${theme.colors.primary.main};
  @media (min-width: 740px) {
    grid-template-columns: 235px 235px;
    grid-template-areas: 
      "summary summary"
      "addresses rest"
      "miscData miscData"
      "actions actions"
      ;
  }
  @media (min-width: 975px) {
    grid-template-columns: auto auto auto;
    grid-template-areas: 
      "summary addresses rest"
      "miscData miscData actions";
  }
  @media (min-width: 1425px) {
    grid-template-columns: 235px 235px 235px auto;
    grid-template-areas: 
      "summary addresses rest miscData"
      "actions actions actions actions";
  }
  @media (min-width: 1660px) {
    grid-template-columns: 235px 235px 235px 500px auto;
    grid-template-areas: 
      "summary addresses rest miscData actions";
  }
`,
);

export const Header = styled('p')(
  ({ theme }) => `
  font-size: 14px;
  padding-bottom: ${theme.spacing(2)};
`,
);

export const HeaderOnHold = styled('p')(
  ({ theme }) => `
  font-size: 15px;
  padding-bottom: ${theme.spacing(2)};
  color: ${theme.colors.secondary.main};
`,
);

export const Section = styled('div')(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};
`,
);

export const MiscDataSection = styled(Section)`
  grid-area: miscData;
`;

export const RestSection = styled(Section)`
  grid-area: rest;
`;

export const AddressSection = styled(Section)`
  grid-area: addresses;
`;

export const SummarySection = styled(Section)(
  ({ theme }) => `
  grid-area: summary;
  @media (min-width: 975px) {
      border-right: 1px solid ${theme.colors.primary.border};  
  }
`,
);

export const ActionSection = styled(Section)`
  grid-area: actions;
  text-align: center;
`;

export const SubSectionRecordValue = styled('span')`
  font-weight: lighter;
`;

export const RecordBox = styled('div')(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(1)};
`,
);

export const RecordLabel = styled('span')(
  ({ theme }) => `
  font-weight: normal;
  padding-right: ${theme.spacing(0.5)};
`,
);

export const RecordValue = styled('span')`
  font-weight: lighter;
`;

export const RecordBlockValue = styled('p')`
  font-weight: lighter;
`;

export const CommentValue = styled(RecordBlockValue)`
  font-style: italic;
`;

export const Subsection = styled('div')(
  ({ theme }) => `
  padding-bottom: ${theme.spacing(1)};
  &:not(:first-of-type) {
    padding-top: ${theme.spacing(1)};
  }
  ${Header} {
    font-size: 13px;
    padding-bottom: ${theme.spacing(0.75)};
  }
  ${RecordBox} {
    padding-bottom: ${theme.spacing(0.5)};
  }
`,
);
