import { useTranslation } from 'app/translations/useTranslation';
import { format } from 'date-fns';
import { WalletEntry } from 'pages/WalletPage/model/Wallet';
import * as Styled from './styled';
import { ActionSection } from './ActionSection';

interface Props {
  walletEntry: WalletEntry;
}

function formatDate(timestamp?: number): string {
  if (timestamp === undefined) {
    return '---';
  }
  return format(timestamp, 'HH:mm dd.MM.RR');
}

export function WalletEntryDetails(props: Props) {
  const { t } = useTranslation('wallet');
  return (
    <Styled.Box>
      <Styled.SummarySection>
        <Styled.Header>
          {t('walletEntry.details.labels.no')}: {props.walletEntry.no}
        </Styled.Header>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('walletEntry.details.labels.payoutDriver')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {props.walletEntry.payoutDriver} {props.walletEntry.currency}
          </Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('walletEntry.details.labels.payoutClient')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {props.walletEntry.payoutClient} {props.walletEntry.currency}
          </Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('walletEntry.details.labels.description')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.walletEntry.description}</Styled.RecordValue>
        </Styled.RecordBox>

        <Styled.RecordBox>
          <Styled.RecordLabel>{t('walletEntry.details.labels.createdAt')}:</Styled.RecordLabel>
          <Styled.RecordValue>{formatDate(props.walletEntry.createdAt)}</Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('walletEntry.details.labels.createdBy')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.walletEntry.createdByEmail}</Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>
            {t('walletEntry.details.labels.creationComment')}:
          </Styled.RecordLabel>
          <Styled.CommentValue>{props.walletEntry.creationComment ?? ''}</Styled.CommentValue>
        </Styled.RecordBox>
      </Styled.SummarySection>
      <Styled.DeletionSection>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('walletEntry.details.labels.deletedAt')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {props.walletEntry.deletedAt ? formatDate(props.walletEntry.deletedAt) : '-'}
          </Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('walletEntry.details.labels.deletedBy')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.walletEntry.deletedByEmail ?? ''}</Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('walletEntry.details.labels.deletionReason')}:</Styled.RecordLabel>
          <Styled.RecordValue>{props.walletEntry.deletionReason ?? ''}</Styled.RecordValue>
        </Styled.RecordBox>
        <Styled.RecordBox>
          <Styled.RecordLabel>
            {t('walletEntry.details.labels.deletionComment')}:
          </Styled.RecordLabel>
          <Styled.CommentValue>{props.walletEntry.deletionComment ?? ''}</Styled.CommentValue>
        </Styled.RecordBox>
      </Styled.DeletionSection>
      <ActionSection walletEntry={props.walletEntry} />
    </Styled.Box>
  );
}
