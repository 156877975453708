import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'app/translations/useTranslation';
import { combineResolvers, simpleResolver } from 'utlis/ReactHookForm/simpleResolver';
import { WalletEntryType } from '../../../model/Wallet';

export type CreateWalletEntryFormValues = {
  type: WalletEntryType;
  payoutDriver: number;
  payoutClient?: number;
  companyId?: string; // Not used yet
  currency: string;
  comment?: string;
};

function useValidationSchema() {
  const { t } = useTranslation('validationErrors');
  return useMemo(() => {
    return yup.object().shape({
      type: yup.string().required(),
      payoutDriver: yup.number().required(),
      payoutClient: yup.number().optional(),
      currency: yup.string().required(),
      comment: yup.string().optional(),
    });
  }, [t]);
}

export const useCreateWalletEntryFormReset = (formContext?: any) => {
  const context = useFormContext();
  const form = formContext ?? context;

  return useCallback(() => {
    form.setValue('type', undefined);
    form.setValue('payoutDriver', undefined);
    form.setValue('payoutClient', undefined);
    form.setValue('currency', undefined);
    form.setValue('comment', '');
  }, [form]);
};

export function useCreateWalletEntryForm() {
  const validationSchema = useValidationSchema();
  const resolver = useCallback(
    combineResolvers<CreateWalletEntryFormValues>([
      simpleResolver<CreateWalletEntryFormValues>(),
      yupResolver(validationSchema),
    ]),
    [validationSchema],
  );
  const form = useForm<CreateWalletEntryFormValues>({
    resolver,
    mode: 'onSubmit',
  });

  return form;
}
