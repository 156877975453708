import {
  DayNumbers,
  DayWorkingHours,
  SlotRange,
  WeekDays,
  WorkingHours,
  WorkingSettings,
} from './model/WorkingSettings';

const changeTZ = (date: Date, timeZone: string) =>
  new Date(date.toLocaleString('en-US', { timeZone }));

const getDayNumbers = (date: Date): DayNumbers => ({
  year: date.getFullYear(),
  month: date.getMonth(),
  day: date.getDate(),
});

const mergeWorkingHours = (workingHours: WorkingHours[]): WorkingHours =>
  workingHours.reduce(
    (prev, curr) => ({
      from:
        curr.from.hour * 60 + curr.from.minute > prev.from.hour * 60 + prev.from.minute
          ? curr.from
          : prev.from,
      to:
        curr.to.hour * 60 + curr.to.minute < prev.to.hour * 60 + prev.to.minute ? curr.to : prev.to,
    }),
    {
      from: { hour: 0, minute: 0 },
      to: { hour: 24, minute: 0 },
    },
  );

const getTimeRangeForDate = (
  date: Date,
  config: WorkingSettings,
  verifyTime = true,
): WorkingHours | false => {
  date = changeTZ(date, config.zone);
  const day = date.getDay();
  const dayKey = WeekDays[day] as keyof typeof WeekDays;

  if (config.workingDays?.weekDays && !config.workingDays.weekDays[dayKey]) {
    return false;
  }

  const dateNumbers = { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() };

  if (
    config.workingDays?.customDates &&
    config.workingDays.customDates.find(
      (d) =>
        d.year === dateNumbers.year && d.month === dateNumbers.month && d.day === dateNumbers.day,
    )
  ) {
    return false;
  }

  const workingHours: WorkingHours[] = [
    verifyTime && {
      from: { hour: date.getHours(), minute: date.getMinutes() },
      to: { hour: 24, minute: 0 },
    },
    config.workingHours,
    (config.nonStandardWorkingHours && config.nonStandardWorkingHours[dayKey]) ||
      config.defaultWorkingHours,
  ].filter((v) => !!v) as WorkingHours[];

  return mergeWorkingHours(workingHours);
};

export const checkDay = (
  configs: WorkingSettings[],
  verifyTime = false,
  date = new Date(),
): DayWorkingHours | undefined => {
  const zone = configs.reduce((ret, curr): string | false | undefined => {
    if (ret === false) {
      return false;
    }

    const currZone = curr.zone || curr.workingHours?.zone;

    if (!currZone) {
      return ret;
    }

    return !ret || currZone === ret ? currZone : false;
  }, undefined as string | false | undefined);

  // TODO: this situation should not happen (it means that configurations have inconsistent or not defined zones)
  //       so probably we should throw exception here
  if (!zone) {
    return undefined;
  }

  const workingHoursArray = configs.map((config) => getTimeRangeForDate(date, config, verifyTime));
  if (workingHoursArray.some((wh) => !wh)) {
    return undefined;
  }
  const workingHours = mergeWorkingHours(workingHoursArray as WorkingHours[]);

  return workingHours.from.hour < workingHours.to.hour ||
    (workingHours.from.hour === workingHours.to.hour &&
      workingHours.from.minute < workingHours.to.minute)
    ? { ...workingHours, day: getDayNumbers(changeTZ(date, zone)) }
    : undefined;
};

export const findNextWorkingDay = (
  workingSettings: WorkingSettings[],
  skipFirst = true,
  date = new Date(),
  limit = 7,
): DayWorkingHours | undefined => {
  date = new Date(date);
  if (skipFirst) {
    date.setDate(date.getDate() + 1);
  }
  let i = 0;

  while (i++ < limit) {
    const workingHours = checkDay(workingSettings, false, date);
    if (workingHours) {
      return workingHours;
    }
  }

  return;
};

export const splitHoursIntoSlotRanges = (
  hours: DayWorkingHours,
  rangeSize = 120,
  newSlotAfter = 120,
): SlotRange[] => {
  const date = dayToDate(hours.day);

  const fromMinutes = hours.from.hour * 60 + hours.from.minute;
  const toMinutes = hours.to.hour * 60 + hours.to.minute;

  const slots: SlotRange[] = [];

  let start = Math.ceil(fromMinutes / newSlotAfter) * newSlotAfter;
  let end = start + rangeSize;

  while (end <= toMinutes) {
    slots.push({
      from: new Date(new Date(date).setHours(Math.floor(start / 60), start % 60)),
      to: new Date(new Date(date).setHours(Math.floor(end / 60), end % 60)),
    });
    start += newSlotAfter;
    end = start + rangeSize;
  }

  return slots;
};

export const splitDayHoursIntoSlotRanges = (
  daysHours: DayWorkingHours[],
): { day: DayNumbers; slots: SlotRange[] }[] =>
  daysHours.map((dayHours) => ({ day: dayHours.day, slots: splitHoursIntoSlotRanges(dayHours) }));

export const dayToDate = (day: DayNumbers) => new Date(day.year, day.month, day.day);
