import { Driver } from 'pages/DriverPage/Driver';
import * as Styled from '../../styled';
import { ShowWalletAction } from './components/ShowWalletAction/ShowWalletAction';
import { usePermissions } from 'services/authorization/hooks/usePermissions';

interface Props {
  driver: Driver;
}

export function ActionSection(props: Props) {
  const walletPermissions = usePermissions('wallet');
  return (
    <Styled.ActionSection>
      {walletPermissions.canAccessPage && <ShowWalletAction driverId={props.driver.id} />}
      {/* {isAvailable.showRouteAction && <ShowRouteAction orderId={props.order.id} />}
      {isAvailable.onHoldHistory && <ShowOnHoldHistoryAction onHoldHistory={props.order.onHold.history} />}*/}
    </Styled.ActionSection>
  );
}
