import { SelectProps } from '@mui/material/Select';

export namespace MuiHandlers {
  export namespace Select {
    export function onChange<Value = string>(realHandler: (value: Value) => void): SelectProps['onChange'] {
      return function (e) {
        const value: any = e.target.value;
        realHandler(value);
      };
    }
  }
}


