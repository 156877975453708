import { useTranslation } from 'app/translations/useTranslation';
import { MiscData, MiscDataType } from 'pages/Orders/model/Order';
import * as Styled from '../../styled';
import { DeliveredProductList } from './components/DeliveredProductList';
import { IqosProductList } from './components/IqosProductList';
import { PackageProductList } from './components/PackageProductList';
import { VehicleProductList } from './components/VehicleProductList';

interface Props {
  miscData: MiscData;
}

export function MiscDataSubsection(props: Props) {
  const { t } = useTranslation('order');
  switch (props.miscData.type) {
    case MiscDataType.Iqos:
      return (
        <>
          <Styled.Subsection>
            <Styled.Header>{t('labels.miscData.products')}:</Styled.Header>
            {props.miscData.products.length > 0 ? (
              <IqosProductList products={props.miscData.products} />
            ) : (
              t('list.values.none')
            )}
          </Styled.Subsection>
          <Styled.Subsection>
            <Styled.Header>{t('labels.miscData.deliveredProducts')}:</Styled.Header>
            {props.miscData.deliveredProducts.length > 0 ? (
              <DeliveredProductList deliveredProducts={props.miscData.deliveredProducts} />
            ) : (
              t('list.values.none')
            )}
          </Styled.Subsection>
        </>
      );
    case MiscDataType.SimpleDelivery:
    case MiscDataType.PickupDelivery:
      return (
        <>
          <Styled.Subsection>
            <Styled.Header>{t('labels.miscData.products')}:</Styled.Header>
            {props.miscData.products.length > 0 ? (
              <PackageProductList products={props.miscData.products} />
            ) : (
              t('list.values.none')
            )}
          </Styled.Subsection>
          <Styled.Subsection>
            <Styled.Header>{t('labels.miscData.deliveredProducts')}:</Styled.Header>
            {props.miscData.deliveredProducts.length > 0 ? (
              <DeliveredProductList deliveredProducts={props.miscData.deliveredProducts} />
            ) : (
              t('list.values.none')
            )}
          </Styled.Subsection>
        </>
      );
      /*TODO: Change implementation as delivery type should not be directly correlated with product type*/
    case MiscDataType.SimpleArrival:
      return (
        <>
          <Styled.Subsection>
            <Styled.Header>{t('labels.miscData.products')}:</Styled.Header>
            {props.miscData.products.length > 0 ? (
              <VehicleProductList products={props.miscData.products} />
            ) : (
              t('list.values.none')
            )}
          </Styled.Subsection>
          <Styled.Subsection>
            <Styled.Header>{t('labels.miscData.deliveredProducts')}:</Styled.Header>
            {props.miscData.deliveredProducts.length > 0 ? (
              <DeliveredProductList deliveredProducts={props.miscData.deliveredProducts} />
            ) : (
              t('list.values.none')
            )}
          </Styled.Subsection>
        </>
      );
    default:
      console.error(`MiscData is not supported ${JSON.stringify(props.miscData)}`);
      return <></>;
  }
}
