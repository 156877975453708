import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Card } from '@mui/material';

import Page from 'components/Page/Page';
import { OrderList } from './components/OrderList/OrderList';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { Link as RouterLink } from 'react-router-dom';
import { AppRoutes, navigate } from 'app/Navigation';
import { useAuth } from '../../../services/authorization';
interface Props {}

export function OrderPage(props: Props) {
  const { t } = useTranslation(['order', 'register']);

  const { displayAccountCreationSuccessMessage } = useAuth();
  return (
    <Page>
      {displayAccountCreationSuccessMessage && (
        <Alert severity="success">
          <AlertTitle>{t('register:initialRegistrationCompleted.successAlert.title')}</AlertTitle>
          <Trans t={t} i18nKey="register:initialRegistrationCompleted.successAlert.body">
            <Link component={RouterLink} to={AppRoutes.settings.root()} />
          </Trans>
        </Alert>
      )}

      <Page.Header>{t('list.title')}</Page.Header>
      <Card sx={{ overflow: 'visible' }}>
        <OrderList />
      </Card>
    </Page>
  );
}
