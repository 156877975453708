import { styled } from '@mui/material/styles';
import { useTranslation } from 'app/translations/useTranslation';
import { Loader } from 'assets/Loader/Loader';
import { SthWentWrong } from 'components/Statements/SthWentWrong';
import { createLeafletMapMarker } from 'externals/Leaflet/createMapMarker';
import { Marker, MarkerType, Route, Coordinates } from 'pages/Orders/model/Route';
import { useXDelivererRoute } from 'pages/Orders/OrderPage/state/useShowXDelivererRoute';
import { forwardRef } from 'react';
import * as Leaflet from 'react-leaflet';
import { AsyncStatus } from 'utlis/State';

interface Props {
  orderId: string;
}

function getMarkerHueRotate(marker: Marker): number {
  switch (marker.type) {
    case MarkerType.LastLocation:
      return 0;
    case MarkerType.Destination:
      return 150;
    case MarkerType.StartLocation:
      return 260;
    case MarkerType.PickupPoint:
      return 70;
    default:
      console.warn(`Marker type: "${marker.type}" is not supported`);
      return 30;
  }
}

function getRouteCenterLocation(route: Route): Coordinates | null {
  const lastLocation = route.markers.find((marker) => marker.type === MarkerType.LastLocation);
  if (lastLocation !== undefined) {
    return lastLocation.location;
  }
  const destination = route.markers.find((marker) => marker.type === MarkerType.Destination);
  if (destination !== undefined) {
    return destination.location;
  }
  return null;
}

export const RouteMap = forwardRef<any, Props>((props: Props, ref) => {
  const { orderId } = props;
  const { route, retryFetching } = useXDelivererRoute(orderId);
  const { t } = useTranslation('order');

  if (route.status === AsyncStatus.Pending || route.status === AsyncStatus.NotStarted) {
    return (
      <Box ref={ref}>
        <LoaderBox>
          <Loader />
        </LoaderBox>
      </Box>
    );
  }

  if (route.status === AsyncStatus.Success) {
    const centerLocation = getRouteCenterLocation(route.data);

    if (centerLocation === null) {
      return <Box>{t('route.noHistory')}</Box>;
    }

    return (
      <Box ref={ref}>
        <MapContainer center={centerLocation} zoom={11} scrollWheelZoom={true}>
          <Leaflet.Polyline positions={route.data.history} />
          {route.data.markers.map((marker) => (
            <Leaflet.Marker
              position={marker.location}
              icon={createLeafletMapMarker({ hueRotate: getMarkerHueRotate(marker) })}
            >
              <Leaflet.Popup closeButton={false}>{t(`route.${marker.type}`)}</Leaflet.Popup>
            </Leaflet.Marker>
          ))}
          <Leaflet.TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      </Box>
    );
  }

  return (
    <Box>
      <SthWentWrong onRefresh={retryFetching} />
    </Box>
  );
});

const Box = styled('div')(
  ({ theme }) => `
  width: 800px;
  height: 100%;
  padding: 50px;
  background: ${theme.colors.primary.background};
  border-radius: 5px;
`,
);

const LoaderBox = styled('div')`
  height: 100px;
  width: 100px;
  margin: 0 auto;
`;

const MapContainer = styled(Leaflet.MapContainer)`
  height: 100%;
`;
