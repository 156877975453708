import { useTranslation } from 'app/translations/useTranslation';
import React, { useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { CompanyDataForm } from './CompanyData/CompanyDataForm';
import Alert from '@mui/material/Alert';
import { ActivationStatus } from './Contract/ActivationStatus';
import { GenerateContract } from './Contract/GenerateContract';
import { styled } from '@mui/material/styles';
import { useCompanies, useCompany } from '../../../services/companies/state/useCompanies';

interface Props {}

export function ContractDataTab(props: Props) {
  const { t } = useTranslation('settings');
  const { company } = useCompanies();
  return (
    <Grid container>
      <Grid item>
        <GenerateContract company={company} />
        <StyledDivider />
      </Grid>
      <Grid item container>
        {!company?.contractData && (
          <Grid item xs={12}>
            <ActivationStatus />
          </Grid>
        )}

        <Grid item xs={12}>
          <CompanyDataForm />
        </Grid>
      </Grid>
    </Grid>
  );
}

const StyledDivider = styled(Divider)`
  margin: 10px;
`;
