import { styled } from '@mui/material/styles';
import { PrimaryButton } from 'components/Button/Buttons';
import { Input as FormInput } from 'components/ReactHookForm/Input/Input';
import { Select as FormSelect } from 'components/ReactHookForm/Select/Select';
import { Divider, Grid } from '@mui/material';

export const Box = styled('div')(
  ({ theme }) => `
  margin-top: ${theme.spacing(5)};
  width: 100%;
  padding: ${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(5)};
 
  border-radius: 3px;
  display: flex;
  flex-direction: column;
`,
);

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
`;

export const FormDivider = styled(Divider)`
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 10px;
`;

export const RegisterButton = styled(PrimaryButton)(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)};
`,
);

export const Input = styled(FormInput)(
  ({ theme }) => `
  margin: 0;
  width: 100%;
`,
);

export const Select = styled(FormSelect)(
  ({ theme }) => `
    margin: ${theme.spacing(4)};
    width: 100%;
  `,
);
export const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;
export const FieldContainer = styled(Grid)`
  width: 100%;
  /*padding: 20px;*/
  display: flex;
  align-items: center;
`;

export const FieldsContainer = styled(Grid)`
  padding: 20px;
  /*margin-left: 0;
  margin-top: 0;
  width: 100%;*/
`;
