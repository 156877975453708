import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import { useCompanies } from 'services/companies/state/useCompanies';
import { AppConfig } from 'app/Config';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import useMUIDataTableDefaultOptions from 'utlis/MUIDataTables/useMUIDataTableDefaultOptions';
import { navigate } from 'app/Navigation';
import { LinkFactory } from '../index';
import { Card } from '@mui/material';

// Props type
// type Props = {};

// Component
const CompaniesList = (/*props: Props*/) => {
  const { t } = useTranslation(['company']);

  const { companies, refreshCompanies, fetchCompaniesState } = useCompanies();

  useEffect(() => {
    refreshCompanies();
  }, [refreshCompanies]);

  const defaultOptions = useMUIDataTableDefaultOptions();
  const options = useMemo<MUIDataTableOptions>(
    () => ({
      ...defaultOptions,
      onRowClick: (rowData, rowMeta) => {
        navigate(LinkFactory.company(companies[rowMeta.dataIndex].id));
      },
    }),
    [defaultOptions, companies],
  );
  //asdasd
  const columns = useMemo<MUIDataTableColumn[]>(
    () => [
      {
        name: 'status',
        label: t('labels.status'),
      },
      {
        name: 'imageFile',
        label: t('labels.logo'),
        options: {
          customBodyRender: (value: string | undefined) =>
            !!value && <Image src={`${AppConfig.backendUrl}/static/images/${value}`} />,
          sort: false,
        },
      },
      { name: 'name', label: t('labels.name') },
      { name: 'countryCode', label: t('labels.countryCode') },
      {
        name: 'createdAt',
        label: t('labels.createdAt'),
        options: {
          filter: false,
          customBodyRender: (createdAt: number) => {
            const date = new Date(createdAt);
            return (
              <>
                {t('common:datetime.datetime', {
                  date,
                })}
              </>
            );
          },
        },
      },
      { name: 'id', label: t('labels.id') },
    ],
    [t],
  );

  return (
    companies && (
      <Card>
        <MUIDataTable title="" data={companies} columns={columns} options={options} />
      </Card>
    )
  );
};

const Image = styled('img')({
  height: '1.2rem',
});

export default CompaniesList;
