import { sortBy } from 'lodash';
import { startOfDay, endOfDay } from 'date-fns';

type CalendarDateRange = [Date, Date | null];
export class CalendarDates {
  private readonly dates: CalendarDateRange;

  public constructor(dates: CalendarDateRange) {
    const [startDate, endDate] = sortBy(dates) as CalendarDateRange;
    this.dates = [
      startOfDay(startDate),
      endDate !== null ? endOfDay(endDate) : null,
    ];
  }

  public pickNewDate(pickedDate: Date): CalendarDates {
    const [startDate, endDate] = this.dates;
    if (endDate === null) {
      return new CalendarDates([startDate, pickedDate]);
    } else {
      return new CalendarDates([pickedDate, null]);
    }
  }

  public getDates(): CalendarDateRange {
    return this.dates;
  }

  public toArray(): Date[] {
    return this.dates.filter(date => date !== null) as Date[];
  }
}
