import { useTranslation } from 'app/translations/useTranslation';
import { round } from 'lodash';
import { OrderEstimations } from 'pages/Orders/model/Order';
import * as Styled from '../styled';
import { Multiplier } from '../../../../../../../../../MultiplierPage/model/Multiplier';

interface Props {
  estimations: OrderEstimations;
  multiplier?: Multiplier;
}

export function EstimationsSubsection(props: Props) {
  const { t } = useTranslation('order');

  return (
    <Styled.Subsection>
      <Styled.Header>{t('labels.estimations')}:</Styled.Header>
      {props.estimations.payout !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.amount')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {props.estimations.payout}{' '}
            {t(`labels.currencies.${props.estimations.currency ?? 'pln'}`)}
          </Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.estimations.payoutDriver !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.amountDriver')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {props.estimations.payoutDriver}{' '}
            {t(`labels.currencies.${props.estimations.currency ?? 'pln'}`)}
          </Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.multiplier && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.multiplierDriver')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            x{props.multiplier.multiplier}{' '}
            {`${props.multiplier.deactivatedAt ? `(${t('labels.multiplierDeactivated')})` : ``}`}
          </Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.estimations.distance !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.distance')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {round(props.estimations.distance / 1000, 2)}
            {' km'}
          </Styled.RecordValue>
        </Styled.RecordBox>
      )}
      {props.estimations.payable !== undefined && (
        <Styled.RecordBox>
          <Styled.RecordLabel>{t('labels.payable')}:</Styled.RecordLabel>
          <Styled.RecordValue>
            {props.estimations.payable
              ? t('cancelOrder.form.values.payable.fullPayment')
              : t('cancelOrder.form.values.payable.noPayment')}
          </Styled.RecordValue>
        </Styled.RecordBox>
      )}
    </Styled.Subsection>
  );
}
