import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { AppConfig } from '../Config';
import { resources } from './resources';
import { StoredLanguage } from './StoredLanguage';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: AppConfig.env === 'development',
    defaultNS: 'common',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    resources,
    react: {
      useSuspense: false,
    },
    detection: {
      lookupLocalStorage: StoredLanguage.key,
    },
    /**
     * Thusly we can extract from i18n.languages[0] the current language without country suffix (eg. en, not en-US)
     */
    load: 'languageOnly',
    appendNamespaceToMissingKey: true,
    simplifyPluralSuffix: false,
  });
