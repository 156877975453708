import { configureStore, isPlain } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';
import { createRootReducer } from './RootReducer';
import { States } from './States';

function* rootSaga() {
  yield all(States.map(state => state.saga));
}

function getStateMiddlewares() {
  return States.filter(state => state.middleware !== undefined).map(state => state.middleware);
}

function configureAppStore() {
  const sagaMiddleware = createSagaMiddleware();
  const stateMiddlewares = getStateMiddlewares();

  const store = configureStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        /**
         * It's been done on purpose - for convenience. Read more about potential drawbacks.
         * @see https://github.com/reduxjs/redux-toolkit/issues/456
         */
        isSerializable: (value: any) => {
          return isPlain(value) || value instanceof Date;
        },
      },
    }).concat([sagaMiddleware, ...stateMiddlewares]),
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureAppStore();
