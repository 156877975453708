import React, { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

interface Props {
  label?: string | ReactElement;
  name: string;
  className?: string;
  dataCy?: string;
  value?: string | true;
  defaultValue?: boolean;
  disabled?: boolean;
  shouldUnregister?: boolean;
  error?: any;
}

export const Checkbox = React.forwardRef<any, Props>(
  (
    {
      label = '',
      name,
      className,
      value = true,
      defaultValue,
      disabled,
      shouldUnregister,
      dataCy,
      error,
    },
    _ref,
  ) => {
    const { control, formState } = useFormContext();
    const hasError = !!formState.errors[name] || !!error;
    const hTexts = !hasError ? '' : formState.errors[name].message;
    return (
      <div className={className}>
        <Controller
          name={name}
          control={control}
          shouldUnregister={shouldUnregister}
          render={({ field }) => {
            return (
              <FormControl className={className} error={hasError} id={name}>
                <MuiFormControlLabel
                  control={
                    <MuiCheckbox
                      {...field}
                      disabled={disabled}
                      checked={!!field.value}
                      onChange={(e) => {
                        if (e.target.checked) {
                          field.onChange(value);
                        } else {
                          field.onChange(false);
                        }
                      }}
                    />
                  }
                  label={label ?? ''}
                  data-cy={dataCy}
                />
                {hTexts && <FormHelperText>{hTexts}</FormHelperText>}
              </FormControl>
            );
          }}
        />
      </div>
    );
  },
);
